<template>
    <div class="h-a-btn-circle">
        <router-link :to="link" class="btn-circle-link">
            <div class="btn-circle-content">
                <i class="icon" v-if="iconClass" v-bind:class="iconClass"></i>
                <slot name="icon" v-if="!iconClass"></slot>
                <span class="title text-truncate" v-show="title">{{ title }}</span>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "ButtonCircle",
        props: {
            title: {
                type: String,
                default: ""
            },
            link: {
                type: String,
                default: "#"
            },
            iconClass: {
                type: String,
                default: ""
            },
        }
    }
</script>

<style lang="stylus">
    .h-a-btn-circle
        .btn-circle-link
            position relative
            display block
            width 100%
            padding-bottom 100%
            border-radius 50%
            background-color rgba(#ACACAC, .12)
            color $primary
            transition background .3s ease, color .3s ease

            &:hover
                color white
                background-color $primary

        .btn-circle-content
            absolute top 0 left 0
            size 100%
            padding 12px
            display flex
            flex-direction column
            justify-content center
            align-items center
            font-size .875rem
            font-weight 700

        .icon, svg, .title
            display block

        .icon
            margin-top -.125rem
            font-size 2rem

        svg
            width auto
            height 2rem
            fill currentColor

        .title
            max-width 100%
            margin-top .125rem
</style>