<template>
    <div class="h-o-notification-area">
        <template v-for="(item, index) in notifications">
            <b-alert :key="`notification-${index}`" :variant="getVariant(item.type)" :show="getTimeout(item.timeout)" @dismissed="$emit('dismissed', item)">
                {{ item.message }}
            </b-alert>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            notifications: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            getVariant(type) {
                return type === 'error' ? 'danger' : type;
            },
            getTimeout(timeout) {
                return (typeof timeout === 'undefined' || !timeout) ? true : (timeout / 1000);
            }
        }
    }
</script>

<style lang="stylus">
    .h-o-notification-area
        position fixed
        top 0
        right 0
        z-index 100
        width 280px
        margin-top calc(1rem + 50px)
        margin-right 1.5rem
        padding 1.5rem 0

        .alert
            margin-bottom .5rem
            padding 0.5rem 0.75rem
</style>