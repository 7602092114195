<template>
    <b-form-checkbox value="1" unchecked-value="0" v-model="isChecked" @input="$emit('input', isChecked)">
        <slot name="pre-text">Eu li e concordo com os </slot>
        <a href="https://hostoo.io/legal/termos" target="_blank">Termos</a> e
        <a href="https://hostoo.io/legal/politica-de-privacidade" target="_blank">Política de Privacidade</a>
        <slot name="post-text"> da Hostoo.</slot>
    </b-form-checkbox>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                default: '0'
            }
        },
        data() {
            return {
                isChecked: false
            }
        },
        mounted() {
            this.isChecked = this.value;
        },
        watch: {
            value: function (val) {
                this.isChecked = val;
            }
        }
    }
</script>