export default {
    data() {
        return {
            apps: [
                { name: "wordpress",   title: "Wordpress",   category: "CMS"        },
                { name: "adminhost",   title: "Admin Host",  category: "Revenda"    },
                { name: "joomla",      title: "Joomla",      category: "CMS"        },
                { name: "drupal",      title: "Drupal",      category: "CMS"        },
                { name: "magento",     title: "Magento",     category: "E-commerce" },
                { name: "prestashop",  title: "Prestashop",  category: "E-commerce" },
                { name: "opencart",    title: "Opencart",    category: "E-commerce" },
                { name: "whmcs",       title: "WHMCS",       category: "E-commerce" },
                { name: "mautic",      title: "Mautic",      category: "Marketing"  },
                { name: "mediawiki",   title: "MediaWiki",   category: "Wiki"       },
                { name: "phpbb",       title: "phpBB",       category: "Fórum"      },
                { name: "moodle",      title: "Moodle",      category: "Educacional"},
                { name: "laravel",     title: "Laravel",     category: "Framework"  },
                { name: "codeigniter", title: "CodeIgniter", category: "Framework"  },
            ],
        }
    }
}
