import { Money }    from "v-money";
import { Api }      from '@/plugins/api'
import ErrorService from '@/services/error.service'

const AskGroupForm = {
    props: {
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        Money
    },
    data() {
        return {
            group: '',
            groupText: '',
            websites: '',
            budget: '',
            origin: '',
            originText: '',
        }
    },
    computed: {
        isSubmitDisabled: function () {
            return (!this.group || !this.websites || !this.budget || !this.origin || (this.group === 'other' && !this.groupText) || (this.origin === 'other' && !this.originText));
        }
    },
    methods: {
        save()
        {
            this.$refs.btnSave.disabled = true;
            this.$refs.btnSave.classList.add('btn-loading');

            let data = {
                group: this.group,
                websites: this.websites,
                budget: this.budget,
                origin: this.origin,
            };

            data.group = data.group === 'other' ? this.groupText : data.group;
            data.origin = data.origin === 'other' ? this.originText : data.origin;

            Api.post('/user/group', data)
                .then(response => {
                    this.$emit('saved');
                })
                .catch(error => {
                    ErrorService.handleFormError(error, this);
                    this.$refs.btnSave.disabled = false;
                })
                .then(() => {
                    this.$refs.btnSave.classList.remove('btn-loading');
                });
        }
    },
    template: `
        <form @submit.prevent="save">
            <h3 class="font-family-title font-weight-bold text-success text-center text-truncate mb-6">{{ name }}</h3>
            <p class="text-justify">
                Aqui na Hostoo estamos sempre buscando oferecer uma <strong>melhor experiência</strong> a nossos usuários.
                Para nos auxiliar nesta tarefa, por favor, responda apenas 3 perguntas rápidas que nos ajudarão a lhe 
                fornecer os <strong>melhores serviços</strong>.
            </p>
            
            <p class="text-primary font-weight-semibold mb-2">
                <i class="fas fa-caret-right mr-1"></i> Qual dos perfis abaixo melhor combina com você?
            </p>
            
            <b-form-radio-group v-model="group" stacked>
                <b-form-radio value="Desenvolvedor">Desenvolvedor, Programador, Freelancer</b-form-radio>
                <b-form-radio value="Designer">Designer, Ilustrador, Analista UX/UI</b-form-radio>
                <b-form-radio value="Agência">Agência Web, Agência de Marketing</b-form-radio>
                <b-form-radio value="Revenda">Revenda de Sites</b-form-radio>
                <b-form-radio value="other">Outro</b-form-radio>
            </b-form-radio-group>
            
            <div class="mt-4" v-show="group === 'other'">
                <p class="text-primary font-weight-semibold mb-2">
                    <i class="fas fa-caret-right mr-1"></i> Poderia informar outro perfil que melhor se adeque à você?
                </p>
                <input type="text" class="form-control" v-model="groupText">
            </div>
            
            <div class="mt-4" v-show="group">
                <p class="text-primary font-weight-semibold mb-2">
                    <i class="fas fa-caret-right mr-1"></i> Quantos sites ou hospedagens você possui ou gerencia atualmente?
                </p>
                <input type="number" class="form-control" min="0" v-model="websites" placeholder="Ex: 5">
            </div>

            <div class="mt-4" v-show="websites">
                <p class="text-primary font-weight-semibold mb-2">
                    <i class="fas fa-caret-right mr-1"></i> Quanto você está investindo atualmente em hospedagem para sites?
                </p>
                <money
                    class="form-control"
                    placeholder="R$ 100,00"
                    autocomplete="off"
                    v-model="budget"
                    v-bind="{
                        decimal: ',',
                        thousands: '.',
                        precision: 2,
                        masked: true,
                        prefix: 'R$ ',
                    }">
                </money>
            </div>

            <div class="mt-4" v-show="budget && budget != 'R$ 0,00'">
                <p class="text-primary font-weight-semibold mb-2">
                    <i class="fas fa-caret-right mr-1"></i> Como você conheceu a Hostoo e começou a usar nosso serviço?
                </p>

                <b-form-radio-group class="d-lg-flex" v-model="origin">
                    <b-form-radio class="d-block d-lg-inline-flex flex-grow-1 mb-0 mr-2" value="facebook">Facebook</b-form-radio>
                    <b-form-radio class="d-block d-lg-inline-flex flex-grow-1 mb-0 mr-2" value="google">Google</b-form-radio>
                    <b-form-radio class="d-block d-lg-inline-flex flex-grow-1 mb-0 mr-2" value="instagram">Instagram</b-form-radio>
                    <b-form-radio class="d-block d-lg-inline-flex flex-grow-1 mb-0 mr-2" value="youtube">Youtube</b-form-radio>
                    <b-form-radio class="d-block d-lg-inline-flex flex-grow-1 mb-0 mr-2" value="recommendation">Indicação</b-form-radio>
                    <b-form-radio class="d-block d-lg-inline-flex flex-grow-1 mb-0 mr-2" value="other">Outro</b-form-radio>
                </b-form-radio-group>
            </div>

            <div class="mt-4" v-show="origin === 'other'">
                <p class="text-primary font-weight-semibold mb-2">
                    <i class="fas fa-caret-right mr-1"></i> Poderia informar por qual outro meio você nos conheceu?
                </p>
                <input type="text" class="form-control" v-model="originText">
            </div>
            
            <div class="form-buttons text-center">
                <button ref="btnSave" type="submit" class="btn btn-success btn-lg px-8" :disabled="isSubmitDisabled">Enviar</button>
            </div>
        </form>
    `
}

const survey = {
    data() {
        return {
            $_survey_modal_id: '',
        }
    },
    methods: {
        /**
         * Exibir modal com questionário para o usuário sobre o grupo de cliente.
         */
        showAskGroupModal(name)
        {
            const html = this.$createElement(AskGroupForm, {
                props: {
                    name: name
                },
                on: {
                    saved: this.closeAskGroupModal
                },
            });

            this.$_survey_modal_id = 'modal-survey-' + Math.round(Math.random() * 1000000);

            this.$bvModal.msgBoxOk([html],
                {
                    id: this.$_survey_modal_id,
                    modalClass: 'modal-survey',
                    size: 'lg',
                    centered: true,
                    hideHeader: true,
                    hideHeaderClose: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    footerClass: 'd-none',
                    title: 'Teste',
                })
                .then (value => {})
                .catch(error => { /* ignore */} );
        },

        /**
         * Fechar modal do questionário sobre o grupo de cliente.
         */
        closeAskGroupModal()
        {
            this.$bvModal.hide(this.$_survey_modal_id);
            this.$eventBus.$emit('ask-group-survey-completed');
        }
    }
}

export default survey