<template>
    <div class="h-m-banner-clock">
        <div class="h-m-banner-clock--box">
            <span class="h-m-banner-clock--border"></span>
            <span id="bf-time-days" class="h-m-banner-clock--number">{{ formatTimeNumber(clockTime.days) }}</span>
            <span class="h-m-banner-clock--label">dias</span>
        </div>
        <div class="h-m-banner-clock--separator">:</div>
        <div class="h-m-banner-clock--box">
            <span class="h-m-banner-clock--border"></span>
            <span id="bf-time-hours" class="h-m-banner-clock--number">{{ formatTimeNumber(clockTime.hours) }}</span>
            <span class="h-m-banner-clock--label">horas</span>
        </div>
        <div class="h-m-banner-clock--separator">:</div>
        <div class="h-m-banner-clock--box">
            <span class="h-m-banner-clock--border"></span>
            <span id="bf-time-minutes" class="h-m-banner-clock--number">{{ formatTimeNumber(clockTime.minutes) }}</span>
            <span class="h-m-banner-clock--label">minutos</span>
        </div>
        <div class="h-m-banner-clock--separator">:</div>
        <div class="h-m-banner-clock--box">
            <span class="h-m-banner-clock--border"></span>
            <span id="bf-time-seconds" class="h-m-banner-clock--number">{{ formatTimeNumber(clockTime.seconds) }}</span>
            <span class="h-m-banner-clock--label">segundos</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            time: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                clockInterval: null,
                clockExpiration: this.time,
                clockTime: {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                },
            }
        },
        created()
        {
            this.calculateTime();
            this.clockInterval = setInterval(this.calculateTime, 1000);
        },
        beforeDestroy()
        {
            this.clockInterval && clearInterval(this.clockInterval);
        },
        methods: {

            calculateTime()
            {
                let expiration = 0;

                if (this.clockExpiration && this.clockExpiration > 0) {
                    expiration = --this.clockExpiration;
                }

                this.clockTime.days    = Math.floor(expiration / (3600 * 24));
                this.clockTime.hours   = Math.floor(expiration % (3600 * 24) / 3600);
                this.clockTime.minutes = Math.floor(expiration % 3600 / 60);
                this.clockTime.seconds = Math.floor(expiration % 60);
            },

            formatTimeNumber(value)
            {
                if (value >= 10) return value;
                else return '0' + value;
            }
        }
    }
</script>

<style lang="stylus">
    .h-m-banner-clock {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .h-m-banner-clock--box {
        position: relative;
    }
    .h-m-banner-clock--number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        text-align: center;
        font-size: 1.25rem;
        line-height: 1;
        border-radius: 5px;
        color: white;
        position: relative;
        z-index: 10;
        margin: 2px;
        background: #222;
    }
    .h-m-banner-clock--border {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        background: linear-gradient(180deg, #5900FF 0%, #FB3640 100%);
    }
    .h-m-banner-clock--label {
        display: block;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color: white;
        margin-top: 4px;
    }
    .h-m-banner-clock--separator {
        margin: 0 2px;
        padding-bottom: 1rem;
        font-size: 1.25rem;
        color: white;
    }
</style>
