import { Api } from '@/plugins/api'

/**
 * @param {string|Number} id            Job ID.
 * @param {Function} [successCallback]  Success callback.
 * @param {Function} [errorCallback]    Error callback.
 * @param {Object} [options]
 */
function check(id, successCallback, errorCallback, options)
{
    const onSuccess = successCallback || (() => {});
    const onError   = errorCallback || (() => {});
    const _options  = options || {};

    apiCheck(id, onSuccess, onError, _options);
}

function apiCheck(id, successCallback, errorCallback, options)
{
    Api.get(`/job/${id}`, options)
        .then(response => {
            const status = response.data.data.status;

            if (status === 'queued' || status === 'executing') {
                setTimeout(() => apiCheck(id, successCallback, errorCallback, options), 5000);
            }
            else {
                successCallback(response.data.data);
            }
        })
        .catch(error => {
            errorCallback(error);
        });
}

export default {
    check
}