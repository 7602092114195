<template>
    <admin-sidebar-template
        class="h-sidebar-hosting"
        sidebar-title="Hospedagens"
        sidebar-filter-by="domain"
        :sidebar-items="hostings"
        :sidebar-loading="isLoading"
    >
        <template slot="sidebar-list-item" slot-scope="{ item }">
            <icon-hosting-status class="mr-2" :status="item.status" :title="item.statusText" />
            <router-link class="h-list-link" :to="'/hosting/' + item.domain" :title="item.domain">{{ item.domain }}</router-link>
        </template>
        <announcement-area />
        <router-view />
    </admin-sidebar-template>
</template>

<script>
    import IconHostingStatus    from '@/components/atoms/IconHostingStatus'
    import AnnouncementArea     from '@/components/organisms/AnnouncementArea'
    import AdminSidebarTemplate from '@/components/templates/AdminSidebarTemplate'

    export default {
        components: {
            AnnouncementArea,
            IconHostingStatus,
            AdminSidebarTemplate
        },
        data() {
            return {
                hostings: [{
                    title: 'Hospedagens',
                    items: [],
                    addLink: '/hosting/add',
                    addLinkTitle: 'Criar nova hospedagem',
                }],
                isLoading: false
            }
        },
        mounted()
        {
            this.getHostings();
        },
        methods: {
            /**
             * Listar hospedagens.
             */
            getHostings()
            {
                this.isLoading = true;

                this.$api.get('/hosting')
                    .then (response => {
                        let data = response.data.data;
                        let hostings = [], shared = [];

                        data.forEach(item => {
                            item.status = item.status.toLowerCase();

                            if (item.isGuest) shared.push(item);
                            else hostings.push(item);
                        });

                        this.hostings[0].items = hostings;

                        if (shared.length) {
                            this.hostings.push({ "title": "Compartilhado", "items": shared });
                        }
                    })
                    .catch(() => {})
                    .then (() => this.isLoading = false);
            },
        }
    }
</script>

<style lang="stylus">
    .h-sidebar-hosting
        .h-o-admin-sidebar
            .h-a-icon-hosting-status
                display inline-block
                vertical-align middle
                margin-top -.125rem
                font-size .75rem
</style>