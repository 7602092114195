<template>
    <hosting-email-template :resource="resource">
        <card-table
            ref="cardEmails"
            inner-title="Contas de E-mail"
            add-text="Adicionar conta de e-mail"
            modal-title="Adicionar conta de e-mail"
            remove-title="Excluir conta de e-mail"
            :table-fields="tableEmailFields"
            :table-data="emails"
            :table-loading="isLoadingEmails"
            :api-save-url="`/hosting/${resource}/email`"
            table-max-height="calc((10 * (3rem + 1px)))"
            table-empty-text="Nenhuma conta de e-mail encontrada"
            hide-edit
            @created="fetchEmails"
            @remove="removeEmail"
        >
            <template slot="table-email" slot-scope="props">
                <span v-bind:class="{ 'font-italic text-muted': props.rowData.disabled }">{{ props.rowData.email }}</span>
                <span v-if="props.rowData.disabled" class="badge badge-warning ml-2">Desabilitado</span>
            </template>

            <template slot="table-size" slot-scope="props">
                {{ props.rowData.size | formatBytes(0) }}
            </template>

            <template slot="table-quota" slot-scope="props">
                <span v-if="props.rowData.quota">{{ props.rowData.quota | formatBytes(0) }}</span>
                <span v-else>Ilimitado</span>
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item-button @click="showChangePasswordModal(rowData)">
                    <i class="fas fa-key fa-fw"></i> Alterar senha
                </b-dropdown-item-button>
                <b-dropdown-item :to="`/hosting/${resource}/email/${encodeURIComponent(rowData.email)}`">
                    <i class="fas fa-cog fa-fw"></i> Configurações
                </b-dropdown-item>
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="E-mail" label-for="emailUsername" :error="formErrors.username">
                    <div class="input-group input-group-rounded">
                        <input ref="emailUsername" type="text" id="emailUsername" class="form-control" maxlength="40" v-model="formData.username">
                        <div class="input-group-append">
                            <span class="input-group-text">{{ '@' + resource }}</span>
                        </div>
                    </div>
                </form-group>

                <form-group label="Senha" label-for="emailPassword" :error="formErrors.password">
                    <password-generator id="emailPassword" autocomplete="new-password" v-model="formData.password" />
                </form-group>

                <form-group :error="formErrors.quota">
                    <label class="form-label mb-0" for="emailQuota">Limite de espaço (em megabytes)</label>
                    <p class="form-text-sm">
                        Deixe em branco ou com valor 0 para criar uma conta de e-mail sem limite de espaço.
                    </p>
                    <div class="input-group">
                        <input type="number" id="emailQuota" class="form-control" min="0" step="512" v-model="formData.quota">
                        <div class="input-group-append">
                            <span class="input-group-text">MB</span>
                        </div>
                    </div>
                </form-group>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir a conta de e-mail abaixo?</p>
                <ul><li>{{ item.email }}</li></ul>
                <div class="alert alert-warning mb-0">
                    <strong>Aviso:</strong> Ao excluir uma conta de e-mail todas as mensagens serão excluídas permanentemente.
                </div>
            </template>
        </card-table>

        <card-table
            ref="cardForwarders"
            inner-title="Encaminhadores de e-mail"
            add-text="Adicionar encaminhador"
            modal-title="Adicionar encaminhador de e-mail"
            remove-title="Excluir encaminhador de e-mail"
            :table-fields="tableForwardersFields"
            :table-data="forwarders"
            :table-loading="isLoadingForwarders"
            :form-data-transform="forwarderFormDataTransform"
            :api-save-url="`/hosting/${resource}/email`"
            table-max-height="calc((10 * (3rem + 1px)))"
            table-empty-text="Nenhum encaminhador de e-mail encontrado"
            hide-edit
            @created="fetchForwarders"
            @remove="removeForwarder"
        >
            <template slot="intro-text">
                Um encaminhador é um endereço de e-mail que redireciona todas as mensagens recebidas para um outro
                endereço de e-mail configurado e não ocupa espaço em disco em sua hospedagem.
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="Encaminhador" label-for="forwarderUsername" :error="formErrors.username">
                    <div class="input-group input-group-rounded">
                        <input ref="emailUsername" type="text" id="forwarderUsername" class="form-control" maxlength="40" v-model="formData.username">
                        <div class="input-group-append">
                            <span class="input-group-text">{{ '@' + resource }}</span>
                        </div>
                    </div>
                </form-group>

                <form-group label="E-mail de destino" label-for="forwarderForward" :error="formErrors.forward">
                    <input type="email" id="forwarderForward" class="form-control" spellcheck="false" v-model="formData.forward">
                </form-group>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir o encaminhador de e-mail abaixo?</p>
                <table class="table table-borderless table-sm mb-0">
                    <tbody>
                        <tr>
                            <th class="col-w-1 col-nowrap pl-0">Encaminhador:</th>
                            <td>{{ item.email }}</td>
                        </tr>
                        <tr>
                            <th class="col-w-1 col-nowrap pl-0">Destino:</th>
                            <td>{{ item.forward }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </card-table>

        <b-modal ref="modalChangePassword" title="Alterar senha" centered hide-footer hide-header-close @shown="$refs.changePasswEmail.focus()">
            <form @submit.prevent="changePassword">
                <form-group label="E-mail" label-for="changePasswEmail">
                    <input ref="changePasswEmail" type="text" id="changePasswEmail" class="form-control" autocomplete="off" :value="formData.email" readonly>
                </form-group>

                <form-group label="Nova senha" label-for="changePasswPassword" class="mb-0" :error="formErrors.password">
                    <password-generator id="changePasswPassword" autocomplete="new-password" v-model="formData.password" />
                </form-group>

                <div class="form-buttons text-right">
                    <button ref="btnChangePasswordCancel" type="button" class="btn btn-secondary px-6 mr-2" @click="$refs.modalChangePassword.hide()">Cancelar</button>
                    <button-form ref="btnChangePasswordSubmit" type="submit" theme="success" class="px-6">Salvar</button-form>
                </div>
            </form>
        </b-modal>
    </hosting-email-template>
</template>

<script>
    import { mapActions }       from 'vuex'
    import AjaxService          from '@/services/AjaxService'
    import ErrorService         from '@/services/error.service'
    import InputSearch          from '@/components/atoms/InputSearch'
    import Vuetable             from '@/components/atoms/Vuetable'
    import ButtonForm           from '@/components/atoms/ButtonForm'
    import ModalConfirm         from '@/components/atoms/ModalConfirm'
    import CardTitle            from '@/components/molecules/CardTitle'
    import TextCopy             from '@/components/molecules/TextCopy'
    import FormGroup            from '@/components/molecules/FormGroup'
    import PasswordGenerator    from '@/components/molecules/PasswordGenerator'
    import HostingEmailTemplate from '@/components/templates/HostingEmailTemplate'
    import CardTable from '@/components/organisms/CardTable'

    export default {
        props: ['resource'],
        components: {
            CardTable,
            ModalConfirm,
            ButtonForm,
            PasswordGenerator,
            FormGroup,
            TextCopy,
            InputSearch,
            Vuetable,
            CardTitle,
            HostingEmailTemplate
        },
        data() {
            return {
                tableEmailFields: [
                    {
                        name: '__slot:email',
                        sortField: 'email',
                        filterable: true,
                        title: 'E-mail',
                    },
                    {
                        name: '__slot:size',
                        sortField: 'size',
                        sortFunction: 'number',
                        title: 'Uso de Disco',
                        titleClass: 'col-w-28 col-nowrap text-right',
                        dataClass: 'col-w-28 col-nowrap text-right',
                    },
                    {
                        name: '__slot:quota',
                        sortField: 'quota',
                        sortFunction: 'number',
                        title: 'Limite',
                        titleClass: 'col-w-28 col-nowrap text-right',
                        dataClass: 'col-w-28 col-nowrap text-right',
                    }
                ],
                tableForwardersFields: [
                    {
                        name: 'email',
                        sortField: 'email',
                        filterable: true,
                        title: 'E-mail',
                        titleClass: 'w-50',
                        dataClass: 'w-50',
                    },
                    {
                        name: 'forward',
                        sortField: 'forward',
                        filterable: true,
                        title: 'Destino',
                        titleClass: 'w-50',
                        dataClass: 'w-50',
                    }
                ],

                emails: [],
                forwarders: [],

                formData: {},
                formErrors: {},

                isLoadingEmails: false,
                isLoadingForwarders: false,
                hasWebmailLink: false,
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.fetch();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert: 'alert/error'
            }),

            /**
             * Obter dados de e-mails e encaminhadores.
             */
            fetch()
            {
                this.isLoadingEmails = this.isLoadingForwarders = true;

                const params = { include: 'emails,forwarders,webmail' };

                this.$api.get(`/hosting/${this.resource}/email`, { params: params, cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.emails     = response.data.data.emails;
                        this.forwarders = response.data.data.forwarders;
                        this.createWebmailLink(response.data.data.webmail.url);
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then(() => this.isLoadingEmails = this.isLoadingForwarders = false)
            },

            /**
             * Criar botão com link de acesso ao webmail.
             */
            createWebmailLink(link)
            {
                if (link && !this.hasWebmailLink)
                {
                    const $btnToolbar = this.$refs.cardEmails.$el.querySelector('.btn-toolbar');
                    const $btnGroup   = $btnToolbar ? $btnToolbar.querySelector('.btn-group') : null;

                    if ($btnToolbar && $btnGroup)
                    {
                        this.hasWebmailLink = true;

                        const $newBtnGroup = document.createElement('div');
                        $newBtnGroup.classList.add('btn-group', 'ml-auto');

                        const $newBtn = document.createElement('a');
                        $newBtn.classList.add('btn', 'btn-secondary');
                        $newBtn.href = link;
                        $newBtn.target = '_blank';
                        $newBtn.innerHTML = 'Acessar webmail';

                        $newBtnGroup.appendChild($newBtn);
                        $btnToolbar.insertBefore($newBtnGroup, $btnGroup);

                        $btnGroup.classList.remove('ml-auto');
                        $btnGroup.classList.add('ml-2');
                    }
                }
            },

            fetchEmails()
            {
                this.isLoadingEmails = true;

                const params = { include: 'emails' };

                this.$api.get(`/hosting/${this.resource}/email`, { params: params })
                    .then (response => this.emails = response.data.data.emails)
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingEmails = false);
            },

            /**
             * Exibir modal para alteração de senha.
             */
            showChangePasswordModal(item)
            {
                this.formData = { 'email': item.email };
                this.formErrors = {};

                this.$refs.modalChangePassword.show();
            },

            /**
             * Alterar senha de uma conta de e-mail.
             */
            changePassword()
            {
                this.formErrors = {};

                this.$refs.btnChangePasswordSubmit.$el.focus();
                this.$refs.btnChangePasswordSubmit.setLoading(true);
                this.$refs.btnChangePasswordCancel.disabled = true;

                this.$api.post(`/hosting/${this.resource}/email/password`, this.formData)
                    .then (response => {
                        this.$refs.modalChangePassword.hide();
                        this.successNotification({ message: 'Senha alterada!' });
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnChangePasswordCancel.disabled = false;
                        this.$refs.btnChangePasswordSubmit.setLoading(false);
                    });
            },

            /**
             * Excluir uma conta de e-mail.
             */
            removeEmail(item)
            {
                const email = item.email;

                this.$api.delete(`/hosting/${this.resource}/email/${email}`)
                    .then (response => {})
                    .catch(error => {
                        error.message = 'Ocorreu um erro ao tentar excluir a conta de e-mail. Por favor, atualize sua página e tente novamente.';
                        this.showErrorAlert(error);
                    });
            },

            fetchForwarders()
            {
                this.isLoadingForwarders = true;

                const params = { include: 'forwarders' };

                this.$api.get(`/hosting/${this.resource}/email`, { params: params })
                    .then (response => this.forwarders = response.data.data.forwarders)
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingForwarders = false);
            },

            forwarderFormDataTransform(data)
            {
                data.forwarder = true;
                return data;
            },

            removeForwarder(item)
            {
                const data = {
                    'email':   item.email,
                    'forward': item.forward,
                };

                this.$api.post(`/hosting/${this.resource}/email/remove-forward`, data)
                    .then ((response) => {})
                    .catch((error) => {
                        error.message = 'Ocorreu um erro ao tentar excluir o encaminhador. Por favor, atualize sua página e tente novamente.';
                        this.showErrorAlert(error);
                    });
            },
        }
    }
</script>