<template>
    <admin-page-template class="h-p-hosting-restore">
        <card-table
            ref="cardHostings"
            title="Hospedagens excluídas"
            table-empty-text="Nenhuma hospedagem disponível para restauração"
            :table-fields="tableHostingsFields"
            api-url="/hosting/restore"
            hide-add
            hide-edit
            hide-remove
        >
            <template slot="table-created" slot-scope="{ rowData }">
                <span :title="$moment(rowData.createdAt).format('DD/MM/YYYY')">
                    {{ rowData.createdAt | moment("DD/MM/YYYY") }}
                </span>
            </template>

            <template slot="table-deleted" slot-scope="{ rowData }">
                <span :title="$moment(rowData.deletedAt).format('DD/MM/YYYY')">
                    {{ rowData.deletedAt | moment("DD/MM/YYYY") }}
                </span>
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item-button @click="openRestoreModal(rowData.username)">
                    <i class="fas fa-trash-restore-alt fa-fw"></i> Restaurar
                </b-dropdown-item-button>
            </template>
        </card-table>

        <b-modal
            ref="modalRestore"
            class="modal-restore"
            type="lg"
            content-class="p-0"
            body-class="p-0"
            centered
            hide-header
            hide-footer
            hide-header-close
            no-close-on-backdrop
            no-close-on-esc
        >
            <card-table
                ref="cardBackups"
                inner-title="Restaurar backup"
                hide-add
                hide-edit
                hide-filter
                hide-remove
                hide-dropdown
                table-empty-text="Nenhum backup encontrado"
                :api-url="`/hosting/${currentUsername}/restore`"
                :table-fields="tableBackupsFields"
            >
                <template slot="intro-text">
                    A restauração de backup possui uma <strong>taxa de R$ 25,00</strong>
                    que será descontada dos créditos em sua conta.
                </template>

                <template slot="table-checkbox" slot-scope="{ rowData }">
                    <b-form-radio-group stacked v-model="selectedBackup">
                        <b-form-radio inline :value="rowData.date">&nbsp;</b-form-radio>
                    </b-form-radio-group>
                </template>

                <template slot="table-date" slot-scope="{ rowData }">
                    {{ rowData.date | moment("DD/MM/YYYY") }}
                    <span v-if="rowData.date === $moment().format('YYYY-MM-DD')" class="text-muted pl-2">hoje</span>
                    <span v-else class="text-muted pl-2">
                        {{ rowData.date | moment("from", $moment().startOf('day')) }}
                    </span>
                </template>

                <template slot="table-size" slot-scope="{ rowData }">
                    {{ rowData.size | formatBytes(0) }}
                </template>

                <template slot="table-after">
                    <b-alert v-if="restoreErrorReason" variant="warning" dismissible show>
                        <div v-if="restoreErrorReason === 'domainUsed'">
                            Já existe uma hospedagem criada com este domínio.
                        </div>
                        <div v-else-if="restoreErrorReason === 'domainNotAvailable'">
                            O domínio já está sendo utilizado por outro usuário.
                        </div>
                        <div v-else-if="restoreErrorReason === 'noBalance'">
                            Você não possui créditos suficientes para restaurar a hospedagem.
                            Para realizar uma recarga,
                            <router-link to="/payment/manual-recharge?amount=25">clique aqui</router-link>.
                        </div>
                    </b-alert>

                    <div class="form-buttons text-right">
                        <button-form ref="btnRestoreSubmit" theme="success" :disabled="!selectedBackup" @click="restore">Restaurar</button-form>
                        <button ref="btnRestoreCancel" type="button" class="btn btn-secondary btn-action ml-2" @click="$refs.modalRestore.hide()">Cancelar</button>
                    </div>
                </template>
            </card-table>
        </b-modal>
    </admin-page-template>
</template>

<script>
    import CardTable from "@/components/organisms/CardTable.vue";
    import AdminPageTemplate from "@/components/templates/AdminPageTemplate.vue";
    import ButtonForm from "@/components/atoms/ButtonForm.vue";
    import {mapActions} from "vuex";

    export default {
        components: {
            ButtonForm,
            CardTable,
            AdminPageTemplate
        },
        data() {
            return {
                tableHostingsFields: [
                    {
                        name: 'domain',
                        sortField: 'domain',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Hospedagem'
                    },
                    {
                        name: '__slot:created',
                        sortField: 'createdAt',
                        sortFunction: 'text',
                        title: 'Criado em',
                        titleClass: 'col-datetime',
                        dataClass: 'col-datetime',
                    },
                    {
                        name: '__slot:deleted',
                        sortField: 'deletedAt',
                        sortFunction: 'text',
                        title: 'Excluído em',
                        titleClass: 'col-datetime',
                        dataClass: 'col-datetime',
                    },
                ],
                tableBackupsFields: [
                    {
                        name: '__slot:checkbox',
                        title: '',
                        titleClass: 'col-status col-w-12',
                        dataClass: 'col-status col-w-12',
                    },
                    {
                        name: '__slot:date',
                        sortField: 'date',
                        sortFunction: 'text',
                        title: 'Data'
                    },
                    {
                        name: '__slot:size',
                        sortField: 'size',
                        title: 'Tamanho',
                        titleClass: 'col-w-32',
                        dataClass: 'col-w-32',
                    }
                ],

                currentUsername: "",
                selectedBackup: null,

                restoreErrorReason: "",
            }
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error',
                successNotification: 'notification/success',
            }),

            openRestoreModal(username)
            {
                this.currentUsername = username;
                this.selectedBackup = null;
                this.restoreErrorReason = "";
                this.$refs.modalRestore.show();
            },

            restore()
            {
                this.restoreErrorReason = "";
                this.$refs.btnRestoreCancel.disabled = true;
                this.$refs.btnRestoreSubmit.loadingFocus();

                const postData = {
                    'date': this.selectedBackup
                };

                this.$api.post(`/hosting/${this.currentUsername}/restore`, postData)
                    .then((response) => {
                        this.$refs.modalRestore.hide();
                        this.successNotification({ message: 'Restauração iniciada com sucesso!' });

                        setTimeout(() => {
                            window.location.href = '/hosting/' + response.data.data.domain;
                        }, 3000);
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            const errorDataKeys = Object.keys(error.response.data.data);

                            this.restoreErrorReason = errorDataKeys.find((value) => {
                                return ["domainUsed", "domainNotAvailable", "noBalance"].indexOf(value) !== -1;
                            });
                        }

                        if (!this.restoreErrorReason) {
                            this.showErrorAlert(error);
                        }

                        this.$refs.btnRestoreCancel.disabled = false;
                        this.$refs.btnRestoreSubmit.setLoading(false);
                    });
            }
        }
    }
</script>

<style lang="stylus">

</style>