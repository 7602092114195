<template>
    <hosting-domain-template :resource="resource">
        <card-table
            ref="cardAlias"
            inner-title="Apontamentos"
            add-text="Adicionar domínio"
            modal-title="Adicionar apontamento"
            hide-edit
            :table-fields="tableAliasFields"
            :api-url="`/domain/${resource}/alias`"
            :data-transform="aliasDataTransform"
            table-empty-text="Nenhum apontamento encontrado"
            remove-title="Excluir apontamento"
            @remove="removeAlias"
        >
            <template slot="intro-text">
                Os apontamentos permitem que o seu site possa ser acessado utilizando domínios adicionais. Por exemplo, você
                pode fazer com que <strong>meudominio.net</strong> e <strong>meudominio.org</strong> exibam o conteúdo
                de <strong>meudominio.com</strong>.
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item :href="`http://${rowData.domain}`" target="_blank">
                    <i class="fas fa-external-link-alt fa-fw"></i> Acessar
                </b-dropdown-item>
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <div class="alert alert-warning">
                    <p class="mb-2">
                        Domínios devem estar registrados e configurados para apontar para os
                        seus servidores DNS antes de poderem ser utilizados como domínio adicional.
                    </p>
                    <p class="mb-0">
                        Os servidores DNS recomendados para sua hospedagem são exibidos na aba
                        <router-link :to="'/hosting/' + resource + '/domain'" target="_blank">
                            <strong>Domínio</strong> <i class="fas fa-external-link-alt ml-1" aria-hidden="true"></i>
                        </router-link>.
                    </p>
                </div>

                <form-group label="Domínio" label-for="aliasDomain" :error="formErrors.domain">
                    <input ref="aliasDomain" type="text" id="aliasDomain" class="form-control" spellcheck="false" v-model="formData.domain" v-input-lowercase>
                </form-group>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir o apontamento abaixo?</p>
                <p class="mb-0"><strong v-html="item.domain"></strong></p>
            </template>
        </card-table>
    </hosting-domain-template>
</template>

<script>
    import CardTable from "../../components/organisms/CardTable"
    import FormGroup from "../../components/molecules/FormGroup"
    import HostingDomainTemplate from "../../components/templates/HostingDomainTemplate"

    export default {
        props: ['resource'],
        components: {
            FormGroup,
            CardTable,
            HostingDomainTemplate

        },
        data() {
            return {
                tableAliasFields: [
                    {
                        name: 'domain',
                        sortField: 'domain',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Domínio'
                    }
                ]
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardAlias.cancelFetch();
            next();
        },
        methods: {
            /**
             * Transformar dados para exibição na tabela.
             */
            aliasDataTransform(data)
            {
                return data.map(value => {
                    return { domain: value };
                });
            },

            /**
             * Excluir domínio de apontamento.
             */
            removeAlias(item)
            {
                const domain = item.domain;

                this.$api.delete(`/domain/${this.resource}/alias/${domain}`)
                    .then (response => {})
                    .catch(error => alert(`Ocorreu um erro ao tentar excluir o domínio adicional. Por favor, atualize sua página e tente novamente.`));
            }
        }
    }
</script>