<template>
    <hosting-email-template :resource="resource">
        <!-- TODO: Utilizar o componente CardTable  -->
        <card-title inner-body>
            <div class="d-flex justify-content-between">
                <div class="flex-grow-1">
                    <h4 class="card-title-inner">Habilitar filtro antispam</h4>
                    <p class="mb-0">
                        O filtro antispam avalia as mensagens de e-mail recebidas e enviadas e classifica as mensagens
                        indesejadas como spam.
                    </p>
                </div>
                <div class="pl-3">
                    <div class="d-flex align-items-center mt-1">
                        <i class="fas fa-circle-notch fa-spin text-muted" v-bind:class="{ 'invisible': !isLoadingAntispamStatus }"></i>
                        <toggle-switch class="ml-3" v-model="antispam.status" :callback="updateAntispamStatus" :disabled="isLoadingAntispamStatus" />
                    </div>
                </div>
            </div>
        </card-title>

        <card-title v-show="antispam.status" inner-body>
            <h4 class="card-title-inner">Blacklist</h4>
            <p class="mb-6">
                Nesta seção você pode adicionar ou excluir endereços de e-mail que devem ser bloqueados pelo filtro antispam.
            </p>

            <div class="btn-toolbar mb-6" role="toolbar">
                <input-search v-model="blacklistFilterText" />
                <div class="btn-group ml-auto" role="group">
                    <button type="button" class="btn btn-success" @click="showAddEmailModal('blacklist')" :disabled="isLoadingBlacklist">Adicionar</button>
                </div>
            </div>

            <vuetable
                ref="tableBlacklist"
                table-class="table-custom-header"
                no-data-template="Nenhum endereço de e-mail encontrado"
                :api-mode="false"
                :data-manager="sortBlacklist"
                :fields="tableAntispamFields"
                :loading="isLoadingAntispam"
                :row-class="onRowClassBlacklist">

                <template slot="options" slot-scope="props">
                    <b-dropdown v-show="!props.rowData.isRemoving" variant="link" right no-caret>
                        <template v-slot:button-content>
                            <i class="fas fa-caret-down"/>
                        </template>
                        <b-dropdown-item-button variant="danger" @click="removeBlacklist(props.rowData)">
                            <i class="far fa-trash-alt fa-fw"></i> Excluir
                        </b-dropdown-item-button>
                    </b-dropdown>
                    <i v-show="props.rowData.isRemoving" class="fas fa-circle-notch fa-spin text-muted"></i>
                </template>
            </vuetable>
        </card-title>

        <card-title v-show="antispam.status" inner-body>
            <h4 class="card-title-inner">Whitelist</h4>
            <p class="mb-6">
                Nesta seção você pode adicionar ou excluir endereços de e-mail que devem ser tratados como origens
                seguras pelo filtro antispam. Mensagens enviadas por endereços de e-mail presentes na lista abaixo não
                serão consideradas como spam.
            </p>

            <div class="btn-toolbar mb-6" role="toolbar">
                <input-search v-model="whitelistFilterText" />
                <div class="btn-group ml-auto" role="group">
                    <button type="button" class="btn btn-success" @click="showAddEmailModal('whitelist')" :disabled="isLoadingWhitelist">Adicionar</button>
                </div>
            </div>

            <vuetable
                ref="tableWhitelist"
                table-class="table-custom-header"
                no-data-template="Nenhum endereço de e-mail encontrado"
                :api-mode="false"
                :data-manager="sortWhitelist"
                :fields="tableAntispamFields"
                :loading="isLoadingAntispam"
                :row-class="onRowClassWhitelist">

                <template slot="options" slot-scope="props">
                    <b-dropdown v-show="!props.rowData.isRemoving" variant="link" right no-caret>
                        <template v-slot:button-content>
                            <i class="fas fa-caret-down"/>
                        </template>
                        <b-dropdown-item-button variant="danger" @click="removeWhitelist(props.rowData)">
                            <i class="far fa-trash-alt fa-fw"></i> Excluir
                        </b-dropdown-item-button>
                    </b-dropdown>
                    <i v-show="props.rowData.isRemoving" class="fas fa-circle-notch fa-spin text-muted"></i>
                </template>
            </vuetable>
        </card-title>

        <b-modal ref="modalAddBlacklist" size="lg" title="Adicionar e-mail na blacklist" centered hide-footer hide-header-close @shown="$refs.blacklistEmail.focus()">
            <form @submit.prevent="addBlacklist">
                <p>
                    Informe abaixo o endereço de e-mail que você deseja adicionar na blacklist.
                    Também é possível inserir o endereço no formato <strong>*@dominio.com.br</strong>
                    para incluir todos os endereços de e-mail que pertencem a um mesmo domínio.
                </p>

                <form-group label="E-mail" label-for="blacklistEmail" :error="formErrors.email">
                    <input ref="blacklistEmail" type="text" id="blacklistEmail" class="form-control" spellcheck="false" v-model="email.email" v-input-lowercase>
                </form-group>

                <div class="form-buttons text-right">
                    <button ref="btnAddBlacklistCancel" type="button" class="btn btn-secondary px-6 mr-2" @click="$refs.modalAddBlacklist.hide()">Cancelar</button>
                    <button-form ref="btnAddBlacklistSubmit" type="submit" theme="success" class="px-6">Adicionar</button-form>
                </div>
            </form>
        </b-modal>

        <b-modal ref="modalAddWhitelist" size="lg" title="Adicionar e-mail na whitelist" centered hide-footer hide-header-close @shown="$refs.whitelistEmail.focus()">
            <form @submit.prevent="addWhitelist">
                <p>
                    Informe abaixo o endereço de e-mail que você deseja adicionar na whitelist.
                    Também é possível inserir o endereço no formato <strong>*@dominio.com.br</strong>
                    para incluir todos os endereços de e-mail que pertencem a um mesmo domínio.
                </p>

                <form-group label="E-mail" label-for="whitelistEmail" :error="formErrors.email">
                    <input ref="whitelistEmail" type="text" id="whitelistEmail" class="form-control" spellcheck="false" v-model="email.email" v-input-lowercase>
                </form-group>

                <div class="form-buttons text-right">
                    <button ref="btnAddWhitelistCancel" type="button" class="btn btn-secondary px-6 mr-2" @click="$refs.modalAddWhitelist.hide()">Cancelar</button>
                    <button-form ref="btnAddWhitelistSubmit" type="submit" theme="success" class="px-6">Adicionar</button-form>
                </div>
            </form>
        </b-modal>
    </hosting-email-template>
</template>

<script>
    import {mapActions}         from 'vuex'
    import AjaxService          from '@/services/AjaxService'
    import ErrorService         from '@/services/error.service'
    import ToggleSwitch         from '@/components/atoms/ToggleSwitch'
    import InputSearch          from '@/components/atoms/InputSearch'
    import Vuetable             from '@/components/atoms/Vuetable'
    import ButtonForm           from '@/components/atoms/ButtonForm'
    import CardTitle            from '@/components/molecules/CardTitle'
    import FormGroup            from '@/components/molecules/FormGroup'
    import HostingEmailTemplate from '@/components/templates/HostingEmailTemplate'

    export default {
        props: ['resource'],
        components: {
            ButtonForm,
            FormGroup,
            InputSearch,
            CardTitle,
            Vuetable,
            ToggleSwitch,
            HostingEmailTemplate
        },
        data() {
            return {
                tableAntispamFields: [
                    {
                        name: 'email',
                        sortField: 'email',
                        title: 'E-mail',
                    },
                    {
                        name: '__slot:options',
                        title: '',
                        titleClass: 'col-dropdown',
                        dataClass: 'col-dropdown',
                    }
                ],

                antispam: {},
                blacklist: [],
                whitelist: [],

                email: {},
                formErrors: {},

                blacklistFilterText: "",
                whitelistFilterText: "",

                isLoadingAntispam: false,
                isLoadingAntispamStatus: false,
                isLoadingBlacklist: false,
                isLoadingWhitelist: false
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getAntispam();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error',
                successNotification: 'notification/success',
            }),

            /**
             * Ordenação da tabela blacklist.
             */
            sortBlacklist(sortOrder)
            {
                if (!this.blacklist.length || this.isLoadingAntispam) return;

                let data = this.blacklist;

                if (sortOrder.length > 0)
                {
                    const field = sortOrder[0].sortField;

                    if (field === "email") {
                        data.sort((a,b) => { return sortOrder[0].direction === "asc" ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]); });
                    }
                }

                return data;
            },

            /**
             * Ordenação da tabela whitelist.
             */
            sortWhitelist(sortOrder)
            {
                if (!this.whitelist.length || this.isLoadingAntispam) return;

                let data = this.whitelist;

                if (sortOrder.length > 0)
                {
                    const field = sortOrder[0].sortField;

                    if (field === "email") {
                        data.sort((a,b) => { return sortOrder[0].direction === "asc" ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]); });
                    }
                }

                return data;
            },

            /**
             * Definição de classes das linhas da tabela.
             */
            onRowClassBlacklist(dataItem)
            {
                let _classes = [];

                if (dataItem.isRemoving) {
                    _classes.push("table-muted");
                }

                if (this.blacklistFilterText) {
                    const term = this.blacklistFilterText.toLowerCase();

                    if (dataItem.email.indexOf(term) === -1) {
                        _classes.push("d-none");
                    }
                }

                return _classes.join(" ");
            },

            /**
             * Definição de classes das linhas da tabela.
             */
            onRowClassWhitelist(dataItem)
            {
                let _classes = [];

                if (dataItem.isRemoving) {
                    _classes.push("table-muted");
                }

                if (this.whitelistFilterText) {
                    const term = this.whitelistFilterText.toLowerCase();

                    if (dataItem.email.indexOf(term) === -1) {
                        _classes.push("d-none");
                    }
                }

                return _classes.join(" ");
            },

            /**
             * Obter dados sobre o antispam.
             */
            getAntispam()
            {
                this.isLoadingAntispam = this.isLoadingAntispamStatus = true;

                this.$api.get(`/hosting/${this.resource}/spam`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.antispam  = response.data.data;
                        this.blacklist = [];
                        this.whitelist = [];

                        this.antispam.blacklist.forEach((el, idx) => {
                            this.blacklist.push({ "email": el });
                        });

                        this.antispam.whitelist.forEach((el, idx) => {
                            this.whitelist.push({ "email": el });
                        });

                        this.$refs.tableBlacklist.setData(this.blacklist);
                        this.$refs.tableWhitelist.setData(this.whitelist);
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingAntispam = this.isLoadingAntispamStatus = false);
            },

            /**
             * Atualizar status do antispam.
             */
            updateAntispamStatus()
            {
                const newValue = this.antispam.status;

                this.isLoadingAntispamStatus = true;

                this.$api.post(`/hosting/${this.resource}/spam`, { status: newValue })
                    .then(response => {
                        this.antispam.status = response.data.data.status;
                        this.successNotification({ message: 'Configuração atualizada!' })
                    })
                    .catch(error => {
                        this.antispam.status = !newValue;
                        this.showErrorAlert(error);
                    })
                    .then(() => this.isLoadingAntispamStatus = false);
            },

            /**
             * Exibir modal para cadastro de e-mail em uma das listas.
             */
            showAddEmailModal(list)
            {
                list = list.toLowerCase();

                const listTitleCase = list.charAt(0).toUpperCase() + list.slice(1);

                this.email = {};
                this.formErrors = {};

                this.$refs[`modalAdd${listTitleCase}`].show();
            },

            /**
             * Adicionar e-mail na blacklist.
             */
            addBlacklist()
            {
                this._addToList("blacklist");
            },

            /**
             * Excluir e-mail da blacklist.
             */
            removeBlacklist(item)
            {
                this._removeFromList("blacklist", item.email);
            },

            /**
             * Adicionar e-mail na whitelist.
             */
            addWhitelist()
            {
                this._addToList("whitelist");
            },

            /**
             * Excluir e-mail da whitelist.
             */
            removeWhitelist(item)
            {
                this._removeFromList("whitelist", item.email);
            },

            /**
             * Adicionar e-mail a uma das listas.
             * @param list  Pode ser "blacklist" ou "whitelist".
             */
            _addToList(list)
            {
                list = list.toLowerCase();

                const listTitleCase = list.charAt(0).toUpperCase() + list.slice(1);

                const data = { email: this.email.email };
                this.formErrors = {};

                this.$refs[`btnAdd${listTitleCase}Submit`].$el.focus();
                this.$refs[`btnAdd${listTitleCase}Submit`].setLoading();
                this.$refs[`btnAdd${listTitleCase}Cancel`].disabled = true;

                this.$set(this, `isLoading${listTitleCase}`, true);

                this.$api.post(`/hosting/${this.resource}/spam/${list}`, data)
                    .then(() => {
                        this.$refs[`modalAdd${listTitleCase}`].hide();
                        this[list].unshift({ email: data.email });
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs[`btnAdd${listTitleCase}Cancel`].disabled = false;
                        this.$refs[`btnAdd${listTitleCase}Submit`].setLoading(false);
                    })
                    .then(() => {
                        this.$set(this, `isLoading${listTitleCase}`, false);
                    });
            },

            /**
             * Excluir e-mail de uma das listas.
             * @param list   Pode ser "blacklist" ou "whitelist".
             * @param email  E-mail a ser removido.
             */
            _removeFromList(list, email)
            {
                list = list.toLowerCase();

                let idx = this[list].findIndex(el => el.email === email);
                this.$set(this[list][idx], "isRemoving", true);

                this.$api.delete(`/hosting/${this.resource}/spam/${list}/`+ encodeURIComponent(email))
                    .then(() => {
                        idx = this[list].findIndex(el => el.email === email);
                        this[list].splice(idx, 1);
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$set(this[list][idx], "isRemoving", false);
                    });
            },
        }
    }
</script>