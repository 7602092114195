<template>
    <div class="card h-m-card-title">
        <div class="card-header" v-if="title && !innerBody" v-bind:class="{ 'no-decoration': noHeaderDecoration }">
            <div class="d-flex justify-content-between">
                <h4 class="card-title m-0" v-bind:class="{ 'lg': titleSize == 'lg' }" v-html="title"></h4>
                <div class="card-header-actions">
                    <slot name="actions"/>
                </div>
            </div>
        </div>

        <slot name="pre-body"/>

        <div class="card-body" v-bind:class="cardBodyClass">
            <div class="d-flex">
                <h4 v-if="title && innerBody" class="card-title-inner flex-grow-1" v-bind:class="{ 'mb-6': !$slots['intro-text'] }" v-html="title"></h4>
                <div v-if="innerBody">
                    <slot name="actions"/>
                </div>
            </div>
            <div v-if="$slots['intro-text']" class="mb-6"><slot name="intro-text"></slot></div>
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ""
            },
            bodyClass: {
                type: String,
                default: ""
            },
            innerBody: {
                type: Boolean,
                default: false
            },
            noHeaderDecoration: {
                type: Boolean,
                default: false
            },
            titleSize: {
                type: String,
                default: ""
            }
        },
        computed: {
            cardBodyClass() {
                return [this.innerBody ? 'ht-card-body-inner' : '', this.bodyClass];
            }
        }
    }
</script>

<style lang="stylus">
    .h-m-card-title
        border 0
        border-radius 0
        box-shadow 0 2px 6px #0000000F
        margin-bottom 1rem

        &:last-child
            margin-bottom 0

        @media (min-width 576px)
            border-radius 6px
            margin-bottom 2rem /* !important */

        > .card-header
            position relative
            background none
            border 0
            padding 1.5rem 1.25rem 0

            @media (min-width 576px)
                padding 1.5rem 1.75rem 0

            &:before
                content ""
                absolute top 1.25rem left 0
                size .5rem 2rem
                border-radius 0 5px 5px 0
                background-color $primary

                @media (min-width 576px)
                    height 2.25rem

            &.no-decoration:before
                content none

            .card-title
                font-family "Nunito", sans-serif
                font-weight bold
                line-height 1.25
                color $gray-800

                @media (max-width 575px)
                    font-size 1.25rem

                &.lg
                    @media (min-width 768px)
                        font-size 2rem

        > .card-body
            @media (min-width 576px)
                padding 1.5rem 1.75rem

        .ht-card-body-inner
            padding 1.5rem

            @media (min-width 576px)
                padding 2.5rem

        .card-title-inner
            margin-bottom .75rem
            font-family "Nunito", sans-serif
            font-weight bold
            line-height 1.25
            color $primary

            @media (max-width 576px)
                font-size 1.25rem

        .card-title-subtitle
            position relative
            display flex
            align-items center
            padding-top 1.5rem
            margin-bottom 1rem

            .card-title-inner
                margin 0

            &:after
                content ""
                display block
                flex-grow 1
                margin-left .5rem
                border-top 1px solid $gray-500

        .table-list-wrapper
            margin-left -1.25rem
            margin-right -1.25rem

            @media (min-width 576px)
                margin-left -1.75rem
                margin-right -1.75rem

        .table-list
            border 0

            tbody tr
                @media (min-width 576px)
                    > th:first-child
                        padding-left 1.75rem

                    > td:last-child
                        padding-right 1.75rem

            @media (max-width 575px)
                tbody
                    th, td
                        display block
                        width 100%

                    td.text-right
                        text-align left !important

        .ht-card-body-inner
            .table-list-wrapper
                @media (min-width 576px)
                    margin-left -2.5rem
                    margin-right -2.5rem

            .table-list tbody tr
                > th:first-child
                    padding-left 2.5rem

                > td:last-child
                    padding-right 2.5rem

        @media (max-width 575px)
            .btn-toolbar
                flex-direction column

                .btn-group
                    width 100%
                    margin-top .5rem
</style>