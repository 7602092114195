/**
 * @callback loadingCallback
 * @returns {Boolean}
 */

export default {
    methods: {
        /**
         * Função para ser utilizada com o Data Manager do plugin Vuetable2.
         * https://www.vuetable.com/guide/api-vs-data-mode.html#data-mode
         *
         * @param {Object[]}        sort    Sort order array.
         * @param {Object[]}        fields  Lista de campos da tabela.
         * @param {Object[]}        data    Dados da tabela.
         * @param {loadingCallback} loading Callback de loading.
         *
         * @returns {Object[]|void}
         */
        sortVuetable(sort, fields, data, loading)
        {
            if (!data || !data.length || loading()) return;

            if (sort.length > 0 && fields.length > 0)
            {
                const name  = sort[0].sortField;
                const field = fields.find(item => item.sortField === name);

                if (field)
                {
                    const sortFunction = field.sortFunction || "text";

                    if (sortFunction === "number") {
                        data.sort((a,b) => {
                            return sort[0].direction === "asc" ? parseFloat(a[name]) - parseFloat(b[name]) : parseFloat(b[name]) - parseFloat(a[name]);
                        });
                    }
                    else if (sortFunction === "text") {
                        // Sort as text
                        data.sort((a,b) => {
                            return sort[0].direction === "asc" ? a[name].localeCompare(b[name]) : b[name].localeCompare(a[name]);
                        });
                    }
                }
            }

            return data;
        },

        /**
         * Função para ser utilizada com o propriedade row-class do plugin Vuetable2.
         * https://ratiw.github.io/vuetable-2/#/Vuetable-Properties?id=-row-class
         *
         * @param {Object}   data
         * @param {String}   [filter]
         * @param {string[]} [filteredFields]
         *
         * @return {string}
         */
        getRowClassVuetable(data, filter, filteredFields)
        {
            let _classes = [];

            if (data.removing || data.isRemoving) {
                _classes.push("table-muted");
            }

            if (filter && filteredFields.length > 0)
            {
                const term = filter.toLowerCase();
                let notFound = true;

                filteredFields.forEach((field) => {
                    if (notFound && data[field].indexOf(term) !== -1) {
                        notFound = false;
                    }
                });

                if (notFound) {
                    _classes.push("d-none");
                }
            }

            return _classes.join(" ");
        }
    }
}