<template>
    <card-title title="Balanceador">
        <p class="mb-6">
            Este recurso permite que o tráfego de sua aplicação seja balanceado entre múltiplas hospedagens, fornecendo
            assim uma <strong>alta disponibilidade</strong>. O recurso é ideal para sites de
            <strong>lançamentos digitais</strong> que utilizam páginas estáticas.
        </p>

        <text-loading v-show="isLoadingBalancer" class="text-center pt-4 pb-5" />

        <div v-if="balancer" v-show="!isLoadingBalancer">

            <div v-if="!balancer.enabled" class="text-center pb-3">
                <button type="button" class="btn btn-success btn-lg" @click="$refs.modalEnableBalancer.show()">Ativar balanceador</button>
            </div>

            <div v-else>
                <div class="row mx-lg-n4">
                    <div class="col-lg-6 px-lg-4">
                        <div class="h-balancer-master">
                            <div class="card h-card-balancer">
                                <div class="card-body">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="h-balancer-icon">
                                            <i class="fas fa-circle h-balancer-status"></i>
                                        </div>
                                        <div class="h-balancer-domain flex-grow text-truncate">
                                            {{ balancer.host }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mx-lg-n4">
                    <div class="col-lg-6 px-lg-4 offset-lg-6">
                        <div class="h-balancer-workers">

                            <template v-for="item in balancer.balancers">
                                <div class="card h-card-balancer">
                                    <div class="card-body">
                                        <div class="d-flex flex-row align-items-center">
                                            <div class="h-balancer-icon">
                                                <i class="fas fa-circle h-balancer-status"></i>
                                            </div>
                                            <div class="h-balancer-domain flex-grow text-truncate">
                                                {{ item.host }}
                                            </div>
                                            <div class="h-balancer-ip">
                                                {{ item.ip_address }}
                                            </div>
                                            <div class="d-flex ml-auto">
                                                <!--<div class="h-balancer-icon">
                                                    <button type="button" class="btn btn-link"><i class="fas fa-cog"></i></button>
                                                </div>-->
                                                <div class="h-balancer-icon">
                                                    <button type="button" class="btn btn-link" @click="removeBalancer(item.host)"><i class="far fa-trash-alt"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-for="n in availableSpots">
                                <div class="card h-card-balancer">
                                    <div class="card-body">
                                        <div class="d-flex flex-row align-items-center">
                                            <div class="h-balancer-icon"></div>
                                            <div class="h-balancer-domain flex-grow text-truncate">
                                                <span class="text-muted">Disponível</span>
                                            </div>
                                            <div class="d-flex ml-auto">
                                                <div class="h-balancer-icon">
                                                    <button type="button" class="btn btn-link" @click="showAddBalancerModal"><i class="fas fa-plus"></i></button>
                                                </div>
                                                <!--<div class="h-balancer-icon">
                                                    <button type="button" class="btn btn-link"><i class="far fa-trash-alt"></i></button>
                                                </div>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <button
                                v-if="!availableSpots"
                                type="button"
                                class="btn btn-success btn-block"
                                @click="$refs.modalAddExtra.show()"
                            >
                                Adicionar balanceador
                            </button>
                        </div>
                    </div>
                </div>

                <div class="btn-toolbar mt-6" role="toolbar">
                    <div class="btn-group" role="group">
                        <button type="button" class="btn btn-danger" @click="$refs.modalDisableBalancer.show()">Desativar</button>
                    </div>
                </div>
            </div>
        </div>

        <modal-confirm
            ref="modalEnableBalancer"
            size="lg"
            title="Ativar Balanceador de Hospedagens"
            title-class="h4 mx-auto mb-3"
            confirmText="Ativar"
            @confirm="enableBalancer"
        >
            <div class="row">
                <div class="col-md-6">
                    <h5 class="font-weight-bold text-success mb-2">O que é?</h5>
                    <p class="mb-4 pb-2">
                        O Balanceador é um recurso exclusivo da Hostoo que
                        <strong>pode melhorar o desempenho do seu site ou aplicação</strong>.
                        Ao ativá-lo, você poderá balancear os acessos ao seu site entre 2 ou mais hospedagens diferentes.
                    </p>
                </div>
                <div class="col-md-6">
                    <h5 class="font-weight-bold text-success mb-2">Quanto custa?</h5>
                    <p class="mb-4 pb-2">
                        Este recurso custa <strong>R$ {{ hourlyPrice }} por hora</strong>. O valor será acrescido ao
                        preço do plano atual de sua hospedagem. Você só será cobrado enquanto o balanceador estiver ativo.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5 class="font-weight-bold text-success mb-2">Quando é recomendado?</h5>
                    <p class="mb-0">
                        O recurso é ideal para sites de <strong>lançamentos digitais</strong> que utilizam páginas
                        estáticas e que terão um grande número de acessos em um curto espaço de tempo.
                        Ele permitirá que seu site tenha uma <strong>alta disponibilidade</strong> e consiga suportar
                        um número maior de acessos simultâneos. O recurso <strong>não é indicado</strong> para sites que
                        utilizem banco de dados, visto que pode ocasionar um problema de sincronização entre os dados de
                        bancos em hospedagens diferentes.
                    </p>
                </div>
            </div>
        </modal-confirm>

        <b-modal
            ref="modalAddBalancer"
            title="Adicionar balanceador"
            centered
            hide-footer
            hide-header-close
            no-close-on-esc
            no-close-on-backdrop
        >
            <form @submit.prevent="addBalancer">
                <form-group v-show="!formData.isManual" label="Hospedagem" label-for="balancerHosting" :error="formErrors.hosting">
                    <b-form-select id="balancerHosting" v-model="formData.hosting" :options="hostings" value-field="domain" text-field="domain">
                        <template v-slot:first>
                            <b-form-select-option value="" disabled>{{ isLoadingHostings ? "Carregando" : "Selecione" }}...</b-form-select-option>
                        </template>
                    </b-form-select>
                </form-group>

                <b-button v-show="!formData.isManual" variant="link" class="text-left p-0" @click="formData.isManual = true">Informar IP e host manualmente</b-button>

                <form-group v-show="formData.isManual" label="Host" label-for="balancerHost" :error="formErrors.host">
                    <input type="text" id="balancerHost" class="form-control" placeholder="dominio.com.br" v-model="formData.host">
                </form-group>

                <form-group v-show="formData.isManual" label="Endereço IP" label-for="balancerIpAddress" :error="formErrors.ip">
                    <input type="text" id="balancerIpAddress" class="form-control" placeholder="0.0.0.0" v-model="formData.ip">
                </form-group>

                <b-button v-show="formData.isManual" variant="link" class="text-left p-0" @click="formData.isManual = false">Informar hospedagem em minha conta</b-button>

                <div class="form-buttons text-right">
                    <button ref="btnAddBalancerCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalAddBalancer.hide()">Cancelar</button>
                    <button-form ref="btnAddBalancerSubmit" type="submit" variant="success" class="btn-action">Salvar</button-form>
                </div>
            </form>
        </b-modal>

        <modal-confirm
            ref="modalAddExtra"
            title="Adicionar balanceador extra"
            @confirm="addExtra"
        >
            <p class="mb-2">
                Cada balanceador extra possui um custo de <strong>R$ {{ extraPrice }} por hora</strong> que
                será acrescido ao preço do plano atual de sua hospedagem.
            </p>
            <p class="mb-0">
                Deseja adicionar um balanceador extra?
            </p>
        </modal-confirm>

        <modal-confirm
            ref="modalDisableBalancer"
            title="Desativar balanceador"
            danger
            @confirm="disableBalancer"
        >
            <p class="mb-0">
                Tem certeza que deseja desativar o serviço do balanceador?
            </p>
        </modal-confirm>
    </card-title>
</template>

<script>
    import {mapActions} from 'vuex'
    import ButtonForm   from '@/components/atoms/ButtonForm'
    import ModalConfirm from '@/components/atoms/ModalConfirm'
    import TextLoading  from '@/components/atoms/TextLoading'
    import CardTitle    from '@/components/molecules/CardTitle'
    import FormGroup    from '@/components/molecules/FormGroup'
    import AjaxService  from '@/services/AjaxService'
    import ErrorService from '@/services/error.service'

    export default {
        name: 'HostingBalancer',
        props: ['resource'],
        components: {
            ButtonForm,
            FormGroup,
            ModalConfirm,
            TextLoading,
            CardTitle
        },
        data() {
            return {
                // Data
                balancer: null,
                hostings: [],
                formData: {},
                formErrors: {},

                // Loaders
                isLoadingBalancer: false,
                isLoadingHostings: false,
            }
        },
        computed: {
            hourlyPrice()
            {
                return (this.balancer && this.balancer.prices)
                    ? (this.balancer.prices.balancer / 720).toFixed(4).replace('.', ',')
                    : "";
            },
            extraPrice()
            {
                return (this.balancer && this.balancer.prices)
                    ? (this.balancer.prices.extra / 720).toFixed(4).replace('.', ',')
                    : "";
            },
            availableSpots()
            {
                return (this.balancer && this.balancer.enabled)
                    ? Math.max((1 + this.balancer.extra - this.balancer.balancers.length), 0)
                    : 0;
            }
        },
        mounted() {
            this.fetchBalancer();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error',
                successNotification: 'notification/success',
            }),

            /**
             * Get data for balancer.
             */
            fetchBalancer()
            {
                this.isLoadingBalancer = true;

                this.$api.get(`/hosting/${this.resource}/balancer`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => this.balancer = response.data.data)
                    .catch(error => this.showErrorAlert('Ocorreu um erro ao carregar as informações sobre o balanceador.'))
                    .then (() => this.isLoadingBalancer = false);
            },

            fetchHostings()
            {
                this.hostings = [];
                this.isLoadingHostings = true;

                this.$api.get('/hosting', { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        const hostings = response.data.data;
                        const balancers = [];

                        if (this.balancer && this.balancer.balancers) {
                            for (let i = 0; i < this.balancer.balancers.length; i++) {
                                balancers.push(this.balancer.balancers[i].host);
                            }
                        }

                        for (let i = 0; i < hostings.length; i++) {
                            if (!hostings[i].isGuest && hostings[i].domain != this.resource && balancers.indexOf(hostings[i].domain) === -1) {
                                this.hostings.push(hostings[i]);
                            }
                        }
                    })
                    .catch(error => this.showErrorAlert('Ocorreu um erro ao carregar a lista de suas hospedagens.'))
                    .then (() => this.isLoadingHostings = false);
            },

            /**
             * Enable balancer service on hosting.
             */
            enableBalancer()
            {
                this.isLoadingBalancer = true;

                this.$api.post(`/hosting/${this.resource}/balancer/enable`)
                    .then (response => {
                        this.fetchBalancer();
                    })
                    .catch(error => {
                        this.showErrorAlert('Ocorreu um erro ao ativar o balanceador.');
                        this.isLoadingBalancer = false;
                    });
            },

            /**
             * Disable balancer service on hosting.
             */
            disableBalancer()
            {
                this.isLoadingBalancer = true;

                this.$api.post(`/hosting/${this.resource}/balancer/disable`)
                    .then (response => {
                        this.fetchBalancer();
                    })
                    .catch(error => {
                        this.showErrorAlert('Ocorreu um erro ao tentar desativar o balanceador.');
                        this.isLoadingBalancer = false;
                    });
            },

            showAddBalancerModal()
            {
                this.fetchHostings();
                this.formData = { hosting: "", isManual: false };
                this.$refs.modalAddBalancer.show();
            },

            /**
             * Add new balancer to the hosting.
             */
            addBalancer()
            {
                this.formErrors = {};

                this.$refs.btnAddBalancerSubmit.loadingFocus();
                this.$refs.btnAddBalancerCancel.disabled = true;

                const postData = {};

                if (this.formData.isManual) {
                    postData.host = this.formData.host;
                    postData.ip   = this.formData.ip;
                }
                else {
                    postData.hosting = this.formData.hosting;
                }

                this.$api.post(`/hosting/${this.resource}/balancer`, postData)
                    .then(response => {
                        this.$refs.modalAddBalancer.hide();
                        this.successNotification({ message: 'Novo balanceador adicionado' });
                        this.fetchBalancer();
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnAddBalancerCancel.disabled = false;
                        this.$refs.btnAddBalancerSubmit.setLoading(false);
                    });
            },

            /**
             * Remove balancer from hosting.
             */
            removeBalancer(domain)
            {
                this.isLoadingBalancer = true;

                this.$api.delete(`/hosting/${this.resource}/balancer/${domain}`)
                    .then(response => {
                        this.successNotification({ message: 'Balanceador removido' });
                        this.fetchBalancer();
                    })
                    .catch(error => {
                        this.showErrorAlert('Ocorreu um erro ao tentar remover o balanceador.');
                        this.isLoadingBalancer = false;
                    });
            },

            /**
             * Add an extra balancer to the hosting.
             */
            addExtra()
            {
                this.isLoadingBalancer = true;

                this.$api.post(`/hosting/${this.resource}/balancer/extra`)
                    .then(response => {
                        this.fetchBalancer();
                    })
                    .catch(error => {
                        this.showErrorAlert('Ocorreu um erro ao adicionar um balanceador extra.');
                        this.isLoadingBalancer = false;
                    });
            }
        }
    }
</script>

<style lang="stylus">
    .h-card-balancer {
        position relative

        .card-body {
            padding .5rem
        }
    }
    .h-balancer-icon {
        width 2rem
        min-width 2rem
        text-align center

        .btn-link {
            padding 0 .25rem
            line-height 1
            text-decoration none !important

            > i {
                display block
            }
        }
    }
    .h-balancer-status {
        display block
        color $gray-500
    }
    .h-balancer-workers {
        margin-top 1.5rem

        .h-card-balancer {
            margin-bottom 1.5rem
        }

        .h-card-balancer:before {
            content ""
            absolute top 50% right 100%
            width calc(50% + 2rem)
            height 1px
            border-top 1px dashed $gray-400
        }

        .h-card-balancer:after {
            content ""
            absolute bottom 50% right 100%
            margin-right calc(50% + 2rem)
            width 1px
            height calc(100% + 1.5rem)
            border-left 1px dashed $gray-400
        }

        .h-card-balancer:first-child:after {
            height calc(50% + 1.5rem)
        }
    }
</style>
