<template>
    <admin-page-template>
        <card-title title="Transferência de Hospedagem">
            <div v-show="isLoading" class="mb-6">
                <p>
                    Por favor, aguarde um momento enquanto estamos confirmando a transferência.
                </p>
                <text-loading text="Processando" />
            </div>
            <div v-show="isSuccess" class="mb-6">
                <p>
                    A hospedagem <strong>{{ domain }}</strong> foi transferida para sua conta com sucesso!
                </p>
                <router-link to="/hostings" class="btn btn-success">Continuar</router-link>
            </div>
            <div v-show="isError" class="mb-6">
                <p class="text-danger">
                    Ocorreu um problema na transferência da hospedagem.
                </p>
                <p v-if="errorMessage">
                    {{ errorMessage }}
                </p>
            </div>
        </card-title>
    </admin-page-template>
</template>

<script>
    import TextLoading       from '@/components/atoms/TextLoading'
    import CardTitle         from '@/components/molecules/CardTitle'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'

    export default {
        components: {
            TextLoading,
            CardTitle,
            AdminPageTemplate
        },
        data() {
            return {
                token: "",
                domain: "",
                isLoading: true,
                isSuccess: false,
                isError: false,
                errorMessage: "",
            }
        },
        beforeRouteEnter(to, from, next)
        {
            if (to.query.token) {
                next(vm => { vm.token = to.query.token; });
            }
            else {
                next('/');
            }
        },
        mounted()
        {
            this.confirm();
        },
        methods: {

            confirm()
            {
                this.isLoading = true;

                const token = this.token || this.$route.query.token;

                this.$api.get('hosting/confirm-transfer', { params: { token: token } })
                    .then (response => {
                        this.domain = response.data.data.domain;
                        this.isSuccess = true;
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            if (error.response.status === 400 && error.response.data && error.response.data.message) {
                                this.errorMessage = error.response.data.message;
                            }
                        }

                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    }
</script>
