import store         from '@/store'
import { ApiCancel } from '@/plugins/api'
import UserService   from '@/services/user.service'

/**
 * Tratar resposta de erro em um carregamento de página.
 *
 * @param error   Resposta de erro do axios.
 * @param [next]  Função de navegação do vue-router.
 */
function handlePageError(error, next)
{
    if (ApiCancel.isCancel(error)) return;

    const defaultAlert = () => {
        alert('Ocorreu um erro ao tentar processar sua solicitação.');
    };

    if (typeof error.response.status !== 'undefined')
    {
        const status = error.response.status;

        if (status === 401) {
            UserService.logout();
            redirect('/auth/login', next);
        }
        else if (status === 403)
        {
            const errorMessage = 'Você não possui permissão para fazer isso! Para mais informações, entre em contato com nosso suporte.';
            alert(errorMessage);
            redirect('/', next);
        }
        else if (status === 404) {
            redirect('/404', next);
        }
        else {
            defaultAlert();
        }
    }
    else {
        defaultAlert();
    }
}

/**
 * Tratar resposta de erro em uma submissão de formulário.
 *
 * @param error  Resposta de erro do axios.
 * @param vm     Instância do Vue.
 */
function handleFormError(error, vm)
{
    if (ApiCancel.isCancel(error)) return;

    const defaultAlert = () => {
        store.dispatch('alert/error', error);
    };

    if (error.response)
    {
        const status = error.response.status;
        const data   = error.response.data || {};

        if (status === 401) {
            UserService.logout();
            redirect('/auth/login', vm.$router.push);
        }
        else if (status === 403) {
            // TODO: Exibir alerta com modal personalizado
            alert('Você não possui permissão para fazer isso! Para mais informações, entre em contato com nosso suporte.');
            redirect('/', vm.$router.push);
        }
        else if (status === 422 || (data.status && data.status === 'fail')) {
            if (typeof vm['formErrors'] === 'object') {
                vm['formErrors'] = data.data;
            }
            else if (typeof vm['errors'] === 'object') {
                vm['errors'] = data.data;
            }
            else defaultAlert();
        }
        else if (data.message) {
            store.dispatch('alert/error', error);
        }
        else {
            defaultAlert();
        }
    }
    else {
        defaultAlert();
    }
}

/**
 * Tratar resposta de erro em uma submissão de formulário de autenticação.
 * Nesse tipo de situação não é possível fazer redirecionamentos.
 *
 * @param error           Resposta de erro do axios.
 * @param vm              Instância do Vue.
 * @param onErrorMessage  Callback para erros com mensagem de retorno.
 * @param onErrorDefault  Callback para outros tipos de erro.
 */
function handleAuthFormError(error, vm, onErrorMessage, onErrorDefault)
{
    const status  = error.response ? error.response.status : 0;

    if (status === 422) {
        handleFormError(error, vm);
    }
    else if (status >= 400 && typeof error.response.data.message !== 'undefined' && typeof onErrorMessage === 'function') {
        onErrorMessage(error.response.data.message);
    }
    else if (typeof onErrorDefault === 'function') {
        onErrorDefault(error);
    }
    else {
        alert('Ocorreu um erro ao tentar processar sua solicitação.');
    }
}

/**
 * Redirecionamento de página usando next ou location.
 * @private
 *
 * @param {string}   to         Endereço de destino
 * @param {Function} [next]     Função de navegação do vue-router
 * @param {Object}   [options]  Opções para a função next()
 */
function redirect(to, next, options)
{
    if (typeof next !== 'undefined')
    {
        let _options = { path: to };

        if (typeof options === 'object') {
            _options = Object.assign(_options, options);
        }

        next(_options);
    }
    else {
        window.location.href = to;
    }
}

const ErrorService = {
    handlePageError,
    handleFormError,
    handleAuthFormError
}

export default ErrorService