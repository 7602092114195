import { Api } from '@/plugins/api'

export default {
    data() {
        return {
            ccFingerprint: "",
        }
    },
    methods: {
        /**
         * Inicializar configurações para o pagamento com cartão de crédito.
         */
        initCreditCard()
        {
            if (typeof window.tmx_profiling_started === 'undefined')
            {
                this.ccFingerprint = this.$cookie.get('hos_cc_fp');

                if (!this.ccFingerprint)
                {
                    Api.get('/recharge/fingerprint')
                        .then(response => {
                            this.ccFingerprint = response.data.data.identifier;
                            this.$cookie.set('hos_cc_fp', this.ccFingerprint, { expires: '48h' });
                            this._loadCieloScript(this.ccFingerprint);
                        })
                        .catch(error => {
                            this.$eventBus.$emit('cc-fingerprint-error');
                        });
                }
                else {
                    this._loadCieloScript(this.ccFingerprint);
                }
            }
            else {
                this.$eventBus.$emit('cc-fingerprint-ready');
            }
        },

        /**
         * Carregar script da Cielo.
         */
        _loadCieloScript(identifier)
        {
            const script = document.createElement('script');

            let src = process.env.VUE_APP_CIELO_JS;
            src += '?org_id=' + process.env.VUE_APP_CIELO_ORG_ID;
            src += '&session_id=' + process.env.VUE_APP_CIELO_MERCHANT_ID + identifier;

            script.onload = () => {
                this.$eventBus.$emit('cc-script-loaded');
            };

            script.onerror = () => {
                this.$eventBus.$emit('cc-script-error');
            };

            script.type  = 'text/javascript';
            script.src   = src;
            script.async = true;
            script.id    = 'js-cc-fingerprint';

            if (!document.getElementById('js-cc-fingerprint')) {
                document.head.appendChild(script);
            }
            else {
                this.$eventBus.$emit('cc-script-loaded');
            }
        },
    }
}
