<template>
    <div class="h-o-announcement-area">
        <template v-for="(item, index) in announcements">
            <component v-if="item.component" v-bind:is="item.component" v-bind="item.props" :key="`announcement-${index}`"></component>
            <b-alert v-else :key="`announcement-${index}`" :variant="item.variant" :dismissible="item.dismissible" show>
                <div v-html="item.content"></div>
                <div v-if="item.actions" v-for="action in item.actions">
                    <b-button :variant="action.variant || 'link'" v-bind:class="action.classes" @click="action.callback($event)">{{ action.text }}</b-button>
                </div>
            </b-alert>
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState({
                announcements: state => state.announcement.items,
            })
        }
    }
</script>