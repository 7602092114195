<template>
    <div class="text-muted">
        <i class="fas fa-circle-notch fa-spin mr-1"></i> {{ text }}&hellip;
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'Carregando'
            }
        }
    }
</script>