function formatMoney(value, decimals, currency = 'R$ ')
{
    let _value = typeof value !== 'number' ? parseFloat(value) : value;
    const _decimals = typeof decimals !== "undefined" ? parseInt(decimals) : 2;

    _value = _value.toLocaleString("en-US", {
        minimumFractionDigits: _decimals,
        maximumFractionDigits: _decimals,
    });

    _value = _value.replace(",", "_")
        .replace(".", ",")
        .replace("_", ".");

    return currency + _value;
}

export default formatMoney
