<template>
    <hosting-files-template :resource="resource">
        <card-title v-show="!isEditing" title="Gerenciador de Arquivos" inner-body>
            <template slot="actions">
                <button type="button" class="btn btn-success" @click="$refs.modalTips.show()">Ajuda</button>
            </template>

            <file-manager
                ref="filemanager"
                class="d-none d-lg-block"
                :files="files"
                :path="currentPath"
                :loading="isLoadingFiles"
                v-on:home="fetchFiles"
                v-on:refresh="fetchFiles(currentPath)"
                v-on:download="fileDownload"
                v-on:upload="fileUpload"
                v-on:folder-changed="changeFolder"
                v-on:file-entered="fileEnter"
                v-on:file-renamed="fileRename"
                v-on:file-moved="fileMove"
                v-on:create-folder="showCreateFolderModal"
                v-on:create-file="showCreateFileModal"
                v-on:rename="showRenameModal"
                v-on:compress="showCompressModal"
                v-on:extract="showExtractModal"
                v-on:remove="confirmRemove"
                v-on:change-permissions="showChangePermissionsModal">
            </file-manager>

            <div class="alert alert-warning d-lg-none">
                No momento, o gerenciador de arquivos não está disponível na versão do painel para celular.
                Para acessar seus arquivos, acesse o painel através de um computador ou utilize FTP ou SCP.
            </div>
        </card-title>

        <card-title v-show="isEditing" :title="getFormattedFilePath(editor.file)" inner-body>
            <text-loading v-show="isLoadingContent" />

            <div v-show="!isLoadingContent" class="editor-wrapper">
                <editor
                    ref="editor"
                    theme="monokai"
                    width="100%"
                    height="500"
                    v-model="editor.content"
                    :options="editorOptions"
                    :lang="editor.lang"
                    @init="initEditor">
                </editor>
            </div>

            <div class="form-buttons text-right">
                <button type="button" ref="btnCancelFile" class="btn btn-secondary btn-action mr-2" @click="cancelEdit">Voltar</button>
                <button type="button" ref="btnSaveFile" class="btn btn-success btn-action" @click="saveFile">Salvar</button>
            </div>
        </card-title>

        <b-modal ref="modalCreateFolder" size="md" title="Criar nova pasta" centered hide-footer hide-header-close>
            <form accept-charset="UTF-8" @submit.prevent="createFolder">
                <form-group label="Nome da pasta" label-for="folderName" :error="errors.name">
                    <input type="text" id="folderName" class="form-control" spellcheck="false" autocomplete="off" v-model="newFolder.name">
                </form-group>

                <div class="form-buttons text-right">
                    <button type="button" ref="cancelCreateFolder" class="btn btn-secondary btn-action mr-2" @click="$refs.modalCreateFolder.hide()">Cancelar</button>
                    <button-form ref="submitCreateFolder" type="submit" variant="success" class="btn-action">Criar</button-form>
                </div>
            </form>
        </b-modal>

        <b-modal ref="modalCreateFile" size="md" title="Criar novo arquivo" centered hide-footer hide-header-close>
            <form accept-charset="UTF-8" @submit.prevent="createFile">
                <form-group label="Nome do arquivo" label-for="fileName" :error="errors.name">
                    <input type="text" id="fileName" class="form-control" spellcheck="false" autocomplete="off" v-model="newFile.name">
                </form-group>

                <div class="form-buttons text-right">
                    <button type="button" ref="cancelCreateFile" class="btn btn-secondary btn-action mr-2" @click="$refs.modalCreateFile.hide()">Cancelar</button>
                    <button-form ref="submitCreateFile" type="submit" variant="success" class="btn-action">Criar</button-form>
                </div>
            </form>
        </b-modal>

        <b-modal ref="modalRename" size="md" title="Renomear arquivo" centered hide-footer hide-header-close>
            <form accept-charset="UTF-8" @submit.prevent="rename">
                <form-group label="Nome atual" label-for="fileOldName">
                    <input type="text" id="fileOldName" class="form-control" readonly v-model="newFile.oldName">
                </form-group>

                <form-group label="Novo nome" label-for="fileNewName" :error="errors.name">
                    <input type="text" id="fileNewName" class="form-control" spellcheck="false" autocomplete="off" v-model="newFile.name">
                </form-group>

                <div class="form-buttons text-right">
                    <button type="button" ref="cancelRename" class="btn btn-secondary btn-action mr-2" @click="$refs.modalRename.hide()">Cancelar</button>
                    <button-form ref="submitRename" type="submit" variant="success" class="btn-action">Renomear</button-form>
                </div>
            </form>
        </b-modal>

        <b-modal ref="modalCompress" size="md" title="Compactar arquivos" centered hide-footer hide-header-close no-close-on-backdrop no-close-on-esc
            @shown="$refs.filemanager.disableKeyboardEvents()"
            @hidden="$refs.filemanager.enableKeyboardEvents()"
        >
            <form accept-charset="UTF-8" @submit.prevent="compress">
                <form-group :error="errors.format">
                    <label class="form-label">Formato da compressão</label>
                    <b-form-radio-group v-model="filesToCompress.format" @change="updateCompressFormat">
                        <b-form-radio value="zip">*.zip</b-form-radio>
                        <b-form-radio value="gz">*.gz</b-form-radio>
                    </b-form-radio-group>
                </form-group>

                <form-group label="Nome do arquivo compactado" label-for="compressName" :error="errors.name">
                    <input type="text" id="compressName" class="form-control" spellcheck="false" autocomplete="off" v-model="filesToCompress.name">
                </form-group>

                <div class="card">
                    <div class="card-header border-bottom-0 p-0">
                        <button type="button" class="btn btn-link w-100 text-left px-2 collapsed" v-b-toggle.collapseCompressFiles>
                            Lista de arquivos <i class="fas fa-angle-down ml-1"></i>
                        </button>
                    </div>
                    <b-collapse id="collapseCompressFiles">
                        <ul class="list-group list-group-flush" style="max-height:180px;overflow-y:scroll">
                            <li class="list-group-item py-1 px-2" v-for="(item, index) in filesToCompress.files" v-bind:class="{'border-top': index === 0}">
                                <span class="text-monospace">{{ item.path }}</span>
                            </li>
                        </ul>
                    </b-collapse>
                </div>

                <div ref="compressAlert" class="alert alert-warning w-100 mx-0" style="display:none">
                    <i class="far fa-clock mr-1"></i>
                    A compactação pode demorar alguns minutos...
                </div>

                <div class="form-buttons text-right">
                    <button type="button" ref="cancelCompress" class="btn btn-secondary btn-action mr-2" @click="$refs.modalCompress.hide()">Cancelar</button>
                    <button-form ref="submitCompress" type="submit" variant="success" class="btn-action">Compactar</button-form>
                </div>
            </form>
        </b-modal>

        <b-modal ref="modalExtract" size="md" title="Descompactar arquivo" centered hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
            <form accept-charset="UTF-8" @submit.prevent="extract">
                <p class="mb-4">
                    <strong>Arquivo:</strong> {{ fileToExtract.file ? fileToExtract.file.name : '' }}<br>
                    <strong>Destino:</strong> {{ fileToExtract.dest }}
                </p>

                <form-group :error="errors.deleteArchive">
                    <b-form-checkbox v-model="fileToExtract.deleteArchive">
                        Excluir arquivo após descompactar
                    </b-form-checkbox>
                </form-group>

                <div class="alert alert-warning w-100 mx-0">
                    <strong>Atenção:</strong> Os arquivos que possuírem o mesmo nome no diretório de destino serão sobrescritos.
                </div>

                <div ref="extractAlert" class="alert alert-warning w-100 mx-0" style="display:none">
                    <i class="far fa-clock mr-1"></i>
                    A descompactação pode demorar alguns minutos...
                </div>

                <div class="form-buttons text-right">
                    <button type="button" ref="cancelExtract" class="btn btn-secondary btn-action mr-2" @click="$refs.modalExtract.hide()">Cancelar</button>
                    <button-form ref="submitExtract" type="submit" variant="success" class="btn-action">Descompactar</button-form>
                </div>
            </form>
        </b-modal>

        <modal-confirm ref="modalConfirmRemove" title="Confirmação de exclusão" confirm-text="Remover" danger @confirm="remove">
            <p>Tem certeza que deseja excluir os seguintes arquivos:</p>
            <div class="card">
                <ul class="list-group list-group-flush" style="max-height:260px;overflow-y:scroll">
                    <li class="list-group-item py-1 px-2" v-for="item in filesToModify">
                        <span class="text-monospace">{{ item.path }}</span>
                    </li>
                </ul>
            </div>
        </modal-confirm>

        <b-modal ref="modalDownloadMultiple" size="md" title="Download de múltiplos arquivos" centered hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
            <p>
                Para fazer o download de múltiplos arquivos ou de uma pasta será gerado um arquivo
                compactado com os itens selecionados.
            </p>
            <p>
                Arquivos e pastas a serem compactados:
            </p>

            <div class="card mb-4">
                <ul class="list-group list-group-flush" style="max-height:260px;overflow-y:scroll">
                    <li class="list-group-item py-1 px-2" v-for="item in filesToModify">
                        <span class="text-monospace">{{ item.path }}</span>
                    </li>
                </ul>
            </div>

            <div class="alert alert-warning">
                <strong>Atenção:</strong> Certifique-se que sua hospedagem possui espaço de armazenamento disponível
                para o arquivo gerado.
            </div>

            <div ref="alertDownloadMultiple" class="alert alert-warning w-100 mx-0" style="display:none">
                <i class="far fa-clock mr-1"></i>
                A compactação pode demorar alguns minutos...
            </div>

            <div class="form-buttons text-right">
                <button ref="cancelDownloadMultiple" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalDownloadMultiple.hide()">Cancelar</button>
                <button-form ref="submitDownloadMultiple" type="submit" variant="success" class="btn-action" @click="downloadMultiple">Compactar e Baixar</button-form>
            </div>
        </b-modal>

        <b-modal ref="modalChangePermissions" size="md" title="Alterar permissões" centered hide-footer hide-header-close>
            <form accept-charset="UTF-8" @submit.prevent="changePermissions">
                <form-group label="Arquivo" label-for="permissionsFile">
                    <input type="text" id="permissionsFile" class="form-control text-monospace" v-model="permissions.name" readonly>
                </form-group>

                <form-group label="Permissões de usuário">
                    <div class="row px-2">
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.u_r" @change="updateNumericPermissions">Leitura</b-form-checkbox>
                        </div>
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.u_w" @change="updateNumericPermissions">Gravação</b-form-checkbox>
                        </div>
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.u_x" @change="updateNumericPermissions">Execução</b-form-checkbox>
                        </div>
                    </div>
                </form-group>

                <form-group label="Permissões de grupo">
                    <div class="row px-2">
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.g_r" @change="updateNumericPermissions">Leitura</b-form-checkbox>
                        </div>
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.g_w" @change="updateNumericPermissions">Gravação</b-form-checkbox>
                        </div>
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.g_x" @change="updateNumericPermissions">Execução</b-form-checkbox>
                        </div>
                    </div>
                </form-group>

                <form-group label="Permissões públicas">
                    <div class="row px-2">
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.o_r" @change="updateNumericPermissions">Leitura</b-form-checkbox>
                        </div>
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.o_w" @change="updateNumericPermissions">Gravação</b-form-checkbox>
                        </div>
                        <div class="col-4">
                            <b-form-checkbox v-model="permissions.o_x" @change="updateNumericPermissions">Execução</b-form-checkbox>
                        </div>
                    </div>
                </form-group>

                <form-group label="Valor numérico (octal)" label-for="permissionsNumeric" :error="errors.numeric">
                    <the-mask
                        type="tel"
                        id="permissionsNumeric"
                        class="form-control"
                        v-model="permissions.numeric"
                        mask="OOO"
                        :tokens="permissionTokens"
                        placeholder="000"
                        readonly />
                </form-group>

                <div class="form-buttons text-right">
                    <button ref="cancelChangePermissions" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalChangePermissions.hide()">Cancelar</button>
                    <button-form ref="submitChangePermissions" type="submit" variant="success" class="btn-action">Salvar</button-form>
                </div>
            </form>
        </b-modal>

        <!-- TODO -->
        <b-modal ref="modalTips" size="md" title="Atalhos do teclado" centered hide-footer hide-header-close>
            <table class="table">
                <tbody>
                    <tr>
                        <td class="col-100 border-top-0">
                            <kbd><kbd>Ctrl</kbd> + <kbd>A</kbd></kbd>
                        </td>
                        <td class="border-top-0">Selecionar todos os arquivos</td>
                    </tr>
                    <tr>
                        <td class="col-100">
                            <kbd><kbd>Alt</kbd> + <kbd>R</kbd></kbd>
                        </td>
                        <td>Atualizar lista de arquivos</td>
                    </tr>
                    <tr>
                        <td class="col-100">
                            <kbd>F2</kbd>
                        </td>
                        <td>Renomear arquivo selecionado</td>
                    </tr>
                    <tr>
                        <td class="col-100">
                            <kbd>Delete</kbd>
                        </td>
                        <td>Excluir arquivo(s)</td>
                    </tr>
                </tbody>
            </table>

            <div class="pb-4"></div>

            <h5 class="text-primary font-weight-bold">Ações do Gerenciador</h5>

            <table class="table mb-0">
                <tbody>
                    <tr>
                        <td class="border-top-0">
                            <strong>Duplo clique em um arquivo</strong><br>
                            Faz o download do arquivo ou abre o editor se for um arquivo editável.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Duplo clique em uma pasta</strong><br>
                            Abrir pasta e listar arquivos.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Selecionar arquivos e clicar no botão Download</strong><br>
                            Será gerado um arquivo compactado com os arquivos selecionados e após a conclusão o
                            arquivo compactado será baixado.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Mover arquivos para uma pasta</strong><br>
                            Selecione um ou mais arquivos e arraste-os para uma pasta para movê-los. Você pode
                            movê-los para uma pasta em um nível superior arrastando-os para a pasta com o nome
                            ".." no início da lista.
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-modal>

    </hosting-files-template>
</template>

<script>
    import { mapActions }       from 'vuex'
    import editor               from 'vue2-ace-editor'
    import { ApiCancel }        from '@/plugins/api'
    import AjaxService          from '@/services/AjaxService'
    import ErrorService         from '@/services/error.service'
    import ButtonForm           from '@/components/atoms/ButtonForm'
    import ModalConfirm         from '@/components/atoms/ModalConfirm'
    import TextLoading          from '@/components/atoms/TextLoading'
    import CardTitle            from '@/components/molecules/CardTitle'
    import FormGroup            from '@/components/molecules/FormGroup'
    import FileManager          from '@/components/organisms/FileManager'
    import HostingFilesTemplate from '@/components/templates/HostingFilesTemplate'

    export default {
        props: ['resource'],
        components: {
            editor,
            TextLoading,
            ModalConfirm,
            ButtonForm,
            FormGroup,
            FileManager,
            CardTitle,
            HostingFilesTemplate
        },
        data() {
            return {
                files: [],
                currentPath: "",
                newFolder: {},
                newFile: {},
                filesToModify: [],
                errors: {},

                filesToCompress: {},
                fileToExtract: {},

                filesToUpload: [],
                countUploads: 0,

                permissions: {},
                permissionTokens: {
                    O: {
                        pattern: /[0-7]/,
                    }
                },

                editor: {
                    file: "",
                    content: "",
                    lang: "",
                    options: {

                    }
                },
                editorOptions: {
                    minLines: 45,
                    tabSize: 4,
                    useSoftTabs: true,
                },

                cancel: null,
                cancelTokens: {},
                isLoadingFiles: false,
                isEditing: false,
                isLoadingContent: false,
                // isFullScreen: false,

                checkJobTimeout: null,
            }
        },
        mounted()
        {
            const CancelToken = ApiCancel.CancelToken;
            this.cancel = CancelToken.source();

            this.fetchFiles();
        },
        beforeDestroy()
        {
            this.cancel.cancel();
        },
        beforeRouteLeave(to, from, next)
        {
            this.checkJobTimeout && clearTimeout(this.checkJobTimeout);
            AjaxService.cancel();
            next();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert: 'alert/error'
            }),

            /**
             * Inicializar editor de arquivos.
             * TODO: Carregar de forma assíncrona
             */
            initEditor()
            {
                let vm = this;

                require("brace/theme/monokai");
                require("brace/ext/language_tools");
                require("brace/ext/searchbox");
                require("brace/mode/html");
                require("brace/mode/css");
                require("brace/mode/javascript");
                require("brace/mode/json");
                require("brace/mode/php");
                require("brace/mode/ini");
                require("brace/mode/xml");
                require("brace/mode/apache_conf");

                vm.$refs.editor.editor.renderer.setScrollMargin(10, 10);

                /*
                vm.$refs.editor.editor.commands.addCommand({
                    name: "Alternar tela cheia",
                    bindKey: "F11",
                    exec: function(editor) {
                        vm.isFullScreen = !vm.isFullScreen;

                        if (vm.isFullScreen) {
                            vm.$refs.editor.$el.classList.add("full-screen");
                        }
                        else {
                            vm.$refs.editor.$el.classList.remove("full-screen");
                        }

                        editor.setAutoScrollEditorIntoView(!vm.isFullScreen);
                        editor.resize();
                    }
                });
                */
            },

            /**
             * Carregar lista de arquivos.
             */
            fetchFiles(path, history)
            {
                const CancelToken = ApiCancel.CancelToken;
                let vm = this;

                let _params   = {},
                    _path     = typeof path !== 'undefined' && path ? path : '',
                    _new_path = _path || '/',
                    _history  = typeof history !== 'undefined' ? history : true;

                if (_path) {
                    _params['path'] = _path;
                }

                this.$set(this, 'currentPath', _new_path);

                this.isLoadingFiles = true;

                if (this.cancelTokens['fetchFiles']) {
                    this.cancelTokens.fetchFiles();
                }

                let promise = this.$api({
                    method: 'get',
                    url: `/hosting/${this.resource}/files`,
                    params: _params,
                    // cancelToken: new CancelToken(function executor(c) {
                    //     vm.cancelTokens['fetchFiles'] = c;
                    // })
                    cancelToken: AjaxService.getCancelToken(),
                });

                promise.then((response) => {
                        let _new_files = response.data.data.files;
                        let _new_path  = response.data.data.path;

                        this.$set(this, 'files', _new_files);
                        this.$set(this, 'currentPath', _new_path);

                        this.$refs.filemanager.removeSelection();

                        if (_history) {
                            this.$refs.filemanager.historyPush(this.currentPath);
                        }

                        this.isLoadingFiles = false;
                    })
                    .catch((error) => {
                        this.showErrorAlert(error)
                        this.isLoadingFiles = false;
                    });
            },

            /**
             *
             */
            sortFiles()
            {
                this.files.sort(function(a, b) {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();

                    if (a.type === 'dir' && b.type !== 'dir') return -1;
                    if (a.type !== 'dir' && b.type === 'dir') return 1;

                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            },

            /**
             *
             */
            fileEnter(file)
            {
                if (file.type === 'dir') {
                    this.fetchFiles(file.path);
                }
                else {
                    if (file.editable) {
                        this.$refs.filemanager.disableKeyboardEvents();
                        this._fileEdit(file);
                    }
                    else {
                        this.downloadSingle(file);
                    }
                }
            },

            /**
             * Exibir editor de arquivo.
             */
            _fileEdit(file)
            {
                const CancelToken = ApiCancel.CancelToken;
                let vm = this;

                let _params = {
                    'path': file.path,
                };

                this.editor.file = file.path;
                this.isEditing = true;
                this.isLoadingContent = true;
                this.$refs.btnSaveFile.disabled = true;

                if (this.cancelTokens['fileEdit']) {
                    this.cancelTokens.fileEdit();
                }

                let promise = this.$api({
                    method: 'post',
                    url: `/hosting/${this.resource}/files/show`,
                    data: _params,
                    cancelToken: new CancelToken(function executor(c) {
                        vm.cancelTokens['fileEdit'] = c;
                    })
                });

                promise
                    .then ((response) => {
                        this.editor.lang = response.data.data.lang;
                        this.$refs.editor.editor.session.setValue(response.data.data.content, 1);
                        this.isLoadingContent = false;
                        this.$refs.btnSaveFile.disabled = false;
                    })
                    .catch((error) => {
                        this.showErrorAlert(error);
                        this.isLoadingContent = false;
                    });
            },

            saveFile()
            {
                let _params = {
                    'path': this.editor.file,
                    'content': this.editor.content,
                };

                this.$refs.btnSaveFile.focus();
                this.$refs.btnSaveFile.disabled = true;
                this.$refs.btnSaveFile.classList.add("btn-loading");
                this.$refs.btnCancelFile.disabled = true;

                this.$api.post(`/hosting/${this.resource}/files/edit`, _params)
                    .then (() => this.successNotification({ message: 'Arquivo salvo com sucesso' }))
                    .catch((error) => this.showErrorAlert(error))
                    .then (() => {
                        this.$refs.btnSaveFile.disabled = false;
                        this.$refs.btnSaveFile.classList.remove("btn-loading");
                        this.$refs.btnCancelFile.disabled = false;
                    });
            },

            /**
             * Cancelar edição de arquivo.
             */
            cancelEdit()
            {
                if (this.cancelTokens['fileEdit']) {
                    this.cancelTokens.fileEdit();
                }

                this.isEditing = false;
                this.editor.file = "";
                this.editor.content = "";
                this.editor.lang = "html"; // Padrão como HTML

                this.$refs.filemanager.enableKeyboardEvents();
            },

            getFormattedFilePath(path)
            {
                if (!path) return "";

                const parts = path.split("/");
                const last = parts.splice(-1,1);
                let html = "";

                parts.forEach((el) => {
                    if (!el) return;
                    html += '<span class="px-1">/</span>' + el;
                });

                html += '<span class="px-1">/</span><strong>'+ last +'</strong>';

                return html;
            },

            changeFolder(path, history)
            {
                const _history = typeof history !== 'undefined' ? history : true;
                this.fetchFiles(path, _history);
            },

            fileRename(value, file, index)
            {
                // Se o nome não mudou, cancela a ação
                if (value == this.files[index].name) return;

                this.$set(this.files[index], 'oldName', this.files[index].name);
                this.$set(this.files[index], 'name', value);
                this.$set(this.files[index], 'loading', true);

                let params = {
                    'file': this.files[index].path,
                    'name': value,
                };

                this.$api.post(`/hosting/${this.resource}/files/rename`, params)
                    .then ((response) => {
                        const newIndex = this.files.findIndex(el => el.id == file.id);
                        this.files[newIndex].path = response.data.data.path;
                    })
                    .catch((error) => {
                        alert('Ocorreu um erro ao tentar renomear o arquivo '+ this.files[newIndex].oldName);

                        const newIndex = this.files.findIndex(el => el.id == file.id);
                        this.files[newIndex].name = this.files[newIndex].oldName;
                    })
                    .then (() => {
                        const newIndex = this.files.findIndex(el => el.id == file.id);
                        delete this.files[newIndex].oldName;
                        this.$set(this.files[newIndex], 'loading', false);
                        this.sortFiles();
                    });

                // Reordenar arquivos após renomear
                this.sortFiles();
            },

            fileMove(files, dest)
            {
                let _files = [];

                files.forEach((el) => { _files.push(el.path) });

                const params = {
                    'file': _files,
                    'dest': dest.path,
                };

                files.forEach((file) => {
                    let index = this.files.findIndex(_el => _el.id == file.id);
                    this.$delete(this.files, index);
                });

                this.$api.post(`/hosting/${this.resource}/files/move`, params, { cancelToken: this.cancel.token })
                    .then ((response) => {})
                    .catch((error) => {
                        alert('Ocorreu um erro ao tentar mover os arquivos.');
                        this.fetchFiles(this.currentPath);
                    });
            },

            fileDownload(items)
            {
                if (items.length === 1 && items[0].type !== 'dir') {
                    this.downloadSingle(items[0]);
                }
                else {
                    this.filesToModify = items;
                    this.showDownloadMultipleModal();
                }
            },

            downloadSingle(file)
            {
                // TODO
                // let $alert = GlobalAlert.add("Preparando o arquivo para download...", "info", 0);

                const data = {
                    'name': file.name,
                    'path': file.path,
                };

                this.$api.post(`/hosting/${this.resource}/files/download`, data)
                    .then ((response) => {
                        // TODO
                        // $alert.remove();
                        // GlobalAlert.success("Iniciando download...");

                        // Download
                        let anchor = document.createElement('a');
                        anchor.href = response.data.data.url;
                        anchor.download = data.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                    })
                    .catch((error) => {
                        // TODO
                        // $alert.remove();
                        alert('Ocorreu um erro ao tentar fazer o download do arquivo');
                    });
            },

            showDownloadMultipleModal()
            {
                this.$refs.modalDownloadMultiple.show();
            },

            /**
             * Download de múltiplos arquivos.
             * Obs: Será gerado um arquivo compactado com os arquivos selecionados.
             */
            downloadMultiple()
            {
                this.$refs.submitDownloadMultiple.loadingFocus();
                this.$refs.cancelDownloadMultiple.disabled = true;

                this.isLoadingFiles = true;

                let data = {
                    dir: this.currentPath,
                    files: [],
                };

                this.filesToModify.forEach((el) => { data.files.push(el.path) });

                // TODO
                // let $alert = GlobalAlert.add("Compactando arquivos para o download...", "info", 0);

                // TODO
                // let alertTimeout = setTimeout(() => { $(this.$refs.alertDownloadMultiple).show(); }, 5000);

                this.$api.post(`/hosting/${this.resource}/files/compress?download=1`, data)
                    .then(response => {
                        this.$refs.modalDownloadMultiple.hide();

                        // TODO
                        // GlobalAlert.success("Arquivos compactados com sucesso!");
                        // GlobalAlert.success("Iniciando download...");

                        this.fetchFiles(this.currentPath);

                        // Download
                        let anchor = document.createElement('a');
                        anchor.href = response.data.data.url;
                        anchor.download = response.data.data.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.submitDownloadMultiple.setLoading(false);
                        this.$refs.cancelDownloadMultiple.disabled = false;
                        this.isLoadingFiles = false;
                    })
                    .then(() => {
                        // TODO
                        // $alert.remove();
                        // clearTimeout(alertTimeout);
                    });
            },

            /**
             *
             */
            fileUpload(file)
            {
                this.filesToUpload.push(file);
                this._nextUpload();
            },

            _nextUpload()
            {
                if (!this.filesToUpload.length || this.countUploads >= 5) return;

                const file = this.filesToUpload.shift();
                this._uploadFile(file);
            },

            /**
             * Upload a file to server.
             */
            _uploadFile(file)
            {
                this.countUploads++;

                let formData = new FormData();

                formData.append('dir',  this.currentPath);
                formData.append('path', file.name);
                formData.append('file', file.file, file.name);

                let promise = this.$api({
                    method: 'post',
                    url: `/hosting/${this.resource}/files/upload`,
                    data: formData,
                    headers: { 'content-type': 'multipart/form-data' },
                });

                promise
                    .then((response) => {
                        this.$refs.filemanager.updateUploadFile(file, { success: true });
                    })
                    .catch((error) => {
                        let message = typeof error.response.data.message !== "undefined" ? error.response.data.message : "Erro";
                        this.$refs.filemanager.updateUploadFile(file, { error: message });
                    })
                    .then (() => {
                        this.countUploads--;

                        // Do stuff here

                        this._nextUpload();
                    });
            },

            /**
             * Exibir diálogo para criação de nova pasta.
             */
            showCreateFolderModal()
            {
                this.newFolder = {};
                this.$refs.modalCreateFolder.show();
            },

            /**
             * Criar nova pasta.
             */
            createFolder()
            {
                this.errors = {};

                this.$refs.submitCreateFolder.loadingFocus();
                this.$refs.cancelCreateFolder.disabled = true;

                this.newFolder.path = this.currentPath;

                this.$api.post(`/hosting/${this.resource}/files/create-folder`, this.newFolder)
                    .then(response => {
                        this.$refs.modalCreateFolder.hide();
                        this.fetchFiles(this.currentPath);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.submitCreateFolder.setLoading(false);
                        this.$refs.cancelCreateFolder.disabled = false;
                    });
            },

            /**
             * Exibir diálogo para criação de novo arquivo.
             */
            showCreateFileModal()
            {
                this.newFile = {};
                this.$refs.modalCreateFile.show();
            },

            /**
             * Criar nova pasta.
             */
            createFile()
            {
                this.errors = {};

                this.$refs.submitCreateFile.loadingFocus();
                this.$refs.cancelCreateFile.disabled = true;

                this.newFile.path = this.currentPath;

                this.$api.post(`/hosting/${this.resource}/files/create-file`, this.newFile)
                    .then(response => {
                        this.$refs.modalCreateFile.hide();
                        this.fetchFiles(this.currentPath);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.submitCreateFile.setLoading(false);
                        this.$refs.cancelCreateFile.disabled = false;
                    });
            },

            /**
             * Exibir diálogo para renomear arquivo ou pasta.
             */
            showRenameModal(file)
            {
                this.newFile = { file: file.path, name: file.name, oldName: file.name };
                this.$refs.modalRename.show();
            },

            /**
             * Renomear arquivo ou pasta.
             */
            rename()
            {
                this.errors = {};

                this.$refs.submitRename.loadingFocus();
                this.$refs.cancelRename.disabled = true;

                this.$api.post(`/hosting/${this.resource}/files/rename`, this.newFile)
                    .then(response => {
                        this.$refs.modalRename.hide();
                        this.fetchFiles(this.currentPath);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.submitRename.setLoading(false);
                        this.$refs.cancelRename.disabled = false;
                    });
            },

            /**
             * Exibir diálogo para compactar arquivos.
             */
            showCompressModal(files)
            {
                this.filesToCompress = {
                    'format': 'zip',
                    'name': files[0].name + '.zip',
                    'files': files,
                };

                this.errors = {};
                this.$refs.modalCompress.show();
            },

            /**
             * Atualizar nome do arquivo comprimido ao alterar o formato.
             */
            updateCompressFormat(value)
            {
                let newFilename = this.filesToCompress.files[0].name;
                let extension = value;

                if (extension === 'gz' && this.filesToCompress.files.length > 1) {
                    extension = 'tar.gz';
                }

                newFilename += '.' + extension;

                this.filesToCompress.name = newFilename;
            },

            /**
             * Compactar arquivos.
             */
            compress()
            {
                this.$refs.submitCompress.loadingFocus();
                this.$refs.cancelCompress.disabled = true;

                this.isLoadingFiles = true;

                let data = {
                    dir: this.currentPath,
                    format: this.filesToCompress.format,
                    name: this.filesToCompress.name,
                    files: [],
                };

                this.filesToCompress.files.forEach((el) => { data.files.push(el.path) });

                // TODO
                // let $alert = GlobalAlert.add("Compactando arquivos...", "info", 0);

                // TODO
                // let alertTimeout = setTimeout(() => { $(this.$refs.compressAlert).show(); }, 5000);

                this.$api.post(`/hosting/${this.resource}/files/compress`, data)
                    .then(response => {
                        this.$refs.modalCompress.hide();

                        // TODO
                        // GlobalAlert.success("Arquivos compactados com sucesso!");

                        this.fetchFiles(this.currentPath);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.submitCompress.setLoading(false);
                        this.$refs.cancelCompress.disabled = false;
                        this.isLoadingFiles = false;
                    })
                    .then(() => {
                        // TODO
                        // $alert.remove();
                        // clearTimeout(alertTimeout);
                        // $(this.$refs.compressAlert).hide();
                    });
            },

            /**
             * Exibir diálogo para descompactar um arquivo.
             */
            showExtractModal(file)
            {
                this.fileToExtract = {
                    'file': file,
                    'deleteArchive': false,
                    'dest': this.currentPath,
                };

                this.errors = {};
                this.$refs.modalExtract.show();
            },

            /**
             * Descompactar arquivo.
             */
            extract()
            {
                this.$refs.submitExtract.loadingFocus();
                this.$refs.cancelExtract.disabled = true;

                this.isLoadingFiles = true;

                let data = {
                    file: this.fileToExtract.file.path,
                    dir: this.fileToExtract.dest,
                    deleteArchive: this.fileToExtract.deleteArchive,
                };

                // TODO
                // let $alert = GlobalAlert.add("Descompactando arquivo...", "info", 0);

                // TODO
                // let alertTimeout = setTimeout(() => { $(this.$refs.extractAlert).show(); }, 5000);

                this.$api.post(`/hosting/${this.resource}/files/extract`, data)
                    .then(response => {
                        // this.$refs.modalExtract.hide();

                        // TODO
                        // GlobalAlert.success("Arquivo descompactado com sucesso!");

                        // this.fetchFiles(this.currentPath);

                        const jobId = response.data.data.job;
                        this.checkJobTimeout = setTimeout(() => this.checkExtractJob(jobId), 3000);

                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.submitExtract.setLoading(false);
                        this.$refs.cancelExtract.disabled = false;
                        this.isLoadingFiles = false;
                    })
                    .then (() => {
                        // TODO
                        // $alert.remove();
                        // clearTimeout(alertTimeout);
                        // $(this.$refs.extractAlert).hide();
                    });
            },

            checkExtractJob(id)
            {
                this.$api.get(`/job/${id}`)
                    .then(response => {
                        const status = response.data.data.status;

                        if (status === 'queued' || status === 'executing') {
                            this.checkJobTimeout = setTimeout(() => this.checkExtractJob(id), 5000);
                        }
                        else {
                            this.$refs.modalExtract.hide();
                            this.fetchFiles(this.currentPath);
                            setTimeout(() => this.successNotification({ message: 'Arquivo extraído com sucesso!' }), 1000);
                        }
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.modalExtract.hide();
                        this.fetchFiles(this.currentPath);
                    })
            },

            /**
             * Exibir diálogo para confirmação de exclusão.
             * @param items
             */
            confirmRemove(items)
            {
                this.filesToModify = items;
                this.$refs.modalConfirmRemove.show();
            },

            /**
             * Remover arquivos.
             */
            remove()
            {
                this.isLoadingFiles = true;

                let _files = [];
                this.filesToModify.forEach((el) => { _files.push(el.path) });

                // TODO
                // let $alert = GlobalAlert.add("Excluindo arquivos...", "info", 0);

                this.$api.post(`/hosting/${this.resource}/files/remove`, { files: _files })
                    .then(response => {
                        this.fetchFiles(this.currentPath);
                        this.successNotification({ message: 'Arquivos excluídos com sucesso!' });
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.isLoadingFiles = false;
                    })
                    .then (() => {
                        // TODO
                        // $alert.remove()
                    });
            },

            showChangePermissionsModal(file)
            {
                this.permissions = {
                    file: file.path,
                    name: file.name,
                    numeric: file.permissions,
                };

                if (file.permissions && file.permissions.length === 3)
                {
                    // Usuário
                    const permUser = file.permissions.charAt(0);
                    const permUserBin = parseInt(permUser).toString(2);

                    this.permissions.u_r = (permUserBin.charAt(0) === "1");
                    this.permissions.u_w = (permUserBin.charAt(1) === "1");
                    this.permissions.u_x = (permUserBin.charAt(2) === "1");

                    // Grupo
                    const permGroup = file.permissions.charAt(1);
                    const permGroupBin = parseInt(permGroup).toString(2);

                    this.permissions.g_r = (permGroupBin.charAt(0) === "1");
                    this.permissions.g_w = (permGroupBin.charAt(1) === "1");
                    this.permissions.g_x = (permGroupBin.charAt(2) === "1");

                    // Outros
                    const permOthers = file.permissions.charAt(2);
                    const permOthersBin = parseInt(permOthers).toString(2);

                    this.permissions.o_r = (permOthersBin.charAt(0) === "1");
                    this.permissions.o_w = (permOthersBin.charAt(1) === "1");
                    this.permissions.o_x = (permOthersBin.charAt(2) === "1");
                }

                this.$refs.modalChangePermissions.show();
            },

            /**
             * Atualizar valor das permissões em formato numérico.
             */
            updateNumericPermissions()
            {
                this.$nextTick(() => {
                    const permUserBin   = (+this.permissions.u_r).toString() + (+this.permissions.u_w).toString() + (+this.permissions.u_x).toString();
                    const permGroupBin  = (+this.permissions.g_r).toString() + (+this.permissions.g_w).toString() + (+this.permissions.g_x).toString();
                    const permOthersBin = (+this.permissions.o_r).toString() + (+this.permissions.o_w).toString() + (+this.permissions.o_x).toString();

                    const permNumeric = parseInt(permUserBin, 2).toString() + parseInt(permGroupBin, 2).toString() +parseInt(permOthersBin, 2).toString();

                    this.$set(this.permissions, "numeric", permNumeric);
                });
            },

            /**
             *
             */
            changePermissions()
            {
                this.errors = {};

                this.$refs.submitChangePermissions.loadingFocus();
                this.$refs.cancelChangePermissions.disabled = true;

                const postData = {
                    file: this.permissions.file,
                    permissions: this.permissions.numeric,
                };

                this.$api.post(`/hosting/${this.resource}/files/permissions`, postData)
                    .then((response) => {
                        this.$refs.modalChangePermissions.hide();
                        this.fetchFiles(this.currentPath);
                    })
                    .catch((error) => {
                        // HandleErrors.formError(error, this);
                        this.$refs.submitChangePermissions.setLoading(false);
                        this.$refs.cancelChangePermissions.disabled = false;
                    });
            },
        }
    }
</script>