<template>
    <domain-template :resource="resource">
        <card-title title="Informações de Contato" inner-body>
            <text-loading />
        </card-title>
    </domain-template>
</template>

<script>
    import CardTitle        from '@/components/molecules/CardTitle'
    import DomainTemplate   from '@/components/templates/DomainTemplate'
    import TextLoading from '@/components/atoms/TextLoading'
    import ErrorService from '@/services/error.service'

    export default {
        name: 'DomainContact',
        props: ['resource'],
        components: {
            TextLoading,
            CardTitle,
            DomainTemplate
        },
        mounted()
        {
            setTimeout(function() { ErrorService.handleFormError({}, this); }, 3000);
        }
    }
</script>
