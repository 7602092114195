<template>
    <card-title class="h-o-card-hosting-top">
        <div class="row">
            <div class="col-lg-8">
                <div class="d-flex align-items-center mb-4 mb-sm-6">
                    <div class="h-server-logo-wrapper d-none d-sm-block">
                        <img src="/images/logo-php.png" width="96" height="96" alt="PHP">
                    </div>
                    <div class="flex-grow-1">
                        <h4 class="ht-hosting-domain text-truncate mb-2">
                            <a :href="`http://${hosting$.domain}`" target="_blank">{{ hosting$.domain }}</a>
                        </h4>

                        <div class="ht-hosting-functions">
                            <button v-if="hosting$.paused" type="button" class="btn btn-light btn-play" title="Reativar" :disabled="$acl.not.check('hasWebsite')" @click="unpauseHosting(hosting$)">
                                <i class="fas fa-play"></i>
                            </button>

                            <button v-else type="button" class="btn btn-light" title="Pausar" :disabled="$acl.not.check('hasWebsite') || !isHostingActive" @click="pauseHosting(hosting$)">
                                <i class="fas fa-pause"></i>
                            </button>

                            <router-link v-if="$acl.check('isOwner')" class="btn btn-light" title="Alterar plano" :to="`/hosting/${hosting$.domain}/plan`">
                                <i class="fas fa-sign-out-alt" style="transform: rotate(270deg) translateX(1px);"></i>
                            </router-link>

                            <router-link v-if="$acl.check('isOwner')" class="btn btn-light" title="Alterar domínio" :to="`/hosting/${hosting$.domain}/domain/edit`">
                                <i class="fas fa-pen"></i>
                            </router-link>

                            <router-link v-if="$acl.check('isOwner')" class="btn btn-light" title="Transferir hospedagem" :to="`/hosting/${hosting$.domain}/transfer`">
                                <i class="fas fa-random"></i>
                            </router-link>

                            <router-link v-if="$acl.check('isOwner')" class="btn btn-light" title="Excluir hospedagem" :to="`/hosting/${hosting$.domain}/remove`">
                                <i class="far fa-trash-alt"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="ht-hosting-states">
                    <router-link
                        class="btn"
                        active-class=""
                        exact-active-class=""
                        v-bind:class="buttonStatusClasses"
                        v-html="hosting$.statusText"
                        :to="`/hosting/${hosting$.domain}`"/>

                    <router-link
                        v-if="this.$acl.check('hasWebsite') && this.hosting$.status === 'Active'"
                        class="btn"
                        active-class=""
                        exact-active-class=""
                        v-bind:class="[ ssl.active ? 'btn-borderless-success' : 'btn-borderless-secondary' ]"
                        :to="`/hosting/${hosting$.domain}/ssl`"
                    >
                        <span v-if="isLoadingSsl"><i class="fas fa-lock-open"></i> <i class="fas fa-circle-notch fa-spin"/></span>
                        <span v-else-if="ssl.active"><i class="fas fa-lock"></i> HTTPS</span>
                        <span v-else><i class="fas fa-lock-open"></i> HTTP</span>
                    </router-link>

                    <router-link
                        v-if="this.$acl.check('hasWebsite') && this.hosting$.status === 'Active'"
                        type="button"
                        ref="btnPhpVersion"
                        class="btn"
                        active-class=""
                        exact-active-class=""
                        v-bind:class="[ isPhpVersionUpdated ? 'btn-borderless-success' : 'btn-borderless-secondary' ]"
                        :to="`/hosting/${hosting$.domain}/php`"
                        :title="isPhpVersionUpdated ? 'Você está utilizando uma versão atualizada do PHP' : ''"
                        v-b-tooltip.hover.bottomright
                    >
                        PHP
                        <i class="fas fa-circle-notch fa-spin" v-if="isLoadingPhp"/>
                        <span v-if="php.version" v-html="php.versionText"></span>
                    </router-link>

                    <router-link
                        v-if="$acl.check('isOwner')"
                        class="btn btn-borderless-secondary"
                        active-class=""
                        exact-active-class=""
                        to="/user/access"
                    >
                        <i class="fas fa-user-friends"></i> Compartilhar
                    </router-link>
                </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
                <progress-resource title="CPU" color="#F88196" v-model="usage.cpu" :loading="isLoadingResourceUsage"/>
                <progress-resource title="RAM" color="#65C9DB" v-model="usage.ram" :loading="isLoadingResourceUsage"/>
                <progress-resource title="Disco" color="#F1CB0F" v-model="usage.disk" :loading="isLoadingDiskUsage"/>

                <div class="progress ht-progress-timer">
                    <div
                        role="progressbar"
                        class="progress-bar"
                        v-bind:class="{ 'full': timer === 0 }"
                        style="background-color:#3EC486"
                        v-bind:style="getTimerBarStyle"
                        :aria-valuenow="timer"
                        aria-valuemin="0"
                        aria-valuemax="60"></div>
                </div>

                <!--<div class="text-muted">
                    <i class="far fa-clock d-inline-block align-middle mr-1"></i>
                    <span class="font-italic d-inline-block align-middle" style="font-size:12px">
                        Atualização em 10 segundos...
                    </span>
                </div>-->
            </div>
        </div>

        <div class="mb-4 mb-sm-12"></div> <!-- spacer -->

        <nav class="nav nav-menu flex-column flex-sm-row h-menu-hosting">
            <template v-for="(item, index) in menuItems">
                <router-link
                    v-if="!item.hidden"
                    class="nav-link"
                    :key="index"
                    :to="item.link"
                    :exact="item.exact"
                    v-bind:class="_getMenuItemClasses(item)"
                    v-html="item.title" />
            </template>
        </nav>
    </card-title>
</template>

<script>
    import { mapActions }   from 'vuex'
    import { ApiCancel }    from '@/plugins/api'
    import hostingActions   from '@/mixins/hostingActions'
    import AjaxService      from '@/services/AjaxService'
    import ErrorService     from '@/services/ErrorService'
    import CardTitle        from '@/components/molecules/CardTitle'
    import ProgressResource from '@/components/molecules/ProgressResource'

    export default {
        mixins: [
            hostingActions
        ],
        props: {
            hosting: {
                type: [Object, Promise],
                required: true
            }
        },
        components: {
            ProgressResource,
            CardTitle
        },
        data() {
            return {
                // Local hosting data
                hosting$: {},

                // Status
                timeoutCheckStatus: null,

                // PHP
                php: {},
                isLoadingPhp: false,

                // SSL
                ssl: {},
                isLoadingSsl: false,

                // Usage
                usage: {
                    cpu:       0,
                    ram:       0,
                    disk:      0,
                    databases: 0,
                    emails:    0,
                },
                resourceUsageTimestamp: null,
                resourceUsageCancel: null,
                resourceUsageInterval: null,
                isLoadingResourceUsage: false,
                isLoadingDiskUsage: false,

                // Timer
                timer: 0,
                timerInterval: null,
            }
        },
        computed: {
            menuItems: function() {
                return [
                    {
                        title: "Resumo",
                        link: `/hosting/${this.hosting$.domain}`,
                        exact: true,
                    },
                    {
                        title: "Domínio",
                        link: `/hosting/${this.hosting$.domain}/domain`,
                        customClass: 'h-menu-item-domain',
                        children: [
                            `/hosting/${this.hosting$.domain}/domain`,
                            `/hosting/${this.hosting$.domain}/dns`,
                            `/hosting/${this.hosting$.domain}/alias`,
                            `/hosting/${this.hosting$.domain}/subdomain`,
                            `/hosting/${this.hosting$.domain}/redirect`,
                        ]
                    },
                    {
                        title: "Arquivos",
                        link: `/hosting/${this.hosting$.domain}/files`,
                        hidden: (this.$acl.not.check('hasWebsite') || this.hosting$.status !== 'Active'),
                        children: [
                            `/hosting/${this.hosting$.domain}/files`,
                            `/hosting/${this.hosting$.domain}/ftp`,
                            `/hosting/${this.hosting$.domain}/deploy`,
                            `/hosting/${this.hosting$.domain}/backup`,
                        ]
                    },
                    {
                        title: "Banco de Dados",
                        link: `/hosting/${this.hosting$.domain}/database`,
                        hidden: (this.$acl.not.check('hasWebsite') || this.hosting$.status !== 'Active'),
                    },
                    {
                        title: "E-mail",
                        link: `/hosting/${this.hosting$.domain}/email`,
                        hidden: (this.$acl.not.check('hasEmail') || this.hosting$.status !== 'Active'),
                        children: [
                            `/hosting/${this.hosting$.domain}/email`,
                            `/hosting/${this.hosting$.domain}/email/lists`,
                            `/hosting/${this.hosting$.domain}/email/access`,
                            `/hosting/${this.hosting$.domain}/antispam`,
                            `/hosting/${this.hosting$.domain}/webmail`,
                        ],
                    },
                    {
                        title: "Aplicativos",
                        link: `/hosting/${this.hosting$.domain}/apps`,
                        customClass: 'h-menu-item-apps',
                        hidden: (this.$acl.not.check('hasWebsite') || this.hosting$.status !== 'Active'),
                        children: [
                            `/hosting/${this.hosting$.domain}/apps`,
                            `/hosting/${this.hosting$.domain}/apps/install`,
                        ],
                    },
                    {
                        title: "Extras",
                        link: `/hosting/${this.hosting$.domain}/balancer`,
                        hidden: (this.$acl.not.check('hasWebsite') || this.hosting$.status !== 'Active'),
                        children: [
                            `/hosting/${this.hosting$.domain}/balancer`,
                        ],
                    },
                    {
                        title: "Configurações",
                        link: `/hosting/${this.hosting$.domain}/php`,
                        hidden: (this.$acl.not.check('hasWebsite') || this.hosting$.status !== 'Active'),
                        children: [
                            `/hosting/${this.hosting$.domain}/php`,
                            `/hosting/${this.hosting$.domain}/ssh`,
                            `/hosting/${this.hosting$.domain}/ssl`,
                            `/hosting/${this.hosting$.domain}/cron`,
                            `/hosting/${this.hosting$.domain}/logs`,
                        ],
                    }
                ]
            },
            buttonStatusClasses: function() {
                return {
                    'btn-borderless-secondary': this.hosting$.status === "Pending",
                    'btn-borderless-success':   this.hosting$.status === "Active",
                    'btn-borderless-warning':   ['Suspended', 'Paused', 'Restoring'].indexOf(this.hosting$.status) >= 0,
                }
            },
            isHostingActive: function () {
                return this.hosting$.status === 'Active';
            },
            isPhpVersionUpdated: function() {
                return (this.php.version && parseInt(this.php.version) >= 72);
            },
            getTimerBarStyle: function() {
                return {
                    width: Math.round(this.timer / 60 * 100) + "%",
                }
            }
        },
        mounted()
        {
            if (this.hosting) {
                this.hosting$ = this.hosting;
            }

            // this.updatePermissions();
            this.init();

            // Event listeners
            this.$eventBus.$on('php-changed', this.fetchPhpVersions);
        },
        destroyed()
        {
            if (this.resourceUsageCancel)   this.resourceUsageCancel();
            if (this.resourceUsageInterval) clearInterval(this.resourceUsageInterval);
            if (this.timerInterval)         clearInterval(this.timerInterval);
        },
        methods: {
            ...mapActions({
                showLoading: 'loading/show',
                hideLoading: 'loading/hide',
            }),

            _getMenuItemClasses(item) {
                let classes = "";

                if (typeof item.customClass !== 'undefined') {
                    classes += item.customClass;
                }

                if (item.children && Array.isArray(item.children)) {
                    for (let i = 0; i < item.children.length; i++) {
                        if (this.$route.path === item.children[i]) {
                            classes += " active";
                            break;
                        }
                    }
                }

                return classes;
            },

            /**
             * Atualizar dados da hospedagem.
             */
            setHosting(hosting)
            {
                if (typeof(hosting) === "undefined" || !hosting) {
                    // eslint-disable-next-line no-console
                    console.error("Missing hosting data.");
                    return;
                }

                if (typeof(hosting) !== 'object') {
                    // eslint-disable-next-line no-console
                    console.error("Hosting data is invalid.");
                    return;
                }

                // Cancelar consultas anteriores
                this.resetUsage();

                if (this.timeoutCheckStatus) {
                    clearTimeout(this.timeoutCheckStatus);
                }

                AjaxService.cancel();

                // Reinicializar painel
                this.hosting$ = hosting;
                this.init();
            },

            /**
             * Redefinir dados de consumo.
             */
            resetUsage()
            {
                this.usage.cpu       = 0;
                this.usage.ram       = 0;
                this.usage.disk      = 0;
                this.usage.databases = 0;
                this.usage.emails    = 0;

                this.resourceUsageTimestamp     = null;
                if (this.resourceUsageCancel)   this.resourceUsageCancel();
                if (this.resourceUsageInterval) clearInterval(this.resourceUsageInterval);
                if (this.timerInterval)         clearInterval(this.timerInterval);
            },

            /**
             * Inicializar funções.
             */
            init()
            {
                // if (this.hosting$.status === "Pending") {
                //     this.refreshStatus();
                // }
                if (this.hosting$.status === "Active") {
                    this.fetchSsl();
                    this.fetchPhpVersions();
                    // this.loadCharts();

                    this.isLoadingResourceUsage = true;
                    this.isLoadingDiskUsage = true;

                    this.getResourceUsage(); // Carregar consumo de CPU e RAM
                    this.getResourceUsage(true); // Carregar consumo de Disco

                    // Intervalo de 10 segundos para verificar consumo de CPU e RAM
                    if (process.env.NODE_ENV === 'production') {
                        this.resourceUsageInterval = setInterval(() => { this.getResourceUsage() }, 10000);
                    }
                }

                // this.checkDns();
            },

            // /**
            //  * Atualizar status da hospedagem.
            //  */
            // refreshStatus()
            // {
            //     this.$api.get(`/hosting/${this.hosting$.domain}`, { cancelToken: AjaxService.getCancelToken() })
            //         .then (response => {
            //             let status = response.data.data.status;
            //
            //             if (status === "Pending") {
            //                 this.timeoutCheckStatus = setTimeout(() => this.refreshStatus(), 15000);
            //             }
            //             else if (status === "Active") {
            //                 window.location.href = `/hosting/${this.hosting$.domain}`;
            //             }
            //             else {
            //                 alert(`Ocorreu um problema durante a configuração do site. Por favor, atualize sua página e tente novamente.`);
            //             }
            //         });
            // },

            /**
             * Obter informações sobre o certificado SSL da hospedagem.
             */
            fetchSsl()
            {
                this.isLoadingSsl = true;

                this.$api.get(`/hosting/${this.hosting$.domain}/ssl`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => this.ssl = response.data.data)
                    .catch(error => ErrorService.showErrorAlert(error))
                    .then (() => this.isLoadingSsl = false)
            },

            /**
             * Obter versões disponiveis do PHP e versão atual da hospedagem.
             */
            fetchPhpVersions()
            {
                this.isLoadingPhp = true;
                this.php = {};

                this.$api.get(`/hosting/${this.hosting$.domain}/php`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => this.php = response.data.data)
                    .catch(error => ErrorService.showErrorAlert(error))
                    .then (() => this.isLoadingPhp = false);
            },

            /**
             * Obter uso de recursos da hospedagem.
             */
            getResourceUsage(isDiskUsage)
            {
                const CancelToken = ApiCancel.CancelToken;
                const _isDiskUsage = typeof isDiskUsage !== "undefined" && isDiskUsage === true ? 1 : 0;
                let vm = this;
                let _params = {};

                if (_isDiskUsage) {
                    _params['disk'] = 1;
                }
                else {
                    if (this.resourceUsageTimestamp) {
                        _params['t'] = this.resourceUsageTimestamp;
                    }

                    // Cancelar verificação anterior
                    if (this.resourceUsageCancel) {
                        this.resourceUsageCancel();
                    }
                }

                let promiseConfig = {
                    method: 'get',
                    url: `/hosting/${this.hosting$.domain}/usage`,
                    params: _params,
                };

                if (!_isDiskUsage) {
                    promiseConfig.cancelToken = new CancelToken(function executor(c) {
                        vm.resourceUsageCancel = c;
                    })
                }

                let promise = this.$api.request(promiseConfig);

                promise
                    .then(response => {
                        if (response.status === 200) {
                            if (_isDiskUsage)
                            {
                                this.usage.disk      = Math.round(response.data.data.disk);
                                this.usage.databases = Math.round(response.data.data.databases);
                                this.usage.emails    = Math.round(response.data.data.emails);
                            }
                            else
                            {
                                this.usage.cpu = Math.round(response.data.data.cpu);
                                this.usage.ram = Math.round(response.data.data.ram);

                                if (response.data.data.timestamp) {
                                    this.resourceUsageTimestamp = response.data.data.timestamp;

                                    // Zerar o timer
                                    this.timer = 0;

                                    if (!this.timerInterval) {
                                        this.timerInterval = setInterval(() => { this.incrementTimer() }, 1000);
                                    }
                                }
                            }
                        }

                        if (_isDiskUsage) this.isLoadingDiskUsage = false;
                        else this.isLoadingResourceUsage = false;
                    })
                    .catch(() => { /* Ignore error */ });
            },

            /**
             * Incrementar timer até o máximo de 60.
             */
            incrementTimer()
            {
                this.timer = this.timer < 60 ? this.timer + 1 : this.timer;
            }
        }
    }
</script>

<style lang="stylus">
    .h-o-card-hosting-top
        .card-body
            padding-bottom 0

        .h-server-logo-wrapper
            margin-right 1.5rem
            border-radius 50%
            padding .5rem
            background-color $primary

        .ht-hosting-domain
            width 80%
            font-family "Nunito", sans-serif
            font-weight 700

            a
                text-decoration none !important

        .ht-hosting-functions
            .btn
                padding 0
                margin-right .5rem
                size 2.25rem
                border-radius 50%
                line-height 2.25rem
                background-color #F0F0F0
                border-color #F0F0F0
                color $gray-700

                &:hover
                    background-color darken(#F0F0F0, 10%)
                    border-color darken(#F0F0F0, 10%)

            .btn-play
                padding-left .25em

        .ht-hosting-states
            .btn
                margin-right .25rem
                font-size 12px
                font-weight 800
                text-transform uppercase

        .nav-menu
            @media (min-width 576px)
                border-top 1px solid rgba(#707070, .15)

            .nav-link
                position relative
                padding .5rem 1rem
                margin-right .5rem
                color $gray-900

                @media (min-width 576px)
                    padding 1.25rem 1rem

                &.active
                    color $primary
                    box-shadow inset 6px 0 0 $primary

                    @media (min-width 576px)
                        box-shadow none

                        &:before
                            content ""
                            absolute top -4px left 0
                            size 100% 7px
                            border-radius 4px
                            background-color $primary

        .ht-progress-timer
            border-radius 0
            height 1px

            .progress-bar
                transition width 1s linear
</style>