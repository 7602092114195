<template>
    <div class="h-o-hosting-region-list">
        <div class="row">
            <div class="col-sm-6">
                <b-form-radio-group button-variant="light" size="lg" class="d-block" buttons v-model="region" @input="$emit('input', region)">
                    <b-form-radio size="lg" value="US" class="btn-block">
                        <img src="/images/US.svg" class="icon" alt="" /> E.U.A.
                    </b-form-radio>
                </b-form-radio-group>
            </div>
            <div class="col-sm-6">
                <b-form-radio-group button-variant="light" size="lg" class="d-block" buttons v-model="region" @input="$emit('input', region)">
                    <b-form-radio size="lg" value="BR" class="btn-block">
                        <img src="/images/BR.svg" class="icon" alt="" /> Brasil
                    </b-form-radio>
                </b-form-radio-group>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HostingRegionList",
        props: {
            value: {
                type: String,
                default: "US"
            }
        },
        data() {
            return {
                region: ""
            }
        },
        mounted() {
            this.region = this.value;
        }
    }
</script>

<style lang="stylus">
    .h-o-hosting-region-list {
        .btn-group-toggle {
            > .btn {
                border 0
                height auto
                line-height 36px
                display flex
                flex-direction row
                justify-content center
                color $primary
                background-color #EFEFEF
                box-shadow none !important

                .icon {
                    width auto
                    height 36px
                    margin-right .75rem
                    margin-left -.75rem
                }
            }
            > .btn.active {
                background-color $primary
                color white
            }
        }
    }
</style>
