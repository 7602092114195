const state = {
    items: [],
    currentId: 0,
};

const actions = {
    add({ commit }, item) {
        commit('add', item);
        return item;
    },
    remove({ commit }, id) {
        commit('remove', id);
    }
};

const mutations = {
    add(state, item) {
        state.currentId++;
        item.id = state.currentId;
        state.items.push(item);
    },
    remove(state, id) {
        const idx = state.items.findIndex(el => el.id === id);
        state.items.splice(idx, 1);
    }
}

export const announcement = {
    namespaced: true,
    state,
    actions,
    mutations
}
