<template>
    <card-title title="Excluir hospedagem">
        <p>
            A hospedagem <strong>{{ this.hosting.domain }}</strong> está prestes a ser excluída.
        </p>

        <div class="alert alert-danger">
            <strong>Atenção:</strong> Ao realizar esta operação, todos os arquivos, bancos de dados, aplicativos,
            contas de e-mail e tudo o mais que estiver presente em sua hospedagem será excluído de forma
            <strong>irreversível</strong>. Alertamos ainda que hospedagens excluídas <strong>não podem ser restauradas</strong>!
        </div>

        <form accept-charset="UTF-8" @submit.prevent="removeHosting">
            <form-group :error="formErrors.domain">
                <p class="mb-1">Por segurança, digite abaixo o domínio do site a ser excluído:</p>
                <input type="text" id="hostingDomain" class="form-control" autocomplete="off" spellcheck="false" v-model="formData.domain" v-input-lowercase>
            </form-group >

            <form-group>
                <b-form-checkbox v-model="formData.acceptance">
                    Eu entendo que a Hostoo não se responsabiliza pela perda de informações causada por uma exclusão indevida da hospedagem.
                </b-form-checkbox>
            </form-group>

            <div v-show="showRemoveAlert" class="alert alert-warning">
                <i class="far fa-clock mr-1"></i>
                A exclusão de hospedagem pode demorar alguns minutos&hellip;
            </div>

            <div class="form-buttons text-right">
                <button-form ref="btnRemoveHosting" type="submit" theme="danger" class="btn-action" :disabled="!isConfirmed">Excluir</button-form>
            </div>
        </form>

        <b-modal ref="modalMovingOut" title="Antes de excluirmos..." centered hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
            <form @submit.prevent="sendFeedback">
                <p>
                    Percebemos que esta é sua última hospedagem aqui na Hostoo.
                </p>
                <p>
                    Esperamos que esteja gostando daqui e que ainda continue utilizando nossos serviços. Mas caso esteja
                    removendo seus serviços de forma definitiva, poderia nos deixar um feedback do motivo de estar saindo?
                </p>
                <p>
                    Estamos sempre aprendendo com nossos clientes e aprimorando nossos serviços para nos tornarmos cada
                    vez melhores.
                </p>
                <textarea rows="6" class="form-control" v-model="messageMovingOut" placeholder="Deixe seu feedback..."></textarea>
                <div class="form-buttons text-right">
                    <button ref="btnMovingOutCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalMovingOut.hide()">Cancelar exclusão</button>
                    <button-form ref="btnMovingOutSubmit" type="submit" theme="danger" class="btn-action">Excluir</button-form>
                </div>
            </form>
        </b-modal>
    </card-title>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ErrorService from '@/services/error.service'
    import ButtonForm   from '@/components/atoms/ButtonForm'
    import CardTitle    from '@/components/molecules/CardTitle'
    import FormGroup    from '@/components/molecules/FormGroup'

    export default {
        props: ['resource'],
        components: {
            ButtonForm,
            FormGroup,
            CardTitle
        },
        data() {
            return {
                formData: {},
                formErrors: {},
                isRemovalChecked: false,
                isRunning: false,
                messageMovingOut: "",
                showRemoveAlert: false
            }
        },
        computed: {
            ...mapState('hosting', ['hosting']),

            isConfirmed: function() {
                return (this.formData.domain === this.hosting.domain && this.formData.acceptance);
            }
        },
        beforeRouteLeave(to, from, next)
        {
            if (this.isRunning && !confirm('Existe um processo em execução no momento. Tem certeza que deseja sair desta página?')) return;
            next();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error',
            }),

            /**
             * Excluir hospedagem.
             */
            removeHosting()
            {
                this.$refs.btnRemoveHosting.loadingFocus();

                if (!this.isRemovalChecked) {
                    this.checkRemoval();
                    return;
                }

                this.formErrors = {};
                this.isRunning = true;
                this.showRemoveAlert = true;

                this.$api.post(`/hosting/${this.hosting.domain}/remove`, this.formData)
                    .then(response => {
                        if (response.status === 202) {
                            setTimeout(() => this.removeHosting(), 5000);
                        }
                        else {
                            this.isRunning = false;
                            window.location.href = '/';
                        }
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnRemoveHosting && this.$refs.btnRemoveHosting.setLoading(false);
                        this.showRemoveAlert = false;
                        this.isRunning = false;
                    });
            },

            /**
             * Verificação pré-exclusão.
             */
            checkRemoval()
            {
                this.$api.post(`/hosting/check-removal`)
                    .then(response => {
                        if (response.data.data.lastHosting === true) {
                            this.messageMovingOut = "";
                            this.$refs.modalMovingOut.show();
                            this.$refs.btnRemoveHosting.setLoading(false);
                        }
                        else {
                            this.isRemovalChecked = true;
                            this.removeHosting();
                        }
                    })
                    .catch(error => {
                        this.isRemovalChecked = true;
                        this.removeHosting();
                    });
            },

            /**
             * Enviar feedback sobre exclusão de hospedagem.
             */
            sendFeedback()
            {
                this.$refs.btnMovingOutSubmit.setDisabled();
                this.$refs.btnMovingOutCancel.disabled = true;
                this.$refs.modalMovingOut.hide();

                this.$refs.btnRemoveHosting.setLoadingAndFocus();

                if (!this.messageMovingOut.trim()) {
                    this.isRemovalChecked = true;
                    this.removeHosting();
                    return;
                }

                this.$api.post(`/hosting/removal-feedback`, { message: this.messageMovingOut })
                    .then(response => {
                        this.isRemovalChecked = true;
                        this.removeHosting();
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.btnRemoveHosting && this.$refs.btnRemoveHosting.setLoading(false);
                    });
            },
        }
    }
</script>