<template>
    <the-mask
        ref="input"
        type="text"
        class="form-control"
        placeholder="00000-000"
        autocomplete="postal-code"
        mask="#####-###"
        :disabled="isDisabled"
        v-model="zipcode"
        @input="$emit('input', zipcode)"
        @blur.native="onBlur" />
</template>

<script>
    import axios from 'axios'

    export default {
        props: {
            value: {
                type: [String, Number],
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                http: null,
                zipcode: this.value,
                isDisabled: false
            }
        },
        mounted()
        {
            this.http = axios.create();
            this.isDisabled = this.disabled;
        },
        methods: {
            onBlur()
            {
                const zipcode = this.zipcode.replace(/[^0-9]/g, '');

                if (!zipcode || zipcode.length !== 8) {
                    this.$set(this, "zipcode", "");
                    this.$emit("input", "");
                    this.$emit("cancel");
                }
            }
        },
        watch: {
            value: function (val, oldVal) {
                if (val !== oldVal) {
                    this.zipcode = val;
                }
            },
            disabled: function (val) {
                this.isDisabled = val;
            },
            zipcode: function (val, oldVal)
            {
                if (val && val !== oldVal && !this.isDisabled)
                {
                    const zipcode = val.replace(/[^0-9]/g, '');

                    if (zipcode && zipcode.length === 8)
                    {
                        this.isDisabled = true;

                        this.http.get(`https://viacep.com.br/ws/${val}/json/`)
                            .then(response => {
                                let data = null;

                                if (response.data && response.data.cep)
                                {
                                    data = {
                                        'street':       response.data.logradouro,
                                        'complement':   response.data.complemento.replace(/^\(/, "").replace(/\)$/, ""),
                                        'neighborhood': response.data.bairro,
                                        'city':         response.data.localidade,
                                        'state':        response.data.uf,
                                    };
                                }

                                this.$emit('ready', data);
                            })
                            .catch(error => {/* ignore error */})
                            .then (() => {
                                this.isDisabled = false;
                                this.$nextTick(() => this.$refs.input.$el.focus());
                            })
                    }
                }
            }
        }
    }
</script>