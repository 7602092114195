<template>
    <hosting-email-template :resource="resource">
        <card-title title="Acesso via POP3, IMAP e SMTP" inner-body>
            <p class="mb-6">
                Configure o acesso às suas contas de e-mail em uma aplicação desktop ou para celular usando as
                informações fornecidas abaixo.
            </p>

            <text-loading v-show="isLoadingSettings" />

            <div class="row">
                <div v-if="settings && settings.secure" class="col-md-6">
                    <table class="table table-sm table-custom-header mb-0">
                        <thead>
                            <tr>
                                <th colspan="2">SSL ativado (recomendado)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="copy-text-wrapper">
                                <th class="col-w-32 pl-4">Servidor:</th>
                                <td><text-copy :text="settings.secure.host" /></td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="col-w-32 pl-4">Porta IMAP:</th>
                                <td><text-copy :text="settings.secure.imap" /></td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="col-w-32 pl-4">Porta POP3:</th>
                                <td><text-copy :text="settings.secure.pop3" /></td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="col-w-32 pl-4">Porta SMTP:</th>
                                <td><text-copy :text="settings.secure.smtp" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="settings && settings.insecure" class="col-md-6">
                    <table class="table table-sm table-custom-header mb-0">
                        <thead>
                            <tr>
                                <th colspan="2">SSL desativado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="copy-text-wrapper">
                                <th class="col-w-32 pl-4">Servidor:</th>
                                <td><text-copy :text="settings.insecure.host" /></td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="col-w-32 pl-4">Porta IMAP:</th>
                                <td><text-copy :text="settings.insecure.imap" /></td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="col-w-32 pl-4">Porta POP3:</th>
                                <td><text-copy :text="settings.insecure.pop3" /></td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="col-w-32 pl-4">Porta SMTP:</th>
                                <td><text-copy :text="settings.insecure.smtp" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </card-title>
    </hosting-email-template>
</template>

<script>
    import { mapActions } from 'vuex'
    import AjaxService    from '@/services/AjaxService'
    import CardTitle      from '@/components/molecules/CardTitle'
    import TextCopy       from '@/components/molecules/TextCopy'
    import TextLoading from '@/components/atoms/TextLoading'
    import HostingEmailTemplate from '@/components/templates/HostingEmailTemplate'

    export default {
        props: ['resource'],
        components: {
            HostingEmailTemplate,
            TextLoading,
            CardTitle,
            TextCopy
        },
        data() {
            return {
                settings: null,
                isLoadingSettings: false,
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getSettings();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Carregar configurações.
             */
            getSettings()
            {
                this.isLoadingSettings = true;

                const params = { include: 'config' };

                this.$api.get(`/hosting/${this.resource}/email`, { params: params, cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.settings = response.data.data.config;
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingSettings = false);
            },
        }
    }
</script>
