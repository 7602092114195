import UserService from '../services/user.service'

const state = {

    user: null,
    credits: null,
    referral: null,
    promotion: null,

    isLoggingIn: false,
    isLoadingUser: false,
    isLoadingCredits: false,
    isLoadingReferral: false,
    isLoadingPromotion: false,
}

const getters = {

    isActivePromotion: state => {
        return state.promotion && state.promotion.active;
    },
    isTesterPromotion: (state, getters) => {
        return getters.isActivePromotion && state.promotion.type === "tester";
    },
    isReferralPromotion: (state, getters) => {
        return getters.isActivePromotion && state.user && !state.user.isAffiliate;
    }
}

const actions = {

    async login({ commit }, { email, password, captcha, code2fa })
    {
        commit('loginRequest');

        try {
            const user = await UserService.login(email, password, captcha, code2fa);
            commit('loginSuccess')
            return user
        }
        catch (error) {
            commit('loginFailure', error)
            throw error
        }
    },

    async getUser({ commit })
    {
        commit('getUserRequest');

        try {
            const user = await UserService.get()
            commit('getUserSuccess', user)
            return user
        }
        catch (error) {
            commit('getUserFailure', error)
            throw error
        }
    },

    getCredits({ commit })
    {
        commit('getCreditsRequest');

        return UserService.getCredits()
            .then (credits => commit('getCreditsSuccess', credits))
            .catch(error   => commit('getCreditsFailure', error))
    },

    getReferral({ commit })
    {
        commit('getReferralRequest');

        return UserService.getReferral()
            .then (referral => commit('getReferralSuccess', referral))
            .catch(error    => commit('getReferralFailure', error))
    },

    getPromotion({ commit })
    {
        commit('getPromotionRequest');

        return UserService.getPromotion()
            .then (data  => commit('getPromotionSuccess', data))
            .catch(error => commit('getPromotionFailure', error))
    },

    fetchUser({ state, dispatch })
    {
        if (!state.user && !state.isLoadingUser) {
            dispatch('getUser')
        }
    },

    fetchCredits({ state, dispatch })
    {
        if (!state.credits && !state.isLoadingCredits) {
            dispatch('getCredits')
        }
    },

    fetchReferral({ state, dispatch })
    {
        if (!state.referral && !state.isLoadingReferral) {
            dispatch('getReferral')
        }
    },

    fetchPromotion({ state, dispatch })
    {
        if (!state.promotion && !state.isLoadingPromotion) {
            dispatch('getPromotion')
        }
    },
}

const mutations = {
    loginRequest(state) {
        state.isLoggingIn = true;
        state.user = null;
    },
    loginSuccess(state) {
        state.isLoggingIn = false;
    },
    loginFailure(state, error) {
        state.isLoggingIn = false;
    },
    getUserRequest(state) {
        state.isLoadingUser = true;
    },
    getUserSuccess(state, user) {
        state.isLoadingUser = false;
        state.user = user;
    },
    getUserFailure(state, error) {
        state.isLoadingUser = false;
        state.user = null;
    },
    getCreditsRequest(state) {
        state.isLoadingCredits = true;
    },
    getCreditsSuccess(state, credits) {
        state.isLoadingCredits = false;
        state.credits = credits;
    },
    getCreditsFailure(state, error) {
        state.isLoadingCredits = false;
        state.credits = null;
    },
    getReferralRequest(state) {
        state.isLoadingReferral = true;
    },
    getReferralSuccess(state, referral) {
        state.isLoadingReferral = false;
        state.referral = referral;
    },
    getReferralFailure(state, error) {
        state.isLoadingReferral = false;
        state.referral = null;
    },
    getPromotionRequest(state) {
        state.isLoadingPromotion = true;
    },
    getPromotionSuccess(state, data) {
        state.isLoadingPromotion = false;
        state.promotion = data;
    },
    getPromotionFailure(state, error) {
        state.isLoadingPromotion = false;
        state.promotion = null;
    },
}

export const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
