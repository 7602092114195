<template>
    <admin-page-template class="h-p-domain-app">
        <card-title title="Adicionar novo domínio">
            <p class="mb-6">
                Possuir um domínio registrado é o primeiro passo para ter o seu endereço na internet. Aqui você pode
                registrar ou transferir um domínio que tenha a ver com você ou com a sua empresa. Basta informar
                diretamente o domínio a ser registrado ou você pode buscar por palavras-chave.
            </p>

            <form @submit.prevent="searchDomain">
                <form-group class="mb-0" :error="formErrors.search">
                    <div class="d-flex">
                        <input
                            ref="inputDomain"
                            type="text"
                            class="form-control form-control-xl flex-grow-1"
                            spellcheck="false"
                            autocomplete="off"
                            autofocus
                            placeholder="Encontre um domínio para você..."
                            v-model="search"
                            v-input-lowercase
                        >
                        <button-form ref="btnSearchDomain" type="submit" variant="success" class="btn-lg ml-4 text-nowrap">
                            <i class="fas fa-search mr-1" aria-hidden="true"></i> Buscar
                        </button-form>
                    </div>
                </form-group>
            </form>

            <div v-if="showOwnerMessage" class="alert alert-info mt-3">
                Este domínio já está cadastrado em sua conta.
                <router-link :to="`/domain/${domain}`">Clique aqui</router-link> para acessá-lo.
            </div>
        </card-title>

        <card-title v-if="domainInfo.domain">
            <div class="row">
                <div class="col">
                    <div class="alert h-alert-availability" v-bind:class="alertAvailabilityClass" v-html="alertAvailabilityMessage"></div>
                    <h3 class="h-domain-display">{{ domainInfo.domain }}</h3>
                </div>
                <div v-if="domainInfo.register || domainInfo.transfer" class="col-auto">
                    <div class="card h-card-domain-price mb-2">
                        <div class="card-body">
                            <div v-if="domainInfo.register" class="h-price-new">
                                <span class="h-price-currency">R$ </span>
                                <span class="h-price-number">{{ Math.floor(domainInfo.price.register) }}</span>
                                <span class="h-price-digits">,{{ domainInfo.price.register.toFixed(2).substr(-2) }}</span>
                            </div>
                            <div v-if="domainInfo.transfer" class="h-price-new">
                                <span class="h-price-currency">R$ </span>
                                <span class="h-price-number">{{ Math.floor(domainInfo.price.transfer) }}</span>
                                <span class="h-price-digits">,{{ domainInfo.price.transfer.toFixed(2).substr(-2) }}</span>
                            </div>
                            <span v-if="domainInfo.register && domainInfo.price.renew > domainInfo.price.register" class="h-price-renovation text-right renovation">
                                 R$ {{ domainInfo.price.renew.toFixed(2).replace('.', ',') }}<br>na renovação
                            </span>
                            <span v-else-if="domainInfo.transfer && domainInfo.price.renew > domainInfo.price.transfer" class="h-price-renovation text-right renovation">
                                 R$ {{ domainInfo.price.renew.toFixed(2).replace('.', ',') }}<br>na renovação
                            </span>
                            <span v-else class="h-price-disclaimer"><i class="fas fa-info-circle fa-fw"></i> No primeiro ano</span>
                        </div>
                    </div>
                    <router-link v-if="domainInfo.transfer" :to="'/domain/'+domainInfo.domain+'/register?transfer=1'" class="btn btn-success btn-lg btn-block">Transferir</router-link>
                    <router-link v-else :to="'/domain/'+domainInfo.domain+'/register'" class="btn btn-success btn-lg btn-block">Registre agora</router-link>
                </div>
            </div>
        </card-title>

        <card-title v-if="domainInfo.suggestions" title="Encontre domínios alternativos">
            <p class="mb-6">
                Se não encontrar o domínio que você procurava, você pode escolher um domínio semelhante a partir das
                sugestões abaixo.
            </p>

            <div class="h-list-suggestions">
                <div v-for="item in domainInfo.suggestions" class="h-item-suggestion">
                    <h5 class="h-domain-display flex-grow-1">{{ item.domain }}</h5>
                    <div class="card h-card-domain-price ml-4">
                        <div class="card-body">
                            <div class="h-price-new">
                                <span class="h-price-number">R$ {{ item.price.register.toFixed(2).replace('.', ',') }}</span>
                            </div>
                            <span v-if="item.register && item.price.renew > item.price.register" class="h-price-renovation text-right renovation">
                                 R$ {{ item.price.renew.toFixed(2).replace('.', ',') }}<br>na renovação
                            </span>
                            <span v-else-if="item.transfer && item.price.renew > item.price.transfer" class="h-price-renovation text-right renovation">
                                 R$ {{ item.price.renew.toFixed(2).replace('.', ',') }}<br>na renovação
                            </span>
                            <span v-else class="h-price-disclaimer"><i class="fas fa-info-circle fa-fw"></i> No primeiro ano</span>
                        </div>
                    </div>
                    <router-link :to="'/domain/'+item.domain+'/register'" class="btn btn-success btn-action ml-4">Registrar</router-link>
                </div>
            </div>
        </card-title>
    </admin-page-template>
</template>

<script>
    import ErrorService      from '@/services/error.service'
    import AjaxService       from '@/services/AjaxService'
    import ButtonForm        from '@/components/atoms/ButtonForm'
    import CardTitle         from '@/components/molecules/CardTitle'
    import FormGroup         from '@/components/molecules/FormGroup'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'

    export default {
        name: "DomainAdd",
        components: {
            ButtonForm,
            FormGroup,
            CardTitle,
            AdminPageTemplate,
        },
        data() {
            return {
                search: "",
                domain: "",
                domainInfo: {},
                formErrors: {},
                showOwnerMessage: false,
            }
        },
        computed: {
            alertAvailabilityClass: function ()
            {
                if (this.domainInfo.register || this.domainInfo.transfer) {
                    return 'alert-success';
                }
                else {
                    return 'alert-danger';
                }
            },
            alertAvailabilityMessage: function ()
            {
                if (this.domainInfo.available === false) {
                    return '<i class="far fa-frown mr-1"></i> Ainda não oferecemos registro para esta extensão';
                }
                else if (this.domainInfo.register) {
                    return '<i class="far fa-check-circle mr-1"></i> Este domínio está disponível para registro';
                }
                else if (this.domainInfo.transfer) {
                    return '<i class="fas fa-exchange-alt mr-1"></i> Este domínio está disponível para transferência';
                }
                else {
                    return 'Este domínio não está disponível';
                }
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            if (this.$route.query.domain)
            {
                const domain = String(this.$route.query.domain);

                if (/[a-zA-Z0-9-_\.]+\.[a-zA-Z0-9][a-zA-Z0-9-_]*[a-zA-Z0-9]/.test(domain)) {
                    this.search = domain;
                }
            }
        },
        methods: {
            /**
             * Search for domain.
             */
            searchDomain()
            {
                this.domainInfo = {};
                this.formErrors = {};
                this.showOwnerMessage = false;

                this.$refs.btnSearchDomain.setLoadingAndFocus();

                this.$api.post('/domain/search', { search: this.search }, { cancelToken: AjaxService.getCancelToken() })
                    .then(response => {
                        const responseData = response.data.data;

                        if (responseData.isOwner) {
                            this.domain = responseData.domain;
                            this.showOwnerMessage = true;
                        }
                        else {
                            this.domainInfo = responseData;
                        }
                    })
                    .catch(error => ErrorService.handleFormError(error, this))
                    .then (() => this.$refs.btnSearchDomain.setLoading(false))
            }
        }
    }
</script>

<style lang="stylus">
    .h-p-domain-app {
        .h-alert-availability {
            display inline-block
            font-family $font-family-title
            font-size 1rem
            font-weight bold
            letter-spacing .0125em
        }
        .h-domain-display {
            margin-bottom 0
            font-family $font-family-title
            font-size 3rem
            font-weight 800
            color $primary
        }
        .h-card-domain-price {
            border 0
            background-color $primary-light

            .card-body {
                text-align center
            }
            .h-price-old {
                display block
                margin-bottom .25rem
                font-size 1.25em
                font-weight bold
                color $gray-500
            }
            .h-price-new {
                display flex
                align-items flex-start
                justify-content center
                font-family $font-family-title
                font-size 1.25em
                font-weight bold
                color $primary
            }
            .h-price-currency, .h-price-digits {
                padding-top .375em
            }
            .h-price-currency {
                margin-right .125em
            }
            .h-price-digits {
                margin-left .25em
            }
            .h-price-number {
                font-size 3.5em
                line-height 1
                letter-spacing -.025em
            }
            .h-price-disclaimer {
                display block
                margin-top .25rem
                font-size .75em
                color $gray-500
            }
            .h-price-renovation{
                display block
                margin-top .25rem
                font-size: 1.2rem;
                line-height: 19px;
                color: #6b737b;
            }
        }
        .h-item-suggestion {
            display flex
            align-items center
            padding .5rem 0

            + .h-item-suggestion {
                border-top 3px solid $primary-light
            }
            .h-domain-display {
                font-size 1.75rem
            }
            .h-card-domain-price {
                background none

                .card-body {
                    padding 0
                }
                .h-price-old, .h-price-disclaimer {
                    line-height 1.5rem
                }
                .h-price-old {
                    font-size 1rem
                    font-weight 600
                }
                .h-price-new {
                    padding .25rem .5rem
                    line-height 1.5rem
                    border-radius $border-radius
                    border 1px solid $primary-light
                    background-color $primary-light
                }
                .h-price-number {
                    font-size 1.5rem
                    line-height 1
                    letter-spacing 0
                }
            }
        }
    }
</style>