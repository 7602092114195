<template>
    <div class="btn-toolbar" role="toolbar">
        <div class="flex-grow-1">
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    spellcheck="false"
                    v-model="password"
                    v-if="isPasswordVisible"
                    @blur="isPasswordVisible = false">

                <input
                    type="password"
                    class="form-control"
                    :id="id"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    v-model="password"
                    v-show="!isPasswordVisible">

                <div class="input-group-append">
                    <button class="btn btn-secondary w-12 px-0" type="button" :title="btnToogleTitle" @click="tooglePassword">
                        <i class="fas " v-bind:class="[ isPasswordVisible ? 'fa-eye-slash' : 'fa-eye' ]"></i>
                    </button>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-secondary ml-2" @click="generatePassword">Gerar</button>
    </div>
</template>

<script>
    import password from '@/helpers/password'

    export default {
        props: {
            value: [String, Number],
            id: String,
            placeholder: String,
            autocomplete: String,
        },
        data() {
            return {
                password: this.value,
                isPasswordVisible: false,
            }
        },
        computed: {
            btnToogleTitle: function() {
                return this.isPasswordVisible ? 'Ocultar senha' : 'Exibir senha';
            }
        },
        methods: {
            /**
             * Exibir ou ocultar campo de senha.
             */
            tooglePassword()
            {
                this.isPasswordVisible = !this.isPasswordVisible;
            },

            /**
             * Gerar uma nova senha e exibí-la.
             */
            generatePassword()
            {
                this.password = password.generate(16);
                this.isPasswordVisible = true;

                this.$nextTick(() => {
                    this.$el.querySelector("input[type=text]").focus();
                });

                this.$emit("generated", this.password);
            }
        },
        watch: {
            value: function(val) {
                this.password = val;
            },
            password: function(val) {
                this.$emit("input", val);
            }
        }
    }
</script>

<style lang="stylus">

</style>