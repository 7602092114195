<template>
    <table class="table table-custom-header htm-table-dns">
        <thead>
            <tr>
                <th class="col-w-48 col-nowrap">Nome</th>
                <th>Valor do registro</th>
                <th class="col-dropdown"><span class="sr-only">Opções</span></th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="isLoading">
                <td colspan="3" class="text-muted">
                    <i class="fas fa-circle-notch fa-spin mr-1"></i> Carregando...
                </td>
            </tr>
            <tr v-else-if="!isLoading && !records.length">
                <td colspan="3">Nenhum registro DNS encontrado.</td>
            </tr>
            <template v-else v-for="(item, index) in records">
                <tr v-if="index === 0 || item.type !== records[index-1].type || (item.type === 'TXT' && item.txtType !== records[index-1].txtType)" class="table-active table-sm">
                    <td colspan="3">
                        <span v-if="item.type === 'TXT' && item.txtType">{{ item.txtType }}</span>
                        <span v-else>{{ item.type }}</span>
                        <span v-if="item.type === 'A'"> (Hostname)</span>
                        <span v-if="item.type === 'AAAA'"> (Hostname IPv6)</span>
                        <span v-if="item.type === 'CNAME'"> (Alias)</span>
                        <span v-if="item.type === 'MX'"> (Mail Exchanger)</span>
                        <span v-if="item.type === 'SRV'"> (Service)</span>
                        <span v-if="item.type === 'TXT' && !item.txtType"> (Text)</span>
                        <span v-if="item.type === 'TXT' && item.txtType === 'DKIM'"> (DomainKeys Identified Mail)</span>
                        <span v-if="item.type === 'TXT' && item.txtType === 'SPF'"> (Sender Policy Framework)</span>
                        <span v-if="item.type === 'TXT' && item.txtType === 'DMARC'"> (Domain-based Message Authentication, Reporting, and Conformance)</span>
                    </td>
                </tr>
                <tr class="row-record" v-bind:class="getRowClasses(item)">
                    <td class="col-w-48 col-nowrap">
                        {{ item.name || '@' }}
                    </td>
                    <td v-bind:class="{ 'col-record': item.type === 'TXT' }">
                        <span v-if="item.type === 'TXT'" :title="item.value" v-bind:class="{'txt-value': item.type === 'TXT'}">{{ item.value }}</span>
                        <text-copy v-else :text="item.value" :title="item.value"></text-copy>
                        <span v-if="item.type === 'MX' || item.type === 'SRV'" class="d-block">Prioridade: {{ item.priority }}</span>
                        <span v-if="item.type === 'SRV'" class="d-block">Peso: {{ item.weight }}</span>
                        <span v-if="item.type === 'SRV'" class="d-block">Porta: {{ item.port }}</span>
                    </td>
                    <td class="col-dropdown">
                        <b-dropdown v-show="!item.isRemoving" variant="link" right no-caret>
                            <template v-slot:button-content>
                                <i class="fas fa-caret-down"/>
                            </template>
                            <b-dropdown-item-button @click="editRecord(item)">
                                <i class="fas fa-pencil-alt fa-fw"></i> Editar
                            </b-dropdown-item-button>
                            <b-dropdown-divider/>
                            <b-dropdown-item-button variant="danger" @click="removeRecord(item)">
                                <i class="far fa-trash-alt fa-fw"></i> Excluir
                            </b-dropdown-item-button>
                        </b-dropdown>
                        <i v-show="item.isRemoving" class="fas fa-circle-notch fa-spin text-muted"></i>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
    import TextCopy from "./TextCopy"

    export default {
        components: {
            TextCopy
        },
        props: {
            data: {
                type: Array,
                default: [],
            },
            filter: {
                type: String,
                default: "",
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                records: this.data,
                isLoading: this.loading
            }
        },
        methods: {
            /**
             * Definição de classes das linhas da tabela.
             */
            getRowClasses(item)
            {
                let classes = [];
                const searchTerm = this.filter ? this.filter.toLowerCase() : "";

                if (item.isRemoving) {
                    classes.push("table-muted");
                }

                if (searchTerm && item.name.toLowerCase().indexOf(searchTerm) === -1 && item.value.toLowerCase().indexOf(searchTerm) === -1) {
                    classes.push("d-none");
                }

                return classes;
            },

            /**
             * Editar registro DNS.
             * @param item
             */
            editRecord(item)
            {
                this.$emit('edit', item);
            },

            /**
             * Excluir registro DNS.
             * @param item
             */
            removeRecord(item)
            {
                this.$emit('remove', item);
            }
        },
        watch: {
            data: function(val) {
                this.records = val;
            },
            loading: function(val) {
                this.isLoading = val;
            }
        }
    }
</script>

<style lang="stylus">
    .htm-table-dns
        tbody tr.row-record td
            border-bottom 0 !important
            border-top 1px solid $gray-300 !important

        tr.table-active
            background-color transparent

            > td
                border-bottom 0 !important
                border-radius $border-radius-lg

            + tr.row-record td
                border-top 0 !important

        /*tr.row-loading td
            border-top 0 !important
            border-bottom 1px solid $gray-300 !important*/

        tr.table-active > td, tr.table-active:hover > td
            background-color $gray-300

        tr.table-active > td
            font-weight 600
            padding-left .75rem
            padding-right .75rem

        .col-record
            width 100%

            span
                display block
                max-width 450px
                white-space nowrap
                overflow hidden
                text-overflow ellipsis

            .txt-value
                display -webkit-box
                white-space normal
                line-height 1.5
                max-height 6em
                -webkit-line-clamp 4
                -webkit-box-orient vertical
</style>