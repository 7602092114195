<template>
    <hosting-email-template :resource="resource">
        <card-table
            ref="cardLists"
            inner-title="Listas de E-mails"
            add-text="Criar lista de e-mails"
            modal-title="Criar lista de e-mails"
            remove-title="Excluir lista de e-mails"
            table-empty-text="Nenhuma lista de e-mails encontrada"
            modal-size="md"
            hide-edit
            :table-fields="tableListFields"
            :api-url="`/hosting/${resource}/email/lists`"
            @remove="removeList"
        >
            <template slot="table-size" slot-scope="{ rowData }">
                {{ rowData.size | formatBytes(0) }}
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item :to="`/hosting/${resource}/email/lists/${encodeURIComponent(rowData.name)}`">
                    <i class="fas fa-cog fa-fw"></i> Configurações
                </b-dropdown-item>
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="Nome da lista" label-for="listName" :error="formErrors.name">
                    <div class="input-group input-group-rounded">
                        <input type="text" id="listName" class="form-control" maxlength="40" v-model="formData.name">
                        <div class="input-group-append">
                            <span class="input-group-text">{{ '@' + resource }}</span>
                        </div>
                    </div>
                </form-group>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir esta lista de e-mails?</p>
                <p class="mb-0"><strong v-html="item.name"></strong></p>
            </template>
        </card-table>
    </hosting-email-template>
</template>

<script>
    import CardTable from "@/components/organisms/CardTable.vue";
    import HostingEmailTemplate from "@/components/templates/HostingEmailTemplate.vue";
    import FormGroup from "@/components/molecules/FormGroup.vue";

    export default {
        props: [
            'resource'
        ],
        components: {
            FormGroup,
            CardTable,
            HostingEmailTemplate
        },
        data() {
            return {
                tableListFields: [
                    {
                        name: 'name',
                        sortField: 'name',
                        filterable: true,
                        title: 'Lista',
                    },
                    {
                        name: '__slot:size',
                        sortField: 'size',
                        sortFunction: 'number',
                        title: 'Uso de Disco',
                        titleClass: 'col-w-28 col-nowrap text-right',
                        dataClass: 'col-w-28 col-nowrap text-right',
                    }
                ],
            }
        },
        methods: {

            showEditListModal(item)
            {
                this.currentList = item;
            },

            /**
             * Excluir lista.
             */
            removeList(item)
            {
                const list = item.name;

                this.$api.delete(`/hosting/${this.resource}/email/lists/${list}`)
                    .then (response => {})
                    .catch(error => this.showErrorAlert(error));
            },
        }
    }
</script>

<style lang="stylus">

</style>
