<template>
    <hosting-settings-template :resource="resource" class="h-p-hosting-logs">
        <card-title title="Logs de Erros" inner-body class="h-card-hosting-logs">
            <p class="mb-6">
                Exibe os registros mais recentes nos logs de erro da hospedagem. Utilize as informações disponíveis nos
                logs para encontrar e resolver problemas em sua aplicação.
                Se o registro no log possuir um ícone de lâmpada ( <i class="far fa-lightbulb"></i> ), você pode clicar
                ou passar o mouse sobre ele para ver uma dica de como resolver o problema informado.
            </p>

            <div class="btn-toolbar mb-6" role="toolbar">
                <button type="button" class="btn mr-2" v-bind:class="getLogGroupClasses('php')" @click="fetchLogs('php')">
                    PHP
                </button>
                <button type="button" class="btn mr-2" v-bind:class="getLogGroupClasses('server')" @click="fetchLogs('server')">
                    Servidor Web
                </button>
            </div>

            <div v-show="showAlertLargeSize" class="alert alert-danger mb-0">
                O arquivo de log solicitado é muito grande. Para poder ter acesso a esse recurso o arquivo de log não
                pode ter mais de 1 MB.
                <hr>
                Para visualizar os logs via FTP ou Gerenciador de Arquivos acesse algum destes arquivos:
                <ul class="mb-0">
                    <li>~/public_html/error_log</li>
                    <li>~/public_html/wp-admin/error_log</li>
                    <li>~/logs/[seudominio.com.br].php.error.log</li>
                </ul>
            </div>

            <table v-show="!showAlertLargeSize" class="table table-custom-header mb-0" v-bind:class="[logs.length ? 'table-hover' : '']">
                <thead>
                    <tr>
                        <th scope="col" class="col-datetime">Data</th>
                        <th scope="col" class="col-w-24 col-nowrap">
                            Tipo
                            <button type="button" class="btn btn-link p-0" title="Ajuda" @click="$refs.modalLogTypeHelp.show()">
                                <i class="fas fa-question-circle" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th scope="col">Mensagem</th>
                        <th scope="col" class="col-dropdown"><span class="sr-only">Opções</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="isLoading">
                        <td colspan="4">
                            <text-loading />
                        </td>
                    </tr>

                    <tr v-else-if="!isLoading && !logs.length">
                        <td colspan="4">
                            Nenhum log de erro encontrado.
                        </td>
                    </tr>
                    <tr v-else v-for="(item, index) in logs">
                        <td class="col-datetime">{{ $moment(item.date).format('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td class="col-w-24">
                            <span v-bind:class="getLogTypeClasses(item.type)">{{ item.typeText }}</span>
                        </td>
                        <td class="col-log-message">
                            <p class="text-collapse mb-0" v-bind:class="{'show':item.show}">
                                <span v-if="item.hint" v-bind:id="'hint-${index}'" class="log-hint mr-1"><i class="far fa-lightbulb"></i></span>
                                {{ item.message }}
                            </p>
                            <b-popover v-if="item.hint" :target="'hint-${index}'" placement="topright" triggers="hover focus">
                                <p class="mb-0"><strong>Dica:</strong> <span v-html="item.hint"></span></p>
                            </b-popover>
                        </td>
                        <td class="col-dropdown">
                            <div class="btn-group visible-on-hover" role="group">
                                <button type="button" class="btn btn-sm btn-outline-primary" title="Expandir" @click="toggleLogMessage(item, index)">
                                    <i class="fas fa-plus" aria-hidden="true"></i>
                                    <span class="sr-only">Expandir</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </card-title>

        <b-modal ref="modalLogTypeHelp" title="Tipos de mensagem" centered hide-footer hide-header-close>
            <table class="table mb-0">
                <tbody>
                    <tr>
                        <td class="col-100 border-top-0">
                            <span class="badge badge-info">Informação</span>
                        </td>
                        <td class="border-top-0">Mensagem informativa. Não compromete o funcionamento da aplicação.</td>
                    </tr>
                    <tr>
                        <td class="col-100">
                            <span class="badge badge-warning">Notícia</span>
                        </td>
                        <td>Condição normal que pode ou não ser considerada um problema.</td>
                    </tr>
                    <tr>
                        <td class="col-100">
                            <span class="badge badge-warning">Alerta</span>
                        </td>
                        <td>Condição de alerta que pode levar a um erro.</td>
                    </tr>
                    <tr>
                        <td class="col-100">
                            <span class="badge badge-danger">Erro</span>
                        </td>
                        <td>Condição de erro impedindo o correto funcionamento da aplicação.</td>
                    </tr>
                </tbody>
            </table>
        </b-modal>
    </hosting-settings-template>
</template>

<script>
    import { mapActions }          from 'vuex'
    import AjaxService             from '@/services/AjaxService'
    import TextLoading             from '@/components/atoms/TextLoading'
    import CardTitle               from '@/components/molecules/CardTitle'
    import HostingSettingsTemplate from '@/components/templates/HostingSettingsTemplate'

    export default {
        props: ['resource'],
        components: {
            TextLoading,
            CardTitle,
            HostingSettingsTemplate
        },
        data() {
            return {
                logGroup: "server",
                logs: [],
                isLoading: false,
                showAlertLargeSize: false,
                cancelFetchLogs: null,
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.fetchLogs();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            getLogGroupClasses(group)
            {
                return {
                    "btn-primary": group === this.logGroup,
                    "btn-outline-primary": group !== this.logGroup,
                }
            },

            getLogTypeClasses(type)
            {
                return {
                    "badge":        typeof type != "undefined" && type,
                    "badge-danger":  type === "error",
                    "badge-warning": type === "warning" || type === "notice",
                    "badge-info":    type === "info",
                }
            },

            /**
             * Carregar logs.
             */
            fetchLogs(group)
            {
                this.logGroup = group || "php";

                this.isLoading = true;
                this.logs = [];

                this.showAlertLargeSize = false;

                // const CancelToken = axios.CancelToken;
                // let vm = this;

                let _params = {
                    group: this.logGroup,
                };

                // if (this.cancelFetchLogs) {
                //     this.cancelFetchLogs();
                // }

                // let promise = axios({
                //     method: 'get',
                //     url: `/api/hosting/${this.resource}/logs`,
                //     params: _params,
                //     cancelToken: new CancelToken(function executor(c) {
                //         vm.cancelFetchLogs = c;
                //     })
                // });

                this.$api.get(`/hosting/${this.resource}/logs`, { params: _params, cancelToken: AjaxService.getCancelToken() })
                    .then(response => {
                        const data = response.data.data;

                        if (data.logs) {
                            this.logs = data.logs;
                        }
                        else if (typeof data.isLargeFile !== "undefined" && data.isLargeFile) {
                            this.showAlertLargeSize = true;
                        }

                        this.isLoading = false;
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.isLoading = false;
                    });
            },

            toggleLogMessage(item, index)
            {
                let newValue = !(item.show || false);
                this.$set(this.logs[index], 'show', newValue);
            },
        }
    }
</script>

<style lang="stylus">
    .h-p-hosting-logs
        .h-card-hosting-logs
            .col-log-message
                max-width 0

                .text-collapse:not(.show)
                    overflow hidden
                    text-overflow ellipsis
                    white-space nowrap

            .log-hint:hover .fa-lightbulb
                font-weight 900
                color #ffc107
                transition color .25s
                will-change color
</style>