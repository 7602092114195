<template>
    <tabs-pages :tabs="tabs">
        <slot></slot>
    </tabs-pages>
</template>

<script>
    import TabsPages from '@/components/organisms/TabsPages'

    export default {
        props: [
            'resource'
        ],
        components: {
            TabsPages
        },
        data() {
            return {
                tabs: [
                    {
                        title: "Contas de e-mail",
                        link: `/hosting/${this.resource}/email`,
                        exact: true,
                    },
                    {
                        title: "Listas de e-mails",
                        link: `/hosting/${this.resource}/email/lists`,
                    },
                    {
                        title: "Dados de acesso",
                        link: `/hosting/${this.resource}/email/access`,
                    },
                    {
                        title: "Antispam",
                        link: `/hosting/${this.resource}/antispam`,
                    },
                    {
                        title: "Webmail",
                        link: `/hosting/${this.resource}/webmail`,
                    },
                ]
            }
        }
    }
</script>