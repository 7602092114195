import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

export default {
    install (Vue) {
        Sentry.init({
            dsn: process.env.VUE_APP_SENTRY_DNS,
            release: process.env.VUE_APP_SENTRY_RELEASE,
            integrations: [
                new Integrations.Vue({
                    Vue,
                    attachProps: true,
                    logErrors: (process.env.NODE_ENV !== 'production')
                })
            ]
        })
    }
}
