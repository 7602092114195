'use strict';

import { ApiCancel } from '../plugins/api';

const CancelToken = ApiCancel.CancelToken;
let source;

export default
{
    getCancelToken()
    {
        if (!source) {
            source = CancelToken.source();
        }

        return source.token;
    },

    cancel()
    {
        if (source) {
            source.cancel();
        }

        source = null;
    }
}
