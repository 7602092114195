<template>
    <div class="h-t-admin-page">
        <quick-access v-if="quickAccess === 'top'" />
        <slot />
        <quick-access v-if="quickAccess === true || quickAccess === 'bottom'" class="mt-4 mt-sm-8" />
    </div>
</template>

<script>
    import QuickAccess from "../organisms/QuickAccess"

    export default {
        props: {
            quickAccess: {
                type: [Boolean, String],
                default: true,
                validator: (value) => {
                    return typeof value === "boolean" || ['top', 'bottom'].indexOf(value) !== -1;
                }
            }
        },
        components: {
            QuickAccess
        }
    }
</script>