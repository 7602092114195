<template>
    <payment-template>
        <card-table
            ref="cardRecharges"
            inner-title="Histórico de Recargas"
            hide-add
            hide-dropdown
            :table-fields="tableRechargesFields"
            api-url="/recharge"
            @loaded="updateShowFooterInfo"
        >
            <template slot="table-date" slot-scope="{ rowData }">
                {{ rowData.date | moment("DD/MM/YYYY") }}
            </template>

            <template slot="table-amount" slot-scope="{ rowData }">
                <span v-bind:class="{ 'text-danger': rowData.amount < 0 }">
                    {{ 'R$ ' + rowData.amount.toFixed(2).replace('.', ',') }}
                </span>
            </template>

            <template slot="table-nfse" slot-scope="{ rowData }">
                <a v-if="rowData.nfse" :href="rowData.nfse" target="_blank" title="Nota Fiscal">
                    <i class="far fa-file-alt fa-fw"></i>
                </a>
            </template>

            <template slot="table-after">
                <p v-show="showFooterInfo" class="text-muted font-italic mb-0">
                    <small>Visualização limitada às 100 últimas recargas</small>
                </p>
            </template>
        </card-table>
    </payment-template>
</template>

<script>
    import CardTable from "../../components/organisms/CardTable"
    import PaymentTemplate from "../../components/templates/PaymentTemplate"

    export default {
        components: {
            CardTable,
            PaymentTemplate
        },
        data() {
            return {
                tableRechargesFields: [
                    {
                        name: '__slot:date',
                        sortField: 'date',
                        title: 'Data',
                        titleClass: 'col-w-32',
                        dataClass: 'col-w-32',
                    },
                    {
                        name: 'description',
                        sortField: 'description',
                        filterable: true,
                        title: 'Descrição',
                        titleClass: 'col-nowrap',
                    },
                    {
                        name: '__slot:amount',
                        sortField: 'amount',
                        sortFunction: 'number',
                        filterable: true,
                        title: 'Valor',
                        titleClass: 'col-w-40',
                        dataClass: 'col-w-40',
                    },
                    {
                        name: 'status',
                        sortField: 'status',
                        filterable: true,
                        title: 'Status',
                        titleClass: 'col-w-40',
                        dataClass: 'col-w-40',
                    },
                    {
                        name: '__slot:nfse',
                        title: '',
                        titleClass: 'col-status col-w-12',
                        dataClass: 'col-status col-w-12',
                    },
                ],
                showFooterInfo: false,
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardRecharges.cancelFetch();
            next();
        },
        methods: {
            updateShowFooterInfo() {
                this.showFooterInfo = this.$refs.cardRecharges.getDataLength() >= 100;
            }
        }
    }
</script>
