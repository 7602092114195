<template>
    <ul class="nav nav-tabs h-m-nav-pages">
        <template v-for="(item, index) in tabs">
            <li v-if="!item.hidden" v-bind:key="index" class="nav-item">
                <router-link class="nav-link" v-bind:class="item.classes" :to="item.link" :exact="item.exact">{{ item.title }}</router-link>
            </li>
        </template>
    </ul>
</template>

<script>
    export default {
        props: {
            tabs: Array,
        }
    }
</script>

<style lang="stylus">
    .h-m-nav-pages
        margin-bottom .5rem
        border-bottom 0
        flex-direction column

        @media (min-width 576px)
            margin-bottom 0
            padding 0 .5rem
            flex-direction row

        .nav-item
            margin-bottom 0

            @media (min-width 576px)
                margin-right .5rem

        .nav-link
            border 0
            border-radius 0
            padding 0.5rem 1.5rem
            background-color $light
            color $gray-800
            box-shadow 0 3px 6px 0 rgba(black, 0.25)

            @media (min-width 576px)
                padding 0.75rem 2.25rem
                border-top-left-radius $border-radius-lg
                border-top-right-radius $border-radius-lg
                font-size 1.125rem

            &:hover
                background-color darken($light, 7%)

            &.active
                background-color $primary
                color white
</style>