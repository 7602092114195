<template>
    <div>
        <card-title inner-body>
            <div class="d-flex justify-content-md-between mb-6">
                <h4 class="card-title-inner flex-grow-1 mb-0">{{ email }}</h4>
                <div class="card-header-actions">
                    <router-link :to="`/hosting/${resource}/email`" class="btn btn-secondary" exact>Voltar</router-link>
                </div>
            </div>
            <!--<div class="d-flex justify-content-md-between">
                <div>
                    <button type="button" class="btn btn-secondary">Alterar senha</button>
                    <button type="button" class="btn btn-secondary ml-2">Alterar limite</button>
                </div>
                <div>
                    &lt;!&ndash;<button type="button" class="btn btn-success ml-2">Habilitar</button>&ndash;&gt;
                    <button type="button" class="btn btn-secondary ml-2">Desabilitar</button>
                    <button type="button" class="btn btn-danger ml-2">Excluir</button>
                </div>
            </div>-->

            <text-loading v-show="isLoadingEmail" />

            <div v-show="!isLoadingEmail" class="table-list-wrapper">
                <table class="table table-list table-sm mb-0">
                    <tbody>
                        <tr>
                            <th class="col-w-px col-nowrap">Limite:</th>
                            <td>
                                <span v-if="info.quota">{{ info.quota | formatBytes(0) }}</span>
                                <span v-else>Ilimitado</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Uso de disco:</th>
                            <td>
                                <span v-if="info.size">{{ info.size | formatBytes(0) }}</span>
                                <span v-else>Sem informação</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="mt-6">
                <button class="btn btn-primary btn-action" @click="showChangeQuotaModal">Alterar limite</button>
            </div>
        </card-title>

        <b-modal ref="modalChangeQuota" title="Alterar limite de espaço" centered hide-footer hide-header-close>
            <form @submit.prevent="changeQuota">
                <form-group label="E-mail" label-for="changeQuotaEmail">
                    <input type="text" id="changeQuotaEmail" class="form-control" readonly :value="formData.email">
                </form-group>

                <form-group :error="formErrors.quota">
                    <label class="form-label mb-0" for="changeQuotaValue">Limite de espaço (em megabytes)</label>
                    <p class="form-text-sm">
                        Deixe em branco ou com valor 0 para uma conta sem limite de espaço.
                    </p>
                    <div class="input-group">
                        <input type="number" id="changeQuotaValue" class="form-control" min="0" step="512" v-model="formData.quota">
                        <div class="input-group-append">
                            <span class="input-group-text">MB</span>
                        </div>
                    </div>
                </form-group>

                <div class="form-buttons text-right">
                    <button ref="btnChangeQuotaCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalChangeQuota.hide()">Cancelar</button>
                    <button-form ref="btnChangeQuotaSubmit" type="submit" variant="success" class="btn-action">Salvar</button-form>
                </div>
            </form>
        </b-modal>

        <card-table
            ref="cardAlias"
            inner-title="Endereços alternativos (alias)"
            add-text="Adicionar"
            modal-title="Adicionar endereço alternativo"
            :table-fields="tableAliasForwardsFields"
            :table-data="alias"
            :table-loading="isLoadingEmail"
            :form-data-transform="aliasForwardFormDataTransform"
            :api-save-url="`/hosting/${resource}/email/alias`"
            table-empty-text="Nenhum endereço alternativo encontrado"
            hide-edit
            no-remove-confirm
            @created="onAliasCreated"
            @remove="removeAlias"
        >
            <template slot="intro-text">
                Um endereço alternativo é uma conta de e-mail com o seu domínio, mas que <strong>apenas recebe mensagens</strong>
                e encaminha para a caixa de e-mail atual. Por exemplo, você pode ter um e-mail <strong>contato@meudominio.com.br</strong>
                e criar um endereço alternativo <strong>atendimento@meudominio.com.br</strong> apenas para receber mensagens.
                O endereço alternativo não consegue enviar mensagens nem pode ser acessado por um cliente de e-mail.
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="E-mail principal" label-for="aliasEmail">
                    <input type="text" id="aliasEmail" class="form-control" :value="formData.email" readonly>
                </form-group>

                <form-group label="Endereço alternativo" label-for="aliasUsername" :error="formErrors.username">
                    <div class="input-group">
                        <input ref="aliasUsername" type="text" id="aliasUsername" class="form-control" maxlength="40" v-model="formData.username" v-input-lowercase>
                        <div class="input-group-append">
                            <span class="input-group-text">{{ '@' + resource }}</span>
                        </div>
                    </div>
                </form-group>
            </template>
        </card-table>

        <card-table
            ref="cardForwards"
            inner-title="Redirecionamentos"
            add-text="Adicionar"
            modal-title="Adicionar redirecionamento"
            :table-fields="tableAliasForwardsFields"
            :table-data="forwards"
            :table-loading="isLoadingEmail"
            :form-data-transform="aliasForwardFormDataTransform"
            :api-save-url="`/hosting/${resource}/email/forward`"
            table-empty-text="Nenhum redirecionamento encontrado"
            hide-edit
            no-remove-confirm
            @created="onForwardCreated"
            @remove="removeForward"
        >
            <template slot="intro-text">
                Envie uma cópia de todo e-mail recebido nesta caixa de e-mail para outro endereço existente.
                Por exemplo, você pode encaminhar todas as mensagens que chegarem neste endereço para
                <strong>seuemail@gmail.com</strong> ou <strong>seuemail@hotmail.com</strong>.
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="E-mail principal" label-for="forwardFrom">
                    <input type="text" id="forwardFrom" class="form-control" :value="formData.email" readonly>
                </form-group>

                <form-group label="E-mail de redirecionamento" label-for="forwardTo" :error="formErrors.forward">
                    <input type="email" id="forwardTo" class="form-control" v-model="formData.forward">
                </form-group>
            </template>
        </card-table>

        <card-title title="Resposta automática" inner-body>
            <template slot="intro-text">
                Uma resposta automática é uma mensagem que sempre será enviada para o contato que enviou um e-mail para
                você. Ela pode ser usada quando você vai sair de férias ou vai estar indisponível em um determinado momento.
            </template>

            <text-loading v-show="isLoadingAutoresponder" />

            <div v-show="!isLoadingAutoresponder">
                <button
                    v-if="!autoresponder"
                    type="button"
                    class="btn btn-success"
                    :disabled="isLoadingAutoresponder"
                    @click="showAutoresponderModal">Habilitar</button>

                <div v-else>
                    <div class="table-list-wrapper">
                        <table class="table table-list table-sm mb-0">
                            <tbody>
                                <tr v-if="autoresponder.startDate">
                                    <th class="col-w-px col-nowrap">Início:</th>
                                    <td>
                                        {{ autoresponder.startDate | moment("DD/MM/YYYY") }}
                                        <span v-if="autoresponder.startTime">- {{ autoresponder.startTime }}</span>
                                    </td>
                                </tr>
                                <tr v-if="autoresponder.stopDate">
                                    <th class="col-w-px col-nowrap">Término:</th>
                                    <td>
                                        {{ autoresponder.stopDate | moment("DD/MM/YYYY") }}
                                        <span v-if="autoresponder.stopTime">- {{ autoresponder.stopTime }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="col-w-px col-nowrap">Assunto:</th>
                                    <td>{{ autoresponder.subject }}</td>
                                </tr>
                                <tr>
                                    <th class="col-w-px col-nowrap align-top">Mensagem:</th>
                                    <td v-html="autoresponder.bodyHtml"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="mt-6">
                        <button-form ref="btnEditAutoresponder" class="btn btn-secondary" @click="showAutoresponderModal">Editar</button-form>
                        <button-form ref="btnRemoveAutoresponder" class="btn btn-danger ml-2" @click="$refs.modalRemoveAutoresponder.show()">Excluir</button-form>
                    </div>
                </div>
            </div>
        </card-title>

        <b-modal ref="modalAutoresponder" title="Configurar resposta automática" size="lg" centered hide-footer hide-header-close>
            <form @submit.prevent="saveAutoresponder">
                <form-group label="Assunto do e-mail" label-for="autoresponderSubject" :error="formErrors.subject">
                    <input type="text" id="autoresponderSubject" class="form-control" v-model="formData.subject">
                </form-group>

                <form-group label="Corpo da mensagem" label-for="autoresponderBody" :error="formErrors.body">
                    <textarea id="autoresponderBody" class="form-control" rows="6" v-model="formData.body"></textarea>
                </form-group>

                <div>
                    <b-form-checkbox v-model="formData.hasStart">
                        Definir data de início
                    </b-form-checkbox>

                    <div class="row" v-show="formData.hasStart">
                        <div class="col-8">
                            <form-group label="Data" label-for="autoresponderStartDate" class="mb-0 mt-2" :error="formErrors.startDate">
                                <input type="date" id="autoresponderStartDate" class="form-control" v-model="formData.startDate" placeholder="Data de início" aria-label="Data de início">
                            </form-group>
                        </div>
                        <div class="col-4">
                            <form-group label="Horário" label-for="autoresponderStartTime" class="mb-0 mt-2" :error="formErrors.startTime">
                                <input type="time" id="autoresponderStartTime" class="form-control" v-model="formData.startTime" placeholder="Horário de início" aria-label="Horário de início">
                            </form-group>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <b-form-checkbox v-model="formData.hasStop">
                        Definir data de término
                    </b-form-checkbox>

                    <div class="row" v-show="formData.hasStop">
                        <div class="col-8">
                            <form-group label="Data" label-for="autoresponderStopDate" class="mb-0 mt-2" :error="formErrors.stopDate">
                                <input type="date" id="autoresponderStopDate" class="form-control" v-model="formData.stopDate" placeholder="Data de término" aria-label="Data de término">
                            </form-group>
                        </div>
                        <div class="col-4">
                            <form-group label="Horário" label-for="autoresponderStopTime" class="mb-0 mt-2" :error="formErrors.stopTime">
                                <input type="time" id="autoresponderStopTime" class="form-control" v-model="formData.stopTime" placeholder="Horário de término" aria-label="Horário de término">
                            </form-group>
                        </div>
                    </div>
                </div>

                <div class="form-buttons text-right">
                    <button ref="btnAutoresponderCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalAutoresponder.hide()">Cancelar</button>
                    <button-form ref="btnAutoresponderSubmit" type="submit" variant="success" class="btn-action">Salvar</button-form>
                </div>
            </form>
        </b-modal>

        <modal-confirm ref="modalRemoveAutoresponder" title="Excluir resposta automática" confirm-text="Excluir" danger @confirm="removeAutoresponder">
            Tem certeza que deseja excluir a resposta automática configurada nesta conta de e-mail?
        </modal-confirm>

        <card-title inner-body>
            <div class="d-flex justify-content-between">
                <div>
                    <h4 class="card-title-inner">Habilitar antispam nesta conta de e-mail</h4>
                    <p class="mb-0">
                        Alterar o status do filtro antispam para esta conta de e-mail específica. Se o filtro antispam estiver
                        desabilitado globalmente não será possível habilitá-lo somente nesta conta.
                    </p>
                </div>
                <div class="ml-4">
                    <div class="d-flex justify-content-end align-items-center">
                        <i class="fas fa-circle-notch fa-spin text-muted mr-3" v-bind:class="{ 'invisible': !isLoadingAntispam }"></i>
                        <toggle-switch v-model="antispam.status" :callback="updateAntispam" :disabled="isLoadingAntispam || !antispam || !antispam.global" />
                    </div>
                </div>
            </div>

            <div v-show="!isLoadingAntispam && antispam && !antispam.global" class="alert alert-warning mt-6 mb-0">
                <strong>Aviso:</strong> O filtro antispam está desativado para todas as contas de e-mail.
            </div>
        </card-title>
    </div>
</template>

<script>
    import CardTitle from '@/components/molecules/CardTitle'
    import ToggleSwitch from '@/components/atoms/ToggleSwitch'
    import FormGroup from '@/components/molecules/FormGroup'
    import ButtonForm from '@/components/atoms/ButtonForm'
    import {mapActions} from 'vuex'
    import ErrorService from '@/services/error.service'
    import CardTable from '@/components/organisms/CardTable'
    import TextLoading from '@/components/atoms/TextLoading'
    import ModalConfirm from '@/components/atoms/ModalConfirm'

    export default {
        props: ['resource', 'account'],
        components: {
            ModalConfirm,
            TextLoading,
            CardTable,
            ButtonForm,
            FormGroup,
            ToggleSwitch,
            CardTitle
        },
        data() {
            return {
                tableAliasForwardsFields: [
                    {
                        name: 'email',
                        sortField: 'email',
                        filterable: true,
                        title: 'E-mail',
                    },
                ],

                email: '',
                info: {},
                alias: [],
                forwards: [],
                autoresponder: null,
                antispam: {},

                formData: {},
                formErrors: {},

                isLoadingEmail: false,
                isLoadingAutoresponder: false,
                isLoadingAntispam: false,
            }
        },
        mounted()
        {
            this.email = this.account.toLowerCase();
            this.fetchData();
            this.fetchAutoresponder();
            this.fetchAntispam();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert:      'alert/error',
            }),

            /**
             * Carregar dados da conta de e-mail.
             */
            fetchData()
            {
                this.isLoadingEmail = true;

                this.$api.get(`/hosting/${this.resource}/email/${encodeURIComponent(this.email)}`)
                    .then (response => {
                        this.info     = response.data.data;
                        this.email    = response.data.data.email;
                        this.alias    = response.data.data.alias.map(item => { return { email: item } });
                        this.forwards = response.data.data.forwards.map(item => { return { email: item } });
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then(() => this.isLoadingEmail = false)
            },

            /**
             * Carregar dados de resposta automática.
             */
            fetchAutoresponder()
            {
                this.isLoadingAutoresponder = true;

                this.$api.get(`/hosting/${this.resource}/email/autoresponder`, {params: { email: this.email }})
                    .then(response => {
                        let data = response.data.data;

                        if (data) {
                            data.hasStart = (data.startDate !== "");
                            data.hasStop  = (data.stopDate !== "");
                        }

                        this.$set(this, 'autoresponder', data);
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingAutoresponder = false);
            },

            aliasForwardFormDataTransform(data)
            {
                data.email = this.email;
                return data;
            },

            onAliasCreated(formData, responseData)
            {
                this.alias.unshift({ email: responseData.alias });
            },

            /**
             * Excluir endereço alternativo.
             */
            removeAlias(item)
            {
                const data = {
                    'email': this.email,
                    'alias': item.email,
                };

                this.$api.post(`/hosting/${this.resource}/email/remove-alias`, data)
                    .then ((response) => {})
                    .catch((error) => {
                        error.message = 'Ocorreu um erro ao tentar excluir o endereço alternativo. Por favor, atualize sua página e tente novamente.';
                        this.showErrorAlert(error);
                    });
            },

            onForwardCreated(formData, responseData)
            {
                this.forwards.unshift({ email: responseData.forward });
            },

            /**
             * Excluir redirecionamento.
             */
            removeForward(item)
            {
                const data = {
                    'email':   this.email,
                    'forward': item.email,
                };

                this.$api.post(`/hosting/${this.resource}/email/remove-forward`, data)
                    .then ((response) => {})
                    .catch((error) => {
                        error.message = 'Ocorreu um erro ao tentar excluir o redirecionamento. Por favor, atualize sua página e tente novamente.';
                        this.showErrorAlert(error);
                    });
            },

            /**
             * Exibir modal de configuração da resposta automática.
             */
            showAutoresponderModal()
            {
                if (this.autoresponder) {
                    this.formData = Object.assign({ email: this.email }, this.autoresponder);
                }
                else {
                    this.formData = { email: this.email };
                }

                this.formErrors = {};
                this.$refs.modalAutoresponder.show();
            },

            /**
             * Salvar informações de resposta automática.
             */
            saveAutoresponder()
            {
                this.formErrors = {};

                this.$refs.btnAutoresponderSubmit.loadingFocus();
                this.$refs.btnAutoresponderCancel.disabled = true;

                this.isLoadingAutoresponder = true;

                this.$api.post(`/hosting/${this.resource}/email/autoresponder`, this.formData)
                    .then(response => {
                        let data = response.data.data;

                        data.hasStart = (data.startDate && data.startDate !== '');
                        data.hasStop  = (data.stopDate && data.stopDate !== '');

                        this.$refs.modalAutoresponder.hide();
                        this.successNotification({ message: 'Resposta automática atualizada!' });
                        this.$set(this, 'autoresponder', data);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnAutoresponderSubmit.setLoading(false);
                        this.$refs.btnAutoresponderCancel.disabled = false;
                    })
                    .then(() => this.isLoadingAutoresponder = false);
            },

            /**
             * Excluir resposta automática.
             */
            removeAutoresponder()
            {
                this.$refs.btnEditAutoresponder.setDisabled(true);
                this.$refs.btnRemoveAutoresponder.loadingFocus();

                this.isLoadingAutoresponder = true;

                this.$api.delete(`/hosting/${this.resource}/email/autoresponder/${encodeURIComponent(this.email)}`)
                    .then (response => this.$set(this, 'autoresponder', null))
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.btnEditAutoresponder.setDisabled(false);
                        this.$refs.btnRemoveAutoresponder.setLoading(false);
                    })
                    .then(() => this.isLoadingAutoresponder = false);
            },

            /**
             * Obter status do antispam.
             */
            fetchAntispam()
            {
                this.antispam = {};
                this.isLoadingAntispam = true;

                this.$api.get(`/hosting/${this.resource}/spam/${encodeURIComponent(this.email)}`)
                    .then (response => this.antispam = response.data.data)
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingAntispam = false);
            },

            /**
             * Atualizar o status do antispam.
             */
            updateAntispam()
            {
                // Ignorar se antispam estiver desativado globalmente
                if (this.antispam.global !== true) return;

                const newValue = this.antispam.status;

                this.isLoadingAntispam = true;

                this.$api.post(`/hosting/${this.resource}/spam/${encodeURIComponent(this.email)}`, { status: newValue })
                    .then(response => {
                        this.antispam.status = response.data.data.status;
                        this.successNotification({ message: 'Configuração de antispam atualizada!' })
                    })
                    .catch(error => {
                        this.antispam.status = !newValue;
                        this.showErrorAlert(error);
                    })
                    .then(() => this.isLoadingAntispam = false);
            },

            /**
             * Exibir modal para editar o limite de espaço.
             */
            showChangeQuotaModal()
            {
                this.formData = {
                    'email': this.email,
                    'username': this.info.username,
                    'quota': 0
                };

                if (this.info.quota) {
                    this.formData.quota = parseInt(this.info.quota) / (1024 * 1024);
                }

                this.formErrors = {};
                this.$refs.modalChangeQuota.show();
            },

            /**
             * Alterar limite de espaço de uma conta de e-mail.
             */
            changeQuota()
            {
                this.formErrors = {};

                this.$refs.btnChangeQuotaSubmit.loadingFocus();
                this.$refs.btnChangeQuotaCancel.disabled = true;

                this.$api.post(`/hosting/${this.resource}/email/quota`, this.formData)
                    .then(response => {
                        this.$refs.modalChangeQuota.hide();
                        this.successNotification({ message: 'Limite de espaço atualizado!' });
                        this.$set(this.info, 'quota', response.data.data.quota);
                        this.fetchData();
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnChangeQuotaCancel.disabled = false;
                        this.$refs.btnChangeQuotaSubmit.setLoading(false);
                    });
            }
        }
    }
</script>