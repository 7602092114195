import { Api } from '@/plugins/api'

/**
 * Obter dados de um domínio.
 *
 * @param {string} domain
 * @return {Object}
 */
function get(domain)
{
    return Api.get(`/domain/${domain}`).then((response) => response.data.data)
}

export default {
    get
}