<template>
    <div class="h-t-admin-sidebar">
        <admin-sidebar :title="sidebarTitle" :list-items="sidebarItems" :filter-by="sidebarFilterBy" :loading="sidebarLoading" class="d-none d-lg-block">
            <template slot="list-item" slot-scope="props">
                <slot name="sidebar-list-item" v-bind="props"></slot>
            </template>
        </admin-sidebar>
        <admin-main-content>
            <slot></slot>
        </admin-main-content>
    </div>
</template>

<script>
    import AdminSidebar     from "../organisms/AdminSidebar"
    import AdminMainContent from "../organisms/AdminMainContent"

    export default {
        props: {
            sidebarTitle: {
                type: String,
                default: ""
            },
            sidebarItems: {
                type: Array,
                default() {
                    return [];
                }
            },
            sidebarFilterBy: {
                type: String,
                default: ""
            },
            sidebarLoading: {
                type: Boolean,
                default: false
            },
        },
        components: {
            AdminMainContent,
            AdminSidebar
        }
    }
</script>

<style lang="stylus">
    .h-t-admin-sidebar
        @media (max-width 1779px)
            display flex
            flex-direction row

            .h-o-admin-sidebar
                position initial
                top auto
                left auto
                margin-top 0

            .h-o-main-content
                flex-grow 1

                .container-lg
                    margin-left 0
                    padding-left 0
                    padding-right 0

        @media (max-width 1499px)
            .h-o-admin-sidebar
                width 240px
</style>