<template>
    <b-modal
        ref="modal"
        :title="title"
        :title-class="titleClass"
        :size="size"
        centered
        hide-header-close
        @ok="confirm"
        @cancel="cancel"
    >
        <template #modal-footer="{ cancel, ok }">
            <b-button class="btn-action" variant="secondary" @click="cancel">{{ cancelTitle }}</b-button>
            <b-button class="btn-action" :variant="okVariant" @click="ok">{{ okText }}</b-button>
        </template>
        <slot></slot>
    </b-modal>
</template>

<script>
    /**
     * TODO: Alterar o componente de átomo para molécula.
     */
    export default {
        props: {
            title: String,
            titleClass: String,
            confirmText: String,
            danger: Boolean,
            loading: Boolean,
            size: {
                type: String,
                default: "md",
            }
        },
        data() {
            return {
                okTitle: "Confirmar",
                cancelTitle: "Cancelar",
            }
        },
        computed: {
            okVariant: function() {
                return this.danger ? "danger" : "success";
            },
            okText: function() {
                return this.confirmText || this.okTitle;
            }
        },
        methods: {
            show() {
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },

            /**
             *
             */
            setLoading(show)
            {
                if (show) {
                    // console.log(this.$refs.modal.$el);
                }
            },

            /**
             * Ação ao clicar no botão de confirmar.
             */
            confirm(e)
            {
                this.$emit('confirm');
            },

            /**
             * Ação ao clicar no botão de cancelar.
             */
            cancel(e)
            {
                this.$emit('cancel');
            }
        },
        watch: {
            loading: function(val, oldVal) {
                if (val !== oldVal) {
                    this.setLoading(val);
                }
            }
        }
    }
</script>