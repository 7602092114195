<template>
    <admin-main-content>
        <announcement-area />
        <router-view />
    </admin-main-content>
</template>

<script>
    import AdminMainContent from '@/components/organisms/AdminMainContent'
    import AnnouncementArea from '@/components/organisms/AnnouncementArea'

    export default {
        components: {
            AnnouncementArea,
            AdminMainContent
        }
    }
</script>