<template>
    <domain-template :resource="resource">
        <card-title title="Servidores DNS" inner-body>
            <p class="mb-6">
                Abaixo estão listados os servidores DNS utilizados atualmente pelo seu domínio. Também é possível
                alterar os servidores atuais para o padrão da Hostoo ou para servidores externos.
            </p>

            <text-loading v-show="isLoading" />

            <div class="table-list-wrapper">
                <table v-if="nameservers" v-show="!isLoading" class="table table-list table-sm mb-0">
                    <tbody>
                        <template v-for="(item, index) in nameservers">
                            <tr>
                                <th class="col-w-px col-nowrap">#{{ index+1 }}</th>
                                <td>{{ item }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <div class="btn-toolbar mt-6" role="toolbar">
                <div class="btn-group ml-auto" role="group">
                    <button type="button" class="btn btn-success" :disabled="isLoading || !nameservers" @click="showChangeNameserversModal">Alterar servidores</button>
                </div>
                <div class="btn-group ml-2" role="group">
                    <button ref="btnResetDns" type="button" class="btn btn-secondary" :disabled="isLoading || !nameservers" @click="">Restaurar padrões</button>
                </div>
            </div>
        </card-title>

        <b-modal ref="modalChangeNameservers" title="Alterar nameservers" centered hide-footer>
            <form @submit.prevent="changeNameservers">
                <template v-for="(item, idx) in newNameservers">
                    <form-group :error="formErrors['nameserver-'+idx]">
                        <div class="input-group">
                            <input type="text" class="form-control" spellcheck="false" :placeholder="'Servidor ' + (idx+1)" v-model="newNameservers[idx]">
                            <div class="input-group-append">
                                <button type="button" class="btn btn-danger" @click="removeNameserver(idx)">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </form-group>
                </template>

                <div class="form-buttons d-flex justify-content-between">
                    <button type="button" class="btn btn-link px-0" :disabled="newNameservers.length >= 5" @click="newNameservers.push('')">Adicionar</button>
                    <div>
                        <button ref="btnChangeNameserversCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalChangeNameservers.hide()">Cancelar</button>
                        <button-form ref="btnChangeNameserversSubmit" type="submit" theme="success" class="btn-action">Salvar</button-form>
                    </div>
                </div>
            </form>
        </b-modal>
    </domain-template>
</template>

<script>
    import { mapActions }   from 'vuex'
    import AjaxService      from '@/services/AjaxService'
    import TextLoading      from '@/components/atoms/TextLoading'
    import CardTitle        from '@/components/molecules/CardTitle'
    import DomainTemplate   from '@/components/templates/DomainTemplate'
    import FormGroup from '@/components/molecules/FormGroup'
    import ButtonForm from '@/components/atoms/ButtonForm'
    import ErrorService from '@/services/error.service'

    export default {
        name: 'DomainNameservers',
        props: ['resource'],
        components: {
            ButtonForm,
            FormGroup,
            TextLoading,
            CardTitle,
            DomainTemplate
        },
        data() {
            return {
                nameservers: null,
                newNameservers: [],
                formErrors: {},
                isLoading: false,
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.fetchNameservers();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Obter nameservers do domínio.
             */
            fetchNameservers()
            {
                this.isLoading = true;

                this.$api.get(`/domain/${this.resource}/nameservers`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => this.nameservers = response.data.data)
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoading = false);
            },

            /**
             * Exibir modal para alteração de nameservers.
             */
            showChangeNameserversModal()
            {
                this.newNameservers = this.nameservers.slice(0);

                for (let i = this.newNameservers.length; i < 2; i++) {
                    this.newNameservers.push("");
                }

                this.formErrors = {};
                this.$refs.modalChangeNameservers.show();
            },

            removeNameserver(idx)
            {
                this.newNameservers.splice(idx, 1);

                for (let i = this.newNameservers.length; i < 2; i++) {
                    this.newNameservers.push("");
                }
            },

            /**
             * Alterar nameservers.
             */
            changeNameservers()
            {
                this.formErrors = {};
                this.$refs.btnChangeNameserversSubmit.setLoadingAndFocus();
                this.$refs.btnChangeNameserversCancel.disabled = true;

                this.$api.post(`/domain/${this.resource}/nameservers`, { "nameservers": this.newNameservers })
                    .then(() => {
                        this.$refs.modalChangeNameservers.hide();
                        this.fetchNameservers();
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnChangeNameserversCancel.disabled = false;
                        this.$refs.btnChangeNameserversSubmit.setLoading(false);
                    });
            }
        }
    }
</script>
