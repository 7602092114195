// Recomendation of babeljs (https://babeljs.io/docs/en/babel-polyfill)
import 'core-js/stable'; // only stable feature also is possible with only `core-js`
import 'regenerator-runtime/runtime'; // To ensure that regeneratorRuntime is defined globally

import Vue          from 'vue'
import router       from './router'
import acl          from './acl'
import store        from './store'
import filters      from './filters'
import directives   from './directives'
import api          from './plugins/api'
import sentry       from './plugins/sentry'

// Moment.JS
import moment    from 'moment'
import VueMoment from 'vue-moment'
import 'moment/locale/pt-br'

// Other plugins
import BootstrapVue from 'bootstrap-vue'
import VueTheMask   from 'vue-the-mask'
import VueCookie    from 'vue-cookie'
import './sass/fontawesome.scss'

// Base component
import App from './components/App.vue'

Vue.config.productionTip = false

Vue.use(filters)
Vue.use(directives)
Vue.use(api)
Vue.use(BootstrapVue)
Vue.use(VueTheMask)
Vue.use(VueCookie)
Vue.use(VueMoment, { moment })

/**
 * Sentry
 */
if (process.env.VUE_APP_SENTRY_ENABLED) {
    Vue.use(sentry)
}

/**
 * Event Bus
 * TODO: Criar um plugin
 */
Vue.prototype.$eventBus = new Vue();

/**
 * Start application
 */
new Vue({
    el: '#app',
    router,
    acl,
    store,
    render: h => h(App),
    mounted() {
        /**
         * Adicionar classe personalizada em todos os tooltips
         */
        this.$root.$on('bv::tooltip::show', evt => {
            if (evt.vueTarget.placement) {
                this.$nextTick(() => document.getElementById(evt.componentId).classList.add("bs-tooltip-"+evt.vueTarget.placement))
            }
        })
    }
})
