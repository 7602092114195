<template>
    <div class="h-p-hosting-apps">
        <card-table
            ref="cardApps"
            title="Aplicativos"
            class="h-card-hosting-apps"
            hide-add
            hide-edit
            remove-title="Excluir aplicativo"
            table-empty-text="Nenhum aplicativo encontrado"
            :api-url="`/hosting/${resource}/apps`"
            :table-fields="tableAppsFields"
            :toolbar-actions="cardAppsActions"
            :filter-term="filterTerm"
            @remove="removeApp"
        >
            <template slot="intro-text">
                Os aplicativos instalados ou em processo de instalação em sua hospedagem estarão listados abaixo.
            </template>

            <template slot="table-dir" slot-scope="{ rowData }">
                <text-copy :text="rowData.dir" text-class="text-monospace" />
            </template>

            <template slot="table-created" slot-scope="{ rowData }">
                <span v-if="rowData.isInstalling" class="text-muted">
                    <i class="fas fa-circle-notch fa-spin mr-1"></i> Instalando ({{ Math.floor(rowData.progress) + '%' }})
                </span>
                <span v-else :title="$moment(rowData.createdAt).format('DD/MM/YYYY HH:mm:ss')">
                    {{ rowData.createdAt | moment("from", new Date()) }}
                </span>
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item :href="rowData.url" :disabled="!rowData.url" target="_blank">
                    <i class="fas fa-external-link-alt fa-fw"></i> Acessar
                </b-dropdown-item>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir o aplicativo abaixo?</p>
                <p class="mb-0"><strong v-html="item.app"></strong></p>
            </template>
        </card-table>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import hostingApps from '@/mixins/hostingApps'
    import AjaxService from '@/services/AjaxService'
    import TextCopy    from '@/components/molecules/TextCopy'
    import CardTable   from '@/components/organisms/CardTable'

    export default {
        props: ['resource'],
        mixins: [
            hostingApps
        ],
        components: {
            CardTable,
            TextCopy
        },
        data() {
            return {
                cardAppsActions: [
                    {
                        text: "Instalar aplicativo",
                        variant: "success",
                        link: `/hosting/${this.resource}/app/install`
                    }
                ],
                tableAppsFields: [
                    {
                        name: 'app',
                        sortField: 'app',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Aplicativo',
                        titleClass: 'col-w-48 col-nowrap',
                        dataClass: 'col-w-48 col-nowrap',
                    },
                    {
                        name: '__slot:dir',
                        sortField: 'dir',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Diretório',
                    },
                    {
                        name: '__slot:created',
                        sortField: 'createdAt',
                        sortFunction: 'text',
                        title: 'Instalado em',
                        titleClass: 'col-datetime',
                        dataClass: 'col-datetime',
                    }
                ],

                appsInstalled: [],
                itemToRemove: {},

                filterTerm: "",

                isLoadingApps: false,
                refreshAppsTimeout: null,
                refreshAppsCancelToken: null
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.refreshAppsTimeout && clearTimeout(this.refreshAppsTimeout);
            this.$refs.cardApps.cancelFetch();
            AjaxService.cancel();
            next();
        },
        created()
        {
            if (this.$route.query.name)
            {
                const appSearch = this.apps.find(item => item.name === this.$route.query.name);

                if (appSearch) {
                    this.filterTerm = appSearch.title;
                }
            }
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Atualizar lista de aplicativos instalados.
             */
            refreshApps()
            {
                this.$api.get(`/hosting/${this.resource}/apps`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.appsInstalled = response.data.data;

                        for (let i = 0; i < this.appsInstalled.length; i++) {
                            if (this.appsInstalled[i].isInstalling) {
                                this.refreshAppsTimeout = setTimeout(() => this.refreshApps(), 5000);
                                break;
                            }
                        }
                    })
                    .catch(error => {
                        this.refreshAppsTimeout = setTimeout(() => this.refreshApps(), 10000);
                    });
            },

            /**
             * Excluir aplicativo.
             */
            removeApp(item)
            {
                const id = item.id;

                this.$api.delete(`/hosting/${this.resource}/apps/${id}`)
                    .then (response => {})
                    .catch(error => this.showErrorAlert(error));
            }
        }
    }
</script>
