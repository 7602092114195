<template>
    <div class="h-layout h-layout-full-height h-layout-admin" v-bind:class="{ 'is-page-loading': showPageLoader }">
        <page-loader v-if="showPageLoader" />

        <div class="h-layout-content">
            <admin-header />
            <router-view />
        </div>

        <notification-area v-show="notifications.length" :notifications="notifications" @dismissed="onNotificationDismiss" />
        <nav-help />

        <b-modal
            ref="globalAlert"
            centered
            hide-header-close
            :title-html="globalAlertTitle"
            :title-class="globalAlertTitleClass"
            @hide="clearGlobalAlert()"
        >
            {{ globalAlertContent }}

            <div v-if="showErrorNotificationCheckbox" class="mt-4">
                <b-form-checkbox v-model="notifyError">Notificar a Hostoo sobre o problema ocorrido.</b-form-checkbox>
                <textarea
                    v-show="notifyError"
                    class="form-control mt-4"
                    rows="3"
                    placeholder="Se desejar, informe mais detalhes sobre o problema..."
                    v-model="errorNotificationDetails"></textarea>
            </div>

            <template slot="modal-footer" slot-scope="{ hide }">
                <button type="button" class="btn btn-secondary btn-action" @click="hide">Fechar</button>
                <button v-show="notifyError" type="button" class="btn btn-primary btn-action" @click="sendErrorNotification">Enviar</button>
            </template>
        </b-modal>
        
        <form-questionnaire  v-if="user && user.questionnaireId" :questionnaireId="user.questionnaireId"></form-questionnaire>

        <template v-for="(item, index) in tutorialPopovers">
            <b-popover
                :key="`tutorial-popover-${index}`"
                :id="item.id"
                :target="item.target"
                :placement="item.placement"
                :show="true"
                triggers=""
                custom-class="tutorial-popover">
                <div v-if="item.step" class="tutorial-header">Etapa {{ item.step }}<span v-if="item.totalSteps">/{{ item.totalSteps }}</span></div>
                <div v-html="item.content"></div>
                <div v-if="item.onNext || item.onFinish" class="tutorial-footer">
                    <button v-if="item.onNext" type="button" class="btn btn-link" @click="item.onNext">Avançar</button>
                    <button v-if="item.onFinish" type="button" class="btn btn-link text-danger" @click="item.onFinish">Finalizar tutorial</button>
                </div>
            </b-popover>
        </template>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import survey                   from '@/mixins/survey'
    import AlertCompact             from '@/components/atoms/AlertCompact'
    import AlertAutoRecharge        from '@/components/atoms/AlertAutoRecharge'
    import PageLoader               from '@/components/molecules/PageLoader'
    import NavHelp                  from '@/components/molecules/NavHelp'
    import NotificationArea         from '@/components/organisms/NotificationArea'
    import AdminHeader              from '@/components/organisms/AdminHeader'
    import FormQuestionnaire        from '@/components/organisms/FormQuestionnaire'

    export default {
        mixins: [
            survey
        ],
        components: {
            NavHelp,
            PageLoader,
            NotificationArea,
            AdminHeader,
            FormQuestionnaire
        },
        data() {
            return {
                globalAlertShow: false,
                notifyError: false,
                errorNotificationDetails: '',

                // Ask group modal
                shownAskGroupModal: false,

                // Jivochat
                isJivoLoaded: false,
                isJivoUserConfigured: false,
            }
        },
        computed: {
            ...mapState({
                user:               state => state.user.user,
                credits:            state => state.user.credits,
                hosting:            state => state.hosting.hosting,
                tutorialPopovers:   state => state.tutorial.popovers,
                showPageLoader:     state => state.loading.counter,
                notifications:      state => state.notification.items,
                globalAlertType:    state => state.alert.type,
                globalAlertMessage: state => state.alert.message,
                errorResponse:      state => state.alert.response,
                rawError:           state => state.alert.rawError,
            }),

            globalAlertSubtype: function()
            {
                if (this.globalAlertType === "danger")
                {
                    if (this.errorResponse && this.errorResponse.status && this.errorResponse.status >= 500) {
                        return "error";
                    }
                    else {
                        return "warning";
                    }
                }

                return "info";
            },

            globalAlertTitle: function()
            {
                switch (this.globalAlertSubtype) {
                    case "error":   return '<i class="fas fa-minus-circle fa-fw"></i> Erro';
                    case "warning": return '<i class="fas fa-exclamation-circle fa-fw"></i> Alerta';
                    case "info":
                    default:        return '<i class="fas fa-info-circle fa-fw"></i> Notificação';
                }
            },
            globalAlertTitleClass: function()
            {
                switch (this.globalAlertSubtype) {
                    case "error": return "text-danger";
                    default:      return "text-primary";
                }
            },
            showErrorNotificationCheckbox: function()
            {
                return (
                    this.globalAlertSubtype === "error" &&
                    this.errorResponse.status !== 502 &&
                    this.errorResponse.status !== 503 &&
                    this.errorResponse.status !== 524
                );
            },
            globalAlertContent: function()
            {
                if (this.globalAlertSubtype === "error" && (this.errorResponse.status === 522 || this.errorResponse.status === 524)) {
                    return 'O servidor demorou muito tempo para responder. Por favor, atualize sua página e tente novamente. Se o erro persistir, entre em contato com nosso Suporte.';
                }
                else {
                    return this.globalAlertMessage;
                }
            }
        },
        created()
        {
            document.body.classList.add('has-scroll');

            // Jivochat
            document.addEventListener('jivoLoaded', (e) => {
                this.isJivoLoaded = true;
                this.configureJivoUser();
            });

            this.fetchUser();
            this.fetchCredits();
        },
        mounted()
        {
            this.getEmergencyCreditInfo();
        },
        destroyed()
        {
            document.body.classList.remove('has-scroll');
        },
        methods: {
            ...mapActions({
                fetchUser: 'user/fetchUser',
                fetchCredits: 'user/fetchCredits',
                addAnnouncement: 'announcement/add',
                clearGlobalAlert: 'alert/clear',
                closeNotification: 'notification/close',
            }),

            /**
             * Obter informações sobre crédito emergencial do usuário.
             */
            getEmergencyCreditInfo()
            {
                this.$api.get('/recharge/emergency')
                    .then (response => {
                        if (response.data.data.isSuspended)
                        {
                            this.addAnnouncement({
                                component: AlertCompact,
                                props: {
                                    variant: 'danger',
                                    title: 'Seus serviços estão suspensos!',
                                    text: 'Um ou mais de seus serviços foram suspensos. Reative agora realizando uma recarga.',
                                    btnText: 'Recarregue agora',
                                    btnLink: '/payment/recharge'
                                }
                            });
                        }
                        else if (
                            !this.credits.bonusExpired &&
                            (
                                !this.credits.hasServices ||
                                this.credits.estimate > 0
                            )
                        ) {
                            this.addAnnouncement({
                                component: AlertCompact,
                                props: {
                                    variant: 'info',
                                    title: 'Sua conta está em período de teste',
                                    text: `Você possui mais ${this.credits.estimate} dias para usar o seu bônus.`,
                                }
                            });
                        }
                        else if (
                            response.data.data.isEnabled ||
                            response.data.data.isAllowed ||
                            (
                                this.credits.hasServices &&
                                this.credits.estimate <= 5
                            )
                        ) {
                            this.addAnnouncement({
                                component: AlertCompact,
                                props: {
                                    variant: 'warning',
                                    title: 'Seus créditos estão terminando!',
                                    text: 'Evite que seus serviços fiquem suspensos realizando uma recarga.',
                                    btnText: 'Recarregue agora',
                                    btnLink: '/payment/recharge'
                                }
                            });
                        }
                    })
                    .catch(error => {});
            },

            onNotificationDismiss(item)
            {
                this.closeNotification(item.id);
            },

            sendErrorNotification()
            {
                const currentPage  = window.location.href || '';
                const errorConfig  = this.errorResponse && this.errorResponse.config ? this.errorResponse.config : {};
                const errorData    = this.errorResponse && this.errorResponse.data ? this.errorResponse.data : {};
                const errorMessage = errorData.message || this.globalAlertMessage || '';

                let message = `Página: ${currentPage}\nMensagem: ${errorMessage}`;

                if (this.errorNotificationDetails) {
                    message += `\n\n${this.errorNotificationDetails}`;
                }

                const apiUrl    = errorConfig.url || '';
                const apiMethod = errorConfig.method ? errorConfig.method.toUpperCase() : '';
                const apiStatus = this.errorResponse && this.errorResponse.status ? this.errorResponse.status : '';

                let note = '';

                if (apiUrl) {
                    note += `API: ${apiMethod} ${apiUrl}\nStatus: ${apiStatus}\n`;
                }

                if (this.rawError)
                {
                    if (this.rawError.message) {
                        note += `Mensagem: ${this.rawError.message}\n`;
                    }

                    if (this.rawError.stack) {
                        note += `Stack: ${this.rawError.stack}`;
                    }
                }

                let postData = {
                    subject: -1,
                    otherSubject: 'Notificação de erro',
                    department: 1,
                    message: message,
                    note: note,
                };

                if (this.hosting && this.hosting.domain) {
                    postData['service'] = this.hosting.domain;
                }

                this.$api.post('/ticket', postData).catch(() => {/* ignore error */});
                this.$refs.globalAlert.hide();
            },

            /**
             * Configurar dados de contato para o Jivochat.
             */
            configureJivoUser()
            {
                if (this.isJivoLoaded && this.user && !this.isJivoUserConfigured)
                {
                    this.isJivoUserConfigured = true;

                    jivo_api.setContactInfo({
                        name:  this.user.name,
                        email: this.user.email,
                        phone: '+55' + this.user.phone,
                    });

                    jivo_api.setUserToken(this.user.id);
                }
            }
        },
        watch: {
            user: function (val)
            {
                if (val) {
                    this.configureJivoUser();

                    if (val.registerDomain) {
                        this.$router.push({ path: '/domain/'+ val.registerDomain +'/register' });
                    }
                    else if (val.showSurveyProfile) {
                        this.$router.push({ path: '/survey-profile' });
                    }
                    else {
                        if (val.askGroup && !this.shownAskGroupModal) {
                            this.shownAskGroupModal = true;
                            this.showAskGroupModal(this.user.name);
                        }

                        if (val.showAutoRechargeAlert && sessionStorage.getItem('hide_auto_recharge_alert') !== '1') {
                            this.addAnnouncement({ component: AlertAutoRecharge });
                        }
                    }
                }
            },
            globalAlertMessage: function (val) {
                if (!!val) {
                    this.notifyError = false;
                    this.$refs.globalAlert.show();
                }
                else {
                    this.$refs.globalAlert.hide();
                }
            }
        }
    }
</script>

<style lang="stylus">
   

    body.modal-open .h-layout .h-layout-content
        filter blur(5px)
        transition filter .3s linear .15s

    .h-layout.is-page-loading .h-layout-content
        filter blur(5px)
        transition filter .3s ease-in

    .h-layout-admin
        background-color #F8F8F8

        .h-layout-content
            padding-top calc(1rem + 50px)
</style>