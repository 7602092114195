<template>
    <hosting-files-template :resource="resource">
        <card-table
            ref="cardDeploy"
            inner-title="Deploy via GIT"
            hide-add
            hide-edit
            remove-title="Excluir integração"
            table-empty-text="Nenhum repositório encontrado"
            :api-url="`/hosting/${resource}/deploy`"
            :table-fields="tableRepositoriesFields"
            :toolbar-actions="cardDeployActions"
            @remove="removeDeploy"
        >
            <template slot="intro-text">
                Publique seu site ou aplicação existente em um repositório GIT nos sistemas de versionamento mais
                populares. Além disso, com o deploy automático sua aplicação vai ser atualizada toda vez que for feito
                um <strong>git push</strong> no branch configurado.
            </template>

            <template slot="table-service" slot-scope="{ rowData }">
                <span>
                    <i class="mr-1" v-bind:class="getProvider(rowData.service, 'icon')"></i>
                    {{ getProvider(rowData.service, 'title') }}
                </span>
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item-button @click="showLogsModal(rowData)">
                    <i class="far fa-file-alt fa-fw"></i> Logs
                </b-dropdown-item-button>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">
                    Tem certeza que deseja excluir a integração com o repositório abaixo?
                </p>
                <table class="table table-borderless table-sm mb-0">
                    <tbody>
                        <tr>
                            <th class="col-w-1 col-nowrap pl-0">Repositório:</th>
                            <td>{{ item.url }}</td>
                        </tr>
                        <tr>
                            <th class="col-w-1 col-nowrap pl-0">Diretório:</th>
                            <td>{{ item.dir }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </card-table>

        <b-modal ref="modalAddDeploy" :size="provider ? 'md' : 'lg'" title="Adicionar integração com repositório GIT" centered hide-footer hide-header-close>
            <p v-show="!provider" class="mb-6">
                Para começar, escolha abaixo o provedor no qual deseja realizar a integração. Se desejar integrar com um
                provedor que não esteja na lista, entre em contato com nosso suporte.
            </p>

            <div v-show="!provider" class="card-apps-wrapper">
                <div class="row">
                    <template v-for="(item, index) in providers">
                        <div class="col-md-4">
                            <div class="card card-app mb-md-0">
                                <div class="card-body">
                                    <img class="app-logo" :src="item.logo" />
                                    <h5 class="card-title">{{ item.title }}</h5>
                                    <button v-if="item.soon" type="button" class="btn btn-secondary btn-block mt-4" disabled>Em breve</button>
                                    <button v-else type="button" class="btn btn-success btn-block mt-4" @click="beginIntegration(item)">Integrar</button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <p v-if="provider" class="mb-6">
                Agora conecte-se à sua conta no {{ provider.title }} para selecionar o repositório desejado para
                realizar a sua integração.
            </p>

            <div v-if="provider" class="form-buttons">
                <button-form ref="btnConnect" variant="success" @click="connectToProvider(provider.id)">
                    Conectar ao {{ provider.title }}
                </button-form>
                <button type="button" class="btn btn-secondary ml-2" @click="provider = null">Voltar</button>
            </div>
        </b-modal>

        <b-modal ref="modalConfigDeploy" size="lg" title="Adicionar integração com repositório GIT" centered hide-footer hide-header-close>
            <form @submit.prevent="addDeploy">
                <form-group label="Repositório *" label-for="deployRepository" :error="formErrors.repository">
                    <div v-show="isLoadingRepositories">
                        <text-loading class="mt-1" text="Carregando lista de repositórios" />
                    </div>
                    <select v-show="!isLoadingRepositories" id="deployRepository" class="custom-select" v-model="formData.repository" @change="onRepositoryChange">
                        <option value="" disabled>Escolha...</option>
                        <template v-for="item in repositories">
                            <option :value="item.url">{{ item.fullPath }}</option>
                        </template>
                    </select>
                </form-group>

                <form-group label="Branch *" label-for="deployBranch" :error="formErrors.branch">
                    <input type="text" id="deployBranch" class="form-control" spellcheck="false" v-model="formData.branch">
                </form-group>

                <form-group label="Diretório de publicação" label-for="deployDir" :error="formErrors.dir">
                    <!--<label class="form-label ml-2" for="deployDir">
                        <a data-toggle="collapse" href="#deployDirHelp" aria-expanded="false" aria-controls="deployDirHelp" title="Clique para expandir">
                            <i class="fas fa-question-circle"></i>
                        </a>
                    </label>-->
                    <p class="form-text-sm">
                        O diretório onde o repositório será clonado. Se o diretório não existir, ele será
                        criado automaticamente. Caso já exista, o diretório deve estar vazio.
                    </p>
                    <!--<div id="deployDirHelp" class="collapse">
                        <p class="form-text text-muted ml-2">
                            Informe o diretório onde o repositório será clonado. Se o diretório não existir, ele será
                            criado automaticamente. Caso já exista, o diretório deve estar vazio.
                        </p>
                    </div>-->
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text text-monospace">~/</span>
                        </div>
                        <input type="text" id="deployDir" class="form-control" spellcheck="false" v-model="formData.dir">
                    </div>
                </form-group>

                <div v-show="showAddDeployAlert" class="alert alert-warning">
                    <i class="far fa-clock mr-1"></i>
                    A integração com o provedor pode demorar alguns minutos.
                </div>

                <div class="form-buttons text-right">
                    <!--<div ref="delayAlert" class="alert alert-warning" style="display:none">
                        <i class="far fa-clock mr-1"></i>
                        A integração com o provedor pode demorar alguns minutos...
                    </div>-->

                    <button ref="btnAddDeployCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="cancelDeploy">Cancelar</button>
                    <button-form ref="btnAddDeploySubmit" type="submit" variant="success" class="btn-action">Integrar</button-form>
                </div>
            </form>
        </b-modal>

        <b-modal ref="modalDeployLogs" size="lg" title="Histórico de deploys" centered hide-footer>
            <table class="table table-custom-header mb-0" v-bind:class="[!isLoadingDeployLogs && deployLogs.length ? 'table-hover' : '']">
                <thead>
                    <tr>
                        <th scope="col" class="col-w-16 text-center">Status</th>
                        <th scope="col">Mensagem</th>
                        <th scope="col" class="col-datetime">Executado em</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="isLoadingDeployLogs">
                        <td colspan="3" class="text-muted">
                            <i class="fas fa-circle-notch fa-spin"></i> Carregando...
                        </td>
                    </tr>
                    <tr v-else-if="!isLoadingDeployLogs && !deployLogs.length">
                        <td colspan="3" class="text-center">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                    <tr v-else v-for="item in deployLogs">
                        <td class="col-w-16 col-status text-center">
                            <i v-if="item.succeeded === true" class="fas fa-check-circle text-success"></i>
                            <i v-else-if="item.succeeded === false" class="fas fa-times-circle text-danger"></i>
                            <i v-else class="fas fa-question-circle text-secondary"></i>
                        </td>
                        <td>{{ item.message }}</td>
                        <td class="col-datetime">
                            <span :title="$moment(item.date).format('DD/MM/YYYY HH:mm:ss')">
                                {{ item.date | moment("from", new Date()) }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-modal>
    </hosting-files-template>
</template>

<script>
    import { mapActions }       from 'vuex'
    import AjaxService          from '@/services/AjaxService'
    import ErrorService         from '@/services/error.service'
    import ButtonForm           from '@/components/atoms/ButtonForm'
    import TextLoading          from '@/components/atoms/TextLoading'
    import CardTable            from '@/components/organisms/CardTable'
    import FormGroup            from '@/components/molecules/FormGroup'
    import HostingFilesTemplate from '@/components/templates/HostingFilesTemplate'

    export default {
        props: [
            'resource'
        ],
        components: {
            TextLoading,
            FormGroup,
            ButtonForm,
            CardTable,
            HostingFilesTemplate
        },
        data() {
            return {
                providers: [
                    { id: "gitlab",    title: "GitLab",    logo: "/images/providers/gitlab.png",    icon: "fab fa-gitlab",    soon: false },
                    { id: "github",    title: "GitHub",    logo: "/images/providers/github.png",    icon: "fab fa-github",    soon: false },
                    { id: "bitbucket", title: "Bitbucket", logo: "/images/providers/bitbucket.png", icon: "fab fa-bitbucket", soon: true  },
                ],
                errorMessages: {
                    "invalid_grant": "A autorização com o provedor expirou ou é inválida. Por favor, tente realizar a autorização novamente.",
                    "default": "Ocorreu um erro na autorização com o provedor."
                },
                tableRepositoriesFields: [
                    {
                        name: '__slot:service',
                        sortField: 'service',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Serviço',
                        titleClass: 'col-w-28',
                        dataClass: 'col-w-28 col-nowrap',
                    },
                    {
                        name: 'url',
                        sortField: 'url',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Repositório',
                    },
                    {
                        name: 'branch',
                        sortField: 'branch',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Branch',
                        titleClass: 'col-w-24',
                        dataClass: 'col-w-24 col-nowrap',
                    },
                    {
                        name: 'dir',
                        sortField: 'dir',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Diretório',
                        titleClass: 'col-w-24',
                        dataClass: 'col-w-24 col-nowrap text-monospace',
                    },
                ],
                cardDeployActions: [
                    {
                        text: "Adicionar integração",
                        variant: "success",
                        callback: (add, edit, remove) => {
                            this.provider = null;
                            this.$refs.modalAddDeploy.show();
                        }
                    }
                ],

                provider: null,
                repositories: [],
                isLoadingRepositories: false,

                formData: {},
                formErrors: {},
                showAddDeployAlert: false,

                deployLogs: [],
                isLoadingDeployLogs: false
            }
        },
        beforeRouteEnter(to, from, next)
        {
            next(vm => vm.init());
        },
        beforeRouteUpdate(to, from, next)
        {
            next();
            this.init();
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardDeploy.cancelFetch();
            AjaxService.cancel();
            next();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert:      'alert/error'
            }),

            /**
             * Obter dados do provedor.
             */
            getProvider(id, field)
            {
                const provider = this.providers.find(el => el.id === id);

                if (typeof field !== "undefined" && field) {
                    return provider[field];
                }
                else {
                    return provider;
                }
            },

            init()
            {
                if (this.$route.query.error)
                {
                    const error = this.$route.query.error;

                    if (error in this.errorMessages) {
                        this.showErrorAlert(this.errorMessages[error]);
                    }
                    else {
                        this.showErrorAlert(this.errorMessages.default);
                    }
                }
                else if (this.$route.query.provider)
                {
                    const provider = this.providers.find(el => el.id === this.$route.query.provider);

                    if (provider)
                    {
                        this.provider = provider;
                        this.$refs.modalConfigDeploy.show();
                        this.fetchRepositories(provider.id);
                    }
                }
            },

            beginIntegration(provider)
            {
                this.provider = provider;
            },

            /**
             * Conectar-se à conta do usuário no provedor.
             */
            connectToProvider(providerId)
            {
                this.$refs.btnConnect.loadingFocus();

                this.$api.post(`/hosting/${this.resource}/deploy/connect`, { provider: providerId })
                    .then((response) => {
                        if (typeof response.data.data.redirect !== "undefined") {
                            window.location.href = response.data.data.redirect;
                        }
                        // else {
                        //     this.step = 3;
                        //     this.fetchProviderRepositories(this.provider.id);
                        // }
                    })
                    .catch((error) => {
                        // HandleErrors.formError(error, this);
                        // this.$refs.btnConnect.disabled = false;
                        // this.$refs.btnConnect.classList.remove("btn-loading");
                    });
            },

            /**
             * Listar repositórios do usuário em um provedor.
             */
            fetchRepositories(providerId)
            {
                this.repositories = [];
                // this.$set(this.deploy, "repository", "");

                this.isLoadingRepositories = true;

                this.$api.get(`/hosting/${this.resource}/deploy/repositories/${providerId}`)
                    .then (response => this.repositories = response.data.data)
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingRepositories = false);
            },

            addDeploy()
            {
                this.formErrors = {};

                this.$refs.btnAddDeploySubmit.loadingFocus();
                this.$refs.btnAddDeployCancel.disabled = true;
                this.showAddDeployAlert = true;

                let postData = Object.assign({ provider: this.provider.id }, this.formData);

                this.$api.post(`/hosting/${this.resource}/deploy`, postData)
                    .then (response => {
                        this.$refs.cardDeploy.fetchData();
                        this.$refs.modalConfigDeploy.hide();
                        setTimeout(() => this.successNotification({ message: 'Integração criada com sucesso!' }), 1000);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                    })
                    .then(() => {
                        this.showAddDeployAlert = false;
                        this.$refs.btnAddDeploySubmit.setLoading(false);
                        this.$refs.btnAddDeployCancel.disabled = false;
                    });
            },

            cancelDeploy()
            {
                this.$refs.modalConfigDeploy.hide();
                this.provider = null;
                this.repositories = [];
            },

            onRepositoryChange(evt)
            {
                if (evt.target.value)
                {
                    const repository = this.repositories.find(el => el.url === evt.target.value);
                    this.$set(this.formData, 'branch', repository.branch);
                    this.$set(this.formData, 'dir', 'public_html/' + repository.path);
                }
            },

            /**
             * Exibir modal com os logs de uma integração.
             */
            showLogsModal(item)
            {
                this.deployLogs = [];
                this.isLoadingDeployLogs = true;

                this.$refs.modalDeployLogs.show();

                const params = { dir: item.dir };

                this.$api.get(`/hosting/${this.resource}/deploy/logs`, { params: params, cancelToken: AjaxService.getCancelToken() })
                    .then (response => this.deployLogs = response.data.data)
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingDeployLogs = false);
            },

            /**
             * Remover integração.
             */
            removeDeploy(item)
            {
                const dir = item.dir;

                this.$api.post(`/hosting/${this.resource}/deploy/remove`, { "dir": dir })
                    .then (response => {})
                    .catch(error => alert(`Ocorreu um erro ao tentar excluir a integração. Por favor, atualize sua página e tente novamente.`));
            },
        }
    }
</script>