<template>
    <hosting-domain-template :resource="resource">
        <card-table
            ref="cardRedirects"
            inner-title="Redirecionamentos"
            hide-add
            hide-edit
            modal-title="Adicionar redirecionamento"
            remove-title="Excluir redirecionamento"
            table-empty-text="Nenhum redirecionamento encontrado"
            :toolbar-actions="cardRedirectsActions"
            :table-fields="tableRedirectsFields"
            :api-url="`/domain/${resource}/redirect`"
            :form-data-transform="formDataTransform"
            @remove="removeRedirect"
        >
            <template slot="intro-text">
                Um redirecionamento permite que você envie os acessos a um domínio ou subdomínio para um URL diferente.
                Os redirecionamentos criados aqui vão funcionar quando o <strong>https</strong> ou <strong>www</strong>
                estiverem presentes ou não no domínio ou subdomínio de origem.
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="Origem" label-for="redirectSource" :error="formErrors.source">
                    <b-form-select ref="redirectSource" id="redirectSource" v-model="formData.source" :options="domains"></b-form-select>
                </form-group>

                <form-group label="Destino" label-for="redirectTarget" :error="formErrors.target">
                    <input type="url" id="redirectTarget" class="form-control" placeholder="Exemplo: http://www.dominio.com.br" spellcheck="false" v-model="formData.target">
                </form-group>

                <div class="d-flex align-items-center pr-2">
                    <b-button
                        variant="link"
                        class="text-left px-2 py-0"
                        style="text-decoration: none;"
                        :class="formData.showAdvancedMode ? 'collapsed' : null"
                        :aria-expanded="formData.showAdvancedMode ? 'true' : 'false'"
                        aria-controls="collapseAdvancedMode"
                        @click="formData.showAdvancedMode = !formData.showAdvancedMode"
                    >
                        <i class="fas fa-plus-circle"></i> Opções avançadas
                    </b-button>
                    <span class="d-block border-top flex-grow-1"></span>
                </div>

                <b-collapse id="collapseAdvancedMode" v-model="formData.showAdvancedMode">
                    <div class="pt-2 mt-1">
                        <form-group label="Tipo de redirecionamento" label-for="redirectType" class="mb-0" :error="formErrors.type">
                            <select id="redirectType" class="form-control custom-select" v-model="formData.type">
                                <option value="301">Permanente (301)</option>
                                <option value="302">Temporário (302)</option>
                            </select>
                        </form-group>
                    </div>
                </b-collapse>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir o redirecionamento abaixo?</p>
                <table class="table table-sm table-borderless mb-0">
                    <tbody>
                        <tr><th class="col-w-12">Tipo:</th><td>{{ item.type }}</td></tr>
                        <tr><th class="col-w-12">Origem:</th><td>{{ item.source }}</td></tr>
                        <tr><th class="col-w-12">Destino:</th><td>{{ item.target }}</td></tr>
                    </tbody>
                </table>
            </template>
        </card-table>
    </hosting-domain-template>
</template>

<script>
    import { mapActions }        from 'vuex'
    import { ApiCancel }         from '@/plugins/api'
    import AjaxService           from '@/services/AjaxService'
    import FormGroup             from '@/components/molecules/FormGroup'
    import CardTable             from '@/components/organisms/CardTable'
    import HostingDomainTemplate from '@/components/templates/HostingDomainTemplate'

    export default {
        props: ['resource'],
        components: {
            CardTable,
            FormGroup,
            HostingDomainTemplate
        },
        data() {
            return {
                cardRedirectsActions: [
                    {
                        text: "Adicionar redirecionamento",
                        variant: "success",
                        callback: (add, edit, remove) => add()
                    }
                ],
                tableRedirectsFields: [
                    {
                        name: 'type',
                        sortField: 'type',
                        sortFunction: 'text',
                        title: 'Tipo',
                        titleClass: 'col-w-20',
                        dataClass: 'col-w-20',
                    },
                    {
                        name: 'source',
                        sortField: 'source',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Origem',
                    },
                    {
                        name: 'target',
                        sortField: 'target',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Destino',
                    }
                ],

                domains: [],
                isLoadingDomains: false
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardRedirects.cancelFetch();
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getDomains();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            formDataTransform(data)
            {
                if (!data.isEditing) {
                    return { type: '302' };
                }

                return data;
            },

            /**
             * Listar domínios disponíveis para redirecionamento.
             */
            getDomains()
            {
                this.isLoadingDomains = true;
                this.domains = [""];

                this.$api.get(`/domain/${this.resource}/redirect/list-domains`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => this.domains = this.domains.concat(response.data.data))
                    .catch(error => {
                        if (!ApiCancel.isCancel(error)) {
                            error.message = 'Ocorreu um erro ao tentar listar os domínios da hospedagem.';
                            this.showErrorAlert(error);
                        }
                    })
                    .then (() => this.isLoadingDomains = false);
            },

            /**
             * Excluir redirecionamento.
             */
            removeRedirect(item)
            {
                const source = item.source;

                this.$api.delete(`/domain/${this.resource}/redirect/${source}`)
                    .then (response => {})
                    .catch(error => this.showErrorAlert(error));
            }
        },
        watch: {
            isLoadingDomains: function(val) {
                this.$refs.cardRedirects.toolbarButtons[0].disabled = Boolean(val);
            }
        }
    }
</script>