<template>
    <admin-page-template class="h-p-hosting-create">
        <card-title title="Criar nova hospedagem" class="h-card-create-form">
            <p class="mb-6">
                Para colocar o seu site no ar ou utilizar um serviço de e-mails, primeiro você precisa criar uma
                hospedagem aqui na Hostoo. Ao criar a sua hospedagem você precisa escolher um domínio que será utilizado
                por ela.
            </p>

            <div v-show="!isDomainChecked">
                <p>O primeiro passo é escolher o <strong>domínio</strong> para a sua nova hospedagem:</p>

                <form @submit.prevent="checkDomain">
                    <form-group class="mb-0" :error="formErrors.domain">
                        <div class="d-flex">
                            <input
                                ref="inputCheckDomain"
                                type="text"
                                class="form-control form-control-xl flex-grow-1 input-domain"
                                autofocus
                                spellcheck="false"
                                autocomplete="off"
                                placeholder="Exemplo: suaempresa.com ou seunome.com.br"
                                v-model="hosting.domain"
                                v-input-lowercase
                            >
                            <button-form
                                ref="btnCheckDomain"
                                type="submit"
                                class="btn-lg ml-4 text-nowrap"
                                :theme="btnCheckDomainTheme">{{ btnCheckDomainText }}</button-form>
                        </div>

                        <div v-show="isWwwDomain && !formErrors.domain" class="form-text-sm font-weight-semibold text-secondary mb-0 mt-1">
                            <i class="fas fa-info-circle fa-fw" aria-hidden="true"></i>
                            Não é necessário usar <strong>www</strong> para o domínio.
                        </div>
                    </form-group>

                    <div
                        role="alert"
                        class="alert mt-4"
                        v-show="domain.message"
                        v-html="domain.message"
                        v-bind:class="{
                            'alert-primary': !domain.isAvailable || !domain.isRegisterable || domain.isSubdomain,
                            'alert-success': domain.isAvailable && domain.isRegisterable,
                            'alert-danger': !domain.isAvailable && !domain.isRegisterable && !domain.isSubdomain,
                        }">
                    </div>
                </form>
            </div>

            <div v-show="isDomainChecked">
                <div class="form-group mb-0">
                    <div class="input-group input-group-lg">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-globe"></i></span>
                        </div>
                        <input type="text" class="form-control" spellcheck="false" readonly disabled v-model="domain.domain" />
                    </div>
                </div>

                <div class="card-title-subtitle mb-5">
                    <h5 class="card-title-inner">Escolha a região do servidor</h5>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <hosting-region-list v-model="hosting.region" />
                    </div>
                </div>

                <div class="card-title-subtitle mb-5">
                    <h5 class="card-title-inner">Escolha o plano para sua hospedagem</h5>
                </div>

                <hosting-plan-list v-model="hosting.package" :region="hosting.region" show-recommendation />

                <div class="form-buttons">
                    <button-form ref="btnCreateHosting" theme="success" class="btn-lg btn-block" @click.native="createHosting">
                        Contratar <span v-if="hosting.package">por R$ {{ hosting.package.price[hosting.region].toFixed(2).replace('.', ',') }}/mês</span>
                    </button-form>
                </div>
            </div>
        </card-title>
    </admin-page-template>
</template>

<script>
    import { mapActions }    from 'vuex'
    import { ApiCancel }     from '@/plugins/api'
    import tutorial          from '@/mixins/tutorialCreateHosting'
    import ErrorService      from '@/services/error.service'
    import ButtonForm        from '@/components/atoms/ButtonForm'
    import CardTitle         from '@/components/molecules/CardTitle'
    import FormGroup         from '@/components/molecules/FormGroup'
    import HostingPlanList   from '@/components/organisms/HostingPlanList'
    import HostingRegionList from '@/components/organisms/HostingRegionList'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'
    import store from "@/store";

    export default {
        mixins: [
            tutorial
        ],
        components: {
            ButtonForm,
            CardTitle,
            FormGroup,
            HostingPlanList,
            HostingRegionList,
            AdminPageTemplate
        },
        data() {
            return {
                hosting: {
                    region: "US",
                },
                domain: {},
                formErrors: {},
                isDomainChecked: false,
                checkDomainCancel: null,
            }
        },
        computed: {
            isWwwDomain: function () {
                return (this.hosting.domain && this.hosting.domain.match(/^www\./i));
            },
            btnCheckDomainTheme: function () {
                return (this.domain.isAvailable || this.domain.isRegisterable || this.domain.isSubdomain) ? 'success' : 'primary';
            },
            btnCheckDomainText: function () {
                return (this.domain.isAvailable || this.domain.isRegisterable || this.domain.isSubdomain) ? 'Continuar' : 'Verificar';
            }
        },
        beforeRouteEnter(to, from, next)
        {
            // TODO: Remover redirecionamento temporario para mudança de preços

            store.dispatch("user/getUser")
                .then(user => {
                    if (user.isNPT) {
                        next("/hosting/create");
                    }
                    else {
                        next();
                    }
                })
                .catch(error => {
                    next();
                })
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Verificar disponibilidade do domínio e outras informações.
             */
            checkDomain()
            {
                if (this.domain.isAvailable || this.domain.isRegisterable || this.domain.isSubdomain)
                {
                    this.isDomainChecked = true;
                    this.$eventBus.$emit('hosting-create-domain-chosen');

                    // Tag do GA4
                    if (typeof window.gtag !== 'undefined' && typeof window.GA4_MEASUREMENT_ID !== 'undefined') {
                        gtag('event', 'escolha_plano', { 'send_to': window.GA4_MEASUREMENT_ID });
                    }

                    return;
                }

                this.$refs.btnCheckDomain.setLoading(true, true);
                this.domain = {};
                this.formErrors = {};

                if (this.hosting.domain) {
                    this.hosting.domain = this.hosting.domain.trim().toLowerCase();
                }

                // Cancelar verificação anterior
                if (this.checkDomainCancel) {
                    this.checkDomainCancel();
                }

                const CancelToken = ApiCancel.CancelToken;
                let vm = this;

                let promiseCheck = this.$api.request({
                    method: 'post',
                    url: '/hosting/check',
                    data: this.hosting,
                    cancelToken: new CancelToken(function executor(c) {
                        vm.checkDomainCancel = c;
                    })
                });

                promiseCheck
                    .then(response => {
                        this.domain = response.data.data;
                        this.$eventBus.$emit('hosting-create-domain-verified');

                        // if (this.domain.isAvailable || this.domain.isRegisterable || this.domain.isSubdomain) {
                        //     vm.$refs.btnNext.disabled = false;
                        // }
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$eventBus.$emit('hosting-create-domain-invalid');
                    })
                    .then (() => {
                        this.$refs.btnCheckDomain.setLoading(false);
                        this.$refs.inputCheckDomain.focus();
                    });
            },

            /**
             * Criar hospedagem com o domínio e plano escolhidos.
             */
            createHosting()
            {
                this.$refs.btnCreateHosting.loadingFocus();
                this.formErrors = {};

                this.hosting.plan = this.hosting.package ? this.hosting.package.id : null;

                this.$api.post('/hosting', this.hosting)
                    .then(response => {
                        const domain = response.data.data.domain;
                        this.$router.push('/hosting/' + domain);
                        this.$eventBus.$emit('hosting-created', { domain: domain });
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.btnCreateHosting.setLoading(false);
                    });
            }
        },
        watch: {
            'hosting.domain': function(val, old) {
                if (val !== old)
                {
                    let fixed = val.replace(/[^0-9a-zA-Z-_\.]/g, '');
                    fixed = fixed.replace(/^https?/g, '');

                    this.hosting.domain = fixed;
                    this.domain = {};
                    this.formErrors = {};

                    this.$eventBus.$emit('hosting-create-domain-changed');
                }
            }
        }
    }
</script>