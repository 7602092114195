<template>
    <div class="h-p-hosting-app-install">
        <card-title v-show="!isAppInstalled" inner-body class="h-card-hosting-app-install">
            <h4 class="card-title-inner">Instalar aplicativo</h4>

            <div v-show="!appSelected" class="card-apps-wrapper">
                <p class="mb-6">
                    Para instalar um novo aplicativo em sua hospedagem, escolha o item desejado na lista abaixo e clique no
                    botão <strong>Instalar</strong>.
                </p>

                <div class="row">
                    <template v-for="(item, index) in apps">
                        <div class="col">
                            <div class="card card-app">
                                <div class="card-body">
                                    <img class="app-logo" :src="`/images/apps/${item.name}.png`"/>

                                    <h5 class="card-title">{{ item.title }}</h5>

                                    <p class="card-text app-category">{{ item.category }}</p>

                                    <p class="card-text app-version">
                                        <span v-show="isLoadingAvailableApps" class="text-muted">
                                            <i class="fas fa-circle-notch fa-spin"></i>
                                        </span>
                                        <span v-show="!isLoadingAvailableApps" v-bind:class="{ 'invisible': !appsAvailable || !appsAvailable[item.name] }">
                                            Versão <span v-if="appsAvailable && appsAvailable[item.name]" v-html="appsAvailable[item.name].version"></span>
                                        </span>
                                    </p>

                                    <router-link class="btn btn-success btn-block mt-4" :to="`/hosting/${resource}/app/install?name=${item.name}`">Instalar</router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div v-show="appSelected">
                <p class="mb-6">
                    Insira as informações básicas para configuração inicial do site. Nosso sistema vai gerar um login e
                    senha para o aplicativo e o banco de dados será criado automaticamente (quando necessário).
                    Você também pode usar a opção <strong>Editar informações do administrador</strong> para informar
                    um login e senha manualmente.
                </p>

                <div v-if="appSelected" class="card border-0 mb-6">
                    <div class="card-body text-left p-0">
                        <div class="d-flex justify-content-center align-items-center">
                            <img class="w-16 mb-0" :src="`/images/apps/${appSelected.name}.png`"/>
                            <div class="pl-4">
                                <h5 class="card-title font-weight-semibold mb-0">
                                    {{ appSelected.title }}
                                    <span v-if="appsAvailable && appsAvailable[appSelected.name]" v-html="appsAvailable[appSelected.name].version"></span>
                                </h5>
                                <p class="card-text mb-0">{{ appSelected.category }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <form @submit.prevent="installApp">
                    <form-group label="Título do site *" label-for="appTitle" :error="formErrors.title">
                        <input type="text" id="appTitle" class="form-control" v-model="app.title" autofocus>
                    </form-group>

                    <form-group label="Subtítulo ou descrição curta" label-for="appDescription" :error="formErrors.description">
                        <input type="text" id="appDescription" class="form-control" v-model="app.description">
                    </form-group>

                    <form-group v-show="app.app === 'whmcs'" label="Licença do WHMCS *" label-for="appLicense" :error="formErrors.license">
                        <input type="text" id="appLicense" class="form-control" v-model="app.license" spellcheck="false">
                    </form-group>

                    <div class="mb-4">
                        <form-group class="mb-0" :error="formErrors.subdomain">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="flex-grow-1">
                                    <label class="form-label mb-0" for="appSubdomainSwitch">Instalar em um subdomínio</label>
                                    <p class="form-text-sm mb-0">
                                        Ao usar um subdomínio você pode instalar o seu aplicativo em uma extensão do seu
                                        domínio como http://blog.meudominio.com/.
                                    </p>
                                </div>
                                <div class="pl-4">
                                    <toggle-switch id="appSubdomainSwitch" v-model="app.hasSubdomain"/>
                                </div>
                            </div>

                            <b-collapse id="collapseAppSubdomain" v-model="app.hasSubdomain">
                                <div class="form-group mt-2 mb-0" v-bind:class="{ 'is-invalid': formErrors.subdomain }">
                                    <label class="sr-only" for="appSubdomain">Subdomínio</label>
                                    <div class="input-group">
                                        <input type="text" id="appSubdomain" class="form-control" v-bind:class="{ 'is-invalid': formErrors.subdomain }" v-model="app.subdomain" spellcheck="false">
                                        <div class="input-group-append">
                                            <span class="input-group-text px-4">.{{ resource }}</span>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </form-group>
                    </div>

                    <div class="mb-4">
                        <form-group class="mb-0" :error="formErrors.dir">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="flex-grow-1">
                                    <label class="form-label mb-0" for="appDirSwitch">Instalar em um subdiretório</label>
                                    <p class="form-text-sm mb-0">
                                        O subdiretório é relativo ao seu domínio. Por exemplo, para instalar o
                                        aplicativo em http://meudominio.com/novo/, insira o subdiretório "novo".
                                    </p>
                                </div>
                                <div class="pl-4">
                                    <toggle-switch id="appDirSwitch" v-model="app.hasDir"/>
                                </div>
                            </div>

                            <b-collapse id="collapseAppDir" v-model="app.hasDir">
                                <div class="form-group mt-2 mb-0" v-bind:class="{ 'is-invalid': formErrors.dir }">
                                    <label class="sr-only" for="appDir">Subdiretório</label>
                                    <div class="input-group input-group-rounded">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text px-4">{{ resource }}/</span>
                                        </div>
                                        <input type="text" id="appDir" class="form-control" spellcheck="false" v-model="app.dir" v-bind:class="{ 'is-invalid': formErrors.dir }">
                                    </div>
                                </div>
                            </b-collapse>
                        </form-group>
                    </div>

                    <div v-if="appHasAdmin" class="mb-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="flex-grow-1">
                                <label class="form-label mb-0" for="appAdvancedMode">Editar informações do administrador</label>
                                <p class="form-text-sm mb-0">
                                    Permite informar login, senha de acesso e e-mail do administrador manualmente.
                                </p>
                            </div>
                            <div class="pl-4">
                                <toggle-switch id="appAdvancedMode" v-model="app.advancedMode"/>
                            </div>
                        </div>

                        <b-collapse id="collapseAppAdvancedMode" v-model="app.advancedMode">
                            <form-group class="mt-4 pl-6" label="E-mail" label-for="appAdminEmail" :error="formErrors.adminEmail">
                                <input type="email" id="appAdminEmail" class="form-control" v-model="app.adminEmail" spellcheck="false">
                            </form-group>

                            <form-group class="pl-6" label="Login" label-for="appAdminUsername" :error="formErrors.adminUsername">
                                <input type="text" id="appAdminUsername" class="form-control" v-model="app.adminUsername" spellcheck="false">
                            </form-group>

                            <form-group class="pl-6 mb-0" label="Senha" label-for="appAdminPassword" :error="formErrors.adminPassword">
                                <!--<password-generator id="appAdminPassword" rounded v-model="app.adminPassword" autocomplete="new-password" />-->
                                <input type="text" class="form-control" v-model="app.adminPassword">
                            </form-group>
                        </b-collapse>
                    </div>

                    <div v-if="app.app === 'moodle' && ramAvailable && ramAvailable < 1024" class="alert alert-warning">
                        O requisito recomendado de memória RAM para este aplicativo é de 1 GB.
                        No plano atual sua aplicação pode apresentar lentidão.
                    </div>

                    <div v-if="(app.app === 'mautic' || app.app === 'magento') && ramAvailable && ramAvailable < 2048" class="alert alert-warning">
                        O requisito recomendado de memória RAM para este aplicativo é de 2 GB.
                        No plano atual sua aplicação pode apresentar lentidão.
                    </div>

                    <div v-show="showInstallAppAlert" class="alert alert-warning">
                        <i class="far fa-clock mr-1"></i>
                        A instalação de aplicativos pode demorar alguns minutos&hellip;
                    </div>

                    <div class="form-buttons text-right">
                        <button ref="btnCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="cancelInstallApp">Cancelar</button>
                        <button-form ref="btnSubmit" type="submit" theme="success" class="btn-action">Instalar</button-form>
                    </div>
                </form>
            </div>
        </card-title>

        <card-title v-show="isAppInstalled" title="Dados do aplicativo instalado" inner-body>
            <p v-if="!appInfo || (typeof appInfo === 'object' && !Object.keys(appInfo).length) || (Array.isArray(appInfo) && !appInfo.length)" class="mb-4">
                O aplicativo foi instalado com sucesso!
            </p>
            <p v-else class="mb-6">
                O aplicativo foi instalado com sucesso! Segue abaixo as informações de acesso.
                <strong>Copie e armazene estas informações em um local seguro</strong>. Você também receberá
                um e-mail com estas informações.
            </p>

            <div v-if="appInfo">
                <div v-if="appInfo.url">
                    <p class="text-secondary font-weight-bold mb-2">Acesso</p>
                    <div class="table-list-wrapper">
                        <table class="table table-list table-sm">
                            <tbody>
                                <tr>
                                    <th class="col-w-px col-nowrap">URL principal:</th>
                                    <td>
                                        {{ appInfo.url }}
                                        <a :href="appInfo.url" target="_blank"><i aria-hidden="true" class="fas fa-external-link-alt ml-1"></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="col-w-px col-nowrap">URL alternativo:</th>
                                    <td>
                                        <span v-if="appInfo.urlAlt">{{ appInfo.urlAlt }}</span>
                                        <a v-if="appInfo.urlAlt" :href="appInfo.urlAlt" target="_blank"><i aria-hidden="true" class="fas fa-external-link-alt ml-2"></i></a>
                                        <button-form v-else ref="btnEnableAlternative" variant="link" class="p-0 border-0" @click="enableAlternative">Habilitar</button-form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="appInfo.adminUrl">
                    <p class="text-secondary font-weight-bold mb-2">Administrador</p>
                    <div class="table-list-wrapper">
                        <table v-if="appInfo" class="table table-list table-sm">
                            <tbody>
                                <tr v-show="appInfo.adminUrl">
                                    <th class="col-w-px col-nowrap">URL:</th>
                                    <td>
                                        {{ appInfo.adminUrl }}
                                        <a :href="appInfo.adminUrl" target="_blank"><i aria-hidden="true" class="fas fa-external-link-alt ml-1"></i></a>
                                    </td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-w-px col-nowrap">Login:</th>
                                    <td class="ht-text-copy-wrapper">
                                        <text-copy :text="appInfo.adminUsername" />
                                    </td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-w-px col-nowrap">Senha:</th>
                                    <td class="ht-text-copy-wrapper">
                                        <text-copy :text="appInfo.adminPassword" />
                                    </td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-w-px col-nowrap">E-mail:</th>
                                    <td class="ht-text-copy-wrapper">
                                        <text-copy :text="appInfo.adminEmail" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="appInfo.dbName">
                    <p class="text-secondary font-weight-bold mb-2">Banco de Dados</p>
                    <div class="table-list-wrapper">
                        <table v-if="appInfo" class="table table-list table-sm">
                            <tbody>
                                <tr>
                                    <th class="col-w-px col-nowrap">Nome:</th>
                                    <td class="ht-text-copy-wrapper">
                                        <text-copy :text="appInfo.dbName" />
                                    </td>
                                </tr>
                                <tr>
                                    <th class="col-w-px col-nowrap">Usuário:</th>
                                    <td class="ht-text-copy-wrapper">
                                        <text-copy :text="appInfo.dbUser" />
                                    </td>
                                </tr>
                                <tr>
                                    <th class="col-w-px col-nowrap">Senha:</th>
                                    <td class="ht-text-copy-wrapper">
                                        <text-copy :text="appInfo.dbPassword" />
                                    </td>
                                </tr>
                                <tr v-show="appInfo.dbHost">
                                    <th class="col-w-px col-nowrap">Host:</th>
                                    <td class="ht-text-copy-wrapper">
                                        <text-copy :text="appInfo.dbHost" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-if="appInfo" class="form-buttons d-flex">
                <a v-if="appInfo.signOnUrl" class="btn btn-primary mr-2" :href="appInfo.signOnUrl" target="_blank">Acessar painel</a>
                <!--<router-link class="btn btn-success ml-auto" :to="`/hosting/${resource}/app/install`" exact>Nova instalação</router-link>-->
                <router-link class="btn btn-secondary ml-auto" :to="'/hosting/' + resource + '/apps'" exact>Listar aplicativos instalados</router-link>
            </div>
        </card-title>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import hostingApps   from '@/mixins/hostingApps'
    import ToggleSwitch  from '@/components/atoms/ToggleSwitch'
    import ButtonForm    from '@/components/atoms/ButtonForm'
    import CardTitle     from '@/components/molecules/CardTitle'
    import FormGroup     from '@/components/molecules/FormGroup'
    import TextCopy      from '@/components/molecules/TextCopy'
    import AjaxService   from '@/services/AjaxService'
    import ErrorService  from '@/services/error.service';

    export default {
        props: ['resource'],
        mixins: [
            hostingApps
        ],
        components: {
            TextCopy,
            ButtonForm,
            ToggleSwitch,
            FormGroup,
            CardTitle
        },
        data() {
            return {
                appsAvailable: null,
                appSelected: null,
                isLoadingAvailableApps: false,

                ramAvailable: null,

                app: {},
                appInfo: null,
                formErrors: {},
                isAppInstalled: false,
                showInstallAppAlert: false,
                enableAlternativeTimeout: null,
            }
        },
        computed: {
            ...mapState('hosting', ['hosting']),

            appHasAdmin: function() {
                return this.app && this.app.app && this.app.app !== 'laravel' && this.app.app !== 'codeigniter';
            },
        },
        beforeRouteEnter(to, from, next)
        {
            next(vm => vm.init());
        },
        beforeRouteUpdate(to, from, next)
        {
            next();
            this.init();
        },
        beforeRouteLeave(to, from, next)
        {
            this.enableAlternativeTimeout && clearTimeout(this.enableAlternativeTimeout);
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getAvailableApps();
            this.getCurrentPlan();
        },
        methods: {
            ...mapActions({
                getHosting: 'hosting/get',
                showErrorAlert: 'alert/error',
                successNotification: 'notification/success',
            }),

            /**
             *
             */
            init()
            {
                this.appSelected = null;
                this.app = {};
                this.formErrors = {};

                if (this.$route.query.name)
                {
                    this.appSelected = this.apps.find(item => item.name === this.$route.query.name);

                    if (!this.appSelected) {
                        alert("O aplicativo informado não está disponível.");
                        this.$route.push(`/hosting/${this.resource}/app/install`);
                    }
                    else {
                        this.app.app = this.appSelected.name;
                        this.app.adminUsername = "admin";
                    }
                }
            },

            /**
             * Obter dados de aplicativos disponíveis.
             */
            getAvailableApps()
            {
                this.isLoadingAvailableApps = true;

                this.$api.get(`/hosting/${this.resource}/apps/list`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        let apps = response.data.data;
                        this.appsAvailable = {};

                        for (let i = 0; i < apps.length; i++) {
                            this.appsAvailable[apps[i].name] = apps[i];
                        }
                    })
                    .catch(error => { /* Ignore error */ })
                    .then (() => this.isLoadingAvailableApps = false);
            },

            /**
             * Listar informações dos planos disponíveis.
             */
            getCurrentPlan()
            {
                this.$api.get('/hosting/plans', { cancelToken: AjaxService.getCancelToken() })
                    .then(response => {
                        const plans   = response.data.data,
                              planId  = parseInt(this.hosting.plan),
                              plan    = plans.find(el => el.id === planId),
                              planRam = plan ? plan.ram : '';

                        this.ramAvailable = parseInt(planRam.replace(/[^0-9.]/g, ''));

                        if (planRam.match(/\d+ GB/g)) {
                            this.ramAvailable *= 1024;
                        }
                    });
            },

            /**
             * Instalar aplicativo.
             */
            installApp()
            {
                this.formErrors = {};
                this.showInstallAppAlert = true;

                // let alertTimeout = setTimeout(() => { $(this.$refs.installAlert).show(); }, 5000);

                this.$refs.btnSubmit.$el.focus();
                this.$refs.btnSubmit.setLoading(true);
                this.$refs.btnCancel.disabled = true;

                this.$api.post(`/hosting/${this.resource}/apps`, this.app)
                    .then (response => {
                        // this.appInfo = response.data.data;
                        // this.isAppInstalled = true;
                        // GlobalAlert.success("Aplicativo instalado com sucesso!");

                        const key = response.data.data.key;
                        setTimeout(() => this.checkInstallStatus(key), 5000);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.showInstallAppAlert = false;
                        this.$refs.btnCancel.disabled = false;
                        this.$refs.btnSubmit.setLoading(false);
                    });
                    // .then(() => {
                    //     clearTimeout(alertTimeout);
                    //     $(this.$refs.installAlert).hide();
                    // });
            },

            /**
             * Verificar o status de uma instalação.
             *
             * @param {string} key
             */
            checkInstallStatus(key)
            {
                this.$api.get(`/hosting/${this.resource}/apps/status?key=${key}`)
                    .then(response => {
                        const status = response.data.data.status;

                        if (status === 'installing') {
                            setTimeout(() => this.checkInstallStatus(key), 5000);
                        }
                        else if (status === 'completed') {
                            this.appInfo = response.data.data.app;
                            this.isAppInstalled = true;
                        }
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.showInstallAppAlert = false;
                        this.$refs.btnCancel.disabled = false;
                        this.$refs.btnSubmit.setLoading(false);
                    })
            },

            /**
             * Cancelar instalação de um aplicativo e voltar para lista.
             */
            cancelInstallApp()
            {
                this.$router.push(`/hosting/${this.resource}/app/install`);
            },

            /**
             * Habilitar URL alternativo.
             */
            enableAlternative()
            {
                this.$refs.btnEnableAlternative.setLoadingAndFocus();
                this.$refs.btnEnableAlternative.setText('Habilitando...');

                this.$api.post(`/hosting/${this.resource}/enable-alternative`)
                    .then(response => {
                        if (response.status === 202) {
                            this.enableAlternativeTimeout = setTimeout(() => this.enableAlternative(), 5000);
                        }
                        else {
                            this.getHosting({ domain: this.resource, force: true })
                                .then(hosting => {
                                    const urlAlt        = 'https://' + hosting.domainAlt;
                                    const urlAltDir     = this.appInfo.appDir ? '/' + this.appInfo.appDir : '/';
                                    this.appInfo.urlAlt = urlAlt + urlAltDir;
                                    this.successNotification({ message: 'URL alternativo habilitado!' });
                                });
                        }
                    })
                    .catch(error => {
                        this.showErrorAlert(error);

                        if (this.$refs.btnEnableAlternative) {
                            this.$refs.btnEnableAlternative.setLoading(false);
                            this.$refs.btnEnableAlternative.setText('Habilitar');
                        }
                    });
            }
        },
        watch: {
            'app.hasSubdomain': function (val) {
                if (val) {
                    this.$set(this.app, "hasDir", false);
                    this.$set(this.app, "dir", "");
                }
                else {
                    this.$delete(this.formErrors, "subdomain");
                }
            },
            'app.hasDir': function (val) {
                if (val) {
                    this.$set(this.app, "hasSubdomain", false);
                    this.$set(this.app, "subdomain", "");
                }
                else {
                    this.$delete(this.formErrors, "dir");
                }
            }
        }
    }
</script>
