let self;

const videos = [
    {
        "title": "Página inicial",
        "url": "https://www.youtube.com/embed/-YOXP3iiqYQ?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
    {
        "title": "Hospedagem - Geral",
        "url": "https://www.youtube.com/embed/v85QEnNIT50?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
    {
        "title": "Hospedagem - Resumo",
        "url": "https://www.youtube.com/embed/Yc7qc0pev7Q?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
    {
        "title": "Hospedagem - Domínio",
        "url": "https://www.youtube.com/embed/P2QmmyNaj6g?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
    {
        "title": "Hospedagem - Arquivos",
        "url": "https://www.youtube.com/embed/OiSNM4Qtuiw?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
    {
        "title": "Hospedagem - Banco de Dados",
        "url": "https://www.youtube.com/embed/eZAFGI4wa0o?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
    {
        "title": "Hospedagem - E-mail",
        "url": "https://www.youtube.com/embed/mO9S0F-El2g?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
    {
        "title": "Hospedagem - Aplicativos",
        "url": "https://www.youtube.com/embed/o3ylF2QjEDA?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
    {
        "title": "Hospedagem - Configurações",
        "url": "https://www.youtube.com/embed/5BysXenRWFs?autoplay=1&modestbranding=1&iv_load_policy=3",
    },
];

const ListItemVideo = {
    props: {
        url: String,
        text: String,
    },
    methods: {

    },
    template: `
        <button type="button" class="list-group-item list-group-item-action" @click="showVideo">
            {{ text }}
        </button>
    `,
}

function showModalVideo(url)
{
    const html = self.$createElement('div', { class: 'embed-responsive embed-responsive-16by9' }, [
        self.$createElement('iframe', {
            class: 'embed-responsive-item',
            attrs: {
                src: url,
                frameborder: 0,
                allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
                allowfullscreen: true,
            }
        })
    ]);

   self.$bvModal.msgBoxOk([html],
        {
            id: "modal-tutorial-video",
            modalClass: "h-modal-video",
            size: "xl",
            centered: true,
        })
       .then (() => {});
}

function showModalPlaylist()
{
    const listItems = videos.map(item => {
        return self.$createElement("button", {
            class: "list-group-item list-group-item-action",
            attrs: {
                type: "button"
            },
            domProps: {
                innerHTML: '<i class="fab fa-youtube fa-fw"></i>' + item.title
            },
            on: {
                click: function ()
                {
                    const embed  = document.querySelector("#modal-tutorial-video .embed-responsive");

                    const iframe = document.createElement("iframe");
                    iframe.setAttribute("src", item.url);
                    iframe.setAttribute("frameborder", "0");
                    iframe.setAttribute("allowfullscreen", "");
                    iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");

                    embed.innerHTML = "";
                    embed.classList.remove("d-none");
                    embed.appendChild(iframe);
                }
            },
        });
    });

    const html = self.$createElement('div', {}, [
        self.$createElement('div', { class: 'embed-responsive embed-responsive-16by9 mb-6 d-none' }),
        self.$createElement('p', { domProps: { innerHTML: 'Confira abaixo alguns tutoriais que a Hostoo preparou para te ajudar a usar o painel.' }}),
        self.$createElement('div', { class: 'list-group' }, listItems),
    ]);

    self.$bvModal.msgBoxOk([html],
        {
            id: "modal-tutorial-video",
            modalClass: "h-modal-tutorial-video",
            size: "lg",
            title: "Tutoriais em vídeo",
            centered: true,
            hideHeaderClose: true,
            footerClass: "d-none",
        })
        .then (() => {});
}

export default {
    created()
    {
        self = this;
    },
    methods: {
        /**
         * Show video tutorials.
         */
        showTutorialVideo()
        {
            // Ignore if modal is already open
            if (document.getElementById("modal-tutorial-video")) return;

            if (this.$route && this.$route.meta && this.$route.meta.video) {
                showModalVideo(this.$route.meta.video);
            }
            else {
                showModalPlaylist();
            }
        }
    }
}
