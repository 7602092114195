<template>
    <div class="h-m-banner-referral">
        <router-link to="#" @click.native="onClickEvent">
            <div class="row align-items-center">
                <div class="col-md-3">
                    <img src="/images/logo-referral-202308.png" class="img-fluid pl-3" alt="Indique e Ganhe">
                </div>
                <div class="col-md-6">
                    <p class="mb-3">
                        Ganhe até <span>R$ 200 reais</span> indicando a Hostoo!
                    </p>
                    <div class="text-center">
                        <a href="#" @click.native="onClickEvent" class="btn btn-white">Indique agora</a>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="h-m-banner-referral--clock">
                        <div class="h-m-banner-referral--clock-box">
                            <span id="bf-time-days" class="h-m-banner-referral--clock-number">{{ formatTimeNumber(clockTime.days) }}</span>
                            <span class="h-m-banner-referral--clock-label">dias</span>
                        </div>
                        <div class="h-m-banner-referral--clock-separator">:</div>
                        <div class="h-m-banner-referral--clock-box">
                            <span id="bf-time-hours" class="h-m-banner-referral--clock-number">{{ formatTimeNumber(clockTime.hours) }}</span>
                            <span class="h-m-banner-referral--clock-label">horas</span>
                        </div>
                        <div class="h-m-banner-referral--clock-separator">:</div>
                        <div class="h-m-banner-referral--clock-box">
                            <span id="bf-time-minutes" class="h-m-banner-referral--clock-number">{{ formatTimeNumber(clockTime.minutes) }}</span>
                            <span class="h-m-banner-referral--clock-label">minutos</span>
                        </div>
                        <div class="h-m-banner-referral--clock-separator">:</div>
                        <div class="h-m-banner-referral--clock-box">
                            <span id="bf-time-seconds" class="h-m-banner-referral--clock-number">{{ formatTimeNumber(clockTime.seconds) }}</span>
                            <span class="h-m-banner-referral--clock-label">segundos</span>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
    import UserService from '@/services/user.service'
    import referral    from '@/mixins/referral'

    export default {
        mixins: [
            referral
        ],
        props: {
            lead: {
                type: Boolean,
                default: false
            },
            expiration: {
                type: Number,
                default: 0  
            },
            eventLabel: {
                type: String,
                default: "",
            }
        },
        data() {
            return {
                clockInterval: null,
                clockExpiration: this.expiration,
                clockTime: {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                },
            }
        },
        created()
        {
            this.calculateTime();
            this.clockInterval = setInterval(this.calculateTime, 1000);
        },
        beforeDestroy()
        {
            this.clockInterval && clearInterval(this.clockInterval);
        },
        methods: {

            calculateTime()
            {
                let expiration = 0;

                if (this.clockExpiration && this.clockExpiration > 0) {
                    expiration = --this.clockExpiration;
                }

                this.clockTime.days    = Math.floor(expiration / (3600 * 24));
                this.clockTime.hours   = Math.floor(expiration % (3600 * 24) / 3600);
                this.clockTime.minutes = Math.floor(expiration % 3600 / 60);
                this.clockTime.seconds = Math.floor(expiration % 60);
            },

            formatTimeNumber(value)
            {
                if (value >= 10) return value;
                else return '0' + value;
            },

            onClickEvent()
            {
                this.showReferralModal();

                UserService.trackEvent({
                    "action"   : "click",
                    "category" : "campaign_referral_2023_03",
                    "label"    : this.eventLabel,
                });
            }
        }
    }
</script>

<style lang="stylus">
    .h-m-banner-referral {
        border-radius: 6px;
        background: linear-gradient(90deg, #5900FF 0%, #FB3640 100%);
        padding: 1.5rem 1rem;

        > a {
            text-decoration none !important
        }

        p {
            margin-bottom: 0;
            font-size: 1.375rem;
            line-height: 1.25;
            text-align: center;
            color: white;

            span {
                font-weight: 700;
            }
        }

        .btn-white {
            text-transform: uppercase;
            font-weight: 700;
            font-size: .875rem;
            color #5900FF
            padding-left 1.5rem
            padding-right 1.5rem
        }
    }
    .h-m-banner-referral--clock {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .h-m-banner-referral--clock-box {
        position: relative;
    }
    .h-m-banner-referral--clock-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        text-align: center;
        font-size: 1.25rem;
        line-height: 1;
        border-radius: 5px;
        color: white;
        border 1px solid #FFF;
        background: linear-gradient(180deg,rgba(255,255,255,.5),rgba(255,255,255,.0),rgba(255,255,255,.0),rgba(255,255,255,.5))
    }
    .h-m-banner-referral--clock-label {
        display: block;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color: white;
        margin-top: 4px;
    }
    .h-m-banner-referral--clock-separator {
        margin: 0 2px;
        padding-bottom: 1rem;
        font-size: 1.25rem;
        color: white;
    }
</style>
