<template>
    <div class="card h-m-card-link" v-bind:class="[primary ? 'primary' : '']">
        <div class="card-body">
            <span class="icon-wrapper">
                <i class="icon" v-if="iconClass" v-bind:class="iconClass"></i>
                <slot name="icon" v-if="!iconClass"></slot>
            </span>
            <router-link :to="link" class="btn btn-link stretched-link">{{ text }}</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: ""
            },
            link: {
                type: String,
                default: "#"
            },
            iconClass: {
                type: String,
                default: ""
            },
            primary: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="stylus">
    .h-m-card-link {
        border 0
        box-shadow 0 2px 6px #0000000F
        margin-bottom 2rem
        transition background .3s ease, color .3s ease
        will-change background, color

        .card-body {
            display flex
            flex-direction column
            align-items flex-start
        }

        .icon-wrapper {
            display inline-block
            margin-bottom 1.5rem
            padding 1.25rem
            border-radius 50%
            background-color $gray-100
            color $primary
        }

        .icon {
            margin-top -.125rem
            font-size 2.5rem
            color currentColor
        }

        svg {
            width 2.5rem
            height 2.5rem
            fill currentColor
        }

        .btn-link {
            padding-left 0
            padding-right 0
            font-size 1.125rem
            font-weight 700
            text-decoration none
        }

        &.primary {
            background-color lighten($primary, 15%)
            color white

            .icon-wrapper {
                background-color $primary
                color white
            }

            .btn-link {
                font-weight 600
                color white
            }
        }

        &:hover {
            background-color $primary
            color white

            .btn-link {
                color white
            }
        }
    }
</style>
