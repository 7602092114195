import { mapActions, mapState } from 'vuex'

let backdropCtx;
const totalSteps = 7;

const tutorialCreateHosting = {
    data() {
        return {
            $_tutorialCreateHosting_backdropCtx: null,
            $_tutorialCreateHosting_ignoreRouteLeave: false,
            $_tutorialCreateHosting_currentPopoverId: null,
            $_tutorialCreateHosting_Step2PopoverId: null,
        }
    },
    computed: {
        ...mapState({
            $_tutorialCreateHosting_user:              state => state.user.user,
            $_tutorialCreateHosting_isRunning:         state => state.tutorial.running,
            $_tutorialCreateHosting_isSkipped:         state => state.tutorial.skipped,
            $_tutorialCreateHosting_isFinished:        state => state.tutorial.finished,
            $_tutorialCreateHosting_currentStep:       state => state.tutorial.step,
            $_tutorialCreateHosting_waitingPageChange: state => state.tutorial.waitingPageChange,
            $_tutorialCreateHosting_domain:            state => state.hosting.domain,
        }),
    },
    beforeRouteEnter(to, from, next)
    {
        next(vm => {
            if (vm.$_tutorialCreateHosting_waitingPageChange)
            {
                vm.$_tutorialCreateHosting_waitPageChange(false);
                vm.$_tutorialCreateHosting_show(true);
            }
        });
    },
    // beforeRouteLeave(to, from, next)
    // {
    //     let ignored = false;
    //
    //     if (!this.$_tutorialCreateHosting_isRunning) {
    //         next();
    //     }
    //     else
    //     {
    //         if (this.$_tutorialCreateHosting_ignoreRouteLeave) {
    //             if (typeof this.$_tutorialCreateHosting_ignoreRouteLeave === 'string') {
    //                 if (this.$_tutorialCreateHosting_ignoreRouteLeave === to.path) {
    //                     ignored = true;
    //                 }
    //             }
    //             else {
    //                 ignored = true;
    //             }
    //         }
    //
    //         if (!ignored) {
    //             if (confirm('O tutorial ainda não foi finalizado. Tem certeza que deseja sair desta página?')) {
    //                 next();
    //                 this.$store.dispatch('tutorial/stop');
    //                 this.$_tutorialCreateHosting_removeBackdrop();
    //             }
    //         }
    //         else {
    //             next();
    //             this.$store.dispatch('tutorial/stop');
    //             // this.$_tutorialCreateHosting_removeBackdrop();
    //         }
    //     }
    // },
    mounted()
    {
        this.$store.dispatch('user/fetchUser');
    },
    destroyed()
    {

    },
    methods: {
        ...mapActions({
            $_tutorialCreateHosting_start: 'tutorial/start',
            $_tutorialCreateHosting_stop:  'tutorial/stop',
            $_tutorialCreateHosting_nextStep: 'tutorial/nextStep',
            $_tutorialCreateHosting_waitPageChange: 'tutorial/waitPageChange',
        }),

        $_tutorialCreateHosting_initEvents()
        {
            // Etapa 2
            this.$eventBus.$on('hosting-create-domain-changed',  this.$_tutorialCreateHosting_step2Redraw)
            this.$eventBus.$on('hosting-create-domain-invalid',  this.$_tutorialCreateHosting_step2Redraw)
            this.$eventBus.$on('hosting-create-domain-verified', this.$_tutorialCreateHosting_step2b)
            this.$eventBus.$on('hosting-create-domain-chosen',   this.$_tutorialCreateHosting_step2c)
            this.$eventBus.$on('hosting-created',                this.$_tutorialCreateHosting_step2Complete)

            // Etapa 3
            this.$eventBus.$on('hosting-creation-completed', this.$_tutorialCreateHosting_step3Complete)

            // Etapa 5
            this.$eventBus.$on('hosting-domain-status-loaded', this.$_tutorialCreateHosting_step5Redraw)
        },

        $_tutorialCreateHosting_finish()
        {
            if (confirm('Tem certeza que deseja finalizar o tutorial? Após finalizar não será possível fazer o tutorial novamente.')) {
                removeBackdrop();
                sessionStorage.setItem('tutorial_create_hosting_skipped', '1');
                this.$store.dispatch('tutorial/stop');
                this.$store.dispatch('tutorial/clearPopovers');
            }
        },

        /**
         * Exibir tutorial de criação de hospedagem.
         */
        $_tutorialCreateHosting_show(ignoreRunning)
        {
            // Verificar se usuário foi carregado
            if (!this.$_tutorialCreateHosting_user) return;

            // Verificar se o tutorial já está em execução
            const ignore = typeof ignoreRunning !== 'undefined' ? ignoreRunning : false;

            if (this.$_tutorialCreateHosting_isRunning && !ignore) return;

            this.$store.dispatch('tutorial/start');

            if (this.$_tutorialCreateHosting_isSkipped || this.$_tutorialCreateHosting_isFinished) {
                this.$store.dispatch('tutorial/stop');
            }
            else {
                this.$_tutorialCreateHosting_initEvents();

                if (this.$_tutorialCreateHosting_currentStep === 1 && this.$router.currentRoute.path === '/') {
                    this.$_tutorialCreateHosting_step1a();
                }
                else if (this.$_tutorialCreateHosting_currentStep === 2 && this.$router.currentRoute.path === '/hosting/add') {
                    this.$_tutorialCreateHosting_step2a();
                }
                else if (this.$_tutorialCreateHosting_currentStep === 3) { // TODO: Usar condicional para a rota
                    this.$_tutorialCreateHosting_step3();
                }
                else if (this.$_tutorialCreateHosting_currentStep === 4) {
                    this.$_tutorialCreateHosting_step4();
                }
                else if (this.$_tutorialCreateHosting_currentStep === 5) {
                    this.$_tutorialCreateHosting_step5();
                }
                else if (this.$_tutorialCreateHosting_currentStep === 6) {
                    this.$_tutorialCreateHosting_step6();
                }
                else if (this.$_tutorialCreateHosting_currentStep === 7) {
                    this.$_tutorialCreateHosting_step7();
                }
            }
        },

        /**
         * Etapa: 1.1
         * Exibir modal de entrada no tutorial.
         */
        $_tutorialCreateHosting_step1a()
        {
            const message = 'Seja muito bem-vindo à nossa plataforma. Chegou a hora de você conhecer o painel da Hostoo ' +
                'e iniciar com a criação de sua primeira hospedagem.'

            this.$bvModal.msgBoxConfirm(message,
                {
                    title: 'Vamos começar!',
                    size: 'md',
                    okVariant: 'success',
                    okTitle: 'Continuar',
                    cancelTitle: 'Pular tutorial',
                    centered: true,
                    hideHeaderClose: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                })
                .then (value => {
                    if (value) {
                        this.$_tutorialCreateHosting_step1b()
                    }
                    else {
                        sessionStorage.setItem('tutorial_create_hosting_skipped', '1')
                    }
                })
                .catch(error => { /* ignore */} )
        },

        /**
         * Etapa: 1.2
         * Realçar botão de criar hospedagem.
         */
        $_tutorialCreateHosting_step1b()
        {
            const buttonElm = document.querySelector('.h-o-card-hosting .btn-toolbar .btn-success');
            let popoverId;

            const action = async () => {
                highlightElement(buttonElm);

                popoverId = await this.$store.dispatch('tutorial/addPopover', {
                    target: buttonElm,
                    placement: 'top',
                    content: 'Clique aqui para criar uma hospedagem',
                    step: 1,
                    totalSteps: totalSteps,
                    onFinish: () => { this.$_tutorialCreateHosting_finish() },
                });
            };

            const changeToNextStep = () => {
                this.$store.dispatch('tutorial/removePopover', popoverId);
                this.$store.dispatch('tutorial/nextStep');
                this.$_tutorialCreateHosting_step2a();
                buttonElm.removeEventListener('click', changeToNextStep);
            };

            // this.$_tutorialCreateHosting_ignoreRouteLeave = '/hosting/add';
            buttonElm.addEventListener('click', changeToNextStep);

            resetBackdrop();
            setTimeout(() => action(), 1000);
        },

        /**
         * Etapa: 2.1
         */
        $_tutorialCreateHosting_step2a()
        {
            const action = async () => {
                const cardElm  = document.querySelector('.h-p-hosting-create .h-card-create-form');
                const inputElm = cardElm.querySelector('.input-domain');

                highlightElement(cardElm, '', 24);

                const popoverId = await this.$store.dispatch('tutorial/addPopover', {
                    target: inputElm,
                    placement: 'bottom',
                    content: `
                        <p class="mb-1">
                            Digite aqui o domínio que você deseja usar em sua hospedagem e depois clique em <strong>Verificar</strong>.
                        </p>
                        <p class="mb-0">
                            Exemplo: <strong>meusite.com.br</strong>
                        </p>
                    `,
                    step: 2,
                    totalSteps: totalSteps,
                    onFinish: () => { this.$_tutorialCreateHosting_finish() },
                });

                this.$_tutorialCreateHosting_Step2PopoverId = popoverId;
            };

            resetBackdrop();
            setTimeout(() => action(), 1000);
        },

        /**
         * Etapa: 2.2
         */
        $_tutorialCreateHosting_step2b()
        {
            this.$_tutorialCreateHosting_step2Redraw();
            this.$store.dispatch('tutorial/removePopover', this.$_tutorialCreateHosting_Step2PopoverId);
        },

        /**
         * Etapa: 2.3
         */
        $_tutorialCreateHosting_step2c()
        {
            const action = async () => {
                let elm = document.querySelector('.h-p-hosting-create .h-o-hosting-plan-list');
                elm = elm.parentElement;

                resetBackdrop();
                highlightElement(elm, '', 24);

                this.$_tutorialCreateHosting_currentPopoverId = await this.$store.dispatch('tutorial/addPopover', {
                    target: elm,
                    placement: 'bottom',
                    content: `
                        <p class="mb-1">Agora você deve escolher um plano para utilizar em sua nova hospedagem.</p>
                        <p class="mb-0">E não se preocupe com sua escolha, você poderá alterar o plano a qualquer momento aqui pelo painel.</p>
                    `,
                    step: 3,
                    totalSteps: totalSteps,
                    onFinish: () => { this.$_tutorialCreateHosting_finish() },
                });
            };

            resetBackdrop();
            setTimeout(() => { action() }, 1000);
        },

        /**
         * Etapa: 2.X
         * Redesenhar backdrop da etapa 2.
         */
        $_tutorialCreateHosting_step2Redraw()
        {
            const elm = document.querySelector('.h-p-hosting-create .h-card-create-form');

            this.$nextTick(() => {
                resetBackdrop();
                highlightElement(elm, '', 24);
            });
        },

        /**
         * Etapa: 2.X
         * Conclusão da etapa 2.
         */
        $_tutorialCreateHosting_step2Complete()
        {
            if (this.$_tutorialCreateHosting_currentPopoverId) {
                this.$store.dispatch('tutorial/removePopover', this.$_tutorialCreateHosting_currentPopoverId);
            }

            this.$store.dispatch('tutorial/nextStep');
            this.$_tutorialCreateHosting_waitPageChange(true);
            resetBackdrop();
        },

        /**
         * Etapa: 3
         * Exibição da tela de espera para criação da hospedagem.
         */
        $_tutorialCreateHosting_step3()
        {
            resetBackdrop();
            setTimeout(() => { this.$_tutorialCreateHosting_step3Redraw() }, 1000);
        },

        /**
         * Etapa: 3.X
         */
        $_tutorialCreateHosting_step3Redraw()
        {
            const elm = document.querySelector('.h-card-hosting-creating');

            this.$nextTick(() => {
                resetBackdrop();
                highlightElement(elm, '', 24);
            });
        },

        /**
         * Etapa: 3
         * Conclusão da etapa 3.
         */
        $_tutorialCreateHosting_step3Complete()
        {
            this.$store.dispatch('tutorial/changeStep', 4);
            this.$_tutorialCreateHosting_step3Redraw();
        },

        /**
         * Etapa: 4
         */
        $_tutorialCreateHosting_step4()
        {
            this.$_tutorialCreateHosting_step4a();
        },

        /**
         * Etapa: 4.1
         * Descrição: Ações da hospedagem.
         */
        $_tutorialCreateHosting_step4a()
        {
            const action = () => {
                const elm = document.querySelector('.h-o-card-hosting-top .h-server-logo-wrapper').parentElement

                elm.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
                resetBackdrop()

                setTimeout(async () => {
                    document.querySelector('.h-o-card-hosting-top .ht-hosting-functions').style.pointerEvents = 'none'

                    highlightElement(elm)

                    const popoverId = await this.$store.dispatch('tutorial/addPopover', {
                        target: elm,
                        placement: 'bottom',
                        content: `
                            Aqui você encontra funções básicas da hospedagem como <strong>Pausar</strong>,
                            <strong>Alterar plano</strong> e <strong>Excluir</strong>.`,
                        step: 4,
                        totalSteps: totalSteps,
                        onNext: () => {
                            document.querySelector('.h-o-card-hosting-top .ht-hosting-functions').style.pointerEvents = 'auto'
                            this.$store.dispatch('tutorial/removePopover', popoverId)
                            this.$_tutorialCreateHosting_step4b()
                        },
                        onFinish: () => {
                            document.querySelector('.h-o-card-hosting-top .ht-hosting-functions').style.pointerEvents = 'auto'
                            this.$_tutorialCreateHosting_finish()
                        },
                    })

                }, 500)
            }

            setTimeout(() => action(), 1000)
        },

        /**
         * Etapa: 4.2
         * Descrição: Endereços da hospedagem e endereço alternativo.
         */
        $_tutorialCreateHosting_step4b()
        {
            const action = async () => {
                const elm = document.querySelector('.ht-card-hosting-addresses');
                const buttonElm = document.querySelector('.ht-card-hosting-addresses .btn-enable-alternative');

                elm.scrollIntoView({ behavior: 'smooth' })
                resetBackdrop()

                setTimeout(async () => {
                    buttonElm.style.pointerEvents = 'none'
                    highlightElement(elm, '', 24);

                    const popoverId = await this.$store.dispatch('tutorial/addPopover', {
                        target: elm,
                        placement: 'top',
                        content: `
                            Aqui são exibidos os endereços para acesso à sua hospedagem. Se desejar, você pode habilitar 
                            o <strong>endereço alternativo</strong> para acessar seu site antes de apontar o DNS.`,
                        step: 4,
                        totalSteps: totalSteps,
                        onNext: () => {
                            this.$store.dispatch('tutorial/removePopover', popoverId)
                            buttonElm.style.pointerEvents = 'auto'
                            this.$_tutorialCreateHosting_step4c()
                        },
                        onFinish: () => {
                            buttonElm.style.pointerEvents = 'auto'
                            this.$_tutorialCreateHosting_finish()
                        },
                    })
                }, 500)
            }

            resetBackdrop()
            setTimeout(() => action(), 500)
        },

        /**
         * Etapa: 4.2
         * Descrição: Clicar em menu Domínio.
         */
        $_tutorialCreateHosting_step4c()
        {
            const elm = document.querySelector('.h-menu-hosting .h-menu-item-domain')
            let popoverId;

            const changeToNextStep = () => {
                this.$store.dispatch('tutorial/removePopover', popoverId)
                this.$store.dispatch('tutorial/nextStep')
                this.$_tutorialCreateHosting_step5()
                elm.removeEventListener('click', changeToNextStep)
            }

            elm.addEventListener('click', changeToNextStep)
            elm.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
            resetBackdrop()

            setTimeout(async () => {
                highlightElement(elm)

                popoverId = await this.$store.dispatch('tutorial/addPopover', {
                    target: elm,
                    placement: 'top',
                    content: 'Agora, clique aqui para continuar.',
                    step: 4,
                    totalSteps: totalSteps,
                    onFinish: () => { this.$_tutorialCreateHosting_finish() },
                })
            }, 500)
        },

        /**
         * Etapa: 5
         */
        $_tutorialCreateHosting_step5()
        {
            this.$_tutorialCreateHosting_step5a();
        },

        /**
         * Etapa: 5.1
         * Descrição: Domínio e DNS.
         */
        $_tutorialCreateHosting_step5a()
        {
            const action = () => {
                const elm = document.querySelector('.h-card-hosting-domain-status')

                const popoverId = this.$store.dispatch('tutorial/addPopover', {
                    target: elm,
                    placement: 'top',
                    content: `
                        Aqui são exibidos os servidores DNS para onde você deve apontar o seu domínio. 
                        Ou, caso o domínio não esteja registrado, vai ser exibida a opção de registrá-lo com a Hostoo.`,
                    step: 5,
                    totalSteps: totalSteps,
                    onNext: () => {
                        this.$store.dispatch('tutorial/removePopover', popoverId)
                        this.$_tutorialCreateHosting_step5b()
                    },
                    onFinish: () => {
                        this.$_tutorialCreateHosting_finish()
                    }
                })
            }

            resetBackdrop()
            setTimeout(() => {
                this.$_tutorialCreateHosting_step5Redraw()
                action()
            }, 500)
        },

        /**
         * Etapa: 5.2
         * Descrição: Clique para mudança de tela.
         */
        $_tutorialCreateHosting_step5b()
        {
            const elm = document.querySelector('.h-menu-hosting .h-menu-item-apps')
            let popoverId;

            const changeToNextStep = () => {
                this.$store.dispatch('tutorial/removePopover', popoverId)
                this.$store.dispatch('tutorial/nextStep')
                this.$_tutorialCreateHosting_step6()
                elm.removeEventListener('click', changeToNextStep)
            }

            elm.addEventListener('click', changeToNextStep)
            elm.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
            resetBackdrop()

            setTimeout(async () => {
                highlightElement(elm)

                popoverId = await this.$store.dispatch('tutorial/addPopover', {
                    target: elm,
                    placement: 'top',
                    content: 'Vamos continuar! Agora, clique aqui.',
                    step: 5,
                    totalSteps: totalSteps,
                    onFinish: () => { this.$_tutorialCreateHosting_finish() },
                })
            }, 500)
        },

        /**
         * Etapa: 5.1
         * Descrição: Redesenhar backdrop da etapa 5.
         */
        $_tutorialCreateHosting_step5Redraw()
        {
            const elm = document.querySelector('.h-card-hosting-domain-status')

            this.$nextTick(() => setTimeout(() => {
                const linkElm = document.querySelector('.h-card-hosting-domain-status .alert-register a')
                if (linkElm) linkElm.style.pointerEvents = 'none'
                resetBackdrop()
                highlightElement(elm, '', 24)
            }), 500)
        },

        /**
         * Etapa: 6
         */
        $_tutorialCreateHosting_step6()
        {
            this.$_tutorialCreateHosting_step6a();
        },

        /**
         * Etapa: 6.1
         * Descrição: Aplicativos.
         */
        $_tutorialCreateHosting_step6a()
        {
            const action = () => {
                const elm = document.querySelector('.h-card-hosting-apps')
                const buttonElm  = document.querySelector('.h-card-hosting-apps .btn-toolbar .btn-success')

                buttonElm.style.pointerEvents = 'none'
                highlightElement(elm, '', 24)

                const popoverId = this.$store.dispatch('tutorial/addPopover', {
                    target: elm,
                    placement: 'top',
                    content: `
                        Nesta tela você consegue instalar aplicativos facilmente em sua hospedagem como 
                        <strong>Wordpress</strong>, <strong>Mautic</strong>, <strong>Magento</strong> e muitos outros aplicativos populares.`,
                    step: 6,
                    totalSteps: totalSteps,
                    onNext: () => {
                        this.$store.dispatch('tutorial/removePopover', popoverId)
                        this.$store.dispatch('tutorial/nextStep')
                        buttonElm.style.pointerEvents = 'auto'
                        this.$router.push('/hosting/' + this.$_tutorialCreateHosting_domain)
                        this.$_tutorialCreateHosting_step7()
                    },
                    onFinish: () => {
                        buttonElm.style.pointerEvents = 'auto'
                        this.$_tutorialCreateHosting_finish()
                    }
                })
            }

            resetBackdrop()
            setTimeout(() => { action() }, 500)
        },

        /**
         * Etapa: 7
         */
        $_tutorialCreateHosting_step7()
        {
            const action = () => {
                const elm = document.querySelector('.h-m-nav-help')

                const popoverId = this.$store.dispatch('tutorial/addPopover', {
                    target: elm,
                    placement: 'topleft',
                    content: 'Quando precisar de ajuda, basta usar o chat para falar com nosso suporte ou acesse a nossa Base de Conhecimento.',
                    step: 7,
                    totalSteps: totalSteps,
                    onNext: () => {
                        this.$store.dispatch('tutorial/removePopover', popoverId)
                        this.$_tutorialCreateHosting_stepFinal()
                    },
                    onFinish: () => {
                        this.$_tutorialCreateHosting_finish()
                    }
                })
            }

            resetBackdrop()
            setTimeout(() => { action() }, 500)
        },

        /**
         * Etapa: Encerramento
         */
        $_tutorialCreateHosting_stepFinal()
        {
            removeBackdrop()

            const message = 'Estes foram os passos básicos para você começar a usar o painel da Hostoo. ' +
                'Agora você pode continuar navegando pelas outras opções disponíveis ou começar a criar o seu site. ' +
                'Se tiver dúvidas ou precisar de ajuda basta falar conosco!'

            this.$bvModal.msgBoxOk(message,
                {
                    title: 'Tutorial',
                    size: 'md',
                    okVariant: 'success',
                    okTitle: 'Finalizar',
                    centered: true,
                    hideHeaderClose: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                })
                .then (value => {
                    sessionStorage.setItem('tutorial_create_hosting_finished', '1')
                })
                .catch(error => { /* ignore */} )

            // this.$bvModal.msgBoxConfirm(message,
            //     {
            //         title: 'Tutorial',
            //         size: 'md',
            //         okVariant: 'success',
            //         okTitle: 'Finalizar',
            //         cancelTitle: 'Ajuda',
            //         centered: true,
            //         hideHeaderClose: true,
            //         noCloseOnBackdrop: true,
            //         noCloseOnEsc: true,
            //     })
            //     .then (value => {
            //         if (value) {
            //             // this.$_tutorialCreateHosting_step1b()
            //             sessionStorage.setItem('tutorial_create_hosting_finished', '1')
            //         }
            //         else {
            //             sessionStorage.setItem('tutorial_create_hosting_finished', '1')
            //         }
            //
            //         sessionStorage.setItem('tutorial_create_hosting_finished', '1')
            //     })
            //     .catch(error => { /* ignore */} )
        }
    },
    watch: {
        $_tutorialCreateHosting_user: function (val) {

            // TODO: Ignorar tutorial no periodo de mudança de preços (2023-12-01)

            if (!val.isNPT && val.isFirstAccess && !val.askGroup && !val.registerDomain) {
                this.$_tutorialCreateHosting_show();
            }
        }
    }
}

function clearRoundedRect(ctx, x, y, width, height, radius)
{
    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.moveTo(x, y + radius);
    ctx.lineTo(x, y + height - radius);
    ctx.arcTo(x, y + height, x + radius, y + height, radius);
    ctx.lineTo(x + width - radius, y + height);
    ctx.arcTo(x + width, y + height, x + width, y + height-radius, radius);
    ctx.lineTo(x + width, y + radius);
    ctx.arcTo(x + width, y, x + width - radius, y, radius);
    ctx.lineTo(x + radius, y);
    ctx.arcTo(x, y, x, y + radius, radius);
    ctx.closePath();
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.globalCompositeOperation = 'source-over';
}

/**
 * Destacar um elemento na tela.
 *
 * @param {Element} elm
 * @param {string} [zindex]
 * @param {number} [padding]
 */
function highlightElement(elm, zindex, padding)
{
    if (!backdropCtx || !elm) return;

    const elmPos     = elm.getBoundingClientRect();
    const elmTop     = elmPos.top + window.scrollY;
    const elmBottom  = elmPos.bottom + window.scrollY;
    const elmZindex  = zindex || '10010';
    const elmPadding = padding || 16;

    clearRoundedRect(
        backdropCtx,
        elmPos.left - (elmPadding/2),
        elmTop - (elmPadding/2),
        elmPos.right - elmPos.left + elmPadding,
        elmBottom - elmTop + elmPadding,
        6
    );

    elm.style.zIndex = elmZindex;
    elm.classList.add('tutorial-highlighted');
}

function getBodyHeight()
{
    return Math.max(
        document.documentElement.clientHeight,
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight
    );
}

/**
 * Inicializar o backdrop para o tutorial.
 */
function initBackdrop()
{
    let $outer = document.body.querySelector('.tutorial-outer'), $backdrop;

    if ($outer) {
        $backdrop = $outer.querySelector('.tutorial-backdrop');
    }
    else {
        $outer = document.createElement('div');
        $outer.classList.add('tutorial-outer');
        $outer.style.cssText = 'position:absolute;top:0;left:0;z-index:10000;';

        $backdrop = document.createElement('canvas');
        $backdrop.classList.add('tutorial-backdrop');
        $backdrop.width = window.innerWidth;
        $backdrop.height = getBodyHeight();
        $outer.appendChild($backdrop);

        document.body.classList.add('is-tutorial');
        document.body.appendChild($outer);
    }

    backdropCtx = $backdrop.getContext('2d');
}

/**
 * Exibir e redefinir o backdrop.
 */
function resetBackdrop()
{
    if (!backdropCtx) initBackdrop();

    const $backdrop = document.body.querySelector('.tutorial-outer .tutorial-backdrop');
    const items = document.querySelectorAll('.tutorial-highlighted');
    let i;

    // Atualizar dimensões do backdrop
    $backdrop.width = window.innerWidth;
    $backdrop.height = getBodyHeight();

    // Remover itens em destaque
    for (i = 0; i < items.length; ++i) {
        items[i].style.zIndex = '';
        items[i].classList.remove('tutorial-highlighted');
    }

    backdropCtx.clearRect(0, 0, window.innerWidth, getBodyHeight());
    backdropCtx.fillStyle = "rgba(69, 48, 126, 0.45)";
    backdropCtx.fillRect(0, 0, window.innerWidth, getBodyHeight());
}

/**
 * Remover o backdrop do tutorial.
 */
function removeBackdrop()
{
    const $outer = document.body.querySelector('.tutorial-outer');
    if ($outer) $outer.remove();
    backdropCtx = null;
    document.body.classList.remove('is-tutorial');
}

export default tutorialCreateHosting