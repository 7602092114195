<template>
    <card-title body-class="d-flex flex-column justify-content-between" class="h-o-card-tickets " title="Tickets de Suporte">
        <template slot="actions">
            <router-link to="/support/tickets?add=1" class="btn btn-success btn-circle" title="Abrir novo ticket" v-b-tooltip.hover.topleft>
                <i class="fas fa-plus"></i>
            </router-link>
        </template>

        <div v-if="isLoadingTickets || !tickets.length" class="d-flex flex-grow-1 align-items-center justify-content-center">
            <text-loading v-show="isLoadingTickets" class="mb-4" />
            <p v-show="!isLoadingTickets && !tickets.length" class="text-muted mb-4">Nenhum ticket aberto</p>
        </div>

        <div v-if="tickets.length" class="list-group flex-grow-1">
            <template v-for="item in tickets">
                <router-link :key="item.id" :to="'/support/ticket/'+ item.id" class="list-group-item list-group-item-action" v-bind:class="getTicketClasses(item)">
                    <h6 class="ht-ticket-title text-truncate" :title="item.subject">{{ item.subject }}</h6>
                    <div class="ht-ticket-info">
                        <span class="ht-ticket-status">{{ item.statusText }}</span> - Atualizado {{ item.updatedAt | moment("from", new Date()) }}
                    </div>
                </router-link>
            </template>
        </div>

        <router-link class="btn btn-secondary mt-3" to="/support/tickets">Ver todos os tickets</router-link>
    </card-title>
</template>

<script>
    import { mapActions } from 'vuex'
    import { ApiCancel }  from '@/plugins/api'
    import TextLoading    from '@/components/atoms/TextLoading'
    import CardTitle      from '@/components/molecules/CardTitle'

    export default {
        components: {
            TextLoading,
            CardTitle
        },
        data() {
            return {
                tickets: [],
                isLoadingTickets: false,
                dataCancelToken: null
            }
        },
        computed: {
            cardBodyClass: function() {
                return (this.isLoadingTickets || !this.tickets.length) ? 'd-flex flex-column justify-content-center align-items-center' : '';
            }
        },
        mounted()
        {
            this.getTickets();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Classes para o elemento do ticket.
             */
            getTicketClasses(data)
            {
                let classes = [];

                if (['answered', 'on-hold', 'scheduled', 'in-progress'].indexOf(data.status) !== -1) {
                    classes.push("ht-ticket-replied");
                }

                return classes;
            },

            /**
             * Listar tickets.
             */
            getTickets()
            {
                this.isLoadingTickets = true;

                const CancelToken = ApiCancel.CancelToken;
                this.dataCancelToken = CancelToken.source();

                this.$api.get('/ticket?recent=1', { cancelToken: this.dataCancelToken.token })
                    .then (response => this.tickets = response.data.data)
                    .catch(error => { /* do nothing */ })
                    .then (() => this.isLoadingTickets = false);
            },

            /**
             * Cancelar carregamento dos dados
             */
            cancelFetch()
            {
                if (this.dataCancelToken) {
                    this.dataCancelToken.cancel();
                }
            }
        }
    }
</script>

<style lang="stylus">
    .h-o-card-tickets
        .list-group-item
            padding 0.75rem 1rem
            border-width 0 0 0 5px
            border-radius 0 6px 6px 0 !important

            + .list-group-item
                margin-top .75rem

            &.ht-ticket-replied
                border-color $yellow

        .list-group-item-action
            background-color #F4F4F4

            &:hover
                background-color $gray-200

        .ht-ticket-title
            margin-bottom 1px
            font-weight 600

        .ht-ticket-info
            font-size 12px
            color $gray-500

        .ht-ticket-status
            font-weight bold
            color $gray-600
</style>