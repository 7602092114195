<template>
    <tabs-pages v-if="domain" :tabs="tabs">
        <slot></slot>
    </tabs-pages>
</template>

<script>
    import TabsPages from '@/components/organisms/TabsPages'
    import {mapState} from 'vuex'

    export default {
        name: 'DomainTemplate',
        props: ['resource'],
        components: {
            TabsPages
        },
        data() {
            return {
                tabs: []
            }
        },
        computed: {
            ...mapState({
                domain: state => state.domain.data,
            })
        },
        mounted()
        {
            this.updateTabs();
        },
        methods:
        {
            updateTabs(domain)
            {
                const _domain = domain || this.domain.domain || this.resource;

                this.tabs = [
                    {
                        title: 'Resumo',
                        link: `/domain/${_domain}`,
                        exact: true,
                    }
                ];

                if (this.domain && !this.domain.isPending)
                {
                    this.tabs.push(
                        {
                            title: 'DNS',
                            link: `/domain/${_domain}/nameservers`,
                        },
                        {
                            title: 'Contatos',
                            link: `/domain/${_domain}/contact`,
                        }
                    );
                }
            }
        },
        watch: {
            resource: function (val, oldVal)
            {
                if (val !== oldVal) {
                    this.updateTabs(val);
                }
            }
        }
    }
</script>
