<template>
    <vue-slider
        ref="slider"
        class="h-a-slider-plan"
        v-bind:class="{ 'theme-green': mainColor === 'green', 'theme-purple': mainColor === 'purple' }"
        v-model="planValue"
        :data="['512 MB','1GB','2 GB','4 GB','8 GB','16 GB','24 GB','32 GB']"
        :marks="true"
        :height="height"
        :dotSize="30"
        tooltip="none"
        @change="onChange"
    />
</template>

<script>
    import VueSlider from 'vue-slider-component'

    export default {
        props: {
            value: {
                type: Number,
                default: 1,
            },
            height: {
                type: Number,
                default: 16
            },
            mainColor: {
                type: String,
                default: "green"
            }
        },
        components: {
            VueSlider,
        },
        data() {
            return {
                planValue: "",
            }
        },
        mounted() {
            this.$refs.slider.setIndex(this.value);
        },
        methods: {

            onChange()
            {
                const index = this.$refs.slider.getIndex();
                this.$emit('input', index);
            }
        }
    }
</script>

<style lang="stylus">
    .h-a-slider-plan
        margin 0 10px

        .vue-slider-rail
            border-radius 0

            &:before, &:after
                content ""
                position absolute
                top 0
                size 10px 100%
                background-color #CCC

            &:before
                left -10px
                border-radius 15px 0 0 15px

            &:after
                right -10px
                border-radius 0 15px 15px 0

        .vue-slider-process
            border-radius 0
            background-color $green

            &:before
                content ""
                absolute top 0 left -10px
                size 10px 100%
                border-radius 15px 0 0 15px
                background-color $green

        .vue-slider-mark-step
            display block !important
            background-color transparent

            &:before, &:after
                content ""
                position absolute
                left 50%
                size 1px 8px
                background-color #CCC

            &:before
                top -10px

            &:after
                bottom -10px

        .vue-slider-mark-label
            font-size 12px
            font-weight 700
            letter-spacing -.025em
            color $primary
            padding-top 4px

        .vue-slider-dot-handle
            border 4px solid $green

        &.theme-purple
            .vue-slider-process
                background-color $purple-light

                &:before
                    background-color $purple-light

            .vue-slider-dot-handle
                border-color $purple-light
</style>
