<template>
    <tabs-pages :tabs="tabs">
        <slot></slot>
    </tabs-pages>
</template>

<script>
    import TabsPages from "../organisms/TabsPages";

    export default {
        props: ['resource'],
        components: {
            TabsPages
        },
        data() {
            return {
                tabs: [
                    {
                        title: "PHP",
                        link: `/hosting/${this.resource}/php`,
                    },
                    {
                        title: "HTTPS",
                        link: `/hosting/${this.resource}/ssl`,
                    },
                    {
                        title: "SSH",
                        link: `/hosting/${this.resource}/ssh`,
                    },
                    {
                        title: "Cron",
                        link: `/hosting/${this.resource}/cron`,
                    },
                    {
                        title: "Logs",
                        link: `/hosting/${this.resource}/logs`,
                    }
                ]
            }
        }
    }
</script>