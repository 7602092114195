<template>
    <div class="input-group">
        <input type="text" :id="id" ref="input" class="form-control" v-bind:value="value" readonly>
        <div class="input-group-append">
            <button type="button" ref="btnCopy" class="btn btn-rounded-right" v-bind:class="buttonClass" @click="copy">{{ buttonText }}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputCopy",
        props: {
            id: {
                type: String,
                default: "",
            },
            value: {
                type: [String, Number],
                default: "",
            },
        },
        data() {
            return {
                isCopied: false,
            }
        },
        computed: {
            buttonClass: function() {
                return this.isCopied ? "btn-success" : "btn-primary";
            },
            buttonText: function () {
                return this.isCopied ? "Copiado" : "Copiar";
            }
        },
        methods: {
            /**
             * Copy input value to clipboard.
             */
            copy()
            {
                this.$refs.input.select();
                document.execCommand('copy');

                this.isCopied = true;
                setTimeout(() => { this.isCopied = false; }, 3000);

                this.$emit('copy');
            },
        }
    }
</script>
