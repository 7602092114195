<template>
    <div class="hta-input-search">
        <div class="input-group">
            <input
                type="search"
                class="form-control"
                :spellcheck="isSpellcheck"
                :placeholder="inputPlaceholder"
                v-model="searchTerm"
                @input="$emit('input', searchTerm)">

            <div class="input-group-append">
                <div class="input-group-text"><i class="fas fa-search" aria-hidden="true"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: String,
            placeholder: String,
            isSpellcheck: { type: Boolean, default: false}
        },
        data() {
            return {
                searchTerm: this.value,
                inputPlaceholder: this.placeholder || "Buscar..."
            }
        },
        watch: {
            value: function (val) {
                this.searchTerm = val;
            }
        }
    }
</script>

<style lang="stylus">
    .hta-input-search
        .input-group-text
            background-color #F0F0F0
            border-color #F0F0F0
            color #ADADAD
</style>