<template>
    <div>
        <card-title inner-body>
            <div class="d-flex justify-content-md-between mb-6">
                <text-loading v-show="isLoadingList && !cardTitle" class="h4" />
                <h4 v-show="cardTitle" class="card-title-inner flex-grow-1 mb-0">{{ cardTitle }}</h4>
                <div class="card-header-actions">
                    <router-link :to="`/hosting/${resource}/email/lists`" class="btn btn-secondary" exact>Voltar</router-link>
                </div>
            </div>

            <div v-if="listData" v-show="!isLoadingList" class="table-list-wrapper">
                <table class="table table-list table-sm mb-0">
                    <tbody>
                        <tr>
                            <th class="col-w-px col-nowrap">Uso de disco:</th>
                            <td>
                                <span v-if="listData.size">{{ listData.size | formatBytes(0) }}</span>
                                <span v-else>Sem informação</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Membros:</th>
                            <td>
                                <span v-if="listData.members">{{ listData.members.length }}</span>
                                <span v-else>Sem informação</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card-title>

        <card-table
            ref="cardMembers"
            inner-title="Membros da lista"
            add-text="Adicionar"
            modal-title="Adicionar membro"
            modal-size="md"
            :table-fields="tableMembersFields"
            :table-data="members"
            :table-loading="isLoadingList"
            :api-save-url="`/hosting/${resource}/email/lists/${list}/member`"
            table-empty-text="Nenhum membro encontrado"
            hide-edit
            no-remove-confirm
            @created="fetchList"
            @remove="removeMember"
        >
            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="E-mail" label-for="memberEmail" :error="formErrors.email">
                    <input type="email" id="memberEmail" class="form-control" v-model="formData.email" v-input-lowercase>
                </form-group>
            </template>
        </card-table>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import TextLoading from "@/components/atoms/TextLoading.vue";
    import CardTitle from "@/components/molecules/CardTitle.vue";
    import CardTable from "@/components/organisms/CardTable.vue";
    import FormGroup from "@/components/molecules/FormGroup.vue";

    export default {
        props: [
            'resource',
            'list'
        ],
        components: {
            FormGroup,
            CardTable,
            TextLoading,
            CardTitle
        },
        data() {
            return {
                cardTitle: "",
                listData: null,
                members: [],
                isLoadingList: false,

                tableMembersFields: [
                    {
                        name: 'email',
                        sortField: 'email',
                        filterable: true,
                        title: 'E-mail',
                    },
                ]
            }
        },
        created()
        {
            this.fetchList();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error',
            }),

            fetchList()
            {
                this.isLoadingList = true;

                this.$api.get(`/hosting/${this.resource}/email/lists/${this.list}`)
                    .then((response) => {
                        this.listData = response.data.data;
                        this.members = response.data.data.members.map(email => ({ email }));
                        this.cardTitle = this.listData.name;
                    })
                    .catch((error) => { this.showErrorAlert(error) })
                    .then(() => { this.isLoadingList = false })
            },

            removeMember(item)
            {
                let postData  = { 'email': item.email };

                this.$api.post(`/hosting/${this.resource}/email/lists/${this.list}/remove-member`, postData)
                    .then (response => {})
                    .catch(error => this.showErrorAlert(error));
            },
        }
    }
</script>
