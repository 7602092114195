export default {
    methods: {
        /**
         * Registrar pagamento com tag de conversão.
         *
         * @param {string} paymentMethod
         * @param {Number} amount
         * @param {string} [coupon]
         */
        trackPurchase(paymentMethod, amount, coupon = "")
        {
            // Tag de conversão do Facebook
            if (typeof window.fbq !== "undefined")
            {
                window.fbq("track", "Purchase", {
                    payment_type : paymentMethod,
                    value        : amount,
                    currency     : "BRL",
                });
            }

            // Tag de conversão do Google Ads
            if (typeof window.gtag !== "undefined")
            {
                gtag("event", "conversion", {
                    "send_to"  : "AW-798302531/fdCoCPXdkcQBEMPC1PwC",
                    "value"    : amount,
                    "currency" : "BRL"
                });

                if (typeof window.GA4_MEASUREMENT_ID !== "undefined")
                {
                    gtag("event", "begin_checkout", {
                        "send_to"  : window.GA4_MEASUREMENT_ID,
                        "currency" : "BRL",
                        "value"    : amount,
                        "items" : [
                            {
                                "item_name"      : (this.isOrderRecharge ? "Pagamento" : "Recarga"),
                                "coupon"         : coupon,
                                "item_category"  : (this.isOrderRecharge ? "Domínio/Serviço" : "Hospedagem"),
                                "item_category2" : "Manual",
                                "price"          : amount,
                            }
                        ]
                    });
                }
            }
        },
    }
}
