import { ApiCancel } from '@/plugins/api'

const state = {
    type: null,
    message: null,
    response: null,
    rawError: null
};

const actions = {

    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, error)
    {
        commit('setRawError', error);

        if (ApiCancel.isCancel(error)) return;

        if (typeof error === 'string') {
            commit('error', error);
        }
        else {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error);
            }

            let message = 'Desculpe! Ocorreu um erro inesperado. Por favor, tente novamente ou entre em contato com nosso suporte';

            if (typeof error === 'object' && error.response)
            {
                commit('setResponse', error.response);

                if (typeof error.response.data.message !== 'undefined') {
                    message = error.response.data.message;
                }
                else if (error.message) {
                    message = error.message;
                }
            }

            commit('error', message);
        }
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {

    success(state, message) {
        state.type = "success";
        state.message = message;
    },
    error(state, message) {
        state.type = "danger";
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setRawError(state, error) {
        state.rawError = error;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
}
