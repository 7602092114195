<template>
    <ul class="h-a-steps-circle">
        <li v-for="(step, index) in steps" v-bind:class="{ 'active': index <= activeIndex }">
            <div>
                <span class="number">{{ index+1 }}</span>
                <span class="label">{{ step }}</span>
            </div>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            steps: {
                type: Array,
                default: [],
            },
            activeIndex: {
                type: Number,
                default: 0,
            }
        }
    }
</script>

<style lang="stylus">
    .h-a-steps-circle {
        padding-left: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > li {
            display flex
            flex-direction: row;
            align-items: start;
            text-align center
            flex-grow 1
            flex-basis 0
            padding-right 8px

            &:first-child {
                flex-grow 0

                > div {
                    margin-left 0
                }
            }

            &:last-child {
                padding-right 0
            }

            &:before {
                content ""
                display block
                flex-grow 1
                height 3px
                border-radius 3px
                margin-top calc(1.25rem - 1px)
                background-color #ADADAD
            }

            &.active {
                font-weight 600

                &:before {
                    background-color $purple-light
                }

                .number {
                    background-color $purple-light
                    color white
                }

                .label {
                    color $purple-light
                }
            }

            > div {
                position relative
                display flex
                flex-direction column
                align-items center
                padding-bottom 1.5rem
                margin-left 8px
            }
        }

        .number {
            display block
            size 2.5rem
            line-height 2.5rem
            font-weight 700
            border-radius 50%
            background-color #ADADAD
            color #7E7E7E
        }

        .label {
            absolute bottom 0 left 50%
            width 200px
            margin-left -100px
            font-size .875rem
            font-weight 600
            color #ADADAD
        }
    }
</style>
