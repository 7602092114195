<template>
    <hosting-email-template :resource="resource">
        <card-title title="Webmail" inner-body>
            <p class="mb-6">
                Seu webmail pode ser acessado através de um endereço personalizado utilizando o mesmo domínio de sua
                hospedagem atual (por exemplo, <strong>webmail.meudominio.com</strong>). Caso o DNS do seu domínio não
                esteja configurado corretamente, o webmail será acessado através de um endereço padrão do servidor.
            </p>

            <text-loading v-show="isLoading" />

            <div class="btn-toolbar mb-0" role="toolbar">
                <div v-show="this.webmailLink" role="group" class="btn-group">
                    <a :href="this.webmailLink" target="_blank" class="btn btn-secondary btn-action">
                        Acessar webmail
                        <i class="fas fa-external-link-alt ml-2" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </card-title>

        <card-title title="Logotipo" inner-body>
            <p class="mb-6">
                Utilize um logotipo personalizado no webmail quando acessado através do seu domínio. O arquivo de imagem
                pode estar no formato JPG ou PNG e deve ter no máximo 100 KB. O tamanho ideal para a imagem é
                <strong>210 x 70 pixels</strong>.
            </p>

            <logo-upload
                ref="logo"
                :image="logoImage"
                :height="70"
                :loading="isLoadingLogo"
                @change="saveLogo"
                @remove="removeLogo"
            />
        </card-title>

        <card-title title="Personalização" inner-body>
            <p class="mb-6">
                Altere detalhes do webmail para deixá-lo com uma identidade visual mais próxima da sua marca.
            </p>

            <text-loading v-show="isLoading" />

            <form v-show="!isLoading" @submit.prevent="customizeWebmail">
                <div class="row">
                    <div class="col-xl-6">
                        <form-group class="mb-0" label="Cor personalizada" label-for="webmailColor">
                            <select id="webmailColor" class="custom-select" v-model="formData.color">
                                <template v-for="(item, index) in colors">
                                    <option :value="index" v-bind:style="{ backgroundColor: item.color, color: 'white' }">{{ item.title }}</option>
                                </template>
                            </select>
                        </form-group>
                    </div>
                </div>
                <div class="form-buttons w-100">
                    <button-form ref="btnSubmitCustomize" type="submit" variant="success" class="btn-action">Salvar</button-form>
                </div>
            </form>
        </card-title>
    </hosting-email-template>
</template>

<script>
import {mapActions}         from 'vuex'
import AjaxService          from '@/services/AjaxService'
import ErrorService         from '@/services/error.service'
import ButtonForm           from '@/components/atoms/ButtonForm'
import TextLoading          from '@/components/atoms/TextLoading'
import ModalConfirm         from '@/components/atoms/ModalConfirm'
import CardTitle            from '@/components/molecules/CardTitle'
import FormGroup            from '@/components/molecules/FormGroup'
import LogoUpload           from '@/components/molecules/LogoUpload'
import HostingEmailTemplate from '@/components/templates/HostingEmailTemplate'

export default {
    name: "HostingWebmail",
    props: ['resource'],
    components: {
        ModalConfirm,
        ButtonForm,
        FormGroup,
        LogoUpload,
        TextLoading,
        CardTitle,
        HostingEmailTemplate
    },
    data() {
        return {
            isLoading: false,
            isLoadingLogo: false,
            webmailLink: "",
            logoImage: null,
            colors: {
                default:  { color: "#45307C", title: "Padrão" },
                magenta:  { color: "#6D307C", title: "Magenta" },
                darkblue: { color: "#30587C", title: "Azul escuro" },
                softblue: { color: "#4757E1", title: "Azul claro" },
                cyan:     { color: "#3C9CA3", title: "Ciano" },
                green:    { color: "#3CA36B", title: "Verde" },
                orange:   { color: "#E1992C", title: "Laranja" },
                red:      { color: "#E14D2C", title: "Vermelho" },
                black:    { color: "#323232", title: "Preto" },
            },
            formData: {
                color: "default",
            },
            formErrors: {},
        }
    },
    beforeRouteLeave(to, from, next)
    {
        AjaxService.cancel();
        next();
    },
    mounted()
    {
        this.fetch();
    },
    methods: {
        ...mapActions({
            showErrorAlert: 'alert/error',
            successNotification: 'notification/success',
        }),

        /**
         * Obter dados de e-mails.
         */
        fetch()
        {
            this.isLoading = true;

            const params = { include: 'webmail,customization' };

            this.$api.get(`/hosting/${this.resource}/email`, { params: params, cancelToken: AjaxService.getCancelToken() })
                .then (response => {
                    const webmailInfo = response.data.data.webmail;

                    this.webmailLink = webmailInfo.url;

                    if (response.data.data.customization.color) {
                        this.formData.color = response.data.data.customization.color;
                    }

                    if (response.data.data.customization.logo) {
                        this.logoImage = response.data.data.customization.logo;
                    }
                })
                .catch(error => this.showErrorAlert(error))
                .then(() => this.isLoading = false)
        },

        /**
         * Salvar logotipo do webmail.
         */
        saveLogo(file)
        {
            this.formErrors = {};
            this.isLoadingLogo = true;

            let formData = new FormData();
            formData.append('logo', file || '');

            let promise = this.$api.request({
                method: 'post',
                url: `/hosting/${this.resource}/webmail/logo`,
                data: formData,
                headers: { 'content-type': 'multipart/form-data' },
            });

            return promise
                .then(response => {
                    this.successNotification({ message: 'Logotipo atualizado!' });
                    this.$refs.logo.reset(response.data.data.logo);
                })
                .catch(error => ErrorService.handleFormError(error, this))
                .then (() => this.isLoadingLogo = false);
        },

        removeLogo()
        {
            this.saveLogo(null)
                .then(() => this.logoImage = null);
        },

        /**
         * Salvar personalizações do webmail.
         */
        customizeWebmail()
        {
            this.formErrors = {};
            this.$refs.btnSubmitCustomize.setLoadingAndFocus();

            this.$api.post(`/hosting/${this.resource}/webmail/customize`, this.formData)
                .then (response => this.successNotification({ message: 'Personalização atualizada!' }))
                .catch(error => ErrorService.handleFormError(error, this))
                .then (() => this.$refs.btnSubmitCustomize.setLoading(false));
        },
    }
}
</script>
