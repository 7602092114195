<template>
    <div class="h-layout h-layout-full-height h-layout-admin">
        <page-loader />
    </div>
</template>

<script>
    import PageLoader from '@/components/molecules/PageLoader'

    export default {
        components: {
            PageLoader
        },
        mounted()
        {
            setTimeout(() => { window.location.href = '/hosting/create'; }, 3000);
        }
    }
</script>