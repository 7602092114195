<template>
    <admin-page-template>
        <card-table
            ref="cardDomains"
            title="Meus Domínios"
            table-empty-text="Nenhum domínio registrado ou transferido"
            table-max-height="calc((10 * (3rem + 1px)))"
            :toolbar-actions="domainActions"
            :table-fields="tableFields"
            :form-data-transform="formDataTransform"
            :post-data-transform="postDataTransform"
            api-url="/domain"
            hide-add
            hide-edit
            hide-remove
        >
            <template slot="table-domain" slot-scope="{ rowData }">
                <div class="domain-name text-truncate">
                    <router-link :to="`/domain/${rowData.domain}`">{{ rowData.domain }}</router-link>
                </div>
            </template>

            <template slot="table-register" slot-scope="{ rowData }">
                <span :title="$moment(rowData.registerDate).format('DD/MM/YYYY')">
                    {{ rowData.registerDate | moment("DD/MM/YYYY") }}
                </span>
            </template>

            <template slot="table-expiration" slot-scope="{ rowData }">
                <span :title="$moment(rowData.expirationDate).format('DD/MM/YYYY')" v-bind:class="{'text-danger': rowData.daysToExpire <= 30}">
                    {{ rowData.expirationDate | moment("DD/MM/YYYY") }}
                </span>
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item :href="`http://${rowData.domain}`" target="_blank">
                    <i class="fas fa-external-link-alt fa-fw"></i> Acessar
                </b-dropdown-item>
                <b-dropdown-item v-if="!rowData.isPending" :to="`/domain/${rowData.domain}/nameservers`">
                    <i class="fas fa-pencil-alt fa-fw"></i> Alterar DNS
                </b-dropdown-item>
            </template>
        </card-table>
    </admin-page-template>
</template>

<script>
    import FormGroup            from '@/components/molecules/FormGroup'
    import CardTable            from '@/components/organisms/CardTable'
    import AdminPageTemplate    from "@/components/templates/AdminPageTemplate"

    export default {
        components: {
            FormGroup,
            CardTable,
            AdminPageTemplate
        },
        data() {
            return {
                domainActions: [
                    {
                        text: 'Adicionar novo domínio',
                        variant: 'success',
                        link: '/domain/add'
                    }
                ],
                tableFields: [
                    {
                        name: '__slot:domain',
                        sortField: 'domain',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Domínio',
                        dataClass: 'col-domain',
                    },
                    {
                        name: '__slot:register',
                        sortField: 'registerDate',
                        sortFunction: 'text',
                        title: 'Registrado em',
                        titleClass: 'col-datetime',
                        dataClass: 'col-datetime',
                    },
                    {
                        name: '__slot:expiration',
                        sortField: 'expirationDate',
                        sortFunction: 'text',
                        title: 'Expira em',
                        titleClass: 'col-datetime',
                        dataClass: 'col-datetime',
                    },
                    {
                        name: 'statusText',
                        sortField: 'statusText',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Status',
                        titleClass: "col-w-40",
                        dataClass: "col-w-40 col-nowrap",
                    }
                ]
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardDomains.cancelFetch();
            next();
        },
        methods:
        {
            formDataTransform(data)
            {
                if (!data.isEditing) {
                    return { type: "register" };
                }

                return data;
            },

            postDataTransform(data)
            {
                data.domain = data.domain.replace(/^https?:?\/?\/?/g, '');

                return data;
            }
        }
    }
</script>