<template>
    <div>
        <alert-compact
            variant="success"
            title="Garanta a integridade dos seus dados!"
            btn-text="Ativar"
            :btn-link="`/hosting/${resource}/backup?daily=1`"
            btn-variant="white"
            style="margin-bottom:2rem;"
        >
            Ative o backup diário para deixar seus dados ainda mais protegidos contra perdas acidentais
            e ataques cibernéticos.
        </alert-compact>
        <tabs-pages :tabs="tabs">
            <slot></slot>
        </tabs-pages>
    </div>
</template>

<script>
    import AlertCompact from "@/components/atoms/AlertCompact.vue";
    import TabsPages from '@/components/organisms/TabsPages';

    export default {
        props: ['resource'],
        components: {
            AlertCompact,
            TabsPages
        },
        data() {
            return {
                tabs: [
                    {
                        title: 'Gerenciador',
                        link: `/hosting/${this.resource}/files`,
                    },
                    {
                        title: 'FTP',
                        link: `/hosting/${this.resource}/ftp`,
                    },
                    {
                        title: 'Deploy',
                        link: `/hosting/${this.resource}/deploy`,
                    },
                    {
                        title: 'Backup',
                        link: `/hosting/${this.resource}/backup`,
                    }
                ]
            }
        }
    }
</script>