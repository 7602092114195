<template>
    <alert-compact
        variant="warning"
        title="Não deixe suas hospedagens ficarem suspensas!"
        btn-text="Ativar"
        btn-link="/payment/auto-recharge"
        icon-class="fas fa-window-close"
        show-ignore
        :on-ignore="hideAutoRechargeAlert"
    >
        Ative a opção de recarga automática e mantenha seus serviços sempre ativos.
    </alert-compact>
</template>

<script>
    import AlertCompact from "@/components/atoms/AlertCompact.vue";

    export default {
        components: {
            AlertCompact
        },
        methods:
        {
            hideAutoRechargeAlert()
            {
                sessionStorage.setItem("hide_auto_recharge_alert", "1");

                const postData = {
                    preferences: {
                        hide_auto_recharge_alert: true,
                    }
                };

                this.$api.post("/user/preferences", postData);
            }
        }
    }
</script>
