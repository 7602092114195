const state = {
    counter: 0
};

const actions = {
    show({ commit }) {
        commit('show');
    },
    hide({ commit }) {
        commit('hide');
    }
};

const mutations = {
    show(state) {
        state.counter++;
    },
    hide(state) {
        state.counter--;
    }
};

export const loading = {
    namespaced: true,
    state,
    actions,
    mutations
}