<template>
    <span class="h-a-icon-hosting-status" v-bind:class="`h-status-${status}`">
        <i v-bind:class="getIconClasses"></i>
    </span>
</template>

<script>
    export default {
        name: "IconHostingStatus",
        props: {
            status: {
                type: String,
                default: "active"
            }
        },
        computed: {
            getIconClasses: function()
            {
                let _classes = "";

                switch (this.status) {
                    case "active":    _classes = "fas fa-circle"; break;
                    case "paused":    _classes = "fas fa-pause"; break;
                    case "suspended": _classes = "fas fa-stop"; break;
                    default:          _classes = "fas fa-circle";
                }

                return _classes;
            }
        }
    }
</script>

<style lang="stylus">
    .h-a-icon-hosting-status
        color $gray-500

        &.h-status-active
            color $green

        &.h-status-paused
            color $yellow

        &.h-status-suspended
            color $red
</style>