import Vue         from 'vue'
import * as Sentry from '@sentry/browser'
import { Api }     from '@/plugins/api'

/**
 * Fazer login de um usuário.
 */
function login(email, password, captcha, code2fa)
{
    const data = {
        email: email,
        password: password,
        captcha: captcha,
        code2fa: code2fa
    };

    return Api.post('/login', data)
        .then((response) => {
            const token      = response.data.data.token;
            const expiration = parseInt(response.data.data.expiration) * 1000;

            if (token && expiration)
            {
                let options = {
                    expires: new Date(expiration),
                    secure: true,
                    samesite: 'strict',
                };

                if (process.env.NODE_ENV === 'production') {
                    options.domain = '.hostoo.io';
                }

                Vue.cookie.set('access_token', token, options);
            }

            return Promise.resolve(response);
        })
}

/**
 * Carregar informações do usuário.
 */
function get()
{
    return Api.get('/user')
        .then((response) => {
            const user = response.data.data;

            // Google Analytics USER-ID
            if (typeof window.gtag !== 'undefined')
            {
                gtag('set', { 'user_id': user.id });

                if (typeof window.GA4_MEASUREMENT_ID !== 'undefined') {
                    gtag('config', window.GA4_MEASUREMENT_ID, { 'user_id': user.id });
                }
            }

            // https://docs.sentry.io/enriching-error-data/context/?platform=node#capturing-the-user
            Sentry.configureScope((scope) => {
                scope.setUser({ 'email': user.email });
            });

            return user;
        })
}

/**
 * Carregar informações sobre créditos do usuário.
 */
function getCredits()
{
    return Api.get('/user/credits').then(response => response.data.data);
}

/**
 * Obter dados do programa de indicações do usuário.
 */
function getReferral()
{
    return Api.get('/referral').then(response => response.data.data);
}

function getPromotion()
{
    return Api.get('/user/promotion').then(response => response.data.data);
}

function trackEvent(data)
{
    try {
        Api.post('/user/event', data).then(() => {});
    }
    catch(e) { /* ignore */ }
}

/**
 * Limpar sessão e deslogar o usuário atual.
 */
function logout()
{
    try {
        const token = Vue.cookie.get('access_token');

        if (token)
        {
            const options = {};

            if (process.env.NODE_ENV === 'production') {
                options.domain = '.hostoo.io';
            }

            Api.post('/logout').catch(error => {});

            setTimeout(() => { Vue.cookie.delete('access_token', options); }, 100);
        }

        // https://docs.sentry.io/enriching-error-data/scopes/?platform=node#configuring-the-scope
        Sentry.configureScope(scope => scope.setUser(null));
    }
    catch(e) { /* ignore */ }

    sessionStorage.clear();
}

export default {
    login,
    logout,
    get,
    getCredits,
    getReferral,
    getPromotion,
    trackEvent
}
