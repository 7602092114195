<template>
    <span class="htm-text-copy ht-text-copy-wrapper">
        <span class="ht-text-copy-content" v-bind:class="textClass" @click="selectText" v-html="text"></span>
        <button type="button" ref="btnCopy" class="btn btn-link ht-btn-copy" @click="copyText">
            {{ labelCopy }}
        </button>
    </span>
</template>

<script>
    export default {
        props: {
            text: {
                type: [String, Number],
                default: "",
            },
            copy: {
                type: [String, Number],
                default: "",
            },
            textClass: {
                type: [String],
                default: "",
            }
        },
        data() {
            return {
                labelCopy: "Copiar",
                labelCopied: "Copiado",
                labelChangeDelay: 3000
            }
        },
        methods: {
            /**
             * Evento de clique no texto.
             * Deixa o texto selecionado.
             */
            selectText(e)
            {
                const copyTextElm = e.target.parentElement.querySelector(".ht-text-copy-content");

                if (copyTextElm) {
                    let selection = window.getSelection();
                    let range = document.createRange();

                    range.selectNodeContents(copyTextElm);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            },

            /**
             * Evento de clique no botão de copiar.
             * Copia o texto para a área de transferência.
             */
            copyText(e)
            {
                if (this.copy) {
                    this._copyHiddenElement(e);
                }
                else {
                    this._copyTextElement(e);
                }
            },

            _copyTextElement(e)
            {
                const copyTextElm = e.target.parentElement.querySelector(".ht-text-copy-content");

                if (copyTextElm) {
                    let selection = window.getSelection();
                    let range = document.createRange();

                    range.selectNodeContents(copyTextElm);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand("copy");

                    this._changeLabel(e);
                }
            },

            _copyHiddenElement(event)
            {
                let inputWrapper = document.createElement("div");
                let auxInput = document.createElement("input");

                auxInput.type = "text";
                auxInput.value = this.copy;
                auxInput.style.opacity = "0";

                inputWrapper.appendChild(auxInput);
                document.body.appendChild(inputWrapper);

                let selection = window.getSelection();
                selection.removeAllRanges();

                const wX = window.scrollX, wY = window.scrollY;

                auxInput.focus();
                window.scrollTo(wX, wY); // https://stackoverflow.com/a/6610501

                auxInput.setSelectionRange(0, auxInput.value.length);
                document.execCommand("copy");

                document.body.removeChild(inputWrapper);

                this._changeLabel(event);
            },

            _changeLabel()
            {
                this.$refs.btnCopy.innerHTML = this.labelCopied;
                this.$refs.btnCopy.classList.add("text-success");

                setTimeout(
                    () => {
                        if (this.$refs.btnCopy) {
                            this.$refs.btnCopy.innerHTML = this.labelCopy;
                            this.$refs.btnCopy.classList.remove("text-success");
                        }
                    },
                    this.labelChangeDelay
                );
            }
        }
    }
</script>

<style lang="stylus">
    .htm-text-copy
        position relative

        .ht-btn-copy
            absolute top 50% left 100%
            transform translateY(-50%)
            margin-top .125em
            z-index 2
            padding 0 0 0 .75rem
            border 0
            font-size 12px
            font-weight 800
            text-decoration none
            text-transform uppercase
            white-space nowrap
            color $primary
            visibility hidden
            opacity 0
            transition opacity .2s ease

            &:before
                display inline-block
                vertical-align middle
                margin-top -.125em
                padding-right .25em
                font-size 1.25em
                font-family "Font Awesome 5 Free"
                font-weight 400
                content "\f0c5"

            &.text-success:before
                font-weight 900
                content "\f00c"

    .htm-text-copy > .ht-btn-copy:hover,
    .ht-text-copy-wrapper:hover .ht-btn-copy
        visibility visible
        opacity 1
</style>