import Domain from '@/services/domain.service'

const state = {
    domain: "",
    data: null,
    isLoadingData: false,
}

const actions =
{
    async get({ state, commit }, { domain, force })
    {
        if (!force && domain === state.domain && state.data) {
            return state.data;
        }

        commit('fetchDataStarted');

        try {
            const data = await Domain.get(domain);
            commit('fetchDataSuccess', data);
            return data;
        }
        catch (error) {
            commit('fetchDataFailed');
            throw error;
        }
    }
}

const mutations =
{
    fetchDataStarted(state) {
        state.isLoadingData = true;
    },
    fetchDataSuccess(state, data) {
        state.data = data;
        state.domain = data.domain;
        state.isLoadingData = false;
    },
    fetchDataFailed(state) {
        state.data = null;
        state.domain = "";
        state.isLoadingData = false;
    }
}

export const domain = {
    namespaced: true,
    state,
    actions,
    mutations
}