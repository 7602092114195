<template>
    <hosting-settings-template :resource="resource">
        <card-title inner-body>
            <h4 class="card-title-inner">Certificado SSL</h4>
            <p class="mb-6">
                O certificado SSL da <a href="https://letsencrypt.org/" target="_blank" rel="nofollow">Let's Encrypt</a>
                é emitido <strong>gratuitamente</strong> pela Hostoo e sua renovação é realizada automaticamente pelo 
                nosso sistema. O certificado é válido para o domínio principal da hospedagem e para os subdomínios.
            </p>

            <text-loading v-show="isLoadingSsl" />

            <div v-if="ssl">
                <div v-if="ssl.checking" class="alert alert-primary mb-0">
                    O certificado SSL está sendo instalado em sua hospedagem e estará ativo dentro de alguns minutos.
                </div>
                <div v-else>
                    <div class="alert alert-success mb-0" v-if="ssl.active">
                        O certificado SSL está habilitado em sua hospedagem!
                    </div>
                    <div v-else>
                        <div class="alert alert-danger">
                            Não foi possível ativar o certificado SSL da Let's Encrypt.<br>
                            Certifique-se de que o domínio <strong>{{ resource }}</strong> está registrado e configurado
                            corretamente.<br> Os servidores DNS recomendados para sua hospedagem são exibidos na aba
                            <router-link :to="'/hosting/' + resource + '/domain'"><strong>Domínio</strong></router-link>&nbsp;
                            <router-link :to="'/hosting/' + resource + '/domain'"><i class="fas fa-external-link-alt" aria-hidden="true"></i></router-link>.
                        </div>
                        <div class="form-buttons">
                            <button-form ref="btnEnableSsl" variant="success" @click="enableSsl">Ativar certificado SSL</button-form>
                        </div>
                    </div>
                </div>
            </div>
        </card-title>

        <card-title inner-body>
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-grow-1">
                    <h4 class="card-title-inner">Sempre usar HTTPS</h4>
                </div>
                <div class="pl-3" v-show="isLoadingHttpsRedirect">
                    <i class="fas fa-circle-notch fa-spin text-muted"></i>
                </div>
                <div class="pl-3">
                    <toggle-switch v-model="httpsRedirect" :callback="updateHttpsRedirect" :disabled="isLoadingHttpsRedirect" />
                </div>
            </div>

            <p class="mb-0">
                Redirecionar todas as requisições do seu domínio com o protocolo HTTP para HTTPS.
            </p>

            <div class="alert alert-warning mt-4 mb-0" v-if="httpsRedirect && !isLoadingHttpsRedirect">
                <strong>Aviso:</strong> Esta função pode causar problemas de redirecionamento se houver conflitos com as
                configurações de sua aplicação.
            </div>
        </card-title>
    </hosting-settings-template>
</template>

<script>
    import { mapActions }          from 'vuex'
    import AjaxService             from '@/services/AjaxService'
    import ToggleSwitch            from '@/components/atoms/ToggleSwitch'
    import CardTitle               from '@/components/molecules/CardTitle'
    import HostingSettingsTemplate from '@/components/templates/HostingSettingsTemplate'
    import TextLoading from '@/components/atoms/TextLoading'
    import ButtonForm from '@/components/atoms/ButtonForm'

    export default {
        props: ['resource'],
        components: {
            ButtonForm,
            TextLoading,
            ToggleSwitch,
            CardTitle,
            HostingSettingsTemplate
        },
        data() {
            return {
                ssl: null,
                httpsRedirect: false,
                isLoadingSsl: false,
                isLoadingHttpsRedirect: false
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.fetchSsl();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert:      'alert/error',
                showSuccessAlert:    'alert/success',
            }),

            /**
             * Obter informações do certificado SSL da hospedagem.
             */
            fetchSsl()
            {
                this.ssl = null;
                this.httpsRedirect = false;
                this.isLoadingSsl = this.isLoadingHttpsRedirect = true;

                this.$api.get(`/hosting/${this.resource}/ssl`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.ssl = response.data.data;
                        this.httpsRedirect = this.ssl.redirect;
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingSsl = this.isLoadingHttpsRedirect = false);
            },

            /**
             * Executar função de habilitar o certificado SSL manualmente.
             */
            enableSsl()
            {
                this.$refs.btnEnableSsl.loadingFocus();

                this.$api.post(`/hosting/${this.resource}/ssl/enable`)
                    .then (response => this.fetchSsl())
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.btnEnableSsl.setLoading(false);
                    });
            },

            /**
             * Atualizar opção de redirecionamento para HTTPS.
             */
            updateHttpsRedirect()
            {
                const newValue = this.httpsRedirect;

                this.isLoadingHttpsRedirect = true;

                this.$api.patch(`/hosting/${this.resource}/ssl/redirect`, { value: newValue })
                    .then (response => {
                        this.httpsRedirect = response.data.data.value;
                        this.successNotification({ message: 'Configuração atualizada!' });
                    })
                    .catch(error => {
                        this.httpsRedirect = !newValue;
                        this.showErrorAlert(error);
                    })
                    .then (() => this.isLoadingHttpsRedirect = false);
            },
        }
    }
</script>