<template>
    <card-table
        ref="cardHostings"
        class="h-o-card-hosting"
        title="Minhas Hospedagens"
        hide-add
        hide-edit
        hide-remove
        :toolbar-actions="cardHostingsActions"
        :table-fields="tableHostingsFields"
        :table-data="hostings"
        :table-loading="isLoadingHostings"
        table-max-height="calc((5 * (4rem + 1px)))"
        table-empty-text="Nenhuma hospedagem encontrada"
    >
        <template slot="toolbar-before">
            <div class="input-group">
                <button type="button" class="btn btn-outline-secondary ml-2" title="Filtros" @click="toggleFilters">
                    <i class="fas fa-filter"></i>
                </button>
            </div>
        </template>

        <template slot="toolbar-after">
            <div class="w-100"></div>

            <div v-show="search.isVisible">
                <div class="input-group mt-2">
                    <b-form-select v-model="search.server" :options="servers" @change="updateFilters">
                        <template v-slot:first>
                            <b-form-select-option :value="null">Servidor</b-form-select-option>
                        </template>
                    </b-form-select>
                </div>
            </div>
        </template>

        <template slot="table-status" slot-scope="{ rowData }">
            <icon-hosting-status :status="rowData.status" :title="rowData.statusText" />
        </template>

        <template slot="table-domain" slot-scope="{ rowData }">
            <div class="domain-name text-truncate">
                <router-link :to="`/hosting/${rowData.domain}`">{{ rowData.domain }}</router-link>
            </div>
            <div v-if="rowData.plan" class="domain-info">
                <span class="domain-info-plan">{{ rowData.plan.name }}</span>
                <span class="text-muted d-none d-sm-inline"> - {{ formatPlanInfo(rowData.plan) }}</span>
            </div>
        </template>

        <template slot="dropdown-before" slot-scope="{ rowData }">
            <b-dropdown-item v-if="!rowData.isGuest && ['active','paused'].indexOf(rowData.status) !== -1" :to="`/hosting/${rowData.domain}/plan`">
                <i class="fas fa-sign-out-alt fa-fw" style="transform: rotate(270deg) translateX(1px)"></i> Alterar plano
            </b-dropdown-item>
            <b-dropdown-item-button v-if="!rowData.isGuest && rowData.paused" @click="unpauseHosting(rowData)">
                <i class="fas fa-play fa-fw"></i> Reativar
            </b-dropdown-item-button>
            <b-dropdown-item-button v-if="!rowData.isGuest && rowData.status === 'active'" @click="pauseHosting(rowData)">
                <i class="fas fa-pause fa-fw"></i> Pausar
            </b-dropdown-item-button>
            <b-dropdown-item :href="`http://${rowData.domain}`" target="_blank">
                <i class="fas fa-external-link-alt fa-fw"></i> Acessar
            </b-dropdown-item>
            <b-dropdown-divider v-if="!rowData.isGuest && ['active','paused'].indexOf(rowData.status) !== -1" />
            <b-dropdown-item v-if="!rowData.isGuest" variant="danger" :to="`/hosting/${rowData.domain}/remove`">
                <i class="far fa-trash-alt fa-fw"></i> Excluir
            </b-dropdown-item>
        </template>
    </card-table>
</template>

<script>
    import { mapActions }    from 'vuex'
    import { ApiCancel }     from '@/plugins/api'
    import hostingActions    from '@/mixins/hostingActions'
    import CardTable         from '@/components/organisms/CardTable'
    import IconHostingStatus from '@/components/atoms/IconHostingStatus'

    export default {
        mixins: [
            hostingActions
        ],
        components: {
            IconHostingStatus,
            CardTable,
        },
        data() {
            return {
                cardHostingsActions: [
                    {
                        text: 'Criar nova hospedagem',
                        variant: 'success',
                        link: '/hosting/add'
                    }
                ],
                tableHostingsFields: [
                    {
                        name: '__slot:status',
                        sortField: 'status',
                        sortFunction: 'text',
                        title: '<span class="sr-only">Status</span>',
                        titleClass: 'col-status col-w-12',
                        dataClass: 'col-status col-w-12',
                    },
                    {
                        name: '__slot:domain',
                        sortField: 'domain',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Domínio',
                        dataClass: 'col-domain',
                    }
                ],

                plans: [],
                servers: [],
                hostings: [],
                hostingsUnfiltered: [],

                // Search
                search: {
                    isVisible: false,
                    server: null,
                },

                cancelToken: null,
                isLoadingHostings: false
            }
        },
        mounted()
        {
            this.fetchData();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            formatPlanInfo(data)
            {
                return data.cpu + ' / ' + data.ram + ' / ' + data.disk + ' Disco';
            },

            /**
             * Listar planos de hospedagem.
             */
            fetchHostingPlans()
            {
                return this.$api.get('/hosting/plans', { cancelToken: this.getCancelToken() })
                    .then(response => response.data.data);
            },

            /**
             * Listar hospedagens.
             */
            fetchHostings()
            {
                return this.$api.get('/hosting?plan=1', { cancelToken: this.getCancelToken() })
                    .then(response => response.data.data);
            },

            /**
             * Carregar dados necessários.
             */
            fetchData()
            {
                this.isLoadingHostings = true;

                this.fetchHostingPlans()
                    .then(plans => {
                        this.plans = plans;
                        return this.fetchHostings();
                    })
                    .then(hostings => {
                        let i, servers = [];

                        for (i = 0; i < hostings.length; i++) {
                            hostings[i].status = hostings[i].status.toLowerCase();
                            hostings[i].plan   = this.plans.find(el => el.name === hostings[i].plan);

                            if (servers.indexOf(hostings[i].server) < 0) {
                                servers.push(hostings[i].server);
                            }
                        }

                        this.hostingsUnfiltered = this.hostings = hostings;

                        servers.sort();
                        this.servers = servers.map(item => { return { value: item, text: item } });
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingHostings = false);
            },

            /**
             * Gerar token para permitir cancelar o carregamento dos dados.
             */
            getCancelToken()
            {
                if (!this.cancelToken) {
                    this.cancelToken = ApiCancel.CancelToken.source();
                }

                return this.cancelToken.token;
            },

            /**
             * Cancelar o carregamento dos dados.
             */
            cancelFetch()
            {
                if (this.cancelToken) {
                    this.cancelToken.cancel();
                }
            },

            toggleFilters()
            {
                this.search.isVisible = !this.search.isVisible;
                this.updateFilters();
            },

            updateFilters()
            {
                let hostings = this.hostingsUnfiltered;

                if (this.search.isVisible)
                {
                    if (this.search.server) {
                        hostings = hostings.filter(item => item.server === this.search.server);
                    }
                }

                this.hostings = hostings;
            }
        }
    }
</script>

<style lang="stylus">
    .h-o-card-hosting
        .table
            .col-status
                @media (max-width 575px)
                    min-width 0px !important
                    width 2rem !important

            td.col-status
                padding-right 0
                vertical-align middle
                text-align center

            td.col-domain
                line-height 1.375

                .domain-name
                    @media (min-width 576px)
                        font-size 1.125rem

                .domain-info
                    font-size 12px
                    font-weight 600

                .domain-info-plan
                    font-weight 700
                    color $gray-600
</style>