<template>
    <admin-page-template>
        <card-title title="Migre os seus sites gratuitamente!">
            <template slot="intro-text">
                Solicite a migração do seu site hospedado em outra empresa para a sua conta na Hostoo. Basta informar
                os dados de acesso ao painel de sua hospedagem anterior que nossa equipe se encarregará de realizar
                a sua migração. Se possuir vários sites hospedados na mesma empresa, basta informar todos os domínios
                que deseja migrar.
            </template>

            <div v-show="isFormSent">
                <p>
                    <strong>Sua solicitação de migração foi recebida!</strong><br>
                    Agora basta aguardar que nossa equipe de migração cuidará do restante.
                </p>

                <div class="mt-6">
                    <button
                        type="button"
                        ref="btnReset"
                        class="btn btn-secondary btn-action"
                        @click="resetFormMigration">Nova solicitação de migração</button>
                </div>
            </div>

            <form v-if="!isFormSent" @submit.prevent="requestMigration">
                <form-group label="Domínio(s)" label-for="migrationDomains" :error="formErrors.domains">
                    <textarea
                        class="form-control"
                        rows="2"
                        placeholder="Informe o(s) domínio(s) que deseja migrar para a Hostoo..."
                        spellcheck="false"
                        v-model="formData.domains">
                    </textarea>
                </form-group>

                <form-group label="Link do painel de hospedagem" label-for="migrationUrl" :error="formErrors.url">
                    <input
                        type="text"
                        id="migrationUrl"
                        class="form-control"
                        placeholder="https://"
                        v-model="formData.url">
                </form-group>

                <form-group label="Dados de acesso" label-for="migrationExtra" :error="formErrors.extra">
                    <textarea
                        class="form-control"
                        rows="5"
                        placeholder="Informe aqui dados de acesso ao painel de sua hospedagem, acessos FTP, SSH, bancos de dados, contas de e-mail e outros que considerar relevante para a migração..."
                        spellcheck="false"
                        v-model="formData.extra">
                    </textarea>
                </form-group>

                <div class="mt-6">
                    <button-form ref="btnMigration" type="submit" theme="success" class="btn-lg btn-block">Solicitar migração</button-form>
                </div>
            </form>
        </card-title>
    </admin-page-template>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ButtonForm from '@/components/atoms/ButtonForm'
    import CardTitle from '@/components/molecules/CardTitle'
    import FormGroup from '@/components/molecules/FormGroup'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'
    import ErrorService from '@/services/ErrorService'

    export default {
        components: {
            ButtonForm,
            FormGroup,
            CardTitle,
            AdminPageTemplate,
        },
        data() {
            return {
                formData: {},
                formErrors: {},
                isFormSent: false,
            }
        },
        computed: {
            ...mapState('user', ['user', 'isLoadingUser']),
        },
        created()
        {
            if (this.$route.query.domain) {
                this.formData.domains = this.$route.query.domain;
            }

            this.fetchUser();
        },
        methods: {
            ...mapActions({
                fetchUser: 'user/fetchUser',
            }),

            requestMigration()
            {
                this.formErrors = {};
                this.$refs.btnMigration.loadingFocus();

                const userData = {
                    email: this.user.email,
                    name:  this.user.name,
                    phone: this.user.phone,
                };

                let postData = Object.assign(userData, this.formData);

                this.$api.post('/migration', postData)
                    .then (response => {
                        this.isFormSent = true;
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnMigration.setLoading(false);
                    });
            },

            resetFormMigration()
            {
                this.formData = {};
                this.formErrors = {};
                this.isFormSent = false;
            }
        }
    }
</script>
