<template>
    <div class="hto-tabs-pages">
        <nav-pages :tabs="tabs"></nav-pages>
        <slot></slot>
    </div>
</template>

<script>
    import NavPages from "../molecules/NavPages"

    export default {
        props: {
            tabs: Array,
        },
        components: {
            NavPages
        }
    }
</script>