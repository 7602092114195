<template>
    <div class="h-o-form-address">
        <div class="row mb-md-4">
            <div v-bind:class="[zipcodeLine ? 'col-md-6' : 'col-md-3']">
                <form-group label="CEP" label-for="addressZip" v-bind:class="{ 'mb-md-0': !zipcodeLine }" :error="errors.addressZip">
                    <zip-code id="addressZip" :disabled="isZipCodeDisabled" v-model="formData.addressZip" @ready="_updateAddress" @cancel="_updateAddress" />
                </form-group>
            </div>
            <div v-bind:class="[zipcodeLine ? 'col-md-12' : 'col-md-9']">
                <div class="row">
                    <div class="col-8 col-sm-9">
                        <form-group label="Endereço" label-for="addressStreet" class="mb-md-0" :error="errors.addressStreet">
                            <input type="text" id="addressStreet" class="form-control" autocomplete="street-address" v-model="formData.addressStreet">
                        </form-group>
                    </div>
                    <div class="col-4 col-sm-3">
                        <form-group label="Número" label-for="addressNumber" class="mb-md-0" :error="errors.addressNumber">
                            <input type="text" id="addressNumber" class="form-control" v-model="formData.addressNumber">
                        </form-group>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <form-group label="Complemento" label-for="addressComplement" :error="errors.addressComplement">
                    <input type="text" id="addressComplement" class="form-control" v-model="formData.addressComplement">
                </form-group>
            </div>
            <div class="col-md-6">
                <form-group label="Bairro" label-for="addressNeighborhood" :error="errors.addressNeighborhood">
                    <input type="text" id="addressNeighborhood" class="form-control" autocomplete="address-level3" v-model="formData.addressNeighborhood">
                </form-group>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-md-6">
                <form-group label="Cidade" label-for="addressCity" class="mb-md-0" :error="errors.addressCity">
                    <input type="text" id="addressCity" class="form-control" autocomplete="address-level2"
                           v-model="formData.addressCity"
                           v-bind:class="{ 'text-muted': isCityStateDisabled }"
                           :disabled="isCityStateDisabled">
                </form-group>
            </div>
            <div class="col-md-6">
                <form-group label="Estado" label-for="addressState" class="mb-0" :error="errors.addressState">
                    <b-select id="addressState" autocomplete="address-level1" v-model="formData.addressState" :disabled="isCityStateDisabled">
                        <b-form-select-option :value="null"></b-form-select-option>
                        <b-form-select-option value="AC">Acre</b-form-select-option>
                        <b-form-select-option value="AL">Alagoas</b-form-select-option>
                        <b-form-select-option value="AP">Amapá</b-form-select-option>
                        <b-form-select-option value="AM">Amazonas</b-form-select-option>
                        <b-form-select-option value="BA">Bahia </b-form-select-option>
                        <b-form-select-option value="CE">Ceará</b-form-select-option>
                        <b-form-select-option value="DF">Distrito Federal </b-form-select-option>
                        <b-form-select-option value="ES">Espírito Santo</b-form-select-option>
                        <b-form-select-option value="GO">Goiás</b-form-select-option>
                        <b-form-select-option value="MA">Maranhão</b-form-select-option>
                        <b-form-select-option value="MT">Mato Grosso</b-form-select-option>
                        <b-form-select-option value="MS">Mato Grosso do Sul</b-form-select-option>
                        <b-form-select-option value="MG">Minas Gerais</b-form-select-option>
                        <b-form-select-option value="PA">Pará</b-form-select-option>
                        <b-form-select-option value="PB">Paraíba</b-form-select-option>
                        <b-form-select-option value="PR">Paraná</b-form-select-option>
                        <b-form-select-option value="PE">Pernambuco</b-form-select-option>
                        <b-form-select-option value="PI">Piauí</b-form-select-option>
                        <b-form-select-option value="RJ">Rio de Janeiro</b-form-select-option>
                        <b-form-select-option value="RN">Rio Grande do Norte</b-form-select-option>
                        <b-form-select-option value="RS">Rio Grande do Sul</b-form-select-option>
                        <b-form-select-option value="RO">Rondônia</b-form-select-option>
                        <b-form-select-option value="RR">Roraima</b-form-select-option>
                        <b-form-select-option value="SC">Santa Catarina</b-form-select-option>
                        <b-form-select-option value="SP">São Paulo</b-form-select-option>
                        <b-form-select-option value="SE">Sergipe</b-form-select-option>
                        <b-form-select-option value="TO">Tocantins</b-form-select-option>
                    </b-select>
                </form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import ZipCode      from '@/components/atoms/ZipCode'
    import FormGroup    from '@/components/molecules/FormGroup'

    export default {
        name: "FormAddress",
        props: {
            value: {
                type: Object,
                default: () => {},
            },
            errors: {
                type: Object,
                default: () => {},
            },
            zipcodeLine: {
                type: Boolean,
                default: false
            }
        },
        components: {
            FormGroup,
            ZipCode
        },
        data() {
            return {
                formData: {
                    addressZip: "",
                    addressStreet: "",
                    addressNumber: "",
                    addressComplement: "",
                    addressNeighborhood: "",
                    addressCity: "",
                    addressState: "",
                },
                isZipCodeDisabled: false,
                isCityStateDisabled: false,
            }
        },
        methods: {
            /**
             * Atualizar dados de endereço a partir do CEP.
             * @param {Object} data
             */
            _updateAddress(data)
            {
                if (data)
                {
                    this.$set(this.formData, 'addressStreet', data.street);
                    this.$set(this.formData, 'addressComplement', data.complement);
                    this.$set(this.formData, 'addressNeighborhood', data.neighborhood);
                    this.$set(this.formData, 'addressCity', data.city);
                    this.$set(this.formData, 'addressState', data.state);
                    this.isCityStateDisabled = true;
                }
                else
                {
                    this.$set(this.formData, 'addressCity', '');
                    this.$set(this.formData, 'addressState', '');
                    this.isCityStateDisabled = false;
                }
            },

            /**
             * Update address data.
             *
             * @param {Object} data
             */
            setData(data)
            {
                this.isZipCodeDisabled = true;

                this.$set(this.formData, 'addressZip', data.addressZip);
                this.$set(this.formData, 'addressStreet', data.addressStreet);
                this.$set(this.formData, 'addressNumber', data.addressNumber);
                this.$set(this.formData, 'addressNeighborhood', data.addressNeighborhood);

                if (data.addressCity && data.addressState)
                {
                    this.$set(this.formData, 'addressCity', data.addressCity);
                    this.$set(this.formData, 'addressState', data.addressState);
                    this.isCityStateDisabled = true;
                }

                setTimeout(() => this.isZipCodeDisabled = false, 100);
            }
        },
        watch: {
            formData: {
                deep: true,
                handler: function (val) {
                    this.$emit('input', val);
                },
            }
        }
    }
</script>
