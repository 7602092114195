import inputLowercase from './_input-lowercase'
import inputUppercase from './_input-uppercase'
import onlyDigits     from './_only-digits'

function install(Vue)
{
    Vue.directive('inputLowercase', inputLowercase);
    Vue.directive('inputUppercase', inputUppercase);
    Vue.directive('onlyDigits', onlyDigits);
}

export default install
