<template>
    <card-title title="Alterar Domínio Principal">
        <p class="mb-6">
            Com este recurso você pode mudar o <strong>domínio principal</strong> da sua hospedagem para um outro que ainda não esteja
            sendo utilizado em sua conta ou por outro usuário da Hostoo.
        </p>

        <div class="alert alert-warning">
            <strong>Atenção:</strong> Ao alterar o domínio principal, todos os subdomínios e contas de e-mail também
            serão alterados para o novo domínio. Além disso, seu site ou aplicação pode precisar ser configurado para
            utilizar o novo domínio.
        </div>

        <form accept-charset="UTF-8" @submit.prevent="editDomain">
            <form-group label="Domínio principal" label-for="mainDomain" :error="formErrors.domain">
                <input type="text" class="form-control form-control-xl" autocomplete="off" spellcheck="false" v-model="mainDomain" v-input-lowercase />
            </form-group>

            <div v-show="showWaitAlert" class="alert alert-warning">
                <i class="far fa-clock mr-1"></i>
                Por favor, aguarde! A alteração de domínio pode demorar alguns minutos&hellip;
            </div>

            <div class="form-buttons text-right">
                <button-form ref="btnEditDomain" type="submit" theme="success" class="btn-action">Alterar</button-form>
            </div>
        </form>
    </card-title>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ButtonForm   from '@/components/atoms/ButtonForm'
    import CardTitle    from '@/components/molecules/CardTitle'
    import FormGroup    from '@/components/molecules/FormGroup'
    import ErrorService from '@/services/error.service'

    export default {
        props: ['resource'],
        components: {
            ButtonForm,
            FormGroup,
            CardTitle,
        },
        data() {
            return {
                mainDomain: "",
                formErrors: {},
                isRunning: false,
                showWaitAlert: false,
            }
        },
        computed: {
            ...mapState('hosting', ['hosting'])
        },
        mounted()
        {
            this.mainDomain = this.hosting.domain;
        },
        beforeRouteLeave(to, from, next)
        {
            if (this.isRunning && !confirm('Existe um processo em execução no momento. Tem certeza que deseja sair desta página?')) return;
            next();
        },
        methods: {
            ...mapActions({
                showLoading: 'loading/show',
            }),

            /**
             * Editar domínio principal da hospedagem.
             */
            editDomain()
            {
                this.isRunning = true;
                this.$refs.btnEditDomain.setLoadingAndFocus();

                this.$api.post(`/hosting/${this.resource}/domain`, { 'domain': this.mainDomain })
                    .then (response => {
                        this.showLoading();
                        window.location.href = '/hosting/' + response.data.data.domain;
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnEditDomain.removeLoading();
                    })
                    .then(() => {
                        this.isRunning = false;
                    });
            }
        },
        watch: {
            mainDomain: function(val, old)
            {
                if (val !== old)
                {
                    this.mainDomain = val.replace(/[^0-9a-zA-Z-_\.]/g, '').replace(/^https?:?\/?\/?/g, '');
                    this.formErrors = {};
                }
            }
        }
    }
</script>
