<template>
    <div>
        <card-title title="Dados da Hospedagem" class="hto-card-hosting-info">
            <template slot="actions">
                <router-link
                    v-if="$acl.check('isOwner') && hosting"
                    class="btn btn-success"
                    :to="`/hosting/${hosting.domain}/plan`"
                >
                    Alterar plano
                </router-link>
            </template>

            <text-loading v-show="isLoading" />

            <div class="table-list-wrapper">
                <table class="table table-sm table-list mb-0" v-show="!isLoading">
                    <tbody>
                        <template v-if="plan">
                            <tr>
                                <th>Plano:</th>
                                <td class="text-right">
                                    {{ plan.name }}
                                    <span class="text-muted px-2">|</span> <!-- separator -->
                                    <small class="font-weight-semibold">
                                        <span title="Memória RAM">{{ plan.ram }}</span> /
                                        <span title="CPU">{{ plan.cpu }}</span> /
                                        <span title="Espaço em disco">{{ plan.disk }}</span>
                                    </small>
                                </td>
                            </tr>
                            <tr>
                                <th>Preço:</th>
                                <td class="text-right">
                                    R$ {{ plan.monthly.toFixed(2).replace('.', ',') }}
                                    <span class="text-muted px-2">|</span> <!-- separator -->
                                    <small class="font-weight-semibold" title="Custo por hora">
                                        R$ {{ plan.hourly.toFixed(4).replace('.', ',') }} / hora
                                    </small>
                                </td>
                            </tr>
                            <tr v-if="hosting.region">
                                <th>Região:</th>
                                <td class="text-right d-flex align-items-center justify-content-end">
                                    <img :src="`/images/${hosting.region}.svg`" class="icon mr-2" :alt="hosting.regionName" />
                                    <span>{{ hosting.regionName }}</span>
                                </td>
                            </tr>
                        </template>
                        <template v-if="hosting">
                            <tr>
                                <th>Data de criação:</th>
                                <td class="text-right">{{ hosting.createdAt | moment("DD/MM/YYYY") }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </card-title>

        <card-title title="Endereços" class="ht-card-hosting-addresses mb-0">
            <text-loading v-show="isLoading" />

            <div v-if="hosting" v-show="!isLoading">
                <div class="table-list-wrapper">
                    <table class="table table-sm table-list mb-0">
                        <tbody>
                            <tr>
                                <th>Principal:</th>
                                <td class="text-right">
                                    {{ hosting.domain }}
                                    <a :href="'http://' + hosting.domain" target="_blank">
                                        <i class="fas fa-external-link-alt ml-2" aria-hidden="true"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th>Alternativo:</th>
                                <td class="text-right">
                                    <span v-if="hosting.domainAlt" v-html="hosting.domainAlt"></span>
                                    <a v-if="hosting.domainAlt" :href="'http://' + hosting.domainAlt" target="_blank">
                                        <i class="fas fa-external-link-alt ml-2" aria-hidden="true"></i>
                                    </a>
                                    <span v-if="!hosting.domainAlt" class="text-muted">Desabilitado</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Servidor:</th>
                                <td class="text-right">{{ hosting.server }}</td>
                            </tr>
                            <tr>
                                <th>IP do servidor:</th>
                                <td class="text-right">{{ hosting.serverIp }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="!hosting.domainAlt" class="mt-4">
                    <button-form
                        ref="btnEnableAlternativeDomain"
                        theme="success"
                        class="btn-enable-alternative"
                        @click="enableAlternative"
                    >
                        Habilitar endereço alternativo
                    </button-form>
                </div>
            </div>
        </card-title>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import hostingActions from '@/mixins/hostingActions'
    import AjaxService    from '@/services/AjaxService'
    import ButtonForm     from '@/components/atoms/ButtonForm'
    import TextLoading    from '@/components/atoms/TextLoading'
    import CardTitle      from '@/components/molecules/CardTitle'

    export default {
        props: {
            hosting: {
                type: Object,
                default() {
                    return {}
                }
            },
            plan: Object,
            loading: Boolean
        },
        mixins: [
            hostingActions
        ],
        components: {
            ButtonForm,
            TextLoading,
            CardTitle
        },
        data() {
            return {
                isLoading: this.loading,
                enableAlternativeTimeout: null,
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.enableAlternativeTimeout && clearTimeout(this.enableAlternativeTimeout);
            AjaxService.cancel();
            next();
        },
        methods: {
            ...mapActions({
                showLoading: 'loading/show',
                hideLoading: 'loading/hide',
                successNotification: 'notification/success',
                showErrorAlert: 'alert/error'
            }),

            /**
             * Ativar o domínio alternativo.
             */
            enableAlternative()
            {
                this.$refs.btnEnableAlternativeDomain.loadingFocus();

                this.$api.post(`/hosting/${this.hosting.domain}/enable-alternative`, { cancelToken: AjaxService.getCancelToken() })
                    .then(response => {
                        if (response.status === 202) {
                            this.enableAlternativeTimeout = setTimeout(() => this.enableAlternative(), 5000);
                        }
                        else {
                            const _message = response.data.message || 'O domínio alternativo foi ativado.';
                            this.successNotification({ message: _message });
                            this.enableAlternativeTimeout = setTimeout(() => window.location.reload(), 1000);
                        }
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.btnEnableAlternativeDomain && this.$refs.btnEnableAlternativeDomain.setLoading(false);
                    });
            }
        },
        watch: {
            loading: function (val) {
                this.isLoading = val;
            }
        }
    }
</script>

<style lang="stylus">
    .hto-card-hosting-info
        .table-wrapper
            @media (min-width 576px)
                margin-left -1.75rem
                margin-right -1.75rem

        .table .icon
            height 20px

        /*.table-list
            border 0

            tbody tr
                > th:first-child
                    padding-left 1.75rem

                > td:last-child
                    padding-right 1.75rem

            @media (max-width 575px)
                tbody
                    th, td
                        display block
                        width 100%
                        padding-left 1.75rem
                        padding-right 1.75rem

                    td.text-right
                        text-align left !important*/
</style>