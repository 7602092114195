<template>
    <div>
        <p class="text-xs text-muted text-uppercase font-weight-bold mb-3">
            Resumo
        </p>

        <p class="plan-name h5 font-weight-bold mb-3">
            {{ planName }}
            <img v-if="region === 'US'" src="/images/US.svg" class="icon" alt="" />
            <img v-if="region === 'BR'" src="/images/BR.svg" class="icon" alt="" />
        </p>

        <ul class="fa-ul mb-0">
            <li>
                <i class="fa-li fa fa-check text-primary"></i>
                <div class="d-flex justify-content-between">
                    <div>Hospedagem</div>
                    <div class="d-flex flex-row align-items-center font-weight-semibold pl-2">
                        <del v-if="oldPrice" class="text-xs text-muted mr-2 text-nowrap">
                            R$ {{ oldPrice | formatMoney(2,"") }}
                        </del>
                        <div class="text-currency text-primary">
                            <span class="currency">R$</span>
                            <span class="amount">
                                {{ planPrice | formatMoney(2,"") }}
                            </span>
                            <span class="period">/mês</span>
                        </div>
                    </div>
                </div>
            </li>
            <li v-if="accelerator">
                <i class="fa-li fa fa-check text-primary"></i>
                <div class="d-flex justify-content-between">
                    <div>Acelerador WP</div>
                    <div class="font-weight-semibold pl-2">
                        <div class="text-currency text-primary">
                            <span class="currency">R$</span>
                            <span class="amount">{{ acceleratorPrice | formatMoney(2,"") }}</span>
                            <span class="period">/mês</span>
                        </div>
                    </div>
                </div>
            </li>
            <li v-if="backup">
                <i class="fa-li fa fa-check text-primary"></i>
                <div class="d-flex justify-content-between">
                    <div>Backup Diário</div>
                    <div class="font-weight-semibold pl-2">
                        <div class="text-currency text-primary">
                            <span class="currency">R$</span>
                            <span class="amount">{{ backupPrice | formatMoney(2,"") }}</span>
                            <span class="period">/mês</span>
                        </div>
                    </div>
                </div>
            </li>
            <li v-if="migration">
                <i class="fa-li fa fa-check text-primary"></i>
                <div class="d-flex justify-content-between">
                    <div>Migração Prioritária</div>
                    <div class="font-weight-semibold pl-2">
                        <div class="text-currency text-primary">
                            <span class="currency">R$</span>
                            <span class="amount">{{ migrationPrice | formatMoney(2,"") }}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <hr v-if="subtotalAmount || discountAmount" class="spacer border-top mt-5 mb-4">

        <div v-if="subtotalAmount" class="d-flex justify-content-between">
            <p class="mb-0">
                Subtotal
            </p>
            <div class="text-currency text-muted font-weight-semibold">
                <span class="currency">R$</span>
                <del class="amount">{{ subtotalAmount | formatMoney(2,"") }}</del>
            </div>
        </div>

        <div v-if="discountAmount" class="d-flex justify-content-between mt-1">
            <p class="mb-0">
                Desconto<span v-if="discountPercent"> de {{ Math.round(discountPercent) }}%</span>
            </p>
            <div class="text-currency text-success font-weight-semibold">
                <span class="pr-1">&ndash;&nbsp;</span>
                <span class="currency">R$</span>
                <span class="amount">{{ discountAmount | formatMoney(2,"") }}</span>
            </div>
        </div>

        <hr v-if="totalAmount" class="spacer border-top mt-5 mb-4">

        <div v-if="totalAmount" class="d-flex justify-content-between">
            <p class="font-weight-bold mb-0">
                Total
            </p>
            <div class="text-currency font-weight-semibold">
                <span class="currency">R$</span>
                <span class="amount">{{ totalAmount | formatMoney(2,"") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            planName: {
                type: String,
                default: ""
            },
            planPrice: {
                type: Number,
                default: 0
            },
            oldPrice: {
                type: Number
            },
            region: {
                type: String,
                default: "BR"
            },
            acceleratorPrice: {
                type: Number,
                default: 0
            },
            backupPrice: {
                type: Number,
                default: 0
            },
            migrationPrice: {
                type: Number,
                default: 0
            },
            accelerator: {
                type: Boolean,
                default: false
            },
            backup: {
                type: Boolean,
                default: false
            },
            migration: {
                type: Boolean,
                default: false
            },
            subtotalAmount: {
                type: Number,
                default: 0
            },
            discountPercent: {
                type: Number,
                default: 0
            },
            discountAmount: {
                type: Number,
                default: 0
            },
            totalAmount: {
                type: Number,
                default: 0
            },
        }
    }
</script>
