<template>
    <card-title title="Transferir Hospedagem">
        <p class="mb-6">
            É possível transferir a propriedade de uma hospedagem para outro cliente cadastrado na Hostoo.
            Informe o e-mail do novo cliente e ele receberá um link de confirmação para concluir o processo.
            A transferência deve ser confirmada pelo novo cliente em até <strong>24 horas</strong>.
        </p>

        <div v-if="transferEmail" class="alert alert-warning">
            Esta hospedagem possui uma transferência pendente para o usuário <strong>{{ transferEmail }}</strong>.
            <button
                type="button"
                class="btn btn-link p-0 text-danger"
                ref="btnCancelTransfer"
                @click="$refs.modalCancelTransfer.show()"
            >
                Cancelar transferência
            </button>
        </div>

        <form accept-charset="UTF-8" @submit.prevent="transferHosting">
            <form-group label="E-mail do novo proprietário" label-for="clientEmail" :error="formErrors.email">
                <input
                    type="email"
                    class="form-control form-control-xl"
                    autocomplete="off"
                    spellcheck="false"
                    required
                    v-model="clientEmail"
                    v-input-lowercase />
            </form-group>

            <div class="form-buttons text-right">
                <button-form
                    ref="btnTransferHosting"
                    type="submit"
                    theme="success"
                    class="btn-action"
                    :disabled="hasPendingTransfer"
                >
                    Enviar
                </button-form>
            </div>
        </form>

        <modal-confirm
            ref="modalCancelTransfer"
            title="Cancelar transferência"
            danger
            @confirm="cancelTransfer"
        >
            <p class="mb-0">
                Tem certeza que deseja cancelar a transferência pendente para esta hospedagem?
            </p>
        </modal-confirm>
    </card-title>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import ButtonForm   from '@/components/atoms/ButtonForm'
    import ModalConfirm from '@/components/atoms/ModalConfirm'
    import CardTitle    from '@/components/molecules/CardTitle'
    import FormGroup    from '@/components/molecules/FormGroup'
    import AjaxService  from '@/services/AjaxService'
    import ErrorService from '@/services/error.service'

    export default {
        props: [
            'resource'
        ],
        components: {
            ModalConfirm,
            ButtonForm,
            FormGroup,
            CardTitle,
        },
        data() {
            return {
                clientEmail: "",
                transferEmail: "",
                formErrors: {},
                isRunning: false,
            }
        },
        computed: {
            ...mapState('hosting', ['hosting']),

            hasPendingTransfer: function () {
                return !!this.transferEmail;
            },
        },
        beforeRouteLeave(to, from, next)
        {
            if (this.isRunning && !confirm('Existe um processo em execução no momento. Tem certeza que deseja sair desta página?')) return;
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.fetchTransfer();
        },
        methods: {
            ...mapActions({
                showLoading: 'loading/show',
                hideLoading: 'loading/hide',
                showErrorAlert: 'alert/error',
                successNotification: 'notification/success',
            }),

            /**
             * Verificar se existe transferência em andamento para esta hospedagem.
             */
            fetchTransfer()
            {
                this.transferEmail = "";

                this.$api.get(`/hosting/${this.resource}/transfer`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        if (response.data.data && response.data.data.email) {
                            this.transferEmail = response.data.data.email;
                        }
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                    });
            },

            /**
             * Solicitar transferência da hospedagem.
             */
            transferHosting()
            {
                this.formErrors = {};
                this.isRunning = true;
                this.$refs.btnTransferHosting.setLoadingAndFocus();

                this.$api.post(`/hosting/${this.resource}/transfer`, { 'email': this.clientEmail })
                    .then (response => {
                        this.successNotification({ message: 'Transferência solicitada com sucesso!' });
                        setTimeout(() => { this.$router.push('/hosting/' + encodeURIComponent(this.resource)); }, 1000);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnTransferHosting.removeLoading();
                    })
                    .then(() => {
                        this.isRunning = false;
                    });
            },

            cancelTransfer()
            {
                this.$refs.btnCancelTransfer.disabled = true;
                this.isRunning = true;
                this.showLoading();

                this.$api.post(`/hosting/${this.resource}/transfer/cancel`)
                    .then (response => {
                        this.successNotification({ message: 'Transferência cancelada com sucesso!' });
                        this.fetchTransfer();
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                    })
                    .then(() => {
                        this.hideLoading();
                        this.isRunning = false;
                        this.$refs.btnCancelTransfer.disabled = false;
                    });
            }
        }
    }
</script>
