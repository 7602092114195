import HostingService from '../services/hosting.service'

const state = {
    domain: "",
    hosting: null,
    plan: null,
    plans: [],
    isLoadingHosting: false,
    isLoadingHostingPlan: false
}

const actions = {

    async get({ state, commit }, { domain, force })
    {
        if (!force && domain === state.domain && state.hosting) {
            return state.hosting;
        }

        commit('getHostingRequest')

        try {
            const hosting = await HostingService.get(domain)
            commit('getHostingSuccess', hosting)
            commit('getHostingPlanSuccess', null)
            return hosting
        }
        catch (error) {
            commit('getHostingFailure', error)
            throw error
        }
    },

    async getPlan({ state, commit }, { domain, force })
    {
        if (!force && domain === state.domain && state.plan) {
            return state.plan
        }
        else if (!state.isLoadingHostingPlan)
        {
            commit('getHostingPlanRequest')

            try {
                const plan = await HostingService.getPlan(domain)
                commit('getHostingPlanSuccess', plan)
                return plan
            }
            catch (error) {
                commit('getHostingPlanFailure', error)
                throw error
            }
        }
    },
}

const mutations = {

    getHostingRequest(state) {
        state.isLoadingHosting = true;
    },
    getHostingSuccess(state, hosting) {
        state.isLoadingHosting = false;
        state.hosting = hosting;
        state.domain  = hosting.domain;
    },
    getHostingFailure(state, error) {
        state.isLoadingHosting = false;
        state.hosting = null;
        state.domain  = "";
    },
    getHostingPlanRequest(state) {
        state.isLoadingHostingPlan = true;
    },
    getHostingPlanSuccess(state, plan) {
        state.isLoadingHostingPlan = false;
        state.plan = plan;
    },
    getHostingPlanFailure(state, error) {
        state.isLoadingHostingPlan = false;
        state.plan = null;
    },
}

export const hosting = {
    namespaced: true,
    state,
    actions,
    mutations
}