<template>
    <admin-page-template>
        <card-table
            ref="cardTokens"
            title="API"
            add-text="Gerar token"
            modal-title="Gerar token de acesso"
            hide-edit
            remove-title="Excluir token"
            table-empty-text="Nenhum token encontrado"
            :table-fields="tableFields"
            api-url="/user/token"
            @created="showToken"
            @remove="removeToken"
        >
            <template slot="intro-text">
                Utilizando um token de acesso você consegue integrar suas aplicações com a API da Hostoo. O uso do
                token garante a integridade e segurança das informações trocadas entre sua aplicação e a API.
            </template>

            <template slot="table-token">
                <p class="mb-0" title="Por questões de segurança seu token não é exibido" v-b-tooltip.hover>
                    <i class="fas fa-key"></i> ********************
                </p>
            </template>

            <template slot="table-date" slot-scope="{ rowData }">
                {{ rowData.date | moment("DD/MM/YYYY") }}
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group :error="formErrors.name">
                    <label class="form-label mb-0" for="tokenName">Nome</label>
                    <p class="form-text-sm">Este será apenas um nome descritivo para o token.</p>
                    <input type="text" id="tokenName" class="form-control" maxlength="100" spellcheck="false" v-model="formData.name">
                </form-group>
            </template>

            <template slot="remove-html">
                Tem certeza que deseja excluir o token? Após excluí-lo não será mais possível utilizá-lo para integração
                em suas aplicações.
            </template>
        </card-table>

        <card-title title="Logotipo">
            <p class="mb-6">
                Se você estiver utilizando a Hostoo como uma revenda, você pode configurar aqui o logotipo que será
                exibido no Painel de Hospedagem para os seus clientes. O ideal é que o logotipo esteja em um formato
                horizontal e com uma altura máxima de 80 pixels.
            </p>

            <text-loading v-if="isLoadingLogo && !logo.isImageLoaded" />

            <div v-else>
                <img v-show="logo.imagePreview" v-bind:src="logo.imagePreview" class="img-thumbnail img-fluid rounded-0 mb-6">

                <form-group class="mb-0" :error="formErrors.logo">
                    <div class="custom-file w-auto d-inline-block align-top">
                        <input type="file" class="custom-file-input" id="logoImageFile" ref="logoImageFile" accept="image/png,image/jpeg" hidden @change="onChangeLogo">
                        <label class="btn btn-secondary btn-rounded" for="logoImageFile" v-bind:class="{'disabled': isLoadingLogo}">Escolher arquivo</label>
                    </div>
                    <button v-if="logo.image || logo.hasChanged" type="button" class="btn btn-danger d-inline-block align-top ml-1" v-bind:class="{'btn-loading': isLoadingLogo}" :disabled="isLoadingLogo" @click="removeLogo">
                        Remover
                    </button>
                    <button v-if="logo.hasChanged" type="button" class="btn btn-success d-inline-block align-top px-6 ml-1" v-bind:class="{'btn-loading': isLoadingLogo}" :disabled="isLoadingLogo" @click="updateLogo">
                        Salvar
                    </button>
                </form-group>
            </div>
        </card-title>

        <card-title title="Tela de Suspensão">
            <p class="mb-6">
                Altere a forma como é exibida a tela de suspensão em suas hospedagens.
            </p>

            <text-loading v-if="isLoadingSuspension" />

            <form v-show="!isLoadingSuspension" @submit.prevent="customizeSuspension">
                <form-group label="Título *" label-for="suspensionTitle" :error="formErrors.title">
                    <input
                        type="text"
                        id="suspensionTitle"
                        class="form-control"
                        placeholder="Site suspenso"
                        v-model="formDataSuspension.title"
                    >
                </form-group>

                <form-group label="Subtítulo" label-for="suspensionSubtitle" :error="formErrors.subtitle">
                    <input
                        type="text"
                        id="suspensionSubtitle"
                        class="form-control"
                        placeholder="Este site está suspenso temporariamente. Para mais informações, entre em contato com o responsável pela hospedagem."
                        v-model="formDataSuspension.subtitle"
                    >
                </form-group>

                <div class="row">
                    <div class="col">
                        <form-group label="Cor de fundo *" label-for="suspensionBgColor" :error="formErrors.bgColor">
                            <input type="color" id="suspensionBgColor" class="form-control" v-model="formDataSuspension.bgColor">
                        </form-group>
                    </div>

                    <div class="col">
                        <form-group label="Cor do texto *" label-for="suspensionTextColor" :error="formErrors.textColor">
                            <input type="color" id="suspensionTextColor" class="form-control" v-model="formDataSuspension.textColor">
                        </form-group>
                    </div>
                </div>

                <div class="alert alert-warning">
                    As alterações podem levar até 5 minutos para serem propagadas nas suas hospedagens.
                </div>

                <div class="form-buttons">
                    <button-form ref="btnSuspensionSubmit" type="submit" variant="success" class="btn-action">Salvar</button-form>
                    <button ref="btnSuspensionReset" type="button" class="btn btn-secondary btn-action ml-2" @click="resetSuspension">Redefinir</button>
                </div>
            </form>
        </card-title>

        <b-modal ref="modalShowToken" size="lg" title="Token de acesso gerado" centered ok-only ok-title="Fechar" ok-variant="secondary" no-close-on-backdrop no-close-on-esc>
            <div class="text-center pt-4">
                <p class="mb-2">
                    Por favor, copie o seu token de acesso e salve-o em um local seguro.
                </p>
                <p class="font-weight-bold text-danger mb-8">
                    Por questões de segurança o seu token não poderá mais ser exibido após fechar.
                </p>
            </div>
            <div class="text-center">
                <div class="ht-text-copy-wrapper bg-light px-4 py-2" v-if="token.token">
                    <text-copy :text="token.token" text-class="text-monospace" />
                </div>
            </div>
        </b-modal>
    </admin-page-template>
</template>

<script>
    import { mapActions }    from 'vuex'
    import AjaxService       from '@/services/AjaxService'
    import ErrorService      from '@/services/error.service'
    import ButtonForm        from '@/components/atoms/ButtonForm'
    import TextLoading       from '@/components/atoms/TextLoading'
    import CardTitle         from '@/components/molecules/CardTitle'
    import FormGroup         from '@/components/molecules/FormGroup'
    import TextCopy          from '@/components/molecules/TextCopy'
    import CardTable         from '@/components/organisms/CardTable'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'

    export default {
        components: {
            ButtonForm,
            TextLoading,
            AdminPageTemplate,
            CardTitle,
            TextCopy,
            FormGroup,
            CardTable
        },
        data() {
            return {
                tableFields: [
                    {
                        name: 'name',
                        sortField: 'name',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Nome',
                        titleClass: 'w-50',
                        dataClass: 'w-50'
                    },
                    {
                        name: '__slot:token',
                        title: 'Token',
                    },
                    {
                        name: '__slot:date',
                        sortField: 'date',
                        sortFunction: 'text',
                        title: 'Data',
                        titleClass: 'col-w-32',
                        dataClass: 'col-w-32'
                    }
                ],

                formErrors: {},

                token: {},

                isLoadingLogo: false,
                logo: {
                    image: null,
                    file: null,
                    hasChanged: false,
                    imagePreview: "",
                    isImageLoaded: false,
                },

                isLoadingSuspension: false,
                formDataSuspension: {
                    bgColor: "#6535d8",
                    textColor: "#ffffff",
                },
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardTokens.cancelFetch();
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getLogo();
            this.fetchSuspension();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error',
                successNotification: 'notification/success',
            }),

            /**
             * Exibir token gerado.
             */
            showToken(item, responseData)
            {
                this.token = responseData;
                this.$refs.modalShowToken.show();
            },

            /**
             * Excluir um token.
             */
            removeToken(item)
            {
                this.$api.delete(`/user/token/${item.id}`)
                    .then (() => {})
                    .catch(() => alert("Ocorreu um erro ao tentar excluir o token. Por favor, atualize sua página e tente novamente."));
            },

            /**
             * Carregar logotipo.
             */
            getLogo()
            {
                this.isLoadingLogo = true;

                this.$api.get('/user/logo', { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.logo.image         = response.data.data.logo;
                        this.logo.imagePreview  = this.logo.image;
                        this.logo.isImageLoaded = true;
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingLogo = false);
            },

            /**
             * Evento de alterar imagem do logotipo.
             */
            onChangeLogo(e)
            {
                let vm = this;

                const files = e.target.files || e.dataTransfer.files;
                const photo = files.length ? files[0] : null;

                this.logo.file = photo;
                this.logo.hasChanged = true;

                let reader = new FileReader();

                reader.onload = (event) => {
                    var image = new Image();

                    image.onload = function () {
                        var canvas  = document.createElement('canvas'),
                            width   = image.width,
                            height  = image.height,
                            start_x = 0,
                            start_y = 0,
                            max_height = 80;

                        if (height > max_height) {
                            width   = (width/height) * max_height;
                            height  = max_height;
                        }

                        canvas.width  = width;
                        canvas.height = height;
                        canvas.getContext('2d').drawImage(image, -start_x, -start_y, width, height);

                        vm.logo.imagePreview = canvas.toDataURL('image/png');
                    };

                    image.src = event.target.result;
                };

                if (photo) {
                    if (/\.(jpe?g|png)$/i.test(photo.name)) {
                        reader.readAsDataURL( photo );
                    }
                    else {
                        alert("Escolha uma imagem no formato jpg ou png.");
                    }
                }
            },

            /**
             * Atualizar logotipo.
             */
            updateLogo()
            {
                this.errors = {};
                this.isLoadingLogo = true;

                let formData = new FormData();
                formData.append('logo', this.logo.file || '');

                let promise = this.$api.request({
                    method: 'post',
                    url: '/user/logo',
                    data: formData,
                    headers: { 'content-type': 'multipart/form-data' },
                });

                promise
                    .then(response => {
                        this.successNotification({ message: 'Logotipo atualizado!' });

                        this.logo.file = null;
                        this.logo.hasChanged = false;
                        this.$refs.logoImageFile.value = "";

                        this.logo.image = response.data.data.logo;
                        this.logo.imagePreview = this.logo.image;
                    })
                    .catch(error => ErrorService.handleFormError(error, this))
                    .then (() => this.isLoadingLogo = false);
            },

            /**
             * Remover logotipo atual ou imagem escolhida.
             */
            removeLogo()
            {
                if (this.logo.image)
                {
                    if (!confirm("Tem certeza que deseja excluir o logotipo atual?")) return;

                    this.errors = {};
                    this.isLoadingLogo = true;

                    this.$api.post('/user/logo')
                        .then (() => {
                            this.successNotification({ message: 'Logotipo removido!' });

                            this.logo.image = null;
                            this.logo.file = null;
                            this.logo.imagePreview = "";
                            this.logo.hasChanged = false;
                            this.$refs.logoImageFile.value = "";
                        })
                        .catch(error => ErrorService.handleFormError(error, this))
                        .then (() => this.isLoadingLogo = false);
                }
                else
                {
                    this.logo.file = null;
                    this.logo.imagePreview = "";
                    this.logo.hasChanged = (this.logo.image !== null);
                    this.$refs.logoImageFile.value = "";
                }
            },

            fetchSuspension()
            {
                this.isLoadingSuspension = true;

                this.$api.get('/user/suspension', { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        const suspensionData = response.data.data;

                        this.formDataSuspension.title     = suspensionData.title;
                        this.formDataSuspension.subtitle  = suspensionData.subtitle;
                        this.formDataSuspension.bgColor   = suspensionData.bgColor || "#6535d8";
                        this.formDataSuspension.textColor = suspensionData.textColor || "#ffffff";
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then(() => this.isLoadingSuspension = false);
            },

            customizeSuspension()
            {
                this.formErrors = {};
                this.$refs.btnSuspensionSubmit.loadingFocus();
                this.$refs.btnSuspensionReset.disabled = true;

                this.$api.post(`/user/suspension`, this.formDataSuspension)
                    .then (response => {})
                    .catch(error => ErrorService.handleFormError(error, this))
                    .then(() => {
                        this.$refs.btnSuspensionSubmit.setLoading(false);
                        this.$refs.btnSuspensionReset.disabled = false;
                    });
            },

            resetSuspension()
            {
                this.formErrors = {};
                this.$refs.btnSuspensionSubmit.loadingFocus();
                this.$refs.btnSuspensionReset.disabled = true;

                this.$api.post(`/user/suspension/reset`)
                    .then (response => {
                        this.formDataSuspension.title     = "";
                        this.formDataSuspension.subtitle  = "";
                        this.formDataSuspension.bgColor   = "#6535d8";
                        this.formDataSuspension.textColor = "#ffffff";
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then(() => {
                        this.$refs.btnSuspensionSubmit.setLoading(false);
                        this.$refs.btnSuspensionReset.disabled = false;
                    });
            }
        }
    }
</script>