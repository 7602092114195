<template>
    <payment-template v-bind:class="{ 'promotion': usePromotionTemplate }">
        <card-title class="h-o-card-auto-recharge">
            <template slot="pre-body">
                <banner-selector
                    :show-tester="false"
                    :show-referral="false"
                    event-label="banner_auto_recharge"
                />
            </template>

            <template slot="intro-text">
                Ative a recarga automática e evite ficar com suas hospedagens suspensas. O <strong>consumo mensal</strong>
                abaixo representa quanto suas hospedagens consomem em créditos de sua conta no périodo de 30 dias.
                As recargas automáticas são realizadas com base nesse valor.
            </template>

            <text-loading v-show="isLoadingAutoRecharge" />

            <div class="row">
                <div class="col">
                    <div v-show="monthlyCost >= 0" class="row">
                        <div class="col-md-auto">
                            <div class="w-56">
                                <form-group label="Consumo mensal" label-for="rechargeAmount" class="mb-6">
                                    <div class="input-group input-group-amount">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text px-4">R$</span>
                                        </div>
                                        <div class="input-group-append flex-grow-1">
                                            <span class="input-group-text font-weight-normal d-block text-right w-100 border-0 pl-0">
                                                {{ monthlyCost | formatMoney(2,"") }}
                                            </span>
                                        </div>
                                    </div>
                                </form-group>
                            </div>
                        </div>
                    </div>
                </div>

                <!--<div class="col order-md-first">
                    <div v-if="isAutoRechargeEnabled === false" class="form-group">
                        <label class="form-label mb-2">Forma de pagamento</label>

                        <div class="btn-group-payment-method">
                            <div class="btn-group-toggle" data-toggle="buttons">
                                <label class="btn btn-outline-primary" v-bind:class="{ active: isCreditCard }">
                                    <i class="icon far fa-credit-card"></i>
                                    <input type="radio" value="CRD" autocomplete="off" v-model="formData.payment"> Cartão
                                </label>
                                <label class="btn btn-outline-primary" v-bind:class="{ active: isBoleto }">
                                    <i class="icon fas fa-barcode"></i>
                                    <input type="radio" value="BOL" autocomplete="off" v-model="formData.payment"> Boleto
                                </label>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>

            <div v-show="isAutoRechargeEnabled === false">
                <div v-show="isCreditCard" class="alert alert-info" role="alert">
                    Será realizada uma recarga automática no seu cartão sempre que faltar <strong>7 dias ou menos</strong>
                    para o término dos seus créditos.
                </div>

                <div v-show="isCreditCard" class="alert alert-success" role="alert">
                    Esta é a forma de pagamento <strong>mais recomendada</strong> para garantir que sua conta sempre
                    terá créditos suficientes.<span class="d-md-block"></span>
                    Se mesmo assim ainda deseja realizar uma recarga manual,
                    <router-link to="/payment/manual-recharge">clique aqui</router-link>.
                </div>

                <div v-show="isBoleto" class="alert alert-info" role="alert">
                    Um boleto será gerado e enviado para o seu e-mail sempre que faltar <strong>7 dias ou menos</strong>
                    para o término dos seus créditos.
                </div>

                <div v-show="isBoleto" class="alert alert-warning" role="alert">
                    Esta não é a forma de pagamento mais recomendada, pois é necessário que o boleto seja pago a tempo
                    antes que sua conta fique sem créditos. Além disso, o pagamento por boleto pode demorar
                    de <strong>1 a 3 dias úteis</strong> para ser contabilizado.
                </div>
            </div>

            <form v-if="isAutoRechargeEnabled === false" accept-charset="UTF-8" @submit.prevent="enableAutoRecharge">
                <div v-show="isCreditCard">
                    <div class="card-title-subtitle">
                        <h5 class="card-title-inner">Dados do cartão de crédito</h5>
                    </div>

                    <form-group label="Número do cartão" label-for="cardNumber" :error="formErrors.cardNumber">
                        <the-mask
                            type="tel"
                            id="cardNumber"
                            class="form-control form-control-lg"
                            autocomplete="cc-number"
                            placeholder="0000 0000 0000 0000"
                            mask="#### #### #### ####"
                            v-model="formData.cardNumber" />
                    </form-group>

                    <div class="row">
                        <div class="col-md-4 col-lg-6">
                            <form-group label="Nome impresso no cartão" label-for="cardName" :error="formErrors.cardName">
                                <input type="text" id="cardName" class="form-control" autocomplete="cc-name" v-model="formData.cardName" v-input-uppercase>
                            </form-group>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <form-group label="Validade" label-for="cardExpiration" :error="formErrors.cardExpiration">
                                <input type="text" id="cardExpiration" class="form-control" autocomplete="cc-exp" placeholder="00/00" v-mask="'##/##'" v-model="formData.cardExpiration">
                            </form-group>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <form-group label="Código de segurança" label-for="cardCode" :error="formErrors.cardCode">
                                <the-mask type="tel" id="cardCode" class="form-control" autocomplete="cc-csc" placeholder="000" v-model="formData.cardCode" :mask="['###', '####']" />
                            </form-group>
                        </div>
                    </div>
                </div>

                <div class="card-title-subtitle">
                    <h5 class="card-title-inner">Dados do cliente</h5>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <form-group label="CPF / CNPJ" label-for="cardCpfCnpj" :error="formErrors.cpfCnpj">
                            <the-mask
                                type="tel"
                                id="cardCpfCnpj"
                                class="form-control"
                                placeholder="000.000.000-00"
                                autocomplete="off"
                                :mask="['###.###.###-##', '##.###.###/####-##']"
                                v-model="formData.cpfCnpj"></the-mask>
                        </form-group>
                    </div>
                </div>

                <form-group label="Nome completo / Razão social" label-for="customerName" :error="formErrors.name">
                    <input type="text" id="customerName" class="form-control" autocomplete="name" v-model="formData.name">
                </form-group>

                <div class="row">
                    <div class="col-md">
                        <form-group label="E-mail" label-for="cardEmail" :error="formErrors.email">
                            <input type="email" id="cardEmail" class="form-control" autocomplete="email" v-model="formData.email">
                        </form-group>
                    </div>
                    <div class="col-md">
                        <form-group label="Telefone" label-for="cardPhone" :error="formErrors.phone">
                            <the-mask
                                type="tel"
                                id="cardPhone"
                                class="form-control"
                                autocomplete="tel-national"
                                placeholder="(00) 0000-0000"
                                :mask="['(##) ####-####', '(##) #####-####']"
                                v-model="formData.phone" />
                        </form-group>
                    </div>
                    <div class="col-md">
                        <form-group label="Data de nascimento" label-for="birthDate" :error="formErrors.birthDate">
                            <input type="text" id="birthDate" class="form-control" autocomplete="bday" placeholder="00/00/0000" v-mask="'##/##/####'" v-model="formData.birthDate">
                        </form-group>
                    </div>
                </div>

                <div class="card-title-subtitle">
                    <h5 class="card-title-inner">Endereço de cobrança</h5>
                </div>

                <div class="row mb-md-4">
                    <div class="col-md-3">
                        <form-group label="CEP" label-for="cardAddressZip" class="mb-md-0" :error="formErrors.cardAddressZip">
                            <zip-code id="cardAddressZip" :disabled="isZipCodeDisabled" v-model="formData.cardAddressZip" @ready="cardUpdateAddress" />
                        </form-group>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-8 col-sm-9">
                                <form-group label="Endereço" label-for="cardAddressStreet" class="mb-md-0" :error="formErrors.cardAddressStreet">
                                    <input type="text" id="cardAddressStreet" class="form-control" autocomplete="street-address" v-model="formData.cardAddressStreet">
                                </form-group>
                            </div>
                            <div class="col-4 col-sm-3">
                                <form-group label="Número" label-for="cardAddressNumber" class="mb-md-0" :error="formErrors.cardAddressNumber">
                                    <input type="text" id="cardAddressNumber" class="form-control" v-model="formData.cardAddressNumber">
                                </form-group>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <form-group label="Complemento" label-for="cardAddressComplement" :error="formErrors.cardAddressComplement">
                            <input type="text" id="cardAddressComplement" class="form-control" v-model="formData.cardAddressComplement">
                        </form-group>
                    </div>
                    <div class="col-md-6">
                        <form-group label="Bairro" label-for="cardAddressNeighborhood" :error="formErrors.cardAddressNeighborhood">
                            <input type="text" id="cardAddressNeighborhood" class="form-control" autocomplete="address-level3" v-model="formData.cardAddressNeighborhood">
                        </form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <form-group label="Cidade" label-for="cardAddressCity" :error="formErrors.cardAddressCity">
                            <input type="text" id="cardAddressCity" class="form-control" autocomplete="address-level2"
                                v-model="formData.cardAddressCity"
                                v-bind:class="{ 'text-muted': disableCityStateFields }"
                                :disabled="disableCityStateFields">
                        </form-group>
                    </div>
                    <div class="col-md-6">
                        <form-group label="Estado" label-for="cardAddressState" :error="formErrors.cardAddressState">
                            <b-select id="cardAddressState" autocomplete="address-level1" v-model="formData.cardAddressState" :disabled="disableCityStateFields">
                                <b-form-select-option :value="null"></b-form-select-option>
                                <b-form-select-option value="AC">Acre</b-form-select-option>
                                <b-form-select-option value="AL">Alagoas</b-form-select-option>
                                <b-form-select-option value="AP">Amapá</b-form-select-option>
                                <b-form-select-option value="AM">Amazonas</b-form-select-option>
                                <b-form-select-option value="BA">Bahia </b-form-select-option>
                                <b-form-select-option value="CE">Ceará</b-form-select-option>
                                <b-form-select-option value="DF">Distrito Federal </b-form-select-option>
                                <b-form-select-option value="ES">Espírito Santo</b-form-select-option>
                                <b-form-select-option value="GO">Goiás</b-form-select-option>
                                <b-form-select-option value="MA">Maranhão</b-form-select-option>
                                <b-form-select-option value="MT">Mato Grosso</b-form-select-option>
                                <b-form-select-option value="MS">Mato Grosso do Sul</b-form-select-option>
                                <b-form-select-option value="MG">Minas Gerais</b-form-select-option>
                                <b-form-select-option value="PA">Pará</b-form-select-option>
                                <b-form-select-option value="PB">Paraíba</b-form-select-option>
                                <b-form-select-option value="PR">Paraná</b-form-select-option>
                                <b-form-select-option value="PE">Pernambuco</b-form-select-option>
                                <b-form-select-option value="PI">Piauí</b-form-select-option>
                                <b-form-select-option value="RJ">Rio de Janeiro</b-form-select-option>
                                <b-form-select-option value="RN">Rio Grande do Norte</b-form-select-option>
                                <b-form-select-option value="RS">Rio Grande do Sul</b-form-select-option>
                                <b-form-select-option value="RO">Rondônia</b-form-select-option>
                                <b-form-select-option value="RR">Roraima</b-form-select-option>
                                <b-form-select-option value="SC">Santa Catarina</b-form-select-option>
                                <b-form-select-option value="SP">São Paulo</b-form-select-option>
                                <b-form-select-option value="SE">Sergipe</b-form-select-option>
                                <b-form-select-option value="TO">Tocantins</b-form-select-option>
                            </b-select>
                        </form-group>
                    </div>
                </div>

                <form-group class="pt-6" :error="formErrors.terms">
                    <checkbox-terms v-model="formData.terms" />
                </form-group>

                <div class="form-buttons">
                    <button-form ref="btnFormSubmit" type="submit" theme="success" class="btn-lg btn-block">
                        Ativar recarga automática
                    </button-form>
                </div>
            </form>

            <div v-show="isAutoRechargeEnabled" class="alert alert-success" role="alert">
                A recarga automática está habilitada em sua conta
                <span v-if="paymentMethod == 'credit_card'">
                    no cartão <strong>**** {{ cardLastDigits }}</strong> e bandeira <strong>{{ cardBrand }}</strong>.
                </span>
                <span v-if="paymentMethod == 'boleto'">
                    via boleto bancário.
                </span>
            </div>

            <div v-show="isAutoRechargeEnabled" class="form-buttons text-right">
                <button-form ref="btnDisableAutoRecharge" type="button" theme="danger" class="btn-lg" @click="$refs.modalDisableAutoRecharge.show()">
                    Desativar recarga automática
                </button-form>
            </div>

            <div v-show="!isLoadingAutoRecharge" class="text-center mt-5">
                <router-link to="/payment/manual-recharge" class="text-muted">
                    Realizar apenas uma recarga manual
                    <i class="fas fa-external-link-alt ml-1" aria-hidden="true"></i>
                </router-link>
            </div>
        </card-title>

        <modal-confirm ref="modalDisableAutoRecharge" title="Desativar recarga automática" confirm-text="Confirmar" danger @confirm="disableAutoRecharge">
            <p>
                Tem certeza que deseja desativar a recarga automática em sua conta?
            </p>
            <div class="alert alert-warning mb-0">
                <strong>Atenção:</strong> Ao desativar a recarga automática suas hospedagens podem ser <strong>suspensas</strong>
                por falta de créditos caso você esqueça de fazer uma recarga manual.
            </div>
        </modal-confirm>
    </payment-template>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import ErrorService      from '@/services/error.service'
    import AjaxService       from '@/services/AjaxService'
    import ButtonForm        from '@/components/atoms/ButtonForm'
    import CheckboxTerms     from '@/components/atoms/CheckboxTerms'
    import ModalConfirm      from '@/components/atoms/ModalConfirm'
    import TextLoading       from '@/components/atoms/TextLoading'
    import ZipCode           from '@/components/atoms/ZipCode'
    import CardTitle         from '@/components/molecules/CardTitle'
    import FormGroup         from '@/components/molecules/FormGroup'
    import BannerSelector    from '@/components/organisms/BannerSelector'
    import PaymentTemplate   from '@/components/templates/PaymentTemplate'

    export default {
        name: "PaymentAutoRecharge",
        components: {
            BannerSelector,
            TextLoading,
            ModalConfirm,
            ZipCode,
            ButtonForm,
            CheckboxTerms,
            FormGroup,
            CardTitle,
            PaymentTemplate
        },
        data() {
            return {
                formData: {
                    payment: "CRD",
                },
                formErrors: {},
                monthlyCost: -1,
                paymentMethod: "",
                cardLastDigits: "",
                cardBrand: "",
                isLoadingAutoRecharge: false,
                isAutoRechargeEnabled: null,
                isZipCodeDisabled: false,
                disableCityStateFields: false,

                // Google
                googleClientId: null,
                googleSessionId: null,
            }
        },
        computed: {
            ...mapState("user", [
                "promotion"
            ]),
            ...mapGetters("user", [
                "isActivePromotion",
                "isTesterPromotion"
            ]),

            usePromotionTemplate: function() {
                return (
                    this.isActivePromotion && !this.isTesterPromotion &&
                    (
                        !this.promotion.hasOwnProperty('available') ||
                        this.promotion.available
                    )
                );
            },
            isCreditCard: function () {
                return this.formData.payment === 'CRD';
            },
            isBoleto: function () {
                return this.formData.payment === 'BOL';
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        created()
        {
            this.fetchPromotion();
        },
        mounted()
        {
            if (typeof window.gtag !== 'undefined' && typeof window.GA4_MEASUREMENT_ID !== 'undefined')
            {
                gtag('get', window.GA4_MEASUREMENT_ID, 'client_id', (clientID) => {
                    this.googleClientId = clientID;
                });

                gtag('get', window.GA4_MEASUREMENT_ID, 'session_id', (sessionID) => {
                    this.googleSessionId = sessionID;
                });
            }

            this.fetchAutoRecharge();
        },
        methods: {
            ...mapActions({
                fetchPromotion: 'user/fetchPromotion',
                showErrorAlert: 'alert/error',
            }),

            /**
             * Atualizar dados de endereço a partir do CEP.
             * @param {Object} data
             */
            cardUpdateAddress(data)
            {
                if (data)
                {
                    this.$set(this.formData, 'cardAddressStreet',       data.street);
                    this.$set(this.formData, 'cardAddressNeighborhood', data.neighborhood);
                    this.$set(this.formData, 'cardAddressCity',         data.city);
                    this.$set(this.formData, 'cardAddressState',        data.state);
                    this.disableCityStateFields = true;
                }
                else
                {
                    this.$set(this.formData, 'cardAddressCity',  '');
                    this.$set(this.formData, 'cardAddressState', '');
                    this.disableCityStateFields = false;
                }
            },

            /**
             * Obter informações sobre a recarga automática.
             */
            fetchAutoRecharge()
            {
                this.isLoadingAutoRecharge = true;
                this.cardLastDigits = "";
                this.cardBrand = "";

                this.$api.get('/recharge/auto', { cancelToken: AjaxService.getCancelToken() })
                    .then(response => {
                        this.monthlyCost           = response.data.data.monthly;
                        this.isAutoRechargeEnabled = response.data.data.enabled;

                        if (this.isAutoRechargeEnabled) {
                            this.paymentMethod  = response.data.data.paymentMethod;
                            this.cardLastDigits = response.data.data.cardLastDigits || "";
                            this.cardBrand      = response.data.data.cardBrand || "";
                        }
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then(() => this.isLoadingAutoRecharge = false);
            },

            /**
             * Habilitar a recarga automática.
             */
            enableAutoRecharge()
            {
                this.formErrors = {};
                this.$refs.btnFormSubmit.loadingFocus();

                const postData = Object.assign({}, this.formData);

                postData.googleClientId  = this.googleClientId;
                postData.googleSessionId = this.googleSessionId;

                this.$api.post('/recharge/auto', postData)
                    .then(response => {
                        this.isAutoRechargeEnabled = true;
                        this.cardLastDigits        = response.data.data.cardLastDigits;
                        this.cardBrand             = response.data.data.cardBrand;
                        this.$refs.btnFormSubmit.setLoading(false);
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnFormSubmit.setLoading(false);
                    });
            },

            /**
             * Desativar a recarga automática.
             */
            disableAutoRecharge()
            {
                this.$refs.btnDisableAutoRecharge.loadingFocus();

                this.$api.post('/recharge/auto', { disable: true })
                    .then(() => {
                        this.isAutoRechargeEnabled = false;
                        this.cardLastDigits = "";
                        this.cardBrand = "";
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnFormSubmit.setLoading(false);
                    })
                    .then(() => this.$refs.btnDisableAutoRecharge.setLoading(false));
            },
        }
    }
</script>

<style lang="stylus">
    .h-o-card-auto-recharge {
        .input-group-amount {
            .input-group-prepend .input-group-text {
                border-color darken($input-bg, 10%)
                background-color darken($input-bg, 10%)
            }

            .input-group-append .input-group-text {
                background-color $input-bg
            }

            .input-group-text {
                padding-top calc(.75rem + 1px)
                padding-bottom calc(.75rem - 1px)
                font-size 1.125rem
            }
        }
    }
</style>
