<template>
    <admin-page-template>
        <card-table
            ref="cardHostings"
            class="h-o-card-hosting"
            title="WordPress"
            hide-add
            hide-edit
            hide-remove
            :toolbar-actions="cardHostingsActions"
            :table-fields="tableHostingsFields"
            api-url="/hosting"
            table-max-height="calc((10 * (3rem + 1px)))"
            table-empty-text="Nenhuma hospedagem encontrada"
        >
            <template slot="intro-text">
                Instale o aplicativo WordPress ou gerencie as instalações existentes em suas hospedagens ativas.
            </template>

            <template slot="table-status" slot-scope="{ rowData }">
                <icon-hosting-status :status="rowData.status.toLowerCase()" :title="rowData.statusText" />
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item :to="`/hosting/${rowData.domain}/app/install?name=wordpress`" :disabled="rowData.status !== 'Active'">
                    <i class="fas fa-plus fa-fw"></i> Instalar
                </b-dropdown-item>
                <b-dropdown-item :to="`/hosting/${rowData.domain}/apps?name=wordpress`" :disabled="rowData.status !== 'Active'">
                    <i class="fas fa-list fa-fw"></i> Listar instalações
                </b-dropdown-item>
            </template>
        </card-table>
    </admin-page-template>
</template>

<script>
    import AjaxService from '@/services/AjaxService'
    import IconHostingStatus from '@/components/atoms/IconHostingStatus'
    import CardTable from '@/components/organisms/CardTable'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'

    export default {
        components: {
            IconHostingStatus,
            CardTable,
            AdminPageTemplate
        },
        data() {
            return {
                cardHostingsActions: [
                    {
                        text: 'Criar nova hospedagem',
                        variant: 'success',
                        link: '/hosting/add?wordpress=1'
                    }
                ],
                tableHostingsFields: [
                    {
                        name: '__slot:status',
                        sortField: 'status',
                        sortFunction: 'text',
                        title: '<span class="sr-only">Status</span>',
                        titleClass: 'col-status col-w-12',
                        dataClass: 'col-status col-w-12',
                    },
                    {
                        name: 'domain',
                        sortField: 'domain',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Domínio',
                        dataClass: 'col-domain',
                    }
                ],
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {

        },
        methods: {

        }
    }
</script>