import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "../router"

Vue.use(AclInstaller)

export default new AclCreate({
    initial: 'public',
    notfound: '/404',
    router,
    acceptLocalRules: true,
    globalRules: {
        isPublic:   new AclRule('public').or('owner').or('guest').generate(),
        isOwner:    new AclRule('owner').generate(),
        isGuest:    new AclRule('guest').generate(),
        hasWebsite: new AclRule('owner').or('guest&&website').generate(),
        hasDomain:  new AclRule('owner').or('guest&&domain').generate(),
        hasEmail:   new AclRule('owner').or('guest&&email').generate(),
    }
})
