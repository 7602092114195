<template>
    <nav class="navbar navbar-expand fixed-top h-o-admin-header">
        <a class="navbar-brand p-0" href="/">
            <svg width="68" height="50" viewBox="0 -14 136 100" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-770.89 -507.88)">
                    <path d="m838.87 525.62c1.219-1.576 2.293-3.036 3.441-4.436 5.372-6.791 13.083-11.335 21.626-12.746 18.766-3.186 36.66 7.385 41.495 26.13 5.026 19.486-5.951 38.419-25.231 44.175-15.1 4.51-29.523-1.521-38.109-12.262-1.09-1.363-2.119-2.775-3.268-4.289-0.422 0.522-0.813 0.959-1.151 1.435-2.838 4.134-6.454 7.675-10.647 10.425-6.54 4.355-14.297 6.516-22.148 6.168-8.223-0.42-16.025-3.764-22-9.429-6.288-5.517-10.395-13.101-11.577-21.381-1.776-12.734 2.239-23.544 11.611-32.291 5.283-5.031 12.084-8.168 19.34-8.921 14.106-1.5 25.779 3.069 34.46 14.563 0.685 0.91 1.372 1.819 2.158 2.859zm-30.285-2.143c-11.377-0.018-20.615 9.19-20.633 20.567s9.19 20.615 20.567 20.633c11.377 0.019 20.615-9.189 20.633-20.567v-0.079c0.014-11.339-9.167-20.541-20.506-20.554h-0.061zm59.918 0c-11.377 0.012-20.59 9.244-20.578 20.621s9.244 20.59 20.621 20.579c11.365-0.012 20.573-9.225 20.578-20.59 0.016-11.369-9.189-20.597-20.559-20.611h-0.061l-1e-3 1e-3z"/>
                    <path d="m906.3 566.59c1.197 4.567 0.646 9.417-1.543 13.6-3.133 5.9-8.131 9.617-14.291 11.822-7.518 2.688-14.967 2.6-22.2-1.058-2.573-1.319-4.872-3.118-6.771-5.3 19.262 3.905 34.631-1.238 44.805-19.064z"/>
                    <path d="m814.01 538.38c-1.6 1.954-1.724 3.6-0.42 4.961 1.275 1.328 2.889 1.292 4.862-0.106 1.834 3.65 0.419 8.69-3.164 11.273-4.237 3.083-10.171 2.146-13.254-2.091-3.083-4.238-2.146-10.172 2.091-13.254 2.885-2.099 6.704-2.402 9.885-0.783z"/>
                    <path d="m872.56 538.39c-1.571 1.779-1.724 3.557-0.476 4.906 1.277 1.379 3.03 1.358 4.846-0.057 1.64 2.891 0.918 7.95-2.541 10.829-4.021 3.369-10.014 2.838-13.382-1.184-2.771-3.31-2.963-8.072-0.466-11.594 2.72-3.832 7.85-5.07 12.019-2.9z"/>
                </g>
            </svg>
        </a>

        <ul class="navbar-nav ml-auto align-items-center">
            <li class="nav-item d-none d-sm-block">
                <button v-if="user && !user.isAffiliate" type="button" class="btn btn-success badge badge-pill badge-referral" @click="onClickReferral">
                    Indique e ganhe
                </button>
            </li>

            <li v-if="credits && credits.estimateText" class="nav-item d-none d-sm-block ml-2">
                <span class="d-block badge badge-pill badge-estimate" :title="estimateTitle" v-b-tooltip.hover.bottomleft>
                    Estimativa: {{ credits.estimateText }}
                </span>
            </li>

            <li ref="btnCredits" class="nav-item ml-6">
                <router-link to="/payment/billing" class="nav-link user-credits">
                    <i class="fas fa-coins mr-2"></i>
                    <i v-if="isLoadingCredits" class="fas fa-circle-notch fa-spin text-muted"></i>
                    <span v-else v-html="creditsText"></span>
                </router-link>
            </li>

            <b-tooltip :target="() => $refs['btnCredits']" placement="bottomleft" :disabled="hideCreditsTooltip">
                <table v-if="credits" class="table table-borderless mb-0">
                    <tr>
                        <td class="text-left font-weight-semibold">Créditos</td>
                        <td class="pl-6 text-right">{{ credits.credit | formatMoney(2,"") }}</td>
                    </tr>
                    <tr>
                        <td class="pt-0 text-left font-weight-semibold">Bônus</td>
                        <td class="pt-0 pl-6 text-right">{{ credits.bonus | formatMoney(2,"") }}</td>
                    </tr>
                </table>
            </b-tooltip>

            <li class="nav-item ml-1">
                <router-link to="/payment/recharge" class="btn btn-success btn-add-credits" title="Adicionar créditos" v-b-tooltip.hover.bottomleft>
                    <i class="icon fas fa-plus"></i>
                </router-link>
            </li>

            <li class="nav-item ml-6 d-none d-md-block">
                <span class="user-name">
                    <i v-if="isLoadingUser" class="fas fa-circle-notch fa-spin text-muted"></i>
                    <template v-else>{{ userName }}</template>
                </span>
            </li>

            <li class="nav-item ml-4">
                <b-dropdown variant="primary" toggle-class="btn-user-avatar" right no-caret>
                    <template slot="button-content">
                        <img :src="userPhoto" width="42" height="42" alt="Foto de perfil">
                    </template>
                    <b-dropdown-item to="/user/profile">
                        <i class="fas fa-user-cog fa-fw mr-1"></i> Editar perfil
                    </b-dropdown-item>
                    <b-dropdown-item to="/settings">
                        <i class="fas fa-cogs fa-fw mr-1"></i> Configurações
                    </b-dropdown-item>
                    <b-dropdown-item to="/payment/history">
                        <i class="fas fa-coins fa-fw mr-1"></i> Créditos
                    </b-dropdown-item>
                    <b-dropdown-item to="/user/notifications">
                        <i class="fas fa-bell fa-fw mr-1"></i> Notificações
                    </b-dropdown-item>
                    <b-dropdown-item to="/support/tickets">
                        <i class="fas fa-life-ring fa-fw mr-1"></i> Suporte
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user && user.isAffiliate" to="/affiliate">
                        <span class="d-inline-block mr-1">
                            <svg class="d-block" width="20" height="16" viewBox="0 0 20 16">
                                <g transform="translate(0 -0.911)">
                                    <g transform="translate(1.75 0.911)">
                                        <path d="M10.588,10.143,9.576,9.186a1.483,1.483,0,0,0,.191-.718,1.511,1.511,0,1,0-.707,1.27l1.01.955A3.437,3.437,0,0,1,10.588,10.143Z" transform="translate(-4.37 -4.824)"/>
                                        <path d="M28.477,6.11l1.345-2.263a1.484,1.484,0,1,0-.652-.381l-1.34,2.256A3.358,3.358,0,0,1,28.477,6.11Z" transform="translate(-17.979 -0.911)"/>
                                        <path d="M36.655,20.952a1.5,1.5,0,0,0-1.4.982l-1.868-.184c0,.014,0,.028,0,.041a3.44,3.44,0,0,1-.075.711l1.867.184a1.5,1.5,0,1,0,1.478-1.735Z" transform="translate(-21.52 -13.859)"/>
                                        <path d="M25.374,34.293l-.418-1.825a3.42,3.42,0,0,1-.737.17l.418,1.825a1.5,1.5,0,1,0,.736-.171Z" transform="translate(-15.394 -21.299)"/>
                                        <path d="M5.081,25.37l-2.325.779A1.5,1.5,0,1,0,3,26.968c0-.035-.007-.067-.01-.1l2.333-.781A3.427,3.427,0,0,1,5.081,25.37Z" transform="translate(0 -16.713)"/>
                                        <ellipse cx="2.573" cy="2.573" rx="2.573" ry="2.573" transform="translate(5.861 5.359)"/>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Afiliados
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user && !user.isAffiliate" to="/referral">
                        <i class="fas fa-user-plus fa-fw mr-1"></i> Indicações
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item to="/logout">
                        <i class="fas fa-sign-out-alt fa-fw mr-1"></i> Sair
                    </b-dropdown-item>
                </b-dropdown>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import referral    from '@/mixins/referral'
    import formatMoney from '@/filters/_format-money'
    import UserService from '@/services/user.service'

    export default {
        mixins: [
            referral
        ],
        data() {
            return {
                defaultPhoto: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' %3E%3Crect width='50' height='50' x='0' y='0' style='fill:%23DDDDDD;' /%3E%3C/svg%3E",
            }
        },
        computed: {
            ...mapState('user', [
                'user',
                'credits',
                'isLoadingUser',
                'isLoadingCredits'
            ]),

            userName: function()
            {
                let name = "";

                if (this.user && this.user.name) {
                    name = this.user.name.length <= 20 ? this.user.name : this.user.name.substr(0, 18) + '...'
                }

                return name;
            },
            userPhoto: function() {
                return this.user && this.user.photo ? this.user.photo : this.defaultPhoto;
            },
            creditsText: function()
            {
                let text = "";

                if (this.credits)
                {
                    const total = this.credits.total || 0;

                    text = formatMoney(total);

                    if (total < 0) {
                        text = '<span class="text-danger">' + text + '</span>';
                    }
                }

                return text;
            },
            estimateTitle: function()
            {
                return this.credits && !this.credits.bonusExpired
                    ? 'Prazo estimado de duração do seu bônus de teste'
                    : 'Prazo estimado de duração dos seus créditos';
            },
            hideCreditsTooltip: function ()
            {
                return this.isLoadingCredits || !this.credits || this.credits.bonus < 0.01;
            }
        },
        created()
        {
            this.$eventBus.$on("credits-recharged", this.getCredits);

            this.fetchUser();
            this.fetchCredits();
        },
        methods: {
            ...mapActions({
                fetchUser: 'user/fetchUser',
                fetchCredits: 'user/fetchCredits',
                getCredits: 'user/getCredits',
            }),

            onClickReferral()
            {
                this.showReferralModal();

                UserService.trackEvent({
                    "action"   : "click",
                    "category" : "referral",
                    "label"    : "button_admin_header",
                });
            }
        }
    }
</script>

<style lang="stylus">
    .h-o-admin-header
        box-shadow 0 1px 6px rgba(black, .2)
        background-color white

        &.navbar
            @media (min-width 992px)
                padding-left 3rem
                padding-right 3rem !important // !important usado devido ao padding inserido pelo modal

        .navbar-brand
            > svg
                fill $primary
                transition fill .3s ease

            &:hover > svg
                fill lighten($primary, 15%)

        .navbar-nav
            .nav-link
                color $gray-800

        .badge-referral
            border 0
            padding .325rem .75rem
            font-size 12px
            font-weight 600
            background linear-gradient(90deg, #6149A7 11.98%, #3EC486 100%)

        .badge-estimate
            padding .325rem .75rem
            font-size 12px
            font-weight 700
            background-color #FFF0C1
            color $gray-700

        .user-credits
            font-size 1.125rem
            font-weight 600

        .btn-add-credits
            padding 0
            margin-bottom .125rem
            border 0
            border-radius 50%
            font-size .625rem

            .icon
                display block
                size 1.375rem
                line-height 1.375rem

        .user-name
            font-size 1.125rem
            font-weight 600

        .btn-user-avatar
            padding 0
            border 0
            border-radius 50%
            box-shadow 0 0 0 3px $primary
            background-color $primary !important
            overflow hidden

            &:hover, &:focus, &.focus
                box-shadow 0 0 0 3px $success
                background-color $success !important

        .dropdown-menu
            margin-top .375rem
</style>