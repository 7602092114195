<template>
    <div>
        <p class="mb-5">
            Utilize o link abaixo para indicar a Hostoo para seus amigos e receba em bônus na sua conta o valor da
            primeira recarga* feita por cada indicado!

            <span v-if="readMore" class="d-block mt-2">
                Para mais informações sobre as indicações, <a href="/referral">clique aqui</a>.
            </span>
        </p>

        <form-group label="Compartilhe seu link de indicação!" label-for="referralLink" class="mb-5">
            <input-copy id="referralLink" v-model="link" @copy="onCopyLink" />
        </form-group>

        <p class="mb-1 font-weight-semibold">
            Compartilhe em suas redes sociais:
        </p>
        <ul class="nav mb-5 ml-1">
            <li class="nav-item mr-2">
                <a
                    :href="facebookLink"
                    target="_blank"
                    rel="noopener"
                    class="nav-link link-facebook p-0"
                    title="Compartilhar no Facebook"
                    aria-label="Compartilhar no Facebook"
                    @click="onClickSocialLink('facebook')"
                >
                    <i class="fab fa-facebook-square fa-3x d-block"></i>
                </a>
            </li>
            <li class="nav-item mr-2">
                <a
                    :href="twitterLink"
                    target="_blank"
                    rel="noopener"
                    class="nav-link link-twitter p-0"
                    title="Compartilhar no Twitter"
                    aria-label="Compartilhar no Twitter"
                    @click="onClickSocialLink('twitter')"
                >
                    <i class="fab fa-twitter-square fa-3x d-block"></i>
                </a>
            </li>
            <li class="nav-item mr-2">
                <a
                    :href="linkedinLink"
                    target="_blank"
                    rel="noopener"
                    class="nav-link link-linkedin p-0"
                    title="Compartilhar no LinkedIn"
                    aria-label="Compartilhar no LinkedIn"
                    @click="onClickSocialLink('linkedin')"
                >
                    <i class="fab fa-linkedin fa-3x d-block"></i>
                </a>
            </li>
            <li class="nav-item mr-2">
                <a
                    :href="whatsappLink"
                    target="_blank"
                    rel="noopener"
                    class="nav-link link-whatsapp p-0"
                    title="Compartilhar no WhatsApp"
                    aria-label="Compartilhar no WhatsApp"
                    @click="onClickSocialLink('whatsapp')"
                >
                    <i class="fab fa-whatsapp-square fa-3x d-block"></i>
                </a>
            </li>
        </ul>

        <p class="mb-0">
            <span class="text-muted text-sm">* O bônus é limitado a R$ 20,00 por indicação.</span>
        </p>
    </div>
</template>

<script>
    import FormGroup from '@/components/molecules/FormGroup'
    import InputCopy from '@/components/molecules/InputCopy'
    import UserService from '@/services/user.service'

    export default {
        name: "ReferralShare",
        props: {
            link: {
                type: String,
                default: ""
            },
            readMore: {
                type: Boolean,
                default: false
            }
        },
        components: {
            FormGroup,
            InputCopy,
        },
        data() {
            return {
                message: "Conheça a Hostoo, a melhor hospedagem cloud do merdado!\n",
            }
        },
        computed: {
            facebookLink: function () {
                return "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(this.link);
            },
            twitterLink: function () {
                return "https://twitter.com/intent/tweet?url=" + encodeURI(this.message + this.link);
            },
            whatsappLink: function () {
                return "https://wa.me/?text=" + encodeURI(this.message + this.link);
            },
            linkedinLink: function () {
                return "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURI(this.link);
            },
        },
        methods: {
            onCopyLink()
            {
                UserService.trackEvent({
                    "action"   : "click",
                    "category" : "referral",
                    "label"    : "button_copy_link",
                });
            },

            onClickSocialLink(value)
            {
                UserService.trackEvent({
                    "action"   : "click",
                    "category" : "referral",
                    "label"    : `button_${value}`,
                });
            }
        }
    }
</script>
