<template>
    <hosting-domain-template :resource="resource" class="h-p-hosting-domain">
        <card-title inner-body class="h-card-hosting-domain-status">
            <h4 class="card-title-inner">Servidores DNS</h4>
            <p class="mb-6">
                Confira o status do seu domínio e os servidores DNS recomendados para configurá-lo.
            </p>

            <div v-if="needsRegister" class="alert alert-warning alert-register mb-0" role="alert">
                O domínio <strong>{{ resource }}</strong> ainda não foi registrado. Faça o registro agora
                <router-link :to="'/domain/'+ resource +'/register'">clicando aqui</router-link>.
            </div>

            <div v-else-if="domain.configured" class="alert alert-success mb-6" role="alert">
                Seu domínio já está configurado corretamente!
            </div>

            <div v-else-if="isNotRegisterable" class="alert alert-primary mb-6" role="alert">
                Ainda não oferecemos o registro para este tipo de domínio, mas você ainda pode
                continuar usando nossos serviços. Faça o registro do domínio <strong>{{ resource }}</strong>
                <template v-if="domain.registrar">
                    pelo
                    <a :href="domain.registrar.link" target="_blank">
                        <strong>{{ domain.registrar.name }}</strong>
                        <i class="fas fa-external-link-alt ml-1"></i>
                    </a>
                </template>
                <template v-else>em outro provedor</template>
                e conecte-o à Hostoo usando os servidores DNS listados abaixo.
            </div>

            <div class="table-responsive-sm">
                <table v-show="isLoadingDomain || !needsRegister" class="table table-custom-header">
                    <thead>
                        <tr>
                            <th class="w-50 col-nowrap">DNS atual</th>
                            <th class="w-50 col-nowrap">DNS recomendado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoadingDomain">
                            <td colspan="2" class="text-muted">
                                <i class="fas fa-circle-notch fa-spin"></i> Carregando...
                            </td>
                        </tr>
                        <tr v-else-if="!domain.ns_current && !domain.ns_recommended">
                            <td colspan="2" class="text-center">
                                Não foi possível obter os servidores DNS para este domínio
                            </td>
                        </tr>
                        <template v-else v-for="n in Math.max(domain.ns_current.length, domain.ns_recommended.length)">
                            <tr class="copy-text-wrapper">
                                <td>
                                    <template v-if="domain.ns_current[n-1] !== 'undefined'">
                                        {{ domain.ns_current[n-1] }}
                                    </template>
                                </td>
                                <td>
                                    <text-copy v-if="domain.ns_recommended[n-1] !== 'undefined'" :text="domain.ns_recommended[n-1]"/>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <p v-show="!needsRegister" class="text-muted mb-0">
                <strong>Aviso:</strong> Modificações de DNS podem demorar de alguns minutos a até 24 horas.
            </p>
        </card-title>
    </hosting-domain-template>
</template>

<script>
    import { mapActions }        from 'vuex'
    import AjaxService           from '../../services/AjaxService'
    import CardTitle             from '../../components/molecules/CardTitle'
    import TextCopy              from '../../components/molecules/TextCopy'
    import HostingDomainTemplate from '../../components/templates/HostingDomainTemplate'

    export default {
        props: ['resource'],
        components: {
            TextCopy,
            CardTitle,
            HostingDomainTemplate
        },
        data() {
            return {
                domain: {},
                isLoadingDomain: false,
            }
        },
        computed: {
            needsRegister() {
                return this.domain.available && this.domain.registerable;
            },
            isNotRegisterable() {
                return this.domain.available && !this.domain.registerable;
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getDomain();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Obter informações do domínio.
             */
            getDomain()
            {
                this.isLoadingDomain = true;

                this.$api.get(`/hosting/${this.resource}/domain`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.domain = response.data.data
                        this.$eventBus.$emit('hosting-domain-status-loaded')
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingDomain = false);
            }
        }
    }
</script>