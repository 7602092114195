import Vue from "vue"
import axios from "axios"

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
});

instance.interceptors.request.use(
    function (config)
    {
        try {
            const token = Vue.cookie.get('access_token');

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        catch(e) { /* ignore */ }

        return config;
    },
    function(error) {
        return Promise.reject(error);
    });

export const Api = instance

export const ApiCancel = {
    CancelToken: axios.CancelToken,
    isCancel: axios.isCancel
}

export const getErrorMessage = (error) => {
    const defaultMessage = "Ocorreu um erro ao tentar processar sua solicitação."

    if (typeof error.response.data.message !== 'undefined') {
        return error.response.data.message;
    }

    return defaultMessage;
}

export default {
    install (Vue) {
        Vue.prototype.$api = instance;
    }
}
