<template>
    <card-title class="h-o-card-table" :title="title" :inner-body="isInnerTitle">
        <div v-if="isInnerTitle" class="d-flex justify-content-between mb-6">
            <div class="flex-grow-1">
                <h4 class="card-title-inner" v-html="innerTitle" v-bind:class="{ 'mb-0': !$slots['intro-text'] }"></h4>
                <div>
                    <slot name="intro-text"></slot>
                </div>
            </div>
            <div>
                <slot name="header-actions"></slot>
            </div>
        </div>
        <div v-else v-show="$slots['intro-text']" class="mb-6">
            <slot name="intro-text"></slot>
        </div>

        <div v-if="!hideFilter || toolbarButtons.length" class="btn-toolbar mb-6" role="toolbar">
            <input-search v-if="!hideFilter" v-model="filterText" />

            <slot name="toolbar-before"></slot>

            <template v-for="(item, idx) in toolbarButtons">
                <div role="group" class="btn-group" v-bind:class="[idx === 0 ? 'ml-auto' : 'ml-2']">
                    <router-link
                        v-if="item.link"
                        class="btn"
                        v-bind:class="[`btn-${item.variant}`, item.loading ? 'btn-loading' : '']"
                        :to="item.link"
                        v-html="item.text"></router-link>
                    <button
                        v-else
                        type="button"
                        class="btn"
                        v-bind:class="[`btn-${item.variant}`, item.loading ? 'btn-loading' : '']"
                        :disabled="item.disabled"
                        v-html="item.text"
                        @click="onBtnToolbarClick(item)"></button>
                </div>
            </template>

            <slot name="toolbar-after"></slot>
        </div>

        <div class="h-card-table-wrapper table-responsive">
            <vuetable
                v-if="isTableFieldsReady"
                ref="table"
                table-class="table-custom-header mb-0"
                :fields="tableFields$"
                :no-data-template="tableEmptyText"
                :api-mode="false"
                :loading="isTableLoading"
                :data-manager="sortTable"
                :row-class="onRowClass"
                :max-height="tableMaxHeight"
                @vuetable:loaded="$emit('loaded')"
            >
                <template v-for="field in slotFields" :slot="field" slot-scope="props">
                    <slot :name="`table-${field}`" v-bind="props"></slot>
                </template>

                <template slot="options" slot-scope="props">
                    <i v-show="props.rowData.isRemoved" class="fas fa-circle-notch fa-spin text-muted"></i>
                    <b-dropdown v-show="!props.rowData.isRemoved" variant="link" no-flip right no-caret :popper-opts="{positionFixed:true,modifiers:{preventOverflow:{escapeWithReference:true}}}">
                        <template v-slot:button-content>
                            <i class="fas fa-caret-down"/>
                        </template>
                        <slot name="dropdown-before" v-bind="props"></slot>
                        <b-dropdown-item-button v-if="!hideEdit" @click="showEditModal(props.rowData)">
                            <span v-html="editIconHtml"></span> {{ editText }}
                        </b-dropdown-item-button>
                        <slot name="dropdown-after" v-bind="props"></slot>
                        <b-dropdown-divider v-show="!hideRemove && (!hideEdit || $scopedSlots['dropdown-before'] || $scopedSlots['dropdown-after'])" />
                        <b-dropdown-item-button v-if="!hideRemove" variant="danger" @click="showRemoveConfirm(props.rowData)">
                            <i class="far fa-trash-alt fa-fw"></i> Excluir
                        </b-dropdown-item-button>
                    </b-dropdown>
                </template>
            </vuetable>
        </div>

        <slot name="table-after"></slot>

        <b-modal ref="modalForm" :size="modalSize" :title="modalTitleFinal" centered hide-footer hide-header-close @shown="onModalFormShown" @hidden="$emit('hide-modal')">
            <form @submit.prevent="saveItem">
                <slot name="form-html" :form-data="formData" :form-errors="formErrors"></slot>

                <slot name="form-buttons" :modal-form="$refs.modalForm">
                    <div class="form-buttons text-right">
                        <button ref="btnFormCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalForm.hide()">Cancelar</button>
                        <button-form ref="btnFormSubmit" type="submit" theme="success" class="btn-action" v-html="modalSubmitText"></button-form>
                    </div>
                </slot>
            </form>
        </b-modal>

        <modal-confirm ref="modalRemove" :title="removeTitle" danger @confirm="removeItem" @cancel="cancelRemove">
            <slot v-if="itemRemoving" name="remove-html" :item="itemRemoving"></slot>
        </modal-confirm>
    </card-title>
</template>

<script>
    import InputSearch   from '../atoms/InputSearch'
    import Vuetable      from '../atoms/Vuetable'
    import ButtonForm    from '../atoms/ButtonForm'
    import ModalConfirm  from '../atoms/ModalConfirm'
    import CardTitle from '../molecules/CardTitle'
    import tableUtil     from '@/mixins/tableUtil'
    import ErrorService  from '@/services/ErrorService'
    import { ApiCancel } from '@/plugins/api'

    export default {
        props: {
            title: {
                type: String,
                default: ""
            },
            innerTitle: {
                type: String,
                default: ""
            },
            hideFilter: {
                type: Boolean,
                default: false
            },
            filterTerm: {
                type: String,
                default: "",
            },
            toolbarActions: {
                type: Array,
                default () {
                    return []
                }
            },
            tableFields: {
                type: Array,
                required: true
            },
            tableEmptyText: {
                type: String,
                default: ""
            },
            tableMaxHeight: {
                type: String,
                default: null
            },
            tableLoading: {
                type: Boolean,
                default: false
            },
            tableData: {
                type: [Array, Object],
                default: null
            },
            apiUrl: {
                type: String,
                default: ""
            },
            /**
             * The path inside the data structure that actually contains the data.
             * If the data is at the root of the structure, set this prop to empty string.
             * https://ratiw.github.io/vuetable-2/#/Vuetable-Properties?id=-data-path
             */
            dataPath: {
                type: String,
                default: ""
            },
            /**
             * Tranformar dados obtidos pela API antes da exibição na tabela.
             */
            dataTransform: {
                type: Function,
                default: null
            },
            apiSaveUrl: {
                type: [String, Function],
                default: ""
            },
            apiSaveHeaders: {
                type: Object,
                default: () => {}
            },
            apiEditMethod: {
                type: String,
                default: "put"
            },
            modalTitle: {
                type: String,
                default: ""
            },
            modalTitleEdit: {
                type: String,
                default: ""
            },
            modalSubmitText: {
                type: String,
                default: "Salvar"
            },
            modalSize: {
                type: String,
                default: "lg",
                validator: function (value) {
                    return ['sm','md','lg','xl'].includes(value);
                }
            },
            hideAdd: {
                type: Boolean,
                default: false
            },
            addText: {
                type: String,
                default: "Adicionar"
            },
            hideEdit: {
                type: Boolean,
                default: false
            },
            editText: {
                type: String,
                default: "Editar"
            },
            editIconHtml: {
                type: String,
                default: '<i class="fas fa-pencil-alt fa-fw"></i>'
            },
            /**
             * Transformar dados do formulário antes da exibição do modal.
             */
            formDataTransform: {
                type: Function,
                default: null
            },
            /**
             * Transformar dados do formulário antes da submissão dos dados.
             */
            postDataTransform: {
                type: Function,
                default: null
            },
            hideRemove: {
                type: Boolean,
                default: false
            },
            removeTitle: {
                type: String,
                default: ""
            },
            noRemoveConfirm: {
                type: Boolean,
                default: false
            },
            hideDropdown: {
                type: Boolean,
                default: false
            }
        },

        mixins: [tableUtil],

        components: {
            ModalConfirm,
            ButtonForm,
            InputSearch,
            Vuetable,
            CardTitle
        },

        data() {
            return {
                filterText: this.filterTerm,
                toolbarButtons: [],

                tableData$: [],
                tableFields$: [],
                isTableFieldsReady: false,
                isLoadingData: false,
                dataCancelToken: null,

                formData: {},
                formErrors: {}
            }
        },

        computed: {
            isInnerTitle() {
                return !this.title && this.innerTitle && this.innerTitle.length > 0;
            },
            isTableLoading() {
                return this.tableLoading || this.isLoadingData;
            },
            filterableFields() {
                return this.tableFields.reduce((a, b) => {
                    if (b.filterable) { a.push(b.name.substring(b.name.indexOf(":") + 1)); }
                    return a;
                }, []);
            },
            slotFields() {
                return this.tableFields.reduce((a, b) => {
                    if (b.name.indexOf('__slot') !== -1) a.push(b.name.substring(b.name.indexOf(':') + 1));
                    return a;
                }, []);
            },
            itemRemoving() {
                return this.tableData$.find(el => el.isRemoving === true);
            },
            modalTitleFinal() {
                return this.formData.isEditing && this.modalTitleEdit ? this.modalTitleEdit : this.modalTitle;
            }
        },

        mounted()
        {
            this.initToolbarButtons();
            this.initTableFields();
            this.fetchData();
        },

        methods: {
            /**
             * Inicializar botões da barra de ferramentas.
             */
            initToolbarButtons()
            {
                this.toolbarButtons = [];

                this.toolbarActions.forEach(item => {
                    this.toolbarButtons.push({
                        text:     item.text || "",
                        variant:  item.variant || "secondary",
                        link:     item.link,
                        callback: item.callback || function() {},
                        disabled: item.disabled || false,
                        loading:  item.loading || false
                    })
                });

                if (!this.hideAdd) {
                    this.toolbarButtons.push({
                        text: this.addText,
                        variant: "success",
                        callback: this.showAddModal
                    });
                }
            },

            /**
             * Inicializar campos da tabela.
             */
            initTableFields()
            {
                let hasOptionsField = false;
                this.tableFields$ = [];

                this.tableFields.forEach(item => {
                    if (item.name === "__slot:options") hasOptionsField = true;
                    this.tableFields$.push(item);
                });

                if (!this.hideDropdown && !hasOptionsField)
                {
                    this.tableFields$.push({
                        name: '__slot:options',
                        title: () => '<span class="sr-only">Opções</span>',
                        titleClass: 'col-dropdown',
                        dataClass: 'col-dropdown',
                    });
                }

                this.isTableFieldsReady = true;
            },

            /**
             * Função de ordenação da tabela.
             */
            sortTable(sortOrder)
            {
                return this.sortVuetable(sortOrder, this.tableFields, this.tableData$, () => this.isLoadingData);
            },

            /**
             * Definição de classes para as linhas da tabela.
             */
            onRowClass(dataItem)
            {
                let _classes = [];

                if (dataItem.isRemoved) {
                    _classes.push("table-muted");
                }

                if (this.filterText && this.filterableFields.length > 0)
                {
                    const term = this.filterText.toLowerCase();
                    let notFound = true;

                    this.filterableFields.forEach((field) => {
                        if (notFound && dataItem[field] && dataItem[field].toString().toLowerCase().indexOf(term) !== -1) {
                            notFound = false;
                        }
                    });

                    if (notFound) {
                        _classes.push("d-none");
                    }
                }

                return _classes.join(" ")
            },

            /**
             * Carregar dados da tabela.
             */
            fetchData()
            {
                if (!this.apiUrl) return;

                this.isLoadingData = true;

                const CancelToken = ApiCancel.CancelToken;
                this.dataCancelToken = CancelToken.source();

                this.$api.get(this.apiUrl, { cancelToken: this.dataCancelToken.token })
                    .then (response => {
                        let data = response.data.data;

                        if (this.dataTransform) {
                            data = this.dataTransform(data);
                        }

                        if (this.dataPath) {
                            data = this.$refs.table.getObjectValue(data, this.dataPath);
                        }

                        this.tableData$ = data;
                        this.$refs.table.setData(this.tableData$);
                    })
                    .catch(error => {
                        this.$emit("fetch-error", error);
                        ErrorService.showErrorAlert(error);
                    })
                    .then (() => this.isLoadingData = false);
            },

            /**
             * Cancelar carregamento dos dados da tabela.
             */
            cancelFetch()
            {
                if (this.dataCancelToken) {
                    this.dataCancelToken.cancel();
                }
            },

            /**
             * @return {number}
             */
            getDataLength()
            {
                return this.tableData$.length;
            },

            /**
             * Evento de abertura do modal.
             * Mudar o foco para primeiro elemento editável.
             */
            onModalFormShown(e)
            {
                this.$nextTick(() => {
                    const modalBody = e.target.querySelector(".modal-body");
                    const inputs = modalBody.querySelectorAll("input[type=text],input[type=email],input[type=radio],input[type=checkbox],select,textarea");
                    let i;

                    for (i = 0; i < inputs.length; ++i) {
                        if (!inputs[i].readOnly && !inputs[i].disabled && inputs[i].offsetParent !== null) {
                            inputs[i].focus();
                            break;
                        }
                    }
                });
            },

            /**
             * Evento de clique em um botão da barra de ferramentas.
             */
            onBtnToolbarClick(item)
            {
                if (item.callback) {
                    item.callback(this.showAddModal, this.showEditModal, this.showRemoveConfirm);
                }
            },

            /**
             * Exibir modal para cadastro.
             */
            showAddModal()
            {
                let defaultData = {};
                this.formData = this.formDataTransform ? this.formDataTransform(defaultData) : defaultData;
                this.formErrors = {};
                this.$refs.modalForm.show();
            },

            /**
             * Exibir modal para edição.
             */
            showEditModal(item)
            {
                let defaultData = Object.assign({ isEditing: true }, item);
                this.formData = this.formDataTransform ? this.formDataTransform(defaultData) : defaultData;
                this.formErrors = {};
                this.$refs.modalForm.show();
            },

            /**
             * Cadastrar ou editar item.
             */
            saveItem()
            {
                this.formErrors = {};

                this.$refs.btnFormSubmit.loadingFocus();
                this.$refs.btnFormCancel.disabled = true;

                let postData = this.formData;

                if (this.postDataTransform) {
                    postData = this.postDataTransform(this.formData);
                }

                let postConfig = {
                    method: "post",
                    data: postData,
                    headers: Object.assign({}, this.apiSaveHeaders),
                };

                if (this.formData.isEditing) {
                    postConfig.method = this.apiEditMethod || "put";
                }

                if (this.apiSaveUrl) {
                    if (typeof this.apiSaveUrl === "function") {
                        postConfig.url = this.apiSaveUrl(this.formData, this.formData.isEditing);
                    }
                    else {
                        postConfig.url = this.apiSaveUrl;
                    }
                }
                else {
                    postConfig.url = this.apiUrl;
                }

                this.$api.request(postConfig)
                    .then(response => {
                        const data = response.data.data;

                        this.$refs.modalForm.hide();
                        this.fetchData();

                        if (this.formData.isEditing) {
                            this.$emit("updated", this.formData, data);
                        }
                        else {
                            this.$emit("created", this.formData, data);
                        }
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnFormCancel.disabled = false;
                        this.$refs.btnFormSubmit.setLoading(false);
                    });
            },

            /**
             * Exibir modal de confirmação para exclusão.
             */
            showRemoveConfirm(item)
            {
                this.$set(item, "isRemoving", true);

                if (!this.noRemoveConfirm) {
                    this.$nextTick(() => this.$refs.modalRemove.show());
                }
                else {
                    this.removeItem();
                }
            },

            /**
             * Cancelar exclusão.
             */
            cancelRemove()
            {
                const idx = this.tableData$.findIndex(el => el.isRemoving === true);
                this.$set(this.tableData$[idx], "isRemoving", false);
            },

            /**
             * Excluir item.
             */
            removeItem()
            {
                let idx = this.tableData$.findIndex(el => el.isRemoving === true);
                let item = Object.assign({}, this.tableData$[idx]);

                this.$emit("remove", item);
                this.$set(this.tableData$[idx], "isRemoved", true);

                setTimeout(() => {
                    idx = this.tableData$.findIndex(el => el.isRemoved === true);
                    this.tableData$.splice(idx, 1);
                    this.$emit("removed");
                }, 3000);
            },

            setFilterText(text)
            {
                this.filterText = text;
            }
        },
        watch: {
            tableData: function(val)
            {
                this.tableData$ = this.$refs.table.getObjectValue(val, this.dataPath);
                this.$refs.table.setData(this.tableData$);
            }
        }
    }
</script>
