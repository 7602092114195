<template>
    <hosting-domain-template :resource="resource">
        <card-title inner-body>
            <h4 class="card-title-inner">Editor de DNS</h4>
            <p class="mb-2">
                Com o Editor de DNS você pode adicionar, editar ou excluir registros na zona DNS de seu domínio.
            </p>
            <p class="mb-6">
                O nome do registro na tabela abaixo é um prefixo do seu domínio atual. Por exemplo, em um registro onde
                o nome exibido é <strong>www</strong>, o seu nome real será <strong>www.seudominio.com.br</strong>.
                Nos registros onde o nome for <strong>@</strong> entende-se que o nome real é apenas o seu domínio, sem um prefixo.
            </p>

            <div class="btn-toolbar mb-6" role="toolbar">
                <input-search v-model="filterText" />
                <div class="btn-group ml-auto" role="group">
                    <button-form ref="btnExportDns" theme="secondary" @click="exportDns">Exportar</button-form>
                </div>
                <div class="btn-group ml-2" role="group">
                    <button ref="btnResetDns" type="button" class="btn btn-secondary" @click="showResetDnsConfirm">Restaurar padrões</button>
                </div>
                <div class="btn-group ml-2" role="group">
                    <button type="button" class="btn btn-success" @click="showCreateRecordModal">Adicionar registro</button>
                </div>
            </div>

            <div class="table-responsive-sm">
                <table-dns
                    :data="records"
                    :filter="filterText"
                    :loading="isLoadingDnsRecords"
                    @edit="showEditRecordModal"
                    @remove="showRemoveRecordConfirm" />
            </div>
        </card-title>

        <b-modal ref="modalDnsRecord" size="lg" :title="modalDnsRecordTitle" centered hide-footer hide-header-close @shown="$refs.recordName.focus()">
            <form @submit.prevent="saveRecord">
                <form-group v-if="!record.isEditing" label="Tipo de registro" :error="formErrors.type">
                    <div>
                        <b-form-radio-group buttons button-variant="outline-primary" v-model="record.type" :options="recordTypes" />
                    </div>
                </form-group>

                <form-group label="Tipo de registro" label-for="recordType" v-show="record.isEditing">
                    <input type="text" id="recordType" class="form-control" readonly :value="record.type">
                </form-group>

                <form-group label="Nome" label-for="recordName" :error="formErrors.name">
                    <input ref="recordName" type="text" id="recordName" class="form-control" spellcheck="false" v-model="record.name" :readonly="record.isEditing">
                </form-group>

                <form-group label="Serviço" label-for="recordService" :error="formErrors.service" v-show="record.type === 'SRV' && !record.isEditing">
                    <input type="text" id="recordService" class="form-control" spellcheck="false" v-model="record.service">
                </form-group>

                <form-group label="Protocolo" label-for="recordProtocol" :error="formErrors.protocol" v-show="record.type === 'SRV' && !record.isEditing">
                    <input type="text" id="recordProtocol" class="form-control" spellcheck="false" v-model="record.protocol">
                </form-group>

                <form-group :label="record.type === 'SRV' ? 'Destino' : 'Valor do registro'" label-for="recordData" :error="formErrors.value" v-show="record.type !== 'TXT'">
                    <input type="text" id="recordData" class="form-control" spellcheck="false" v-model="record.value">
                </form-group>

                <form-group label="Valor do registro" label-for="recordDataTxt" :error="formErrors.value" v-show="record.type === 'TXT'">
                    <textarea id="recordDataTxt" rows="6" class="form-control" v-model="record.value"></textarea>
                </form-group>

                <form-group label="Prioridade" label-for="recordPriority" v-show="record.type === 'MX' || record.type === 'SRV'" :error="formErrors.priority">
                    <input type="text" id="recordPriority" class="form-control" maxlength="5" v-model="record.priority" v-only-digits>
                </form-group>

                <form-group label="Peso" label-for="recordWeight" v-show="record.type === 'SRV'" :error="formErrors.weight">
                    <input type="text" id="recordWeight" class="form-control" maxlength="5" v-model="record.weight" v-only-digits>
                </form-group>

                <form-group label="Porta" label-for="recordPort" v-show="record.type === 'SRV'" :error="formErrors.port">
                    <input type="text" id="recordPort" class="form-control" maxlength="5" v-model="record.port" v-only-digits>
                </form-group>

                <form-group :error="formErrors.ttl" v-show="record.type !== 'MX'">
                    <label class="form-label" for="recordTtl">
                        TTL (Time To Live)
                        <!-- TODO -->
                        <!--<a data-toggle="collapse" href="#recordTtlHelp" aria-expanded="false" aria-controls="recordTtlHelp" title="Clique para expandir">
                            <i class="fas fa-question-circle"></i>
                        </a>-->
                    </label>
                    <!--<div id="recordTtlHelp" class="collapse">
                        <p class="form-text text-muted">
                            Determina quanto tempo levará para uma alteração nesse registro ser efetuada.
                        </p>
                    </div>-->
                    <select id="recordTtl" class="form-control" v-model="record.ttl">
                        <option value="86400">24 horas</option>
                        <option value="43200">12 horas</option>
                        <option value="21600">6 horas</option>
                        <option value="14400">4 horas</option>
                        <option value="10800">3 horas</option>
                        <option value="3600">1 hora</option>
                        <option value="1800">30 minutos</option>
                        <option value="600">10 minutos</option>
                        <option value="60">1 minuto</option>
                    </select>
                </form-group>

                <div class="form-buttons text-right">
                    <button ref="btnDnsRecordCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalDnsRecord.hide()">Cancelar</button>
                    <button-form ref="btnDnsRecordSubmit" type="submit" theme="success" class="btn-action">Salvar</button-form>
                </div>
            </form>
        </b-modal>

        <modal-confirm ref="modalResetDns" title="Restaurar configuração padrão" @confirm="resetDns">
            <p class="mb-2">
                Todos os registros DNS atuais serão <strong>excluídos</strong> e os registros padrão serão recriados.
            </p>
            <p class="mb-0">
                Tem certeza que deseja continuar?
            </p>
        </modal-confirm>

        <modal-confirm ref="modalRemoveRecord" title="Excluir registro DNS" danger @confirm="removeRecord">
            <p class="mb-2">
                Tem certeza que deseja excluir o registro DNS abaixo?
            </p>
            <table class="table table-sm table-borderless mb-0">
                <tr>
                    <th class="col-w-12">Tipo:</th><td>{{ itemToRemove.type }}</td>
                </tr>
                <tr>
                    <th class="col-w-12">Nome:</th><td>{{ itemToRemove.name || '@' }}</td>
                </tr>
                <tr>
                    <th class="col-w-12">Dados:</th>
                    <td>
                        <span v-if="itemToRemove.type === 'TXT'">
                            {{ itemToRemove.value.length > 30 ? itemToRemove.value.substr(0,30) + '...' : itemToRemove.value }}
                        </span>
                        <span v-else>{{ itemToRemove.value }}</span>
                    </td>
                </tr>
            </table>
        </modal-confirm>
    </hosting-domain-template>
</template>

<script>
    import { mapActions }        from 'vuex'
    import AjaxService           from '@/services/AjaxService'
    import ErrorService          from '@/services/error.service'
    import InputSearch           from '@/components/atoms/InputSearch'
    import ButtonForm            from '@/components/atoms/ButtonForm'
    import ModalConfirm          from '@/components/atoms/ModalConfirm'
    import CardTitle             from '@/components/molecules/CardTitle'
    import TableDns              from '@/components/molecules/TableDns'
    import FormGroup             from '@/components/molecules/FormGroup'
    import HostingDomainTemplate from '@/components/templates/HostingDomainTemplate'

    export default {
        props: ['resource'],
        components: {
            ModalConfirm,
            ButtonForm,
            FormGroup,
            InputSearch,
            CardTitle,
            TableDns,
            HostingDomainTemplate
        },
        data() {
            return {
                recordTypes: [
                    { value: 'A',     text: 'A' },
                    { value: 'AAAA',  text: 'AAAA' },
                    { value: 'CNAME', text: 'CNAME' },
                    { value: 'TXT',   text: 'TXT' },
                    { value: 'MX',    text: 'MX' },
                    { value: 'SRV',   text: 'SRV' },
                ],

                records: [],
                filterText: "",
                itemToRemove: {},

                record: {},
                formErrors: {},

                isLoadingDnsRecords: false
            }
        },
        computed: {
            modalDnsRecordTitle: function() {
                return this.record.isEditing ? "Editar registro DNS" : "Adicionar registro DNS";
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getDnsRecords();
        },
        methods: {
            ...mapActions({
                infoNotification: 'notification/info',
                closeNotification: 'notification/close',
                showErrorAlert: 'alert/error',
                showLoading: 'loading/show',
                hideLoading: 'loading/hide',
            }),

            /**
             * Listar registros DNS.
             */
            getDnsRecords()
            {
                this.isLoadingDnsRecords = true;

                return this.$api.get(`/domain/${this.resource}/dns`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => this.records = response.data.data)
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingDnsRecords = false);
            },

            /**
             * Exibir modal para cadastro de um registro DNS.
             */
            showCreateRecordModal()
            {
                this.record = { type: 'A', ttl: 3600, priority: 0 };
                this.formErrors = {};

                this.$refs.modalDnsRecord.show();
            },

            /**
             * Exibir modal para edição de um registro DNS.
             */
            showEditRecordModal(item)
            {
                this.record = Object.assign({}, item);
                this.record.isEditing = true;
                this.formErrors = {};

                this.$refs.modalDnsRecord.show();
            },

            /**
             * Cadastrar ou atualizar um registro DNS.
             */
            saveRecord()
            {
                this.formErrors = {};

                this.$refs.btnDnsRecordSubmit.$el.focus();
                this.$refs.btnDnsRecordSubmit.setLoading();
                this.$refs.btnDnsRecordCancel.disabled = true;

                let postData = {
                    url: `/domain/${this.resource}/dns`,
                    method: "post",
                    data: this.record,
                };

                if (this.record.isEditing) {
                    postData.url += "/" + this.record.id;
                    postData.method = "put";
                }

                this.$api.request(postData)
                    .then(() => {
                        this.$refs.modalDnsRecord.hide();
                        this.getDnsRecords();
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnDnsRecordCancel.disabled = false;
                        this.$refs.btnDnsRecordSubmit.setLoading(false);
                    });
            },

            /**
             * Exportar arquivo com registros DNS.
             */
            exportDns()
            {
                let alert;

                this.$refs.btnExportDns.setLoading(true);
                this.infoNotification({ message: 'Gerando arquivo...', timeout: 0 }).then(item => alert = item);

                this.$api.post(`/domain/${this.resource}/dns/export`)
                    .then (response => {
                        const disposition = response.headers['content-disposition'] || '';
                        const matches = /"([^"]*)"/.exec(disposition);
                        const filename = (matches != null && matches[1] ? matches[1] : 'records.txt');

                        // The actual download
                        const contentType = response.headers['content-type'] || 'text/plain';
                        let blob = new Blob([response.data], { type: contentType });

                        let anchor = document.createElement('a');
                        anchor.href = window.URL.createObjectURL(blob);
                        anchor.download = filename;
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => {
                        this.closeNotification(alert.id);
                        this.$refs.btnExportDns.setLoading(false);
                    });
            },

            /**
             * Exibir confirmação para restauração do DNS.
             */
            showResetDnsConfirm()
            {
                this.$refs.modalResetDns.show();
            },

            /**
             * Restaurar configuração padrão do DNS.
             */
            resetDns()
            {
                this.showLoading();
                this.$refs.btnResetDns.blur();

                this.$api.post(`/domain/${this.resource}/dns/reset`)
                    .then (response => this.getDnsRecords())
                    .catch(error => alert("Ocorreu um erro ao tentar restaurar os registros. Por favor, atualize sua página e tente novamente."))
                    .then (() => this.hideLoading());
            },

            /**
             * Exibir confirmação para exclusão de um registro DNS.
             */
            showRemoveRecordConfirm(item)
            {
                this.itemToRemove = Object.assign({}, item);
                this.$refs.modalRemoveRecord.show();
            },

            /**
             * Excluir registro DNS.
             */
            removeRecord()
            {
                const id = this.itemToRemove.id;

                // let idx = this.records.findIndex(el => el.id === id);
                // this.$set(this.records[idx], 'isRemoving', true);

                this.isLoadingDnsRecords = true;

                this.$api.delete(`/domain/${this.resource}/dns/${id}`)
                    .then (response => this.getDnsRecords())
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.isLoadingDnsRecords = false;
                    });
            }
        }
    }
</script>