import Vue              from 'vue'
import VueRouter        from 'vue-router'
import store            from '@/store'
import meta             from './meta'

import AdminBase        from '@/views/AdminBase'
import AdminBasicBase   from '@/views/AdminBasicBase'
import AdminHostingBase from '@/views/AdminHostingBase'
import AdminDomainBase  from '@/views/AdminDomainBase'
import HostingBase      from '@/views/HostingBase'
import DomainBase       from '@/views/DomainBase'

import * as admin       from '@/views/admin'
import * as hosting     from '@/views/hosting'
import * as domain      from '@/views/domain'
import * as payment     from '@/views/payment'

import PageNotFound     from '@/views/error/PageNotFound'

import UserService      from '@/services/user.service'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: AdminBase,
        children: [
            {
                path: '',
                component: AdminHostingBase,
                children: [
                    { path: '',                  component: admin.AdminDashboard, meta: meta.dashboard },
                    { path: 'hostings',          component: admin.AdminHostings   },
                    { path: 'hosting/add',       component: hosting.HostingCreate },
                    { path: 'hosting/migration', component: hosting.HostingMigration },
                    { path: 'hosting/wordpress', component: hosting.HostingWordpress },
                    { path: 'hosting/transfer',  component: hosting.HostingTransferConfirmation },
                    { path: 'hosting/restore',   component: hosting.HostingRestore },
                    {
                        path: 'hosting/:resource([a-zA-Z0-9-_\\.]+\\.[a-zA-Z0-9][a-zA-Z0-9-_]*[a-zA-Z0-9][\\/]?)',
                        component: HostingBase,
                        props: true,
                        beforeEnter: (to, from, next) => {
                            const resourceLower = to.params.resource.toLowerCase();

                            if (resourceLower !== to.params.resource) {
                                const newPath = to.path.replace('hosting/' + to.params.resource, 'hosting/' + resourceLower);
                                next({ path: newPath, replace: true, query: to.query });
                            }
                            else {
                                next();
                            }
                        },
                        children: [
                            { path: '',                  component: hosting.HostingSummary,       props: true, meta: meta.hosting.summary },
                            { path: 'charts',            component: hosting.HostingCharts,        props: true },
                            { path: 'domain',            component: hosting.HostingDomain,        props: true, meta: meta.hosting.domain },
                            { path: 'domain/edit',       component: hosting.HostingDomainEdit,    props: true, meta: meta.hosting.domain_edit },
                            { path: 'dns',               component: hosting.HostingDns,           props: true, meta: meta.hosting.dns },
                            { path: 'alias',             component: hosting.HostingAlias,         props: true, meta: meta.hosting.alias },
                            { path: 'subdomain',         component: hosting.HostingSubdomain,     props: true, meta: meta.hosting.subdomain },
                            { path: 'redirect',          component: hosting.HostingRedirect,      props: true, meta: meta.hosting.redirect },
                            { path: 'files',             component: hosting.HostingFiles,         props: true, meta: meta.hosting.files },
                            { path: 'ftp',               component: hosting.HostingFtp,           props: true, meta: meta.hosting.ftp },
                            { path: 'deploy',            component: hosting.HostingDeploy,        props: true, meta: meta.hosting.deploy },
                            { path: 'backup',            component: hosting.HostingBackup,        props: true, meta: meta.hosting.backup },
                            { path: 'database',          component: hosting.HostingDatabase,      props: true, meta: meta.hosting.database },
                            { path: 'email',             component: hosting.HostingEmail,         props: true, meta: meta.hosting.email },
                            { path: 'email/lists',       component: hosting.HostingEmailLists,    props: true, meta: meta.hosting.email },
                            { path: 'email/access',      component: hosting.HostingEmailAccess,   props: true, meta: meta.hosting.email },
                            { path: 'email/:account',    component: hosting.HostingEmailSettings, props: true, meta: meta.hosting.email },
                            { path: 'email/lists/:list', component: hosting.HostingEmailListEdit, props: true, meta: meta.hosting.email },
                            { path: 'antispam',          component: hosting.HostingAntispam,      props: true, meta: meta.hosting.email },
                            { path: 'webmail',           component: hosting.HostingWebmail,       props: true, meta: meta.hosting.email },
                            { path: 'apps',              component: hosting.HostingApps,          props: true, meta: meta.hosting.apps },
                            { path: 'app/install',       component: hosting.HostingAppInstall,    props: true, meta: meta.hosting.apps },
                            { path: 'php',               component: hosting.HostingPhp,           props: true, meta: meta.hosting.php },
                            { path: 'ssl',               component: hosting.HostingSsl,           props: true, meta: meta.hosting.ssl },
                            { path: 'ssh',               component: hosting.HostingSsh,           props: true, meta: meta.hosting.ssh },
                            { path: 'cron',              component: hosting.HostingCron,          props: true, meta: meta.hosting.cron },
                            { path: 'logs',              component: hosting.HostingLogs,          props: true, meta: meta.hosting.logs },
                            { path: 'plan',              component: hosting.HostingPlan,          props: true },
                            { path: 'transfer',          component: hosting.HostingTransfer,      props: true },
                            { path: 'balancer',          component: hosting.HostingBalancer,      props: true },
                            { path: 'remove',            component: hosting.HostingRemove,        props: true },
                        ]
                    }
                ]
            },
            {
                path: '',
                component: AdminDomainBase,
                children: [
                    { path: 'domains', component: admin.AdminDomains },
                    { path: 'domain/add', component: domain.DomainAdd },
                    {
                        path: 'domain/:resource([a-zA-Z0-9-_\\.]+\\.[a-zA-Z0-9][a-zA-Z0-9-_]*[a-zA-Z0-9][\\/]?)',
                        component: DomainBase,
                        props: true,
                        beforeEnter: (to, from, next) => {
                            const resourceLower = to.params.resource.toLowerCase();

                            if (resourceLower !== to.params.resource) {
                                const newPath = to.path.replace('domain/' + to.params.resource, 'domain/' + resourceLower);
                                next({ path: newPath, replace: true, query: to.query });
                            }
                            else {
                                next();
                            }
                        },
                        children: [
                            { path: '',            component: domain.DomainInfo,        props: true },
                            { path: 'nameservers', component: domain.DomainNameservers, props: true },
                            { path: 'renew',       component: domain.DomainRenew,       props: true },
                            { path: 'contact',     component: domain.DomainContact,     props: true },
                        ]
                    }
                ],
            },

            {
                path: '',
                component: AdminBasicBase,
                children: [
                    { path: 'first-steps',             component: admin.AdminFirstSteps       },
                    { path: 'hosting/create',          component: admin.AdminHostingCreate    },
                    { path: 'settings',                component: admin.AdminSettings         },
                    { path: 'payment',                 redirect: '/payment/recharge'          },
                    { path: 'payment/auto-recharge',   redirect: '/payment/recharge'          },
                    { path: 'payment/recharge',        component: payment.PaymentAutoRecharge },
                    { path: 'payment/manual-recharge', component: payment.PaymentRecharge     },
                    { path: 'payment/history',         component: payment.PaymentHistory      },
                    { path: 'payment/billing',         component: payment.PaymentBilling      },
                    {
                        path: 'support/tickets',
                        component: () => import(/* webpackChunkName: 'support' */ '../views/support/SupportTickets')
                    },
                    {
                        path: 'support/ticket/:resource',
                        component: () => import(/* webpackChunkName: 'support' */ '../views/support/SupportTicket'),
                        props: true
                    },
                    {
                        path: 'user/profile',
                        component: () => import(/* webpackChunkName: 'user' */ '../views/user/UserProfile')
                    },
                    {
                        path: 'user/notifications',
                        component: () => import(/* webpackChunkName: 'user' */ '../views/user/UserNotifications')
                    },
                    {
                        path: 'user/access',
                        component: () => import(/* webpackChunkName: 'user' */ '../views/user/UserAccess')
                    },
                    {
                        path: 'user/access/:resource',
                        component: () => import(/* webpackChunkName: 'user' */ '../views/user/UserPermissions'),
                        props: true
                    },
                    {
                        path: 'affiliate',
                        component: () => import(/* webpackChunkName: 'user' */ '../views/user/UserAffiliate'),
                    },
                    {
                        path: 'referral',
                        component: () => import(/* webpackChunkName: 'user' */ '../views/user/UserReferral'),
                    },
                    {
                        // TODO: Rota temporariamente nesse bloco
                        path: 'domain/:resource/register',
                        component: domain.DomainRegister,
                        props: true
                    },
                ]
            },
        ]
    },
    {
        path: '/welcome',
        component: admin.AdminWelcome
    },
    {
        path: '/migration',
        component: () => import(/* webpackChunkName: 'auth' */ '../views/AuthBase'),
        meta: { guest: true },
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/guest/GuestMigration'),
                meta: { guest: true }
            }
        ]
    },
    {
        path: '/become-affiliate',
        component: () => import(/* webpackChunkName: 'auth' */ '../views/AuthBase'),
        meta: { guest: true },
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/guest/GuestAffiliate'),
                meta: { guest: true }
            }
        ]
    },
    {
        path: '/become-partner',
        component: () => import(/* webpackChunkName: 'auth' */ '../views/AuthBase'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/admin/AdminBecomePartner'),
            }
        ]
    },
    {
        path: '/survey-profile',
        component: () => import(/* webpackChunkName: 'auth' */ '../views/AuthBase'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/admin/AdminSurveyProfile'),
            }
        ]
    },
    {
        path: '/auth',
        component: () => import(/* webpackChunkName: 'auth' */ '../views/AuthBase'),
        meta: { guest: true },
        children: [
            // {
            //     path: 'login',
            //     component: () => import(/* webpackChunkName: 'auth' */ '../views/auth/AuthLogin'),
            //     meta: { guest: true }
            // },
            {
                path: 'register',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/auth/AuthRegister'),
                meta: { guest: true }
            },
            {
                path: 'reset-password',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/auth/AuthResetPassword'),
                meta: { guest: true }
            },
            {
                // Verificação do e-mail de cadastro
                path: 'verification',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/auth/AuthVerification'),
                meta: { guest: true }
            },
            {
                // Reenviar e-mail de verificação
                path: 'verification/resend',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/auth/AuthVerificationResend'),
                meta: { guest: true }
            },
            {
                // Verificação de telefone
                path: 'verification/phone',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/auth/AuthVerificationPhone'),
                meta: { guest: true }
            },
            {
                // Verificação de dois fatores
                path: 'verification/2fa',
                component: () => import(/* webpackChunkName: 'auth' */ '../views/auth/AuthVerification2FA'),
                meta: { guest: true },
            }
        ]
    },
    {
        path: '/auth/login',
        component: () => import(/* webpackChunkName: 'auth' */ '../views/auth/v2/AuthLogin'),
        meta: { guest: true }
    },
    {
        path: '/logout',
        meta: { guest: true },
        beforeEnter: (to, from, next) => {
            UserService.logout();
            next('/auth/login');
        }
    },

    // Rotas do painel antigo
    { path: '/login',               redirect: '/auth/login'               },
    { path: '/register',            redirect: '/auth/register'            },
    { path: '/verification',        redirect: '/auth/verification'        },
    { path: '/verification/resend', redirect: '/auth/verification/resend' },
    { path: '/verification/phone',  redirect: '/auth/verification/phone'  },
    { path: '/login/new-password',  redirect: '/auth/reset-password'      },
    { path: '/user/settings',       redirect: '/user/profile'             },

    // Página não encontrada
    { path: '/404', component: PageNotFound },
    { path: '*',    redirect: '/404'        },
]

let router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        if (to.path.startsWith('/hosting/') && from.path.startsWith('/hosting/')) return false;
        else return savedPosition || { x: 0, y: 0 }
    },
    routes
})

router.beforeEach((to, from, next) => {

    let token = '';

    try { token = Vue.cookie.get('access_token') }
    catch(e) {}

    // Se não possui token e a rota precisa de autenticação, redireciona para login
    if (!token && !to.matched.some(r => r.meta && r.meta.guest))
    {
        let _query = {};

        if (to.path !== '/') {
            _query.redirect = to.fullPath;
        }

        next({ path: '/auth/login', query: _query, replace: true });
    }
    else {
        next()
    }
})

router.afterEach((to) => {

    // GTM
    if (typeof window.GTM_CONTAINER_ID !== 'undefined' && typeof window.dataLayer !== 'undefined')
    {
        window.dataLayer.push({
            'event': 'Page View',
            'pageUrl': to.fullPath,
            'pageTitle': document.title,
        });
    }

    // Google Analytics
    if (typeof window.GA_TRACKING_ID !== 'undefined' && typeof window.gtag !== 'undefined')
    {
        gtag('config', window.GA_TRACKING_ID, {
            app_name: 'Hostoo App',
            page_path: to.fullPath,
            page_title: document.title,
            send_page_view: true,
        });
    }

    // Google Ads
    if (typeof window.AW_CONVERSION_ID !== 'undefined' && typeof window.gtag !== 'undefined')
    {
        const user_id = (typeof store.state.user !== 'undefined' && store.state.user.user) ? store.state.user.user.id : '';

        gtag('event', 'page_view', {
            'send_to': window.AW_CONVERSION_ID,
            'user_id': user_id,
        });
    }
})

export default router
