<template>
    <admin-page-template>
        <card-hostings ref="cardHostings" />
    </admin-page-template>
</template>

<script>
    import CardHostings      from '@/components/organisms/CardHostings'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'

    export default {
        components: {
            AdminPageTemplate,
            CardHostings
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardHostings.cancelFetch();
            next();
        }
    }
</script>