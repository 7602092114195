<template>
    <div class="h-m-banner-promotion">
        <component :is="link ? 'router-link': 'div'" :to="link" @click.native="trackClickEvent">
            <div class="row align-items-center">
                <div class="col-md-3">
                    <img :src="logoImg" class="img-fluid h-m-banner-promotion--img" :alt="logoImgAlt">
                </div>
                <div class="col-md-6">
                    <div class="h-m-banner-promotion--content">
                        <slot name="content"></slot>

                        <router-link
                            v-if="buttonText && link"
                            :to="link"
                            class="btn"
                            v-bind:class="buttonClasses"
                        >
                            {{ buttonText }}
                        </router-link>

                        <button
                            v-else-if="buttonText && action"
                            type="button"
                            class="btn"
                            v-bind:class="buttonClasses"
                            @click="buttonAction"
                        >
                            {{ buttonText }}
                        </button>
                    </div>
                </div>
                <div class="col-md-3">
                    <banner-clock :time="expiration" />
                </div>
            </div>
        </component>
    </div>
</template>

<script>
    import BannerClock from '@/components/molecules/BannerClock'
    import UserService from '@/services/user.service'

    export default {
        components: {
            BannerClock
        },
        props: {
            expiration: {
                type: Number,
                default: 0  
            },
            link: {
                type: String,
                default: "/payment/manual-recharge",
            },
            action: {
                type: Function,
                default: null
            },
            buttonText: {
                type: String,
                default: "",
            },
            buttonClasses: {
                type: String,
                default: "",
            },
            logoImg: {
                type: String,
                default: "",
            },
            logoImgAlt: {
                type: String,
                default: "",
            },
            eventCategory: {
                type: String,
                default: "",
            },
            eventLabel: {
                type: String,
                default: "",
            },
        },
        data() {
            return {

            }
        },
        created()
        {

        },
        beforeDestroy()
        {

        },
        methods:
        {
            trackClickEvent()
            {
                if (!this.eventCategory && !this.eventLabel) return;

                UserService.trackEvent({
                    "action"   : "click",
                    "category" : this.eventCategory,
                    "label"    : this.eventLabel,
                });
            },

            buttonAction()
            {
                if (this.action) {
                    this.action();
                }

                this.trackClickEvent();
            }
        }
    }
</script>

<style lang="stylus">
    .h-m-banner-promotion {
        border-radius: 6px;
        background: linear-gradient(90deg, #0E1116 0%, #0E1428 100%);
        padding: 0.75rem 1rem;

        > a {
            text-decoration none !important
            color white
        }

        .h-m-banner-promotion--content {
            font-size: 1.25rem;
            line-height: 1.25;
            text-align: center;

            p {
                margin-bottom: 0;

                span {
                    font-weight: 700;
                    color: #FB3640;
                }
            }
        }
    }
</style>
