<template>
    <span class="h-a-toggle-switch switch switch-sm">
        <input type="checkbox" :id="id" v-model="toggle" @change="onChange" :disabled="disabled">
        <label :for="id" class="mb-0"></label>
    </span>
</template>

<script>
    export default {
        props: {
            value: {
                type: [String, Boolean],
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            callback: {
                type: Function,
                default: () => {},
            }
        },
        data () {
            return {
                id: null,
                toggle: null,
            }
        },
        mounted() {
            this.id = 'switch-' + this._uid;
            this.toggle = this.value;
        },
        methods: {
            onChange()
            {
                this.$emit('input', this.toggle);
                this.$emit('change');
                this.callback();
            }
        },
        watch: {
            value: function (val) {
                this.toggle = val;
            }
        }
    }
</script>