<template>
    <hosting-settings-template :resource="resource">
        <card-table
            ref="cardJobs"
            inner-title="Tarefas Cron"
            add-text="Adicionar tarefa"
            modal-title="Adicionar tarefa"
            :table-fields="tableJobsFields"
            :api-url="`/hosting/${resource}/cron`"
            :api-save-url="getApiSaveUrl"
            data-path="tasks"
            :form-data-transform="getFormData"
            remove-title="Excluir tarefa"
            table-empty-text="Nenhuma tarefa encontrada"
            @remove="removeJob"
        >
            <template slot="intro-text">
                Agende tarefas para serem executadas de forma automática em tempos pré-configurados.
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="Intervalo de execução" label-for="jobInterval" :error="formErrors.interval">
                    <select id="jobInterval" class="form-control custom-select" v-model="formData.interval">
                        <option value="5">A cada 5 minutos</option>
                        <option value="10">A cada 10 minutos</option>
                        <option value="30">A cada 30 minutos</option>
                        <option value="60">A cada 1 hora</option>
                        <option value="D">Diariamente</option>
                        <option value="S">Semanalmente</option>
                        <option value="M">Mensalmente</option>
                        <option value="P">Personalizado</option>
                    </select>
                </form-group>

                <form-group label="Dia da semana" label-for="jobDayWeek" v-show="formData.interval === 'S'" :error="formErrors.dayWeek">
                    <select id="jobDayWeek" class="form-control custom-select" v-model="formData.dayWeek">
                        <option value="1">Segunda-feira</option>
                        <option value="2">Terça-feira</option>
                        <option value="3">Quarta-feira</option>
                        <option value="4">Quinta-feira</option>
                        <option value="5">Sexta-feira</option>
                        <option value="6">Sábado</option>
                        <option value="0">Domingo</option>
                    </select>
                </form-group>

                <form-group label="Dia do mês" label-for="jobDayMonth" v-show="formData.interval === 'M'" :error="formErrors.dayMonth">
                    <input type="tel" id="jobDayMonth" class="form-control" placeholder="12" v-model="formData.dayMonth" v-mask="'##'">
                </form-group>

                <form-group label="Horário" label-for="jobTime" v-show="['D','S','M'].indexOf(formData.interval) !== -1" :error="formErrors.time">
                    <input type="tel" id="jobTime" class="form-control" placeholder="00:00" v-model="formData.time" v-mask="'##:##'">
                </form-group>

                <form-group v-show="formData.interval === 'P'" :error="formErrors.expression">
                    <label class="form-label mb-0" for="jobExpression">Intervalo no padrão Cron</label>
                    <p class="form-text-sm">
                        Formato: &#x3C;minuto&#x3E; &#x3C;hora&#x3E; &#x3C;dia-do-mes&#x3E; &#x3C;mes&#x3E; &#x3C;dia-da-semana&#x3E;
                    </p>
                    <input type="text" id="jobExpression" class="form-control" placeholder="* * * * *" spellcheck="false" v-model="formData.expression">
                </form-group>

                <form-group label="Comando" label-for="jobCommand" :error="formErrors.command">
                    <input type="text" id="jobCommand" class="form-control text-monospace" spellcheck="false" v-model="formData.command">
                </form-group>

                <div class="alert alert-secondary" role="alert">
                    <p class="mb-1">
                        <strong>Exemplo para comandos PHP:</strong>
                    </p>
                    <p class="mb-0">
                        <span class="text-monospace">/usr/local/bin/php ~/public_html/caminho/do/script.php</span>
                    </p>
                </div>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir a tarefa abaixo?</p>
                <table class="table table-sm table-borderless mb-0">
                    <tr>
                        <th class="col-w-12">Intervalo:</th>
                        <td>{{ item.expression }}</td>
                    </tr>
                    <tr>
                        <th class="col-w-12">Comando:</th>
                        <td>
                            <span v-if="item.command" :title="item.command" class="text-monospace">
                                {{ item.command.length > 30 ? item.command.substr(0,30) + '...' : item.command }}
                            </span>
                        </td>
                    </tr>
                </table>
            </template>
        </card-table>
    </hosting-settings-template>
</template>

<script>
    import { mapActions }          from 'vuex'
    import FormGroup               from '@/components/molecules/FormGroup'
    import CardTable               from '@/components/organisms/CardTable'
    import HostingSettingsTemplate from '@/components/templates/HostingSettingsTemplate'

    export default {
        props: ['resource'],
        components: {
            FormGroup,
            CardTable,
            HostingSettingsTemplate
        },
        data() {
            return {
                tableJobsFields: [
                    {
                        name: 'expression',
                        title: 'Intervalo',
                        titleClass: 'col-w-32',
                        dataClass: 'col-w-32',
                    },
                    {
                        name: 'command',
                        sortField: 'command',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Comando',
                        dataClass: 'text-monospace',
                    }
                ]
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardJobs.cancelFetch();
            next();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * URLs de cadastro e edição.
             */
            getApiSaveUrl(data, isEditing)
            {
                return !isEditing ?
                    `/hosting/${this.resource}/cron` :
                    `/hosting/${this.resource}/cron/${data.id}`;
            },

            /**
             * Transformar dados do formulário ao abrir modal de cadastro e edição.
             */
            getFormData(data)
            {
                if (!data.isEditing) {
                    return {
                        interval: "5",
                        dayWeek: 1
                    };
                }

                return data;
            },

            /**
             * Excluir tarefa.
             */
            removeJob(item)
            {
                const id = item.id;

                this.$api.delete(`/hosting/${this.resource}/cron/${id}`)
                    .then (response => {})
                    .catch(error => this.showErrorAlert(error));
            },
        }
    }
</script>