import Vue from 'vue'
import Vuex from 'vuex'

import { user }         from './user.module'
import { hosting }      from './hosting.module'
import { domain }       from './domain.module'
import { loading }      from './loading.module'
import { alert }        from './alert.module'
import { notification } from './notification.module'
import { announcement } from './announcement.module'
import { tutorial }     from './tutorial.module'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        hosting,
        domain,
        loading,
        alert,
        notification,
        announcement,
        tutorial
    }
})
