<script>
    import { Line }             from "vue-chartjs"
    import * as ChartAnnotation from "chartjs-plugin-annotation"
    import moment               from "moment"

    export default {
        extends: Line,
        props: {
            title: {
                type: String,
                default: ""
            },
            titleSize: {
                type: [Number, String],
                default: 40
            },
            beforeRender: {
                type: Function,
                default: null
            },
            theme: {
                type: String,
                default: "green",
                validator: function (value) {
                    return ["green", "blue"].indexOf(value) !== -1
                }
            },
            extraTitle: {
                type: String,
                default: "",
            }
        },
        data: () => ({
            themes: {
                green: {
                    borderColor: 'rgb(248, 129, 150)',
                    backgroundColor: 'rgba(248, 129, 150, 0.9)',
                },
                blue: {
                    borderColor: 'rgb(101, 201, 219)',
                    backgroundColor: 'rgba(101, 201, 219, 0.9)',
                    borderColorExtra: 'rgb(69, 48, 126)',
                    backgroundColorExtra: 'rgba(69, 48, 126, 0.9)',
                }
            },
            chartdata: {
                datasets: [
                    {
                        fill: true,
                        order: 1,
                        borderWidth: 1.5,
                        lineTension: 0.4,
                        pointRadius: 0.5,
                        data: [],
                    },
                    {
                        fill: true,
                        order: 0,
                        borderWidth: 1.5,
                        lineTension: 0.4,
                        pointRadius: 0.5,
                        data: [],
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                spanGaps: true,
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                animation: {
                    duration: 0,
                },
                plugins: {
                    filler: {
                        propagate: false
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    displayColors: false,
                    xPadding: 12,
                    yPadding: 12,
                    cornerRadius: 4,
                    titleFontFamily: "Nunito Sans",
                    titleFontStyle: "600",
                    bodyFontFamily: "Nunito Sans",
                    bodyFontStyle: "600",
                    callbacks: {
                        title: function (tooltipItem, data)
                        {
                            if (tooltipItem && tooltipItem[0]) {
                                let title = data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].t || '';
                                let titleDate = moment.unix(parseInt(title));
                                return titleDate.format("DD/MM/YYYY - HH:mm");
                            }
                        },
                        label: function (tooltipItem, data)
                        {
                            let label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y || 0;
                            label = parseFloat(label).toFixed(1).toString().replace('.', ',') + '%';

                            if (data.datasets[tooltipItem.datasetIndex].label) {
                                label = data.datasets[tooltipItem.datasetIndex].label + ": " + label;
                            }

                            return label;
                        }
                    }
                },
                hover: {
                    mode: 'nearest',
                    intersect: true,
                    // animationDuration: 0,
                },
                responsiveAnimationDuration: 0,
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            parser: (data) => new Date(data * 1000),
                            displayFormats: {
                                minute: 'HH:mm',
                                hour: 'HH[h]',
                            }
                        },
                        ticks: {
                            fontFamily: "Nunito Sans",
                            fontStyle: "600",
                            // padding: 5,
                            // major: true,
                        },
                        gridLines: {
                            display: false,
                            // drawTicks: false,
                            // zeroLineWidth: 0,
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 20,
                            fontFamily: "Nunito Sans",
                            fontStyle: "600",
                            callback: function (value) {
                                return value ? value + '%' : '';
                            }
                        },
                        gridLines: {
                            borderDash: [2, 2],
                            zeroLineBorderDash: [2, 2],
                            zeroLineColor: 'rgba(0, 0, 0, 0.1)',
                            zeroLineWidth: 0,
                        }
                    }]
                },
                annotation: {
                    annotations: [
                        {
                            type: 'box',
                            drawTime: 'beforeDatasetsDraw',
                            id: 'box-danger-area-cpu',
                            xScaleID: 'x-axis-0',
                            yScaleID: 'y-axis-0',
                            yMin: 80,
                            yMax: 100,
                            borderWidth: 0,
                            backgroundColor: 'rgba(255,0,0,0.05)'
                        },

                    ]
                }
            }
        }),
        mounted ()
        {
            this.addPlugin(ChartAnnotation);

            if (this.title)
            {
                this.options.annotation.annotations.push({
                    type: "line",
                    id: 'text-resource-name',
                    drawTime: "beforeDatasetsDraw",
                    mode: "horizontal",
                    scaleID: "y-axis-0",
                    value: 40,
                    borderColor: "transparent",
                    label: {
                        enabled: true,
                        content: this.title,
                        fontFamily: "Nunito Sans",
                        fontSize: parseInt(this.titleSize),
                        fontStyle: "bold",
                        fontColor: "rgba(0,0,0,0.075)",
                        backgroundColor: "transparent"
                    }
                });
            }

            this.chartdata.datasets[0].borderColor = this.themes[this.theme].borderColor;
            this.chartdata.datasets[0].backgroundColor = this.themes[this.theme].backgroundColor;
            this.chartdata.datasets[1].borderColor = this.themes[this.theme].borderColorExtra;
            this.chartdata.datasets[1].backgroundColor = this.themes[this.theme].backgroundColorExtra;

            this.chartdata.datasets[0].label = this.title;

            if (this.extraTitle) {
                this.chartdata.datasets[1].label = this.extraTitle;
            }

            if (this.beforeRender) {
                this.options = this.beforeRender(this.options);
            }

            this.renderChart(this.chartdata, this.options);
        },
        methods: {
            /**
             * Atualizar dados do gráfico.
             */
            updateData(newData)
            {
                for (let i = 0; i < newData.length; i++) {
                    this.chartdata.datasets[i].data = newData[i];
                }

                if (this.beforeRender) {
                    this.options = this.beforeRender(this.options);
                }

                this.renderChart(this.chartdata, this.options);
            },

            updateDataset(index, key, value)
            {
                if (key === "data") return;

                this.chartdata.datasets[index][key] = value;
            }
        }
    }
</script>