<template>
    <form @submit.prevent="checkDomain">
        <form-group class="mb-0" :error="formErrors.domain">
            <div class="d-flex">
                <input
                    ref="inputCheckDomain"
                    type="text"
                    class="form-control form-control-xl flex-grow-1 input-domain"
                    autofocus
                    spellcheck="false"
                    autocomplete="off"
                    placeholder="Exemplo: suaempresa.com ou seunome.com.br"
                    v-model="domain"
                    v-input-lowercase
                >
                <button-form
                    ref="btnCheckDomain"
                    type="submit"
                    class="btn-lg ml-4 text-nowrap"
                    :loading="loading"
                    :disabled="loading"
                    :theme="btnCheckDomainTheme"
                >
                    {{ btnCheckDomainText }}
                </button-form>
            </div>

            <div v-show="isWwwDomain && !formErrors.domain" class="form-text-sm font-weight-semibold text-secondary mb-0 mt-1">
                <i class="fas fa-info-circle fa-fw" aria-hidden="true"></i>
                Não é necessário usar <strong>www</strong> para o domínio.
            </div>
        </form-group>

        <div
            role="alert"
            class="alert mt-4"
            v-show="domainStatus.message"
            v-html="domainStatus.message"
            v-bind:class="{
                'alert-primary' : !domainStatus.isAvailable || !domainStatus.isRegisterable || domainStatus.isSubdomain,
                'alert-success' : domainStatus.isAvailable && domainStatus.isRegisterable,
                'alert-danger'  : !domainStatus.isAvailable && !domainStatus.isRegisterable && !domainStatus.isSubdomain,
            }">
        </div>
    </form>
</template>

<script>
    import {ApiCancel} from '@/plugins/api'
    import ErrorService from '@/services/error.service'
    import ButtonForm from '@/components/atoms/ButtonForm'
    import FormGroup from '@/components/molecules/FormGroup'

    export default {
        components: {
            ButtonForm,
            FormGroup
        },
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                domain: "",
                domainStatus: {},
                formErrors: {},
                isDomainChecked: false,
                checkDomainCancel: null,
            }
        },
        computed: {
            isWwwDomain: function () {
                return (this.domain && this.domain.match(/^www\./i));
            },
            btnCheckDomainTheme: function () {
                return (this.domainStatus.isAvailable || this.domainStatus.isRegisterable || this.domainStatus.isSubdomain) ? 'success' : 'primary';
            },
            btnCheckDomainText: function () {
                return (this.domainStatus.isAvailable || this.domainStatus.isRegisterable || this.domainStatus.isSubdomain) ? 'Continuar' : 'Verificar';
            }
        },
        methods: {
            /**
             * Verificar disponibilidade do domínio e outras informações.
             */
            checkDomain()
            {
                if (this.domain) {
                    this.domain = this.domain.trim().toLowerCase();
                }

                if (this.domainStatus.isAvailable || this.domainStatus.isRegisterable || this.domainStatus.isSubdomain)
                {
                    this.isDomainChecked = true;
                    this.$eventBus.$emit('hosting-create-domain-chosen', { domain: this.domain });

                    // Tag do GA4
                    if (typeof window.gtag !== 'undefined' && typeof window.GA4_MEASUREMENT_ID !== 'undefined') {
                        gtag('event', 'escolha_plano', { 'send_to': window.GA4_MEASUREMENT_ID });
                    }

                    return;
                }

                this.$refs.btnCheckDomain.setLoading(true, true);
                this.domainStatus = {};
                this.formErrors = {};

                // Cancelar verificação anterior
                if (this.checkDomainCancel) {
                    this.checkDomainCancel();
                }

                const CancelToken = ApiCancel.CancelToken;
                let vm = this;

                let promiseCheck = this.$api.request({
                    method: 'post',
                    url: '/hosting/check',
                    data: {
                        domain: this.domain,
                    },
                    cancelToken: new CancelToken(function executor(c) {
                        vm.checkDomainCancel = c;
                    })
                });

                promiseCheck
                    .then(response => {
                        this.domainStatus = response.data.data;
                        this.$eventBus.$emit('hosting-create-domain-verified');

                        // if (this.domainStatus.isAvailable || this.domainStatus.isRegisterable || this.domainStatus.isSubdomain) {
                        //     vm.$refs.btnNext.disabled = false;
                        // }
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$eventBus.$emit('hosting-create-domain-invalid');
                    })
                    .then (() => {
                        this.$refs.btnCheckDomain.setLoading(false);
                        this.$refs.inputCheckDomain.focus();
                    });
            },
        },
        watch: {
            'domain': function(val, old) {
                if (val !== old)
                {
                    let fixed = val.replace(/[^0-9a-zA-Z-_\.]/g, '');
                    fixed = fixed.replace(/^https?/g, '');

                    this.domain = fixed;
                    this.domainStatus = {};
                    this.formErrors = {};

                    this.$eventBus.$emit('hosting-create-domain-changed');
                }
            }
        }
    }
</script>
