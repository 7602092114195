<template>
    <hosting-settings-template :resource="resource" class="h-p-hosting-ssh">
        <card-title inner-body class="h-card-ssh-info">
            <div class="d-flex justify-content-between mb-6">
                <div class="flex-grow-1">
                    <h4 class="card-title-inner">Habilitar acesso SSH</h4>
                    <p class="mb-0">
                        O SSH (Secure SHell) é um protocolo que permite a você acessar, executar comandos e transferir
                        arquivos em sua hospedagem de forma segura. Habilite o acesso SSH em sua hospedagem usando o
                        botão ao lado.
                    </p>
                </div>
                <div class="pl-3">
                    <div class="d-flex align-items-center mt-1">
                        <i class="fas fa-circle-notch fa-spin text-muted mr-3" v-bind:class="{ 'invisible': !isLoading }"></i>
                        <toggle-switch v-model="ssh.active" :callback="toggleSsh" :disabled="isLoading" />
                    </div>
                </div>
            </div>

            <text-loading v-show="isLoading" />

            <div v-show="showToggleSshAlert" class="alert alert-warning mb-0 mt-4">
                <i class="far fa-clock mr-1"></i>
                A ativação/desativação do SSH pode demorar alguns minutos.
            </div>

            <div class="table-wrapper">
                <table v-show="!isLoading && ssh.active" class="table table-list table-sm mb-0">
                    <tbody>
                        <tr>
                            <th class="col-w-px col-nowrap">IP do servidor:</th>
                            <td><text-copy :text="hosting.serverIp" /></td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Usuário:</th>
                            <td><text-copy :text="ssh.username" /></td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Porta:</th>
                            <td><text-copy :text="ssh.port" /></td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Senha:</th>
                            <td><text-copy :text="'&bull;'.repeat(8)" :copy="ssh.password" /></td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Comando CLI:</th>
                            <td><text-copy :text="ssh.command" text-class="text-monospace" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card-title>

        <card-table
            ref="cardKeys"
            inner-title="Chaves públicas autorizadas"
            modal-title="Adicionar chave pública"
            remove-title="Excluir chave pública"
            table-empty-text="Nenhuma chave pública encontrada"
            :table-data="keys"
            :table-fields="tableKeysFields"
            :table-loading="isLoadingSsh"
            :api-save-url="`/hosting/${resource}/ssh`"
            @created="fetchSsh"
            @remove="removeKey"
        >
            <template slot="intro-text">
                As chaves públicas permitem que você acesse sua hospedagem com <strong>mais segurança</strong> sem a
                utilização de uma senha. Além disso, facilita a automatização de scripts e processos que precisem
                acessar o servidor. Basta gerar um par de chaves em seu computador e importar a chave pública nesta seção.
            </template>

            <template slot="table-created" slot-scope="{ rowData }">
                {{ rowData.createdAt | moment("DD/MM/YYYY HH:mm") }}
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group label="Chave" label-for="sshKey" :error="formErrors.key">
                    <p class="form-text-sm">
                        Cole sua chave SSH pública, que geralmente se encontra no arquivo <strong>~/.ssh/id_rsa.pub</strong>
                        e começa com "ssh-rsa". Não use sua chave privada.
                    </p>
                    <textarea id="sshKey" class="form-control text-monospace" rows="8" placeholder="ssh-rsa ..." v-model="formData.key"></textarea>
                </form-group>

                <form-group label="Título" label-for="sshKeyTitle" :error="formErrors.title">
                    <p class="form-text-sm">Use o título para identificar a sua nova chave.</p>
                    <input type="text" id="sshKeyTitle" class="form-control" placeholder="Exemplo: minha_chave" v-model="formData.title">
                </form-group>
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item-button @click="showViewKeyModal(rowData)">
                    <i class="fas fa-key fa-fw"></i> Visualizar chave
                </b-dropdown-item-button>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir a chave abaixo?</p>
                <ul class="mb-0"><li>{{ item.title }}</li></ul>
            </template>
        </card-table>

        <b-modal ref="modalViewKey" size="lg" centered hide-header hide-footer>
            <div v-show="isLoadingKey" class="text-muted">
                <i class="fas fa-circle-notch fa-spin"></i> Carregando...
            </div>
            <div v-show="!isLoadingKey && key.key">
                <h4 class="font-weight-bold">{{ key.title }}</h4>
                <samp style="word-break:break-all">{{ key.key }}</samp>
            </div>
        </b-modal>
    </hosting-settings-template>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import AjaxService              from '@/services/AjaxService'
    import ToggleSwitch             from '@/components/atoms/ToggleSwitch'
    import ModalConfirm             from '@/components/atoms/ModalConfirm'
    import CardTitle                from '@/components/molecules/CardTitle'
    import TextCopy                 from '@/components/molecules/TextCopy'
    import CardTable                from '@/components/organisms/CardTable'
    import HostingSettingsTemplate  from '@/components/templates/HostingSettingsTemplate'
    import TextLoading from '@/components/atoms/TextLoading'
    import {ApiCancel} from '@/plugins/api'
    import FormGroup from '@/components/molecules/FormGroup'

    export default {
        props: ['resource'],
        components: {
            FormGroup,
            TextLoading,
            CardTable,
            TextCopy,
            ModalConfirm,
            ToggleSwitch,
            CardTitle,
            HostingSettingsTemplate
        },
        data() {
            return {
                tableKeysFields: [
                    {
                        name: 'title',
                        sortField: 'title',
                        filterable: true,
                        title: 'Título',
                    },
                    {
                        name: '__slot:created',
                        sortField: 'createdAt',
                        title: 'Criada em',
                        titleClass: 'col-datetime',
                        dataClass: 'col-datetime',
                    }
                ],

                ssh: {},
                keys: [],

                key: {},
                itemToRemove: {},
                viewKeyCancelToken: null,

                checkStatusTimeout: null,
                checkStatusCancelToken:  null,

                isLoadingFtp: false,
                isLoadingSsh: false,
                isLoadingKey: false,
                isLoadingSshToggle: false,
                showToggleSshAlert: false,
            }
        },
        computed: {
            ...mapState('hosting', ['hosting']),

            isLoading: function() {
                return (this.isLoadingFtp || this.isLoadingSsh || this.isLoadingSshToggle);
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.viewKeyCancelToken && this.viewKeyCancelToken.cancel();
            this.checkStatusCancelToken && this.checkStatusCancelToken.cancel();
            this.checkStatusTimeout && clearTimeout(this.checkStatusTimeout);
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.$refs.modalViewKey.$on('hidden', () => {
                if (this.viewKeyCancelToken) this.viewKeyCancelToken.cancel();
            });

            this.fetchFtp();
            this.fetchSsh();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert:      'alert/error',
            }),

            /**
             * Obter informações de FTP na hospedagem.
             */
            fetchFtp()
            {
                this.isLoadingFtp = true;

                return this.$api.get(`/hosting/${this.resource}/ftp`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.ssh = Object.assign(this.ssh, {
                            username: response.data.data.main.username,
                            password: response.data.data.main.password,
                        });
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingFtp = false);
            },

            /**
             * Obter informações de SSH na hospedagem.
             */
            fetchSsh()
            {
                this.isLoadingSsh = true;

                return this.$api.get(`/hosting/${this.resource}/ssh`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        if (response.data.data.queued) {
                            this.isLoadingSshToggle = true;
                            this.showToggleSshAlert = true;
                            this.checkStatusTimeout = setTimeout(() => this.checkStatus(), 10000);
                        }

                        this.keys        = response.data.data.keys;
                        this.ssh.active  = response.data.data.active;
                        this.ssh.command = response.data.data.connection;

                        const matched = this.ssh.command && this.ssh.command.match(/-p \d+/);
                        this.ssh.port = matched ? matched[1] : '22';
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingSsh = false);
            },

            /**
             * Exibir conteúdo de uma chave pública.
             */
            showViewKeyModal(item)
            {
                this.key = { title: item.title };
                this.isLoadingKey = true;

                this.$refs.modalViewKey.show();

                const CancelToken = ApiCancel.CancelToken;
                this.viewKeyCancelToken = CancelToken.source();

                this.$api.get(`/hosting/${this.resource}/ssh/${this.key.title}`, { cancelToken: this.viewKeyCancelToken.token })
                    .then (response => {
                        const data = response.data.data;
                        this.$set(this.key, 'key', data.key);
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingKey = false);
            },

            /**
             * Excluir chave pública.
             */
            removeKey(item)
            {
                const title = item.title;

                this.$api.delete(`/hosting/${this.resource}/ssh/${title}`)
                    .then (response => {})
                    .catch(error => {
                        error.message = 'Ocorreu um erro ao tentar excluir a chave pública. Por favor, atualize sua página e tente novamente.';
                        this.showErrorAlert(error);
                    });
            },

            /**
             * Habilitar ou desabilitar o acesso SSH.
             */
            toggleSsh()
            {
                const newValue = this.ssh.active;

                this.isLoadingSshToggle = true;
                this.showToggleSshAlert = true;

                this.$api.patch(`/hosting/${this.resource}/ssh`, { value: newValue })
                    .then (response => {
                        if (response.data.data.queued) {
                            clearTimeout(this.checkStatusTimeout);
                            this.checkStatusTimeout = setTimeout(() => this.checkStatus(), 10000);
                        }
                        else {
                            this.ssh.active = response.data.data.value;
                            this.successNotification({ message: 'Configuração atualizada!' });
                            this.isLoadingSshToggle = false;
                            this.showToggleSshAlert = false;
                        }
                    })
                    .catch(error => {
                        this.ssh.active = !newValue;
                        this.showErrorAlert(error);
                        this.isLoadingSshToggle = false;
                        this.showToggleSshAlert = false;
                    });
            },

            checkStatus()
            {
                this.checkStatusCancelToken && this.checkStatusCancelToken.cancel();

                const CancelToken = ApiCancel.CancelToken;
                this.checkStatusCancelToken = CancelToken.source();

                this.$api.get(`/hosting/${this.resource}/ssh`, { cancelToken: this.checkStatusCancelToken.token })
                    .then (response => {
                        if (response.data.data.queued)
                        {
                            if (response.data.data.queued === true) {
                                this.checkStatusTimeout = setTimeout(() => this.checkStatus(), 10000);
                            }
                            else {
                                if (response.data.data.queued === 'error') {
                                    this.showErrorAlert('Ocorreu um erro ao tentar alterar o status do SSH. Por favor, atualize sua página e tente novamente.');
                                }

                                this.ssh.active = response.data.data.active;
                                this.isLoadingSshToggle = false;
                                this.showToggleSshAlert = false;
                            }
                        }
                        else {
                            this.ssh.active = response.data.data.active;
                            this.isLoadingSshToggle = false;
                            this.showToggleSshAlert = false;
                        }
                    })
                    .catch(error => {});
            }
        }
    }
</script>

<style lang="stylus">
    .h-p-hosting-ssh
        .h-card-ssh-info
            .table-wrapper
                margin-left -2.5rem
                margin-right -2.5rem

            .table-list
                border 0

                tbody tr
                    > th:first-child
                        padding-left 2.5rem

                    > td:last-child
                        padding-right 2.5rem
</style>