/**
 * https://github.com/lian-yue/vue-upload-component/issues/152
 */
function formatBytes(size, decimals, optionalDecimals)
{
    const _decimals = typeof decimals !== "undefined" ? parseInt(decimals) : 2;
    const _optional = !(typeof optionalDecimals !== "undefined" && optionalDecimals === false);

    let _value = "";
    let _unit = "";

    if (size >= 1024 * 1024 * 1024 * 1024) {
        _value = (size / 1024 / 1024 / 1024 / 1024);
        _unit = ' TB';
    }
    else if (size >= 1024 * 1024 * 1024) {
        _value = (size / 1024 / 1024 / 1024);
        _unit = ' GB';
    }
    else if (size >= 1024 * 1024) {
        _value = (size / 1024 / 1024);
        _unit = ' MB';
    }
    else if (size >= 1024) {
        _value = (size / 1024);
        _unit = ' KB';
    }
    else {
        _value = size;
        _unit = ' B'
    }

    if (!_optional || (_value - Math.floor(_value))) {
        _value = _value.toFixed(_decimals);
    }
    else {
        _value = _value.toFixed(0);
    }

    _value = _value.replace(".", ",");

    return _value + _unit;
}

export default formatBytes
