import store from '@/store'

let self;

const hostingActions = {
    created()
    {
        self = this;
    },
    methods: {
        /**
         * Pausar hospedagem.
         * @param {Object} hosting
         */
        pauseHosting(hosting)
        {
            if (hosting.isTester) {
                showTesterModal();
            }
            else {
                showConfirmPauseModal(hosting);
            }
        },

        /**
         * Reativar hospedagem.
         * @param {Object} hosting
         */
        unpauseHosting(hosting)
        {
            unpause(hosting.domain);
        },
    }
}

/**
 * Exibir modal de confirmação para pausar a hospedagem.
 * @param {Object} hosting  Objeto com dados da hospedagem.
 */
function showConfirmPauseModal(hosting)
{
    const html = self.$createElement('div', {}, [
        self.$createElement('p', { class: 'mb-2', domProps: { innerHTML: 'Tem certeza que deseja pausar a hospedagem abaixo?' } }),
        self.$createElement('p', { class: 'mb-4', domProps: { innerHTML: '<strong>' + hosting.domain + '</strong>' } }),
        self.$createElement('div', {
            class: 'alert alert-warning mb-0',
            domProps: {
                innerHTML: '<strong>Atenção:</strong> As hospedagens pausadas continuam a ser cobradas em 30% do '+
                    'valor do plano atual para manter os seus arquivos armazenados em nossos servidores.'
            }
        }),
    ]);

    self.$bvModal.msgBoxConfirm([html],
        {
            size: 'md',
            centered: true,
            hideHeaderClose: true,
            title: 'Pausar hospedagem',
            okVariant: 'success',
            okTitle: 'Pausar',
            cancelTitle: 'Cancelar'
        })
        .then (value => { if (value) pause(hosting.domain); })
        .catch(error => { /* ignore */ } );
}

/**
 * Pausar uma hospedagem.
 * @param {String} domain  Domínio da hospedagem a ser pausada.
 */
function pause(domain)
{
    store.dispatch('loading/show');

    self.$api.post(`/hosting/${domain}/pause`)
        .then (response => window.location.href = '/hosting/' + domain)
        .catch(error => store.dispatch('alert/error', error))
        .then (() => store.dispatch('loading/hide'));
}

/**
 * Reativar uma hospedagem que está pausada.
 * @param {String} domain  Domínio da hospedagem a ser reativada.
 */
function unpause(domain)
{
    store.dispatch('loading/show');

    self.$api.post(`/hosting/${domain}/unpause`)
        .then (response => window.location.reload())
        .catch(error => store.dispatch('alert/error', error))
        .then (() => store.dispatch('loading/hide'));
}

/**
 * Exibir modal com mensagem de aviso sobre a função de pausar para clientes em fase de teste.
 */
function showTesterModal()
{
    const html = self.$createElement('div', `
        A função de pausar não está disponível durante o período de testes da conta.
        Para habilitar a função o proprietário desta hospedagem precisa realizar uma primeira recarga.    
    `);

    self.$bvModal.msgBoxOk([html],
        {
            size: 'md',
            centered: true,
            hideHeaderClose: true,
            bodyClass: 'mb-2',
            footerClass: 'd-none',
            title: 'Pausar hospedagem',
            okVariant: 'secondary',
            okTitle: 'Recarregar'
        })
        .then (value => {})
        .catch(error => { /* ignore */} );
}

export default hostingActions