<template>
    <card-title title="Gráficos de Consumo">
        <div class="btn-toolbar mb-6" role="toolbar">
            <div class="input-group">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="chartInterval">Intervalo:</label>
                </div>
                <select class="custom-select" id="chartInterval" v-model="chartInterval" :disabled="isLoadingStatistics" @change="getStatistics">
                    <option value="1">1 hora</option>
                    <option value="6">6 horas</option>
                    <option value="D">24 horas</option>
                    <option value="W">7 dias</option>
                    <option value="M">30 dias</option>
                </select>
            </div>
            <div class="input-group ml-3">
                <button type="button" class="btn btn-outline-secondary" :disabled="isLoadingStatistics" @click="getStatistics">Atualizar</button>
            </div>
            <div class="d-flex align-items-center ml-3 text-muted">
                <i v-show="isLoadingStatistics" class="fas fa-circle-notch fa-spin"></i>
            </div>

            <div role="group" class="btn-group ml-auto">
                <router-link class="btn btn-success" :to="`/hosting/${resource}/plan`">Alterar plano</router-link>
            </div>
        </div>

        <div class="h-chart-wrapper">
            <chart-resource ref="chartCpu" title="CPU" title-size="80" theme="green" :before-render="setChartOptions" :style="{height:'300px'}"/>
        </div>

        <div class="h-chart-wrapper mt-6">
            <chart-resource
                ref="chartRam"
                title="RAM"
                title-size="80"
                extra-title="Cache"
                theme="blue"
                :before-render="setChartOptions"
                :style="{height:'300px'}"/>
        </div>

        <ul class="mt-4 mb-0">
            <li>Os gráficos com período de 24 horas ou mais exibem uma média do consumo no intervalo de 1 hora ou 1 dia.</li>
            <li>O gráfico de memória RAM de 1 hora ou 6 horas exibe adicionalmente o uso de memória exclusiva para cache em sua aplicação.</li>
        </ul>
    </card-title>
</template>

<script>
    import { mapActions } from 'vuex'
    import AjaxService    from '@/services/AjaxService'
    import ChartResource  from '@/components/molecules/ChartResource'
    import CardTitle      from '@/components/molecules/CardTitle'

    export default {
        props: ['resource'],
        components: {
            ChartResource,
            CardTitle
        },
        data() {
            return {
                chartInterval: "1",
                chartOptions: {
                    1: { step: 15, unit: 'minute' },
                    6: { step:  1, unit: 'hour'   },
                    D: { step:  1, unit: 'hour'   },
                    W: { step:  1, unit: 'day'    },
                    M: { step:  5, unit: 'day'    },
                },

                isLoadingStatistics: false
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getStatistics();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Alterar opções do gráfico.
             */
            setChartOptions(options)
            {
                if (this.chartOptions[this.chartInterval])
                {
                    if (this.chartOptions[this.chartInterval].step) {
                        options.scales.xAxes[0].time.stepSize = this.chartOptions[this.chartInterval].step;
                    }

                    if (this.chartOptions[this.chartInterval].unit) {
                        options.scales.xAxes[0].time.unit = this.chartOptions[this.chartInterval].unit;
                    }
                }

                return options;
            },

            /**
             * Carregar dados para o gráfico de consumo.
             */
            getStatistics()
            {
                this.isLoadingStatistics = true;

                this.$api.get(`/hosting/${this.resource}/statistics`, {
                    params: { period: this.chartInterval },
                    cancelToken: AjaxService.getCancelToken()
                })
                    .then (response => {
                        let dataCpu = [];
                        let dataRam = [];
                        let dataCache = [];
                        let i;

                        for (i in response.data.data) {
                            dataCpu.push({ t: i, y: response.data.data[i].cpu });
                            dataRam.push({ t: i, y: response.data.data[i].ram });

                            if (typeof response.data.data[i].cache !== "undefined") {
                                dataCache.push({ t: i, y: response.data.data[i].cache });
                            }
                        }

                        this.$refs.chartCpu.updateData([dataCpu]);
                        this.$refs.chartRam.updateData([dataRam, dataCache]);
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingStatistics = false);
            }
        }
    }
</script>
