const tutorialStep     = sessionStorage.getItem('tutorial_create_hosting_step')
const tutorialSkipped  = sessionStorage.getItem('tutorial_create_hosting_skipped')
const tutorialFinished = sessionStorage.getItem('tutorial_create_hosting_finished')

const state = {
    step:     (tutorialStep ? parseInt(tutorialStep) : 1),
    skipped:  (tutorialSkipped === '1'),
    finished: (tutorialFinished === '1'),
    running: false,
    popovers: [],
    waitingPageChange: false,
}

const actions = {
    start({ commit }) {
        commit('setRunning', true)
    },
    stop({ commit }) {
        commit('setRunning', false)
    },
    nextStep({ commit }) {
        commit('incrementStep')
    },
    changeStep({ commit }, step) {
        commit('changeStep', step)
    },
    addPopover({ commit }, options) {
        options.id = options.id || 'tutorial-popover-' + Math.round(Math.random() * 1000000);
        commit('addPopover', options);
        return options.id;
    },
    removePopover({ commit }, id) {
        commit('removePopover', id);
    },
    clearPopovers({ commit }) {
        commit('clearPopovers');
    },
    waitPageChange({ commit }, value) {
        commit('setWaitingPageChange', value);
    }
}

const mutations = {
    incrementStep(state) {
        ++state.step;
        sessionStorage.setItem('tutorial_create_hosting_step', state.step.toString());
    },
    changeStep(state, step) {
        state.step = step;
        sessionStorage.setItem('tutorial_create_hosting_step', state.step.toString());
    },
    setRunning(state, status) {
        state.running = status;
    },
    addPopover(state, options) {
        state.popovers.push(options);
    },
    removePopover(state, id) {
        const idx = state.popovers.findIndex(el => el.id === id);
        state.popovers.splice(idx, 1);
    },
    clearPopovers(state) {
        state.popovers = [];
    },
    setWaitingPageChange(state, value) {
        state.waitingPageChange = value;
    }
}

export const tutorial = {
    namespaced: true,
    state,
    actions,
    mutations
}