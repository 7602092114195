import { Api } from '@/plugins/api'

/**
 * Obter dados de uma hospedagem
 *
 * @param {string} domain  Domínio da hospedagem
 * @return {Object}        Dados da hospedagem
 */
function get(domain)
{
    return Api.get(`/hosting/${domain}`).then((response) => response.data.data)
}

/**
 * Obter dados do plano de cobrança da hospedagem
 *
 * @param {string} domain  Domínio da hospedagem
 * @return {Object}
 */
function getPlan(domain)
{
    return Api.get(`/hosting/${domain}/plan`).then((response) => response.data.data)
}

/**
 * Listar os planos de cobrança disponíveis
 */
function listPlans()
{
    return Api.get('/hosting/plans').then((response) => response.data.data)
}

/**
 * Gerar array de permissões usando os dados de uma hospedagem
 *
 * @param {Object} hosting  Dados da hospedagem
 * @returns {[]}            Array de permissões com base no ACL
 */
function generatePermissions(hosting)
{
    let permissions = [];

    if (hosting.isGuest)
    {
        permissions.push('guest');

        Object.keys(hosting.permissions).map((key) => {
            if (hosting.permissions[key]) {
                permissions.push(key);
            }
        });
    }
    else {
        permissions.push('owner');
    }

    return permissions;
}

export default {
    get,
    getPlan,
    generatePermissions
}