export default {
    "dashboard": {
        "video": "https://www.youtube.com/embed/-YOXP3iiqYQ?autoplay=1&modestbranding=1&iv_load_policy=3"
    },
    "hosting": {
        "summary": {
            "video": "https://www.youtube.com/embed/v85QEnNIT50?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "domain": {
            "video": "https://www.youtube.com/embed/P2QmmyNaj6g?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "domain_edit": {
            "video": "https://www.youtube.com/embed/P2QmmyNaj6g?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "dns": {
            "video": "https://www.youtube.com/embed/P2QmmyNaj6g?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "alias": {
            "video": "https://www.youtube.com/embed/P2QmmyNaj6g?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "subdomain": {
            "video": "https://www.youtube.com/embed/P2QmmyNaj6g?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "redirect": {
            "video": "https://www.youtube.com/embed/P2QmmyNaj6g?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "files": {
            "video": "https://www.youtube.com/embed/OiSNM4Qtuiw?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "ftp": {
            "video": "https://www.youtube.com/embed/OiSNM4Qtuiw?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "deploy": {
            "video": "https://www.youtube.com/embed/OiSNM4Qtuiw?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "backup": {
            "video": "https://www.youtube.com/embed/OiSNM4Qtuiw?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "database": {
            "video": "https://www.youtube.com/embed/eZAFGI4wa0o?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "email": {
            "video": "https://www.youtube.com/embed/mO9S0F-El2g?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "apps": {
            "video": "https://www.youtube.com/embed/o3ylF2QjEDA?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "php": {
            "video": "https://www.youtube.com/embed/5BysXenRWFs?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "ssl": {
            "video": "https://www.youtube.com/embed/5BysXenRWFs?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "ssh": {
            "video": "https://www.youtube.com/embed/5BysXenRWFs?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "cron": {
            "video": "https://www.youtube.com/embed/5BysXenRWFs?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
        "logs": {
            "video": "https://www.youtube.com/embed/5BysXenRWFs?autoplay=1&modestbranding=1&iv_load_policy=3"
        },
    }
}
