<template>
    <admin-sidebar-template
        class="h-sidebar-domain"
        sidebar-title="Domínios"
        sidebar-filter-by="domain"
        :sidebar-items="domains"
        :sidebar-loading="isLoading"
    >
        <template slot="sidebar-list-item" slot-scope="{ item }">
            <icon-hosting-status class="mr-2" :status="item.status" :title="item.statusText" />
            <router-link class="h-list-link" :to="'/domain/' + item.domain" :title="item.domain">{{ item.domain }}</router-link>
        </template>
        <announcement-area />
        <router-view />
    </admin-sidebar-template>
</template>

<script>
    import AnnouncementArea     from '@/components/organisms/AnnouncementArea'
    import AdminSidebarTemplate from '@/components/templates/AdminSidebarTemplate'
    import IconHostingStatus from '@/components/atoms/IconHostingStatus'

    export default {
        components: {
            IconHostingStatus,
            AnnouncementArea,
            AdminSidebarTemplate
        },
        data() {
            return {
                domains: [{
                    title: 'Domínios',
                    items: [],
                    addLink: '/domain/add',
                    addLinkTitle: 'Registrar novo domínio',
                }],
                isLoading: false,
            }
        },
        mounted()
        {
            this.getDomains();
        },
        methods: {
            /**
             * Listar domínios.
             */
            getDomains()
            {
                this.isLoading = true;

                this.$api.get('/domain')
                    .then (response => {
                        let data = response.data.data;
                        let domains = [];

                        data.forEach(item => {
                            item.status = item.status.toLowerCase();
                            domains.push(item);
                        });

                        this.domains[0].items = domains;
                    })
                    .catch(() => {})
                    .then (() => this.isLoading = false);
            }
        }
    }
</script>