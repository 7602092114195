<template>
    <b-alert
        class="alert-compact"
        :variant="variant"
        :show="show && !dismissed"
    >
        <div class="row align-items-center">
            <div v-if="!noIcon" class="col-md-auto pr-1">
                <i class="alert-icon fa-fw" v-bind:class="[iconClasses]"></i>
            </div>
            <div class="col-md">
                <p v-if="title" class="alert-heading mb-0">
                    {{ title }}
                </p>

                <p v-if="text" class="mb-0" v-html="text"></p>
                <slot v-else></slot>
            </div>
            <div v-if="btnText && btnLink" class="col-md-auto">
                <router-link
                    :to="btnLink"
                    class="btn"
                    v-bind:class="[buttonVariant]"
                >
                    {{ btnText }}
                </router-link>
                <button
                    v-if="showIgnore"
                    type="button"
                    class="btn btn-link ml-2"
                    @click="ignore"
                >
                    Ignorar
                </button>
            </div>
        </div>
    </b-alert>
</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: true
            },
            variant: {
                type: String,
                default: 'primary'
            },
            noIcon: {
                type: Boolean,
                default: false
            },
            showIgnore: {
                type: Boolean,
                default: false
            },
            onIgnore: {
                type: Function,
                default: () => {},
            },
            iconClass: String,
            title: String,
            text: String,
            btnText: String,
            btnLink: String,
            btnVariant: String
        },
        data () {
            return {
                dismissed: false,
            }
        },
        computed: {
            buttonVariant()
            {
                if (this.btnVariant) {
                    return `btn-${this.btnVariant}`;
                }
                else {
                    switch (this.variant) {
                        case 'danger':  return 'btn-light';
                        case 'warning': return 'btn-dark';
                        case 'info':    return 'btn-light';
                        default:        return 'btn-primary';
                    }
                }
            },
            iconClasses()
            {
                if (this.iconClass) {
                    return this.iconClass;
                }
                else {
                    switch (this.variant) {
                        case 'warning': return 'fas fa-exclamation-triangle';
                        case 'danger':  return 'fas fa-exclamation-circle';
                        case 'info':    return 'fas fa-lightbulb';
                        default:        return 'fas fa-lightbulb';
                    }
                }
            }
        },
        methods: {
            ignore()
            {
                this.dismissed = true;
                this.onIgnore();
            }
        }
    }
</script>
