<template>
    <main class="h-o-main-content">
        <div class="container-lg">
            <slot></slot>
        </div>
    </main>
</template>

<script>
    export default {}
</script>

<style lang="stylus">
    .h-o-main-content
        padding 1rem 0

        @media (max-width 575px)
            > .container-lg
                padding-left 0
                padding-right 0

        @media (min-width 576px)
            padding 1.5rem
</style>