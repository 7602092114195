<template>
    <admin-page-template>
        <card-title v-if="domain.domain" class="h-o-card-hosting-top">
            <div class="row">
                <div class="col">
                    <div class="flex-grow-1 mb-4 mb-sm-6">
                        <h3 class="ht-hosting-domain text-truncate mb-2">
                            <a :href="`http://${domain.domain}`" target="_blank">{{ domain.domain }}</a>
                        </h3>
                    </div>

                    <div class="ht-hosting-states">
                        <router-link
                            class="btn"
                            active-class=""
                            exact-active-class=""
                            v-bind:class="buttonStatusClasses"
                            v-html="domain.statusText"
                            :to="`/domain/${domain.domain}`"
                        />
                        <router-link
                            v-if="!domain.isPending"
                            class="btn btn-borderless-danger"
                            active-class=""
                            exact-active-class=""
                            :to="`/domain/${domain.domain}/renew`"
                            title="A renovação automática está desabilitada para o domínio"
                            v-b-tooltip.hover.bottomright
                        >
                            <span><i class="fas fa-times"></i> Renovação</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="mb-4 mb-sm-6"></div> <!-- spacer -->
        </card-title>

        <router-view v-if="domain.domain" />
    </admin-page-template>
</template>

<script>
    import {mapActions}         from 'vuex'
    import ErrorService         from '@/services/error.service'
    import CardTitle            from '@/components/molecules/CardTitle'
    import AdminPageTemplate    from '@/components/templates/AdminPageTemplate'

    export default {
        name: 'DomainBase',
        props: ['resource'],
        components: {
            CardTitle,
            AdminPageTemplate
        },
        data() {
            return {
                domain: {},
            }
        },
        computed: {
            buttonStatusClasses: function()
            {
                if (this.domain && this.domain.isPending) {
                    return 'btn-borderless-secondary';
                }

                return 'btn-borderless-success';
            },
        },
        beforeRouteEnter(to, from, next)
        {
            next(vm => {
                vm.fetchDomain(to.params.resource).catch(error => ErrorService.handlePageError(error));
            });
        },
        beforeRouteUpdate(to, from, next)
        {
            if (from.params.resource !== to.params.resource)
            {
                this.fetchDomain(to.params.resource)
                    .then (response => next())
                    .catch(error => ErrorService.handlePageError(error, next));
            }
            else {
                next()
            }
        },
        methods: {
            ...mapActions({
                getDomain: 'domain/get',
                showLoading: 'loading/show',
                hideLoading: 'loading/hide',
                showErrorAlert: 'alert/error'
            }),

            fetchDomain(domain)
            {
                this.showLoading();

                this.domain = {};

                return this.getDomain({ domain: domain })
                    .then(domain => this.domain = domain)
                    .then(() => this.hideLoading());
            }
        }
    }
</script>
