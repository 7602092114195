<template>
    <b-alert variant="warning" dismissible show>
        <p class="alert-heading font-weight-bold mb-2">
            <i class="fas fa-exclamation-triangle"></i> Crédito Emergencial
        </p>
        <p class="mb-2">
            Evite que suas hospedagens fiquem suspensas. Ative agora o crédito emergencial e mantenha suas
            hospedagens ativas por até <strong>3 dias</strong>. O crédito emergencial será compensado após a
            confirmação da sua recarga. Este serviço <strong>não cobra nenhuma taxa adicional</strong>.
        </p>
        <div>
            <button ref="btnEnable" type="button" class="btn btn-link px-0" @click="enableEmergencyCredit">Ativar crédito emergencial</button>
        </div>
    </b-alert>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        methods: {
            ...mapActions({
                showLoading:         'loading/show',
                hideLoading:         'loading/hide',
                successNotification: 'notification/success',
                showErrorAlert:      'alert/error'
            }),

            /**
             * Ativar crédito emergencial.
             */
            enableEmergencyCredit()
            {
                this.$refs.btnEnable.disabled = true;
                this.showLoading();

                this.$api.post('/recharge/emergency')
                    .then (response => {
                        this.successNotification({ message: 'Crédito emergencial ativado!' });
                        setTimeout(() => window.location.reload(), 1000);
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.btnEnable.disabled = false;
                    })
                    .then (() => this.hideLoading());
            }
        }
    }
</script>