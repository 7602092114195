<template>
    <card-title class="hto-quick-access" title="Acesso Rápido">
        <ul class="nav nav-fill nav-buttons d-none d-sm-flex">
            <li class="nav-item nav-item-primary">
                <button-circle title="Migração" link="/hosting/migration" icon-class="fas fa-exchange-alt" />
            </li>
            <li class="nav-item nav-item-primary">
                <button-circle title="WordPress" link="/hosting/wordpress" icon-class="fab fa-wordpress-simple" />
            </li>
            <li class="nav-item">
                <button-circle title="Hospedagens" link="/hostings" icon-class="fas fa-server" />
            </li>
            <li class="nav-item">
                <button-circle title="Domínios" link="/domains">
                    <template slot="icon">
                        <svg width="37px" height="32px" viewBox="0 0 37 32" focusable="false">
                            <g transform="translate(1.663 -33.182)">
                                <g transform="translate(-1.663 33.182)">
                                    <path d="M34.122,34.62H2.878A2.878,2.878,0,0,0,0,37.5v3.271a.411.411,0,0,0,.411.411H36.589A.411.411,0,0,0,37,40.769V37.5A2.878,2.878,0,0,0,34.122,34.62ZM5.189,38.828a.928.928,0,1,1,.928-.928A.928.928,0,0,1,5.189,38.828Zm2.974,0a.928.928,0,1,1,.928-.928A.928.928,0,0,1,8.163,38.828Zm2.974,0a.928.928,0,1,1,.928-.928A.928.928,0,0,1,11.137,38.828Z" transform="translate(0 -34.62)" />
                                    <path d="M36.589,148.15H.411a.411.411,0,0,0-.411.411v20.505a2.878,2.878,0,0,0,2.878,2.878H34.122A2.878,2.878,0,0,0,37,169.067V148.561A.411.411,0,0,0,36.589,148.15Zm-23.711,9.936-1.435,4.429a.822.822,0,0,1-1.476.188l-1.1-1.733-1.1,1.733a.823.823,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767L8.171,159a.823.823,0,0,1,1.388,0l.859,1.35.9-2.767A.822.822,0,0,1,12.878,158.086Zm9.635,0-1.435,4.429a.822.822,0,0,1-1.476.188l-1.1-1.733L17.4,162.7a.823.823,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767.859-1.35a.823.823,0,0,1,1.387,0l.859,1.35.9-2.767A.822.822,0,0,1,22.513,158.086Zm9.635,0-1.435,4.429a.823.823,0,0,1-1.476.188l-1.1-1.733-1.1,1.733a.822.822,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767.859-1.35a.823.823,0,0,1,1.387,0l.859,1.35.9-2.767A.822.822,0,0,1,32.148,158.086Z" transform="translate(0 -139.945)" />
                                </g>
                            </g>
                        </svg>
                    </template>
                </button-circle>
            </li>
            <li class="nav-item">
                <button-circle title="Suporte" link="/support/tickets" icon-class="fas fa-life-ring" />
            </li>
            <li class="nav-item">
                <button-circle title="Créditos" link="/payment" icon-class="fas fa-coins" />
            </li>
            <li class="nav-item">
                <button-circle title="Acessos" link="/user/access" icon-class="fas fa-users" />
            </li>
            <li class="nav-item">
                <button-circle title="Perfil" link="/user/profile" icon-class="fas fa-user-cog" />
            </li>
        </ul>

        <ul class="nav nav-fill d-sm-none">
            <li class="nav-item">
                <router-link to="/" class="nav-link" title="Home"><i class="fas fa-home"></i></router-link>
            </li>
            <li class="nav-item">
                <router-link to="/hostings" class="nav-link" title="Hospedagens"><i class="fas fa-server"></i></router-link>
            </li>
            <li class="nav-item">
                <router-link to="/domains" class="nav-link" title="Domínios">
                    <svg class="d-inline-block" width="37px" height="32px" viewBox="0 0 37 32" focusable="false">
                        <g transform="translate(1.663 -33.182)">
                            <g transform="translate(-1.663 33.182)">
                                <path d="M34.122,34.62H2.878A2.878,2.878,0,0,0,0,37.5v3.271a.411.411,0,0,0,.411.411H36.589A.411.411,0,0,0,37,40.769V37.5A2.878,2.878,0,0,0,34.122,34.62ZM5.189,38.828a.928.928,0,1,1,.928-.928A.928.928,0,0,1,5.189,38.828Zm2.974,0a.928.928,0,1,1,.928-.928A.928.928,0,0,1,8.163,38.828Zm2.974,0a.928.928,0,1,1,.928-.928A.928.928,0,0,1,11.137,38.828Z" transform="translate(0 -34.62)" />
                                <path d="M36.589,148.15H.411a.411.411,0,0,0-.411.411v20.505a2.878,2.878,0,0,0,2.878,2.878H34.122A2.878,2.878,0,0,0,37,169.067V148.561A.411.411,0,0,0,36.589,148.15Zm-23.711,9.936-1.435,4.429a.822.822,0,0,1-1.476.188l-1.1-1.733-1.1,1.733a.823.823,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767L8.171,159a.823.823,0,0,1,1.388,0l.859,1.35.9-2.767A.822.822,0,0,1,12.878,158.086Zm9.635,0-1.435,4.429a.822.822,0,0,1-1.476.188l-1.1-1.733L17.4,162.7a.823.823,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767.859-1.35a.823.823,0,0,1,1.387,0l.859,1.35.9-2.767A.822.822,0,0,1,22.513,158.086Zm9.635,0-1.435,4.429a.823.823,0,0,1-1.476.188l-1.1-1.733-1.1,1.733a.822.822,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767.859-1.35a.823.823,0,0,1,1.387,0l.859,1.35.9-2.767A.822.822,0,0,1,32.148,158.086Z" transform="translate(0 -139.945)" />
                            </g>
                        </g>
                    </svg>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" title="Suporte" to="/support/tickets"><i class="fas fa-life-ring"></i></router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" title="Créditos" to="/payment"><i class="fas fa-coins"></i></router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" title="Acessos" to="/user/access"><i class="fas fa-users"></i></router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" title="Notificações" to="/user/notifications"><i class="fas fa-bell"></i></router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" title="Perfil" to="/user/profile"><i class="fas fa-user-cog"></i></router-link>
            </li>
        </ul>
    </card-title>
</template>

<script>
    import CardTitle from '../molecules/CardTitle'
    import ButtonCircle from '../atoms/ButtonCircle'

    export default {
        components: {
            CardTitle,
            ButtonCircle
        }
    }
</script>

<style lang="stylus">
    .hto-quick-access {
        .card-header:before {
            background-color $yellow
        }
        @media (max-width 575px) {
            .nav-item {
                width 25%

                svg {
                    fill currentColor
                    margin-top: -.375em;
                    font-size: 1rem;
                }
            }
            .nav-link {
                font-size 2rem
            }
        }
        .nav-buttons {
            margin-left -.5rem
            margin-right -.5rem

            .nav-item {
                max-width calc(100% / 8)
                padding 0 .5rem

                @media (max-width 1199px) {
                    max-width calc(100% / 6)

                    &:nth-child(n+7) {
                        display none
                    }
                }
                @media (max-width 859px) {
                    max-width calc(100% / 5)

                    &:nth-child(n+6) {
                        display none
                    }
                }
                @media (max-width 767px) {
                    max-width calc(100% / 4)

                    &:nth-child(n+5) {
                        display none
                    }
                }
                @media (max-width 599px) {
                    max-width calc(100% / 3)

                    &:nth-child(n+4) {
                        display none
                    }
                }
            }
        }
        .nav-item-primary .h-a-btn-circle .btn-circle-link {
            background-color lighten($primary, 20%)
            color white

            &:hover {
                background-color $primary
            }

            .btn-circle-content {
                font-weight normal
            }
        }
    }
</style>