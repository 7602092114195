<template>
    <admin-page-template>
        <card-title v-if="hosting && hosting.status === 'Pending'" class="h-card-hosting-creating">
            <div v-show="!isHostingCreated" class="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(50 50)">
                        <g transform="rotate(22.0462)">
                            <animateTransform attributeName="transform" type="rotate" values="0;45" keyTimes="0;1" dur="0.2s" repeatCount="indefinite"></animateTransform>
                            <path d="M28.91366458960192 -8 L36.91366458960192 -8 L36.91366458960192 8 L28.91366458960192 8 A30 30 0 0 1 26.101902549753255 14.78819405076849 L26.101902549753255 14.78819405076849 L31.758756799245635 20.445048300260872 L20.445048300260872 31.75875679924563 L14.788194050768492 26.10190254975325 A30 30 0 0 1 8.000000000000002 28.91366458960192 L8.000000000000002 28.91366458960192 L8.000000000000002 36.91366458960192 L-7.999999999999997 36.91366458960192 L-7.999999999999998 28.91366458960192 A30 30 0 0 1 -14.78819405076849 26.101902549753255 L-14.78819405076849 26.101902549753255 L-20.445048300260872 31.758756799245635 L-31.75875679924563 20.445048300260876 L-26.10190254975325 14.788194050768494 A30 30 0 0 1 -28.91366458960192 8.000000000000004 L-28.91366458960192 8.000000000000004 L-36.91366458960192 8.000000000000005 L-36.91366458960192 -7.999999999999996 L-28.91366458960192 -7.9999999999999964 A30 30 0 0 1 -26.101902549753255 -14.788194050768489 L-26.101902549753255 -14.788194050768489 L-31.758756799245635 -20.44504830026087 L-20.445048300260876 -31.75875679924563 L-14.788194050768496 -26.10190254975325 A30 30 0 0 1 -8.000000000000007 -28.913664589601918 L-8.000000000000007 -28.913664589601918 L-8.000000000000009 -36.91366458960192 L7.999999999999993 -36.91366458960192 L7.999999999999995 -28.91366458960192 A30 30 0 0 1 14.788194050768487 -26.10190254975326 L14.788194050768487 -26.10190254975326 L20.445048300260865 -31.75875679924564 L31.75875679924563 -20.44504830026088 L26.10190254975325 -14.788194050768498 A30 30 0 0 1 28.913664589601918 -8.000000000000009 M0 -20A20 20 0 1 0 0 20 A20 20 0 1 0 0 -20" fill="#6149A7"></path>
                        </g>
                    </g>
                </svg>
                <div class="my-6">
                    <p class="mb-1">Aguarde um pouco enquanto sua hospedagem está sendo criada.</p>
                    <p class="mb-0">Este processo pode levar até 2 minutos.</p>
                </div>
            </div>

            <div v-show="isHostingCreated" class="text-center">
                <i class="fas fa-check text-success" style="font-size:200px"></i>
                <div class="my-6">
                    <p class="mb-1">Sua hospedagem está pronta!</p>
                    <p class="mb-0">Você está sendo redirecionado para ela agora...</p>
                </div>
            </div>
        </card-title>

        <div v-if="hosting && hosting.status !== 'Pending'">
            <card-hosting-top ref="cardHostingTop" :hosting="hosting" />
            <router-view />
        </div>
    </admin-page-template>
</template>

<script>
    import { mapActions }       from 'vuex'
    import { ApiCancel }        from '@/plugins/api'
    import tutorial             from '@/mixins/tutorialCreateHosting'
    import store                from '@/store'
    import HostingService       from '@/services/hosting.service'
    import JobService           from '@/services/job.service'
    import CardTitle            from '@/components/molecules/CardTitle'
    import CardHostingTop       from '@/components/organisms/CardHostingTop'
    import AdminPageTemplate    from '@/components/templates/AdminPageTemplate';

    export default {
        props: [
            'resource'
        ],
        mixins: [
            tutorial
        ],
        components: {
            CardTitle,
            CardHostingTop,
            AdminPageTemplate
        },
        data() {
            return {
                hosting: null,
                isHostingCreated: false,
                redirectToMigration: false,
                refreshStatusTimeout: null,
                checkWordpressCancelToken: null,
                checkWordpressAnnouncementId: null,
            }
        },
        beforeRouteEnter(to, from, next)
        {
            store.dispatch('loading/show');

            store.dispatch('hosting/get', { domain: to.params.resource })
                .then(hosting => next(vm => {
                    vm.updateHostingPermissions(hosting);
                    vm.hosting = hosting;
                    vm.init();
                }))
                .catch(error => {
                    if (error.response && error.response.status === 402) {
                        next(vm => vm.needsRecharge = true);
                    }
                    else if (error.response && error.response.status === 403) {
                        next("/");
                    }
                    else {
                        next(vm => vm.showErrorAlert(error));
                    }
                })
                .then(() => store.dispatch('loading/hide'))
        },
        beforeRouteUpdate(to, from, next)
        {
            if (from.params.resource === to.params.resource) {
                next()
            }
            else {
                this.showLoading();
                this.checkWordpressCancelToken && this.checkWordpressCancelToken.cancel();
                this.checkWordpressAnnouncementId && this.removeAnnouncement(this.checkWordpressAnnouncementId);

                this.getHosting({ domain: to.params.resource, force: true })
                    .then(hosting => {
                        this.updateHostingPermissions(hosting);
                        this.hosting = hosting;
                        this.$refs.cardHostingTop.setHosting(this.hosting);
                        this.init();
                        next();
                    })
                    .catch(error => {
                        this.resetPermissions();
                        this.hosting = null;

                        if (error.response && error.response.status !== 402) {
                            this.showErrorAlert(error);
                        }

                        next();
                    })
                    .then(() => this.hideLoading())
            }
        },
        created()
        {
            if (this.$route.query.migration) {
                this.redirectToMigration = true;
            }
        },
        methods: {
            ...mapActions({
                getHosting: 'hosting/get',
                showLoading: 'loading/show',
                hideLoading: 'loading/hide',
                showErrorAlert: 'alert/error',
                addAnnouncement: 'announcement/add',
                removeAnnouncement: 'announcement/remove',
            }),

            /**
             * Definir permissões do usuário para a hospedagem atual.
             */
            updateHostingPermissions(hosting)
            {
                const permissions = HostingService.generatePermissions(hosting);
                this.$acl.change(permissions);
            },

            /**
             * Redefinir permissões do usuário para a hospedagem.
             */
            resetPermissions()
            {
                this.$acl.change('public');
            },

            /**
             * Inicializar funções.
             */
            init()
            {
                if (this.hosting.status === "Pending") {
                    this.refreshStatusTimeout = setTimeout(() => this.refreshStatus(), 10000);
                }
                else if (this.hosting.status === "Active") {
                    this.checkWordpress();
                }
            },

            /**
             * Atualizar status da hospedagem.
             */
            refreshStatus()
            {
                this.getHosting({ domain: this.resource, force: true })
                    .then(hosting => {
                        const status = hosting.status;

                        if (status === "Pending") {
                            this.refreshStatusTimeout = setTimeout(() => this.refreshStatus(), 10000);
                        }
                        else if (status === "Active")
                        {
                            this.isHostingCreated = true;
                            this.$eventBus.$emit('hosting-creation-completed')

                            const redirectUrl = this.redirectToMigration
                                ? `/hosting/migration?domain=${hosting.domain}`
                                : `/hosting/${hosting.domain}?created=1`;

                            // Tag do GA4
                            if (typeof window.gtag !== 'undefined' && typeof window.GA4_MEASUREMENT_ID !== 'undefined')
                            {
                                gtag('event', 'hospedagem_criada', {
                                    'send_to': window.GA4_MEASUREMENT_ID,
                                    'event_callback': () => {
                                        setTimeout(() => window.location.href = redirectUrl, 3000);
                                    }
                                });
                            }
                            else {
                                setTimeout(() => window.location.href = redirectUrl, 3000);
                            }
                        }
                    })
                    .catch(error => {});
            },

            checkWordpress()
            {
                // Avoid running on development (comment this if necessary)
                if (process.env.NODE_ENV !== 'production') return;

                const CancelToken = ApiCancel.CancelToken;
                this.checkWordpressCancelToken = CancelToken.source();

                this.$api.post(`/hosting/${this.hosting.domain}/wordpress/start-check`, { cancelToken: this.checkWordpressCancelToken.token })
                    .then((response) => {
                        const checkJob = response.data.data ? response.data.data.job : null;

                        if (checkJob)
                        {
                            const onCheckSuccess = (data) => {
                                if (data.installed === true && data.cache === false) {
                                    this.addAnnouncement({
                                        variant: 'warning',
                                        dismissible: true,
                                        content: `
                                            <p class="alert-heading h5 font-weight-bold mb-2">
                                                Melhore o desempenho do seu site WordPress
                                            </p>
                                            <p class="mb-2">
                                                Percebemos que você possui um site WordPress instalado em sua hospedagem que não possui o nosso
                                                <strong>plugin de cache</strong>. A Hostoo possui uma solução de cache específica para sites em WordPress
                                                que pode melhorar o desempenho e deixar o seu site mais rápido.
                                            </p>
                                        `,
                                        actions: [{
                                            text: 'Instale agora!',
                                            variant: 'success',
                                            callback: (e) =>
                                            {
                                                e.target.disabled = true;
                                                e.target.classList.add("btn-loading");

                                                this.$api.post(`/hosting/${this.hosting.domain}/wordpress/install-cache`)
                                                    .then (response => {
                                                        const installJob = response.data.data.job;

                                                        const onInstallSuccess = () => {
                                                            this.showLoading();
                                                            window.location.reload();
                                                        };

                                                        const onInstallError = (error) => {
                                                            this.showErrorAlert(error);
                                                            e.target.classList.remove("btn-loading");
                                                            e.target.disabled = false;
                                                        };

                                                        JobService.check(installJob, onInstallSuccess, onInstallError);
                                                    })
                                                    .catch(error => {
                                                        this.showErrorAlert(error);
                                                        e.target.classList.remove("btn-loading");
                                                        e.target.disabled = false;
                                                    });
                                            }
                                        }],
                                    })
                                    .then((item) => this.checkWordpressAnnouncementId = item.id);
                                }
                            };

                            JobService.check(checkJob, onCheckSuccess, null, { cancelToken: this.checkWordpressCancelToken.token });
                        }
                    })
                    .catch(error => {});
            }
        }
    }
</script>
