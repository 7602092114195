<template>
    <div class="h-o-hosting-plan-list">
        <div class="row">
            <div class="col-lg-3">
                <div class="card h-card-package" v-if="packages[0]" v-bind:class="{ 'active': currentPackage === 0 }">
                    <div class="card-body" @click="changePackage(0)">
                        <h3 class="card-title" v-bind:class="{'mb-1':showRecommendation}">{{ packages[0].name }}</h3>
                        <span v-if="showRecommendation" class="badge badge-sm badge-secondary">Para pequenos sites</span>

                        <table class="table table-borderless h-table-resources">
                            <tr>
                                <th>RAM:</th>
                                <td>{{ packages[0].ram }}</td>
                            </tr>
                            <tr>
                                <th>CPU:</th>
                                <td>{{ packages[0].cpu }}</td>
                            </tr>
                            <tr>
                                <th>Disco:</th>
                                <td>{{ packages[0].disk }}</td>
                            </tr>
                        </table>

                        <button
                            type="button"
                            class="btn btn-block btn-price"
                            v-bind:class="[ currentPackage === 0 ? 'btn-success' : 'btn-primary' ]"
                            :disabled="disabled"
                            @click="changePackage(0)"
                        >
                            <span class="price-currency">R$ </span>
                            <span v-if="hourly" class="price-amount">{{ getFormattedPriceExtra(packages[0].price[region]) }}</span>
                            <span v-else class="price-amount">{{ getFormattedPrice(packages[0].price[region]) }}</span>
                            <span v-if="hourly" class="price-period"> / hora</span>
                            <span v-else class="price-period"> / mês</span>
                        </button>

                        <div class="price-extra">
                            R$
                            <span v-if="hourly">{{ getFormattedPrice(packages[0].price[region]) }} / mês</span>
                            <span v-else>{{ getFormattedPriceExtra(packages[0].price[region]) }} / hora</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="card h-card-package" v-if="packages[1]" v-bind:class="{ 'active': currentPackage === 1 }">
                    <div class="card-body" @click="changePackage(1)">
                        <h3 class="card-title" v-bind:class="{'mb-1':showRecommendation}">{{ packages[1].name }}</h3>
                        <span v-if="showRecommendation" class="badge badge-sm badge-success">Recomendado</span>

                        <table class="table table-borderless h-table-resources">
                            <tr>
                                <th>RAM:</th>
                                <td>{{ packages[1].ram }}</td>
                            </tr>
                            <tr>
                                <th>CPU:</th>
                                <td>{{ packages[1].cpu }}</td>
                            </tr>
                            <tr>
                                <th>Disco:</th>
                                <td>{{ packages[1].disk }}</td>
                            </tr>
                        </table>

                        <button
                            type="button"
                            class="btn btn-block btn-price"
                            v-bind:class="[ currentPackage === 1 ? 'btn-success' : 'btn-primary' ]"
                            :disabled="disabled"
                            @click="changePackage(1)"
                        >
                            <span class="price-currency">R$ </span>
                            <span v-if="hourly" class="price-amount">{{ getFormattedPriceExtra(packages[1].price[region]) }}</span>
                            <span v-else class="price-amount">{{ getFormattedPrice(packages[1].price[region]) }}</span>
                            <span v-if="hourly" class="price-period"> / hora</span>
                            <span v-else class="price-period"> / mês</span>
                        </button>

                        <div class="price-extra">
                            R$
                            <span v-if="hourly">{{ getFormattedPrice(packages[1].price[region]) }} / mês</span>
                            <span v-else>{{ getFormattedPriceExtra(packages[1].price[region]) }} / hora</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card h-card-package" v-if="premium" v-bind:class="{ 'active': currentPackage >= 2 }">
                    <div class="card-body" @click="changePackage(2)">
                        <h3 class="card-title" v-bind:class="{'mb-1':showRecommendation}">{{ premium.name }}</h3>
                        <span v-if="showRecommendation" class="badge badge-sm badge-secondary">Para sites de grande porte</span>

                        <div class="row align-items-center mb-4">
                            <div class="col-6">
                                <table class="table table-borderless h-table-resources mb-0">
                                    <tr>
                                        <th>RAM:</th>
                                        <td>{{ premium.ram }}</td>
                                    </tr>
                                    <tr>
                                        <th>CPU:</th>
                                        <td>{{ premium.cpu }}</td>
                                    </tr>
                                    <tr>
                                        <th>Disco:</th>
                                        <td>{{ premium.disk }}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-6">
                                <button
                                    type="button"
                                    class="btn btn-block btn-price"
                                    v-bind:class="[ currentPackage >= 2 ? 'btn-success' : 'btn-primary' ]"
                                    :disabled="disabled"
                                    @click="changePackage(2)"
                                >
                                    <span class="price-currency">R$ </span>
                                    <span v-if="hourly" class="price-amount">{{ getFormattedPriceExtra(premium.price[region]) }}</span>
                                    <span v-else class="price-amount">{{ getFormattedPrice(premium.price[region]) }}</span>
                                    <span v-if="hourly" class="price-period"> / hora</span>
                                    <span v-else class="price-period"> / mês</span>
                                </button>

                                <div class="price-extra">
                                    R$
                                    <span v-if="hourly">{{ getFormattedPrice(premium.price[region]) }} / mês</span>
                                    <span v-else>{{ getFormattedPriceExtra(premium.price[region]) }} / hora</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <vue-slider
                                ref="premiumSlider"
                                class="h-slider-package"
                                v-model="premiumSliderValue"
                                :data="['2 GB','4 GB','8 GB','16 GB','24 GB','32 GB']"
                                :marks="true"
                                :height="16"
                                :dotSize="30"
                                :disabled="disabled"
                                tooltip="none"
                                @change="onChangePremiumSlider">
                            </vue-slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueSlider     from 'vue-slider-component'
    import ErrorService  from '@/services/ErrorService'
    import { ApiCancel } from '@/plugins/api'

    export default {
        props: {
            value: [Number, String, Object],
            region: {
                type: String,
                default: "US"
            },
            disabled: {
                type: Boolean,
                default: false
            },
            showRecommendation: {
                type: Boolean,
                default: false
            },
            hourly: {
                type: Boolean,
                default: false
            }
        },
        components: {
            VueSlider,
        },
        data() {
            return {
                packages: [],
                currentPackage: null,
                premium: null,
                premiumSliderValue: 1,
                fetchCancelToken: null,
            }
        },
        mounted()
        {
            this.getPackages().then(() => {
                if (this.value) {
                    this.setCurrentPackage(this.value);
                }
                else {
                    this.currentPackage = 1;
                    this.$emit('input', this.packages[this.currentPackage]);
                }

                this.$emit('ready');
            });
        },
        methods: {
            /**
             *
             */
            getFormattedPrice(price)
            {
                const _price = parseFloat(price);
                return _price.toFixed(2).replace('.', ',');
            },

            getFormattedPriceExtra(price)
            {
                const _price = parseFloat(price);
                return (_price / 720).toFixed(4).replace('.', ',');
            },

            /**
             * Listar informações dos pacotes.
             */
            getPackages()
            {
                const CancelToken = ApiCancel.CancelToken;
                this.fetchCancelToken = CancelToken.source();

                return this.$api.get('/hosting/plans', { cancelToken: this.fetchCancelToken.token })
                    .then(response => {
                        this.packages = response.data.data;
                        this.premium  = this.packages[2];
                    })
                    .catch(error => ErrorService.showErrorAlert(error));
            },

            /**
             * Cancelar carregamento dos dados
             */
            cancelFetch()
            {
                if (this.fetchCancelToken) {
                    this.fetchCancelToken.cancel();
                }
            },

            setCurrentPackage(value)
            {
                if (typeof value === "number" || typeof value === "string" || value instanceof String) {
                    this.currentPackage = this.packages.findIndex((item) => item.id == value);
                }
                else {
                    this.currentPackage = this.packages.findIndex((item) => item.id == value.id);
                }

                if (this.currentPackage >= 2) {
                    this.premium = this.packages[this.currentPackage];
                    this.$refs.premiumSlider.setIndex(this.currentPackage - 2);
                }
            },

            onChangePremiumSlider()
            {
                const index = this.$refs.premiumSlider.getIndex();
                this.currentPackage = index + 2;
                this.premium = this.packages[this.currentPackage];
                this.$emit('input', this.packages[this.currentPackage]);
            },

            changePackage(index)
            {
                if (index >= 2) {
                    index = this.$refs.premiumSlider.getIndex();
                    index = index === -1 ? 0 : index;
                    index += 2;
                }

                this.currentPackage = index;
                this.$emit('input', this.packages[this.currentPackage]);
            }
        },
        watch: {
            value: function (val) {
                this.setCurrentPackage(val);
            }
        }
    }
</script>

<style lang="stylus">
    .h-o-hosting-plan-list
        position relative

        .h-card-package
            border 0
            background-color #EFEFEF
            color $primary

            @media (min-width 992px)
                height 100%

            .card-body
                padding 1.5rem 1.75rem

            .card-title
                font-size 1.75em
                font-weight 600
                color inherit
                margin-bottom .75rem

            .h-table-resources
                margin-bottom 1.5rem
                color inherit

                th, td
                    padding 0

                th
                    width 1px
                    min-width 1px
                    white-space nowrap
                    padding-right .5rem
                    font-weight 800

            .btn-price
                display flex
                align-items center
                justify-content center

                span
                    display block
                    white-space pre
                    font-size 14px

                .price-amount
                    font-size 1.5em
                    font-weight 700

            .price-extra
                margin-top .25rem
                text-align center
                font-size 12px
                font-weight 700

            &.active
                background-color $primary
                color white

        .h-slider-package
            &.vue-slider
                margin 0 10px

            .vue-slider-rail
                border-radius 0

                &:before, &:after
                    content ""
                    position absolute
                    top 0
                    size 10px 100%
                    background-color #CCC

                &:before
                    left -10px
                    border-radius 15px 0 0 15px

                &:after
                    right -10px
                    border-radius 0 15px 15px 0

            .vue-slider-process
                border-radius 0
                background-color $green

                &:before
                    content ""
                    absolute top 0 left -10px
                    size 10px 100%
                    border-radius 15px 0 0 15px
                    background-color $green

            .vue-slider-mark-step
                display block !important
                background-color transparent

                &:before, &:after
                    content ""
                    position absolute
                    left 50%
                    size 3px 8px
                    margin-left -1px
                    background-color #CCC

                &:before
                    top -10px

                &:after
                    bottom -10px

            .vue-slider-mark-label
                font-size 12px
                font-weight 700
                letter-spacing -.025em
                color $primary

            .vue-slider-dot-handle
                border 4px solid $green

        .h-card-package.active
            .price-extra
                font-weight 600

            .h-slider-package .vue-slider-mark-label
                font-weight 600
                color white
</style>