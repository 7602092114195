<template>
    <div class="h-o-admin-sidebar">
        <input-search v-if="!hideSearch" class="mb-4" v-model="searchTerm" />

        <template v-for="(list, index) in lists">
            <div :key="`list-${index}`" class="h-sidebar-list">
                <div v-if="list.title" class="h-sidebar-title-wrapper px-3" v-bind:class="{ 'has-button': list.addLink }">
                    <h5 class="h-sidebar-title">{{ list.title }}</h5>
                    <router-link v-if="list.addLink" class="btn btn-success btn-circle" :to="list.addLink" :title="list.addLinkTitle" v-b-tooltip.right>
                        <i class="fas fa-plus"></i>
                    </router-link>
                </div>

                <text-loading v-show="loading" class="px-3" />

                <div class="h-list-wrapper">
                    <ul v-if="list.items.length" v-show="!loading" class="list-group">
                        <li v-for="(item, index2) in list.items" :key="`list-item-${index2}`" class="list-group-item text-truncate" v-bind:class="{ 'd-none': item.isHidden }">
                            <slot name="list-item" :item="item">
                                <router-link class="h-list-link" :to="item.link" :title="item.text">{{ item.text }}</router-link>
                            </slot>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import InputSearch from "../atoms/InputSearch"
    import TextLoading from "../atoms/TextLoading"

    export default {
        props: {
            title: {
                type: String,
                default: "",
            },
            listItems: {
                type: [Array, Object],
                default() {
                    return [];
                }
            },
            filterBy: {
                type: String,
                default: "text"
            },
            loading: {
                type: Boolean,
                default: false
            },
            hideSearch: {
                type: Boolean,
                default: false
            }
        },
        components: {
            InputSearch,
            TextLoading
        },
        data() {
            return {
                searchTerm: "",
                lists: [],
            }
        },
        mounted()
        {
            if (this.listItems) {
                this.setListItems(this.listItems);
            }
        },
        methods: {

            /**
             * @param {Object|Object[]} listItems
             */
            setListItems(listItems)
            {
                if (listItems === null || typeof(listItems) === "undefined") return;

                let _lists = [];

                if (Array.isArray(listItems)) {
                    if (listItems.length) {
                        if (typeof listItems[0] === "object")
                        {
                            if (listItems[0].hasOwnProperty("items")) {
                                _lists = listItems.filter(item => typeof item === "object" && item.hasOwnProperty("items"));
                            }
                            else {
                                _lists.push({
                                    title: this.title,
                                    items: listItems
                                });
                            }
                        }
                    }
                }
                else if (typeof listItems === "object")
                {
                    if (listItems.hasOwnProperty("items")) {
                        _lists.push(listItems);
                    }
                    else {
                        _lists.push({
                            title: this.title,
                            items: [listItems]
                        });
                    }
                }

                this.lists = _lists;
            },

            /**
             * Atualizar lista de itens.
             */
            filterLists()
            {
                const term = this.searchTerm.trim().toLowerCase();
                let i, j, hidden;

                if (this.lists.length) {
                    for (i = 0; i < this.lists.length; i++) {
                        for (j = 0; j < this.lists[i].items.length; j++) {
                            hidden = (term && this.lists[i].items[j][this.filterBy] && this.lists[i].items[j][this.filterBy].toLowerCase().indexOf(term) === -1);
                            this.$set(this.lists[i].items[j], "isHidden", hidden);
                        }
                    }
                }
            }
        },
        watch: {
            listItems(val) {
                this.setListItems(val);
            },
            searchTerm(val, oldVal) {
                if (val !== oldVal) {
                    this.filterLists();
                }
            }
        }
    }
</script>

<style lang="stylus">
    .h-o-admin-sidebar
        position fixed
        top 0
        left 0
        width 280px
        margin-top $header-height
        margin-left 1.5rem
        padding 1.5rem 0

        .h-sidebar-list + .h-sidebar-list
            margin-top 1.5rem

        .h-sidebar-title-wrapper
            position relative
            display flex
            align-items center
            margin-bottom .5rem

            &:after
                content ""
                display block
                flex-grow 1
                margin-left .5rem
                border-top 1px solid $gray-900

            .btn
                absolute top 50% right 0
                transform translateY(-50%)

            &.has-button:after
                margin-right 2rem

        .h-sidebar-title
            margin-bottom 0
            font-family "Nunito", sans-serif

        .h-list-wrapper
            max-height 16rem
            overflow-y auto

        .list-group-item
            border 0
            background none
            padding .25rem .75rem

        .h-list-link
            text-decoration none
            color $gray-900

            &:hover, &:focus
                color $primary
</style>