<template>
    <domain-template :resource="resource">
        <card-title title="Renovação de Domínio" inner-body>

        </card-title>
    </domain-template>
</template>

<script>
    import CardTitle        from '@/components/molecules/CardTitle'
    import DomainTemplate   from '@/components/templates/DomainTemplate'

    export default {
        name: 'DomainRenew',
        props: ['resource'],
        components: {
            DomainTemplate,
            CardTitle
        },
    }
</script>
