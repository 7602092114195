<script>
    import Vuetable from "vuetable-2/src/components/Vuetable"

    export default {
        extends: Vuetable,
        props: {
            tableClass: String,
            maxHeight: String,
            loading: Boolean,
            loadingText: String,
            emptyText: String
        },
        data() {
            return {
                isLoading: false,
            }
        },
        computed: {
            displayEmptyDataRow: function() {
                return !this.isLoading && this.countTableData === 0 && this.noDataTemplate.length > 0;
            },
            isFixedHeader () {
                return this.maxHeight;
            }
        },
        mounted()
        {
            this.css.ascendingIcon  = "fas fa-caret-up";
            this.css.descendingIcon = "fas fa-caret-down";

            if (this.tableClass) {
                this.css.tableClass += ' ' + this.tableClass;
            }

            if (this.loading) {
                this.showLoading();
            }

            this.$nextTick(() => {
                if (this.maxHeight) {
                    this.$el.querySelector('.vuetable-body-wrapper').style.maxHeight = this.maxHeight;
                    this.fixHeader();
                }
            });
        },
        methods:
        {
            isSortable(field) {
                return !this.isLoading && this.countTableData > 0 && typeof field.sortField !== 'undefined';
            },

            /**
             *
             */
            showLoading()
            {
                if (this.isLoading) return;

                this.isLoading = true;

                let cell, row;

                cell = document.createElement("td");
                cell.setAttribute("colspan", this.countVisibleFields);
                cell.innerHTML = '<span class="text-muted"><i class="fas fa-circle-notch fa-spin mr-1"></i> Carregando...</span>';

                row = document.createElement("tr");
                row.classList.add("loading-row");
                row.appendChild(cell);

                const body = this.$el.querySelector(".vuetable-body");
                body.parentNode.appendChild(row);
                body.style.display = "none";
            },

            hideLoading()
            {
                if (!this.isLoading) return;

                this.isLoading = false;

                this.$el.querySelector(".loading-row").remove();
                this.$el.querySelector(".vuetable-body").style.display = "";
            }
        },
        watch: {
            loading: function(val) {
                if (val) {
                    this.showLoading();
                }
                else {
                    this.hideLoading();
                }
            },
            maxHeight: function(val, old) {
                if (val) {
                    if (val !== old) {
                        this.$el.querySelector('.vuetable-body-wrapper').style.maxHeight = val;
                        this.fixHeader();
                    }
                }
                else {
                    this.$el.querySelector('.vuetable-body-wrapper').style.maxHeight = '';
                    this.fixHeader();
                }
            }
        }
    }
</script>

<style lang="stylus">
    .table
        thead th .sort-icon
            float none !important
            margin-left .125rem

        &.table-custom-header .loading-row td
            border-top 0
            border-bottom 1px solid $gray-300
</style>
