<template>
    <admin-page-template>
        <card-title :title="isTransfer ? 'Transferência de Domínio' : 'Registro de Domínio'">
            <template slot="intro-text">
                Escolha por quanto tempo deseja registrar o domínio e preencha os dados de contato do responsável.
                Em caso de transferência de domínio de outro registrador para a Hostoo, também será necessário informar
                o código de autorização (EPP) fornecido pela empresa onde seu domínio foi registrado inicialmente.
            </template>

            <form-group label="Domínio" :error="formErrors.domain">
                <div class="input-group input-group-lg">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-globe"></i></span>
                    </div>
                    <input type="text" class="form-control" spellcheck="false" readonly disabled v-model="resource" v-input-lowercase />
                </div>
            </form-group>

            <form accept-charset="UTF-8" @submit.prevent="registerDomain">
                <form-group label="Período" label-for="domainPeriod" :error="formErrors.period">
                    <select id="domainPeriod" class="custom-select" v-model="domain.period">
                        <template v-if="Object.keys(prices).length">
                            <option value="" disabled>Escolha...</option>
                            <option v-for="(value, key) in prices" :value="key">{{ key }} {{ key == 1 ? 'ano' : 'anos' }} ({{ value }})</option>
                        </template>
                        <option v-else value="" disabled>Nenhuma opção de preço disponível</option>
                    </select>
                    <p class="form-text-sm mt-1 mb-0 ml-1">
                        <i class="fas fa-info-circle fa-fw" aria-hidden="true"></i>
                        É necessário possuir o valor acima em créditos em sua conta (exceto bônus recebidos).
                    </p>
                </form-group>

                <form-group v-if="isTransfer" label="Código de autorização (EPP)" label-for="domainEpp" :error="formErrors.epp">
                    <input type="text" id="domainEpp" class="form-control" autocomplete="off" spellcheck="false" v-model="domain.epp">
                    <p class="form-text-sm mt-1 mb-0 ml-1">
                        <i class="fas fa-info-circle fa-fw" aria-hidden="true"></i>
                        Para transferência de domínio, você deve obter o código EPP com o seu registrador atual.
                    </p>
                </form-group>

                <div class="card-title-subtitle">
                    <h5 class="card-title-inner">Dados do responsável</h5>
                </div>

                <form-group label="CPF / CNPJ" label-for="domaincpfCnpj" :error="formErrors.cpfCnpj">
                    <the-mask
                        type="tel"
                        id="domaincpfCnpj"
                        class="form-control"
                        placeholder="000.000.000-00"
                        :mask="['###.###.###-##', '##.###.###/####-##']"
                        v-model="domain.cpfCnpj" />
                </form-group>

                <form-group label="Nome completo / Razão social" label-for="domainName" :error="formErrors.name">
                    <input type="text" id="domainName" class="form-control" v-model="domain.name">
                </form-group>

                <div class="row">
                    <div class="col-md">
                        <form-group label="E-mail de contato" label-for="domainEmail" :error="formErrors.email">
                            <input type="email" id="domainEmail" class="form-control" v-model="domain.email" autocomplete="off">
                        </form-group>
                    </div>
                    <div class="col-md">
                        <form-group label="Telefone" label-for="domainPhone" :error="formErrors.phone">
                            <the-mask
                                type="tel"
                                id="domainPhone"
                                class="form-control"
                                placeholder="(00) 0000-0000"
                                :mask="['(##) ####-####', '(##) #####-####']"
                                v-model="domain.phone" />
                        </form-group>
                    </div>
                </div>

                <div class="card-title-subtitle">
                    <h5 class="card-title-inner">Endereço do responsável</h5>
                </div>

                <form-address v-model="domainAddress" :errors="formErrors" />

                <!--<div class="row mb-md-4">
                    <div class="col-md-3">
                        <form-group label="CEP" label-for="domainAddressZip" class="mb-md-0" :error="formErrors.addressZip">
                            <zip-code id="domainAddressZip" :disabled="isZipCodeDisabled" v-model="domain.addressZip" @ready="updateAddress" />
                        </form-group>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-8 col-sm-9">
                                <form-group label="Endereço" label-for="domainAddressStreet" class="mb-md-0" :error="formErrors.addressStreet">
                                    <input type="text" id="domainAddressStreet" class="form-control" placeholder="Rua, número, complemento" autocomplete="street-address"
                                        v-model="domain.addressStreet">
                                </form-group>
                            </div>
                            <div class="col-4 col-sm-3">
                                <form-group label="Número" label-for="domainAddressNumber" class="mb-md-0" :error="formErrors.addressNumber">
                                    <input type="text" id="domainAddressNumber" class="form-control" v-model="domain.addressNumber">
                                </form-group>
                            </div>
                        </div>
                    </div>
                </div>

                <form-group label="Bairro" label-for="domain.addressNeighborhood" :error="formErrors.addressNeighborhood">
                    <input type="text" id="domainAddressNeighborhood" class="form-control" autocomplete="address-level3" v-model="domain.addressNeighborhood">
                </form-group>

                <div class="row">
                    <div class="col-6">
                        <form-group label="Cidade" label-for="domainAddressCity" :error="formErrors.addressCity">
                            <input type="text" id="domainAddressCity" class="form-control" autocomplete="address-level2"
                               v-model="domain.addressCity"
                               v-bind:class="{ 'text-muted': disableCityStateFields }"
                               :disabled="disableCityStateFields">
                        </form-group>
                    </div>
                    <div class="col-6">
                        <form-group label="Estado" label-for="domainAddressState" :error="formErrors.addressState">
                            <b-select id="domainAddressState" autocomplete="address-level1" v-model="domain.addressState" :disabled="disableCityStateFields">
                                <b-form-select-option :value="null"></b-form-select-option>
                                <b-form-select-option value="AC">Acre</b-form-select-option>
                                <b-form-select-option value="AL">Alagoas</b-form-select-option>
                                <b-form-select-option value="AP">Amapá</b-form-select-option>
                                <b-form-select-option value="AM">Amazonas</b-form-select-option>
                                <b-form-select-option value="BA">Bahia </b-form-select-option>
                                <b-form-select-option value="CE">Ceará</b-form-select-option>
                                <b-form-select-option value="DF">Distrito Federal </b-form-select-option>
                                <b-form-select-option value="ES">Espírito Santo</b-form-select-option>
                                <b-form-select-option value="GO">Goiás</b-form-select-option>
                                <b-form-select-option value="MA">Maranhão</b-form-select-option>
                                <b-form-select-option value="MT">Mato Grosso</b-form-select-option>
                                <b-form-select-option value="MS">Mato Grosso do Sul</b-form-select-option>
                                <b-form-select-option value="MG">Minas Gerais</b-form-select-option>
                                <b-form-select-option value="PA">Pará</b-form-select-option>
                                <b-form-select-option value="PB">Paraíba</b-form-select-option>
                                <b-form-select-option value="PR">Paraná</b-form-select-option>
                                <b-form-select-option value="PE">Pernambuco</b-form-select-option>
                                <b-form-select-option value="PI">Piauí</b-form-select-option>
                                <b-form-select-option value="RJ">Rio de Janeiro</b-form-select-option>
                                <b-form-select-option value="RN">Rio Grande do Norte</b-form-select-option>
                                <b-form-select-option value="RS">Rio Grande do Sul</b-form-select-option>
                                <b-form-select-option value="RO">Rondônia</b-form-select-option>
                                <b-form-select-option value="RR">Roraima</b-form-select-option>
                                <b-form-select-option value="SC">Santa Catarina</b-form-select-option>
                                <b-form-select-option value="SP">São Paulo</b-form-select-option>
                                <b-form-select-option value="SE">Sergipe</b-form-select-option>
                                <b-form-select-option value="TO">Tocantins</b-form-select-option>
                            </b-select>
                        </form-group>
                    </div>
                </div>-->

                <div v-show="showPassword">
                    <form-group label="Informe sua senha para continuar" label-for="userPassword" :error="formErrors.password">
                        <input ref="userPassword" type="password" id="userPassword" class="form-control" autocomplete="new-password" v-model="domain.password">
                    </form-group>
                </div>

                <div class="form-buttons text-right">
                    <button-form ref="btnSave" type="submit" variant="success" class="btn-lg" v-html="isTransfer ? 'Solicitar transferência' : 'Registrar'"></button-form>
                </div>
            </form>
        </card-title>

        <b-modal ref="modalRecharge" title="Realize uma recarga" centered hide-footer hide-header-close>
            <p class="mb-0">
                Agora você será redirecionado para a tela de recarga onde poderá adicionar créditos em sua conta e
                concluir o registro.
            </p>
        </b-modal>
    </admin-page-template>
</template>

<script>
    import ButtonForm        from '@/components/atoms/ButtonForm'
    import ZipCode           from '@/components/atoms/ZipCode'
    import FormGroup         from '@/components/molecules/FormGroup'
    import CardTitle         from '@/components/molecules/CardTitle'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'
    import ErrorService from '@/services/error.service'
    import store from '@/store'
    import {Api} from '@/plugins/api'
    import FormAddress from '@/components/organisms/FormAddress'

    export default {
        props: ['resource'],
        data() {
            return {
                prices: {},
                domain: {
                    period: "",
                    epp: "",
                    name: "",
                    email: "",
                    cpfCnpj: "",
                    password: "",
                },
                domainAddress: {},
                formErrors: {},
                isTransfer: false,
                showPassword: false,
                isZipCodeDisabled: false,
                disableCityStateFields: false,
            }
        },
        components: {
            FormAddress,
            ZipCode,
            CardTitle,
            AdminPageTemplate,
            ButtonForm,
            FormGroup,
        },
        beforeRouteEnter(to, from, next)
        {
            store.dispatch('loading/show');

            let domainType = 'register';

            if (to.query.transfer) {
                domainType = 'transfer';
            }

            Api.post("/domain/prices", { "domain": to.params.resource, "type": domainType })
                .then (response => next(vm => {
                    vm.prices = response.data.data;

                    if (Object.keys(vm.prices).length) {
                        vm.domain.period = Object.keys(vm.prices)[0];
                    }

                    if (domainType === 'transfer') {
                        vm.domain.epp = to.query.epp || "";
                    }
                }))
                .catch(error => {
                    if (error.response.status === 404) {
                        alert("Domínio não encontrado.");
                        next("/");
                    }
                    else {
                        next(vm => ErrorService.handlePageError(error, vm));
                    }
                })
                .then (() => store.dispatch('loading/hide'));
        },
        mounted()
        {
            if (this.$route.query.transfer) {
                this.isTransfer = true;
            }
        },
        methods: {
            /**
             * Atualizar dados de endereço a partir do CEP.
             * @param {Object} data
             */
            updateAddress(data)
            {
                if (data)
                {
                    this.$set(this.domain, 'addressStreet',       data.street);
                    this.$set(this.domain, 'addressNeighborhood', data.neighborhood);
                    this.$set(this.domain, 'addressCity',         data.city);
                    this.$set(this.domain, 'addressState',        data.state);
                    this.disableCityStateFields = true;
                }
                else
                {
                    this.$set(this.domain, 'addressCity',  '');
                    this.$set(this.domain, 'addressState', '');
                    this.disableCityStateFields = false;
                }
            },

            registerDomain()
            {
                if (!this.domain.password) {
                    this.showPassword = true;
                    this.$nextTick(() => this.$refs.userPassword.focus());
                    return;
                }

                this.formErrors = {};
                this.$refs.btnSave.loadingFocus();

                let data = Object.assign({}, this.domain, this.domainAddress);

                data.domain = this.resource;
                data.type = this.isTransfer ? 'transfer' : 'register';

                this.$api.post('/domain', data)
                    .then(response => {
                        this.showPassword = false;
                        this.domain.password = "";

                        if (response.data.data && response.data.data.needsRecharge)
                        {
                            this.$refs.modalRecharge.show();

                            const queryOptions = {
                                order: response.data.data.order,
                            };

                            setTimeout(() => this.$router.push({
                                path: '/payment/manual-recharge',
                                query: queryOptions
                            }), 5000);
                        }
                        else {
                            // GlobalAlert.success("Seu domínio foi registrado com sucesso!");
                            this.$router.push("/domains");
                        }
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnSave.setLoading(false);
                    });
            }
        }
    }
</script>