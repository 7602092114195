<template>
    <div>
        <div v-if="isCreatedHosting" class="row">
            <div class="col-md-4">
                <card-link
                    text="Solicitar migração"
                    :link="`/hosting/migration?domain=${resource}`"
                    icon-class="fas fa-exchange-alt"
                />
            </div>
            <div class="col-md-4">
                <card-link
                    text="Instalar WordPress"
                    :link="`/hosting/${resource}/app/install?name=wordpress`"
                    icon-class="fab fa-wordpress-simple"
                />
            </div>
            <div class="col-md-4">
                <card-link
                    class="mb-md-0"
                    text="Criar um e-mail"
                    :link="`/hosting/${resource}/email`"
                    icon-class="fas fa-envelope-open-text"
                />
            </div>
        </div>

        <div class="row no-gutters mb-4 mb-sm-8">
            <div class="col-lg-7">
                <card-hosting-info class="mr-lg-4" :hosting="hosting" :plan="plan" :loading="isLoadingHostingPlan"/>
            </div>
            <div class="col-lg-5 mt-4 mt-lg-0">
                <card-title title="Gráficos de Consumo" class="ml-lg-4">
                    <template slot="actions">
                        <span class="d-inline-block" title="Atualizar" v-b-tooltip.hover.topleft>
                            <button type="button" class="btn btn-secondary btn-circle" :disabled="isLoadingStatistics" @click="fetchStatistics">
                                <i class="fas fa-redo" v-bind:class="{'fa-spin': isLoadingStatistics}"></i>
                            </button>
                        </span>

                        <router-link class="btn btn-success btn-circle ml-1" :to="`/hosting/${resource}/charts`" title="Veja mais informações" v-b-tooltip.topleft>
                            <i class="fas fa-plus"></i>
                        </router-link>
                    </template>

                    <!-- TODO: Usar loading com logo da Hostoo animado -->
                    <div v-show="isLoadingStatistics" class="w-100 text-center">
                        <text-loading />
                    </div>

                    <div v-show="!isLoadingStatistics">
                        <div>
                            <chart-resource ref="chartCpu" title="CPU" theme="green" :before-render="setChartOptions" :style="{height:'160px'}"/>
                        </div>
                        <div class="mt-6">
                            <chart-resource ref="chartRam" title="RAM" extra-title="Cache" theme="blue" :before-render="setChartOptions" :style="{height:'160px'}"/>
                        </div>
                    </div>
                </card-title>

                <card-title title="Acelerador de WordPress" class="mb-0 ml-lg-4">
                    <template slot="actions">
                        <span v-if="accelerator.enabled === true" class="d-inline-block" title="Desativar acelerador" v-b-tooltip.hover.topleft>
                            <button type="button" class="btn btn-danger btn-circle" :disabled="isLoadingAccelerator" @click="toggleAccelerator(false)">
                                <i class="fas fa-power-off"></i>
                            </button>
                        </span>

                        <span v-else class="d-inline-block" title="Ativar acelerador" v-b-tooltip.hover.topleft>
                            <button type="button" class="btn btn-success btn-circle" :disabled="isLoadingAccelerator" @click="showEnableAcceleratorModal">
                                <i class="fas fa-power-off"></i>
                            </button>
                        </span>
                    </template>

                    <text-loading v-show="isLoadingAccelerator" />

                    <div v-show="!isLoadingAccelerator">
                        <div class="table-list-wrapper">
                            <table class="table table-sm table-list mb-0">
                                <tbody>
                                    <tr v-if="typeof accelerator.enabled !== 'undefined'">
                                        <th>Status:</th>
                                        <td class="text-right">
                                            <span v-if="accelerator.enabled" class="text-success">Ativado</span>
                                            <span v-else class="text-muted">Desativado</span>
                                        </td>
                                    </tr>
                                    <tr v-if="accelerator.enabled === true && typeof accelerator.plugin !== 'undefined'">
                                        <th>Plugin de cache:</th>
                                        <td class="text-right">
                                            <span v-if="accelerator.plugin === true" class="text-success">Configurado</span>
                                            <span v-else-if="accelerator.plugin === false" class="text-danger">Pendente</span>
                                            <span v-else class="text-muted">Não identificado</span>
                                        </td>
                                    </tr>
                                    <tr v-if="accelerator.enabled === true && typeof accelerator.plugin !== 'undefined'">
                                        <th>Preço mensal:</th>
                                        <td class="text-right">R$ 15,00</td>
                                    </tr>
                                    <tr v-if="accelerator.enabled === true && typeof accelerator.plugin !== 'undefined'">
                                        <th>Preço por hora:</th>
                                        <td class="text-right">R$ 0,0208</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="accelerator.enabled !== true" class="mt-4">
                            <button-form ref="btnEnableAccelerator" theme="success" @click="showEnableAcceleratorModal">Ativar acelerador</button-form>
                        </div>
                    </div>

                    <div v-show="acceleratorAlertVisible" class="alert alert-warning mb-0 mt-3">
                        <i class="far fa-clock mr-1"></i>
                        A ativação pode demorar alguns minutos&hellip;
                    </div>

                    <div v-if="typeof accelerator.error !== 'undefined'">
                        <span class="text-danger"><i class="fas fa-times-circle mr-1"></i> Erro ao carregar o status do acelerador</span>
                    </div>
                </card-title>

                <modal-confirm ref="modalEnableAccelerator" confirmText="Ativar" size="lg" title="Ativar Acelerador de WordPress" title-class="h4 mx-auto mb-3" @confirm="toggleAccelerator(true)">
                    <div v-html="acceleratorDescription"></div>
                </modal-confirm>

                <b-modal ref="modalAcceleratorUnavailable" size="lg" title="Ativar Acelerador de WordPress" title-class="h4 mx-auto mb-3" centered hide-footer hide-header-close>
                    <div class="mb-4 pb-2" v-html="acceleratorDescription"></div>
                    <div class="alert alert-warning mb-0" role="alert">
                        Este recurso está disponível a partir do plano <strong>Nuvem Pro</strong>
                        <router-link :to="'/hosting/' + resource + '/plan'"><i class="fas fa-external-link-alt ml-1" aria-hidden="true"></i></router-link>.
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import AjaxService              from '@/services/AjaxService'
    import ButtonForm               from '@/components/atoms/ButtonForm'
    import ModalConfirm             from '@/components/atoms/ModalConfirm'
    import TextLoading              from '@/components/atoms/TextLoading'
    import CardTitle                from '@/components/molecules/CardTitle'
    import ChartResource            from '@/components/molecules/ChartResource'
    import CardHostingInfo          from '@/components/organisms/CardHostingInfo'
    import JobService from '@/services/job.service'
    import CardLink from "@/components/molecules/CardLink.vue";

    export default {
        props: [
            'resource'
        ],
        components: {
            CardLink,
            ButtonForm,
            ModalConfirm,
            TextLoading,
            ChartResource,
            CardTitle,
            CardHostingInfo
        },
        data() {
            return {
                domain: "",
                accelerator: {},

                isCreatedHosting: false,
                isLoadingStatistics: false,
                isLoadingAccelerator: false,

                acceleratorAlertVisible: false,
                acceleratorAlertTimeout: null,
            }
        },
        computed: {
            ...mapState('hosting', [
                'hosting',
                'plan',
                'isLoadingHostingPlan'
            ]),
            acceleratorDescription: function() {
                return `
                    <div class="row">
                        <div class="col-md-6">
                            <h5 class="font-weight-bold text-success mb-2">O que é?</h5>
                            <p class="mb-4 pb-2">
                                O Acelerador de WordPress é um recurso exclusivo da Hostoo que
                                <strong>pode melhorar o desempenho do seu site ou aplicação</strong>.
                                Ao ativá-lo, recursos extras de cache serão adicionados à sua hospedagem e integrados à sua
                                aplicação automaticamente.
                            </p>
                        </div>
                        <div class="col-md-6">
                            <h5 class="font-weight-bold text-success mb-2">Quanto custa?</h5>
                            <p class="mb-4 pb-2">
                                Este recurso custa <strong>R$ 0,0208 por hora</strong>. O valor será acrescido ao preço do
                                plano atual de sua hospedagem. Você só será cobrado enquanto o acelerador estiver ativo.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h5 class="font-weight-bold text-success mb-2">Quando é recomendado?</h5>
                            <p class="mb-0">
                                O acelerador pode melhorar <strong>em até 57%</strong> a performance de sites que fazem
                                <strong>muitas consultas ao banco de dados</strong>, que utilizam <strong>temas pesados</strong>
                                e/ou que foram feitos usando <strong>Elementor</strong> ou <strong>WooCommerce</strong>.
                                Quando o site faz poucas consultas ao banco de dados ou usa um tema mais leve o ganho de
                                performance pode ser menor, mas ainda assim significativo.
                            </p>
                        </div>
                    </div>
                `;
            }
        },
        beforeRouteEnter(to, from, next)
        {
            next(vm => {
                vm.domain = to.params.resource;
                vm.getPlanInfo();
                vm.fetchStatistics();
                vm.fetchAccelerator();
            });
        },
        beforeRouteUpdate(to, from, next)
        {
            if (from.params.resource !== to.params.resource) {
                this.domain = to.params.resource;
                this.getPlanInfo();
                this.fetchStatistics();
                this.fetchAccelerator();
            }

            next();
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        created()
        {
            if (this.$route.query.created) {
                this.isCreatedHosting = true;
            }
        },
        methods: {
            ...mapActions({
                getHostingPlan: 'hosting/getPlan',
                showErrorAlert: 'alert/error'
            }),

            /**
             * Alterar opções do gráfico.
             */
            setChartOptions(options)
            {
                options.scales.xAxes[0].time.stepSize = 15;
                return options;
            },

            /**
             * Obter informações do plano de cobrança da hospedagem.
             */
            getPlanInfo()
            {
                if (this.$acl.check('isOwner')) {
                    this.getHostingPlan({ domain: this.domain, force: true });
                }
            },

            /**
             * Carregar dados para o gráfico de consumo.
             */
            fetchStatistics()
            {
                this.isLoadingStatistics = true;

                this.$api.get(`/hosting/${this.domain}/statistics`, {
                        params: { period: 1 },
                        cancelToken: AjaxService.getCancelToken()
                    })
                    .then (response => {
                        let dataCpu = [];
                        let dataRam = [];
                        let dataCache = [];
                        let i;

                        for (i in response.data.data) {
                            dataCpu.push({ t: i, y: response.data.data[i].cpu });
                            dataRam.push({ t: i, y: response.data.data[i].ram });

                            if (typeof response.data.data[i].cache !== "undefined") {
                                dataCache.push({ t: i, y: response.data.data[i].cache });
                            }
                        }

                        this.$refs.chartCpu.updateData([dataCpu]);
                        this.$refs.chartRam.updateData([dataRam, dataCache]);
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingStatistics = false);
            },

            /**
             * Carregar status do acelerador.
             */
            fetchAccelerator()
            {
                this.accelerator = {};
                this.isLoadingAccelerator = true;

                this.$api.get(`/hosting/${this.domain}/addons`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        if (response.data.data.accelerator) {
                            this.$set(this, 'accelerator', response.data.data.accelerator);
                        }
                    })
                    .catch(error => this.accelerator.error = true)
                    .then (() => this.isLoadingAccelerator = false);
            },

            showEnableAcceleratorModal()
            {
                if (this.accelerator && this.accelerator.available) {
                    this.$refs.modalEnableAccelerator.show();
                }
                else {
                    this.$refs.modalAcceleratorUnavailable.show();
                }
            },

            /**
             * Alterar status do acelerador.
             *
             * @param {boolean} status
             */
            toggleAccelerator(status)
            {
                this.isLoadingAccelerator = true;

                this.$refs.btnEnableAccelerator && this.$refs.btnEnableAccelerator.loadingFocus();
                this.$refs.btnDisableAccelerator && this.$refs.btnDisableAccelerator.loadingFocus();

                if (status) {
                    this.acceleratorAlertTimeout = setTimeout(() => this.acceleratorAlertVisible = true, 10000);
                }

                this.$api.patch(`/hosting/${this.hosting.domain}/addons/accelerator`, { status: status })
                    .then (response => {
                        if (response.data.data && response.data.data.job) {
                            const job = response.data.data.job;
                            const onSuccess = () => this.fetchAccelerator();
                            const onError = (error) => {
                                this.showErrorAlert(error);
                                this.isLoadingAccelerator = false;
                                this.$refs.btnEnableAccelerator && this.$refs.btnEnableAccelerator.setLoading(false);
                                this.$refs.btnDisableAccelerator && this.$refs.btnDisableAccelerator.setLoading(false);
                            };

                            JobService.check(job, onSuccess, onError);
                        }
                        else {
                            this.fetchAccelerator();
                        }
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.isLoadingAccelerator = false;
                    })
                    .then (() => {
                        this.$refs.btnEnableAccelerator && this.$refs.btnEnableAccelerator.setLoading(false);
                        this.$refs.btnDisableAccelerator && this.$refs.btnDisableAccelerator.setLoading(false);
                        this.acceleratorAlertTimeout && clearTimeout(this.acceleratorAlertTimeout);
                        this.acceleratorAlertVisible = false;
                    });
            },
        }
    }
</script>