<template>
    <div class="h-m-page-loader">
        <lottie class="h-loader-image" :options="options" :height="400" :width="400" />
    </div>
</template>

<script>
    import Lottie from '../../../node_modules/vue-lottie/src/lottie'
    import animationData from '@/assets/logo-animation-data.json'

    export default {
        components: {
            Lottie
        },
        data() {
            return {
                options: {
                    animationData: animationData
                }
            }
        }
    }
</script>

<style lang="stylus">
    .h-m-page-loader
        background-color rgba(255,255,255,0.75)
        position fixed
        top 0
        right 0
        bottom 0
        left 0
        z-index 10000
        size 100%
        opacity 0
        animation .3s ease-in-out .1s fadeIn
        animation-fill-mode forwards

        .h-loader-image
            position absolute
            top calc(50% - 200px)
            left calc(50% - 200px)
</style>