<template>
    <hosting-settings-template :resource="resource" class="h-p-hosting-php">
        <card-title title="Versão do PHP" inner-body>
            <template slot="intro-text">
                Escolha a versão do PHP que melhor se adequa ao seu site ou aplicação. As versões mais recentes do PHP
                possuem recursos mais modernos e melhor desempenho, além de fornecer maior segurança.
            </template>

            <div v-show="isLoadingPhp">
                <div class="text-muted">
                    <i class="fas fa-circle-notch fa-spin mr-1"/> Carregando&hellip;
                </div>
            </div>

            <div class="text-center" v-if="php && php.versions">
                <b-form-radio-group class="h-btn-group-php" buttons button-variant="outline-primary" v-model="php.version" @change="changePhpVersion">
                    <b-form-radio v-for="item in php.versions" v-bind:key="item.value" :value="item.value" :disabled="isChangingPhp && item.value !== php.version">
                        {{ item.text }}
                    </b-form-radio>
                </b-form-radio-group>

                <div class="mt-4 text-muted" v-show="isChangingPhp">
                    <i class="fas fa-circle-notch fa-spin mr-1"/> Atualizando&hellip;
                </div>
            </div>
        </card-title>

        <card-title title="Configurações adicionais" inner-body>
            <text-loading v-show="isLoadingPhp" />

            <table v-if="php" class="table table-borderless h-table-php-settings">
                <tbody>
                    <template v-for="(item, index) in directives">
                        <tr v-if="typeof php[item.name] !== 'undefined'" :key="item.name">
                            <td class="pt-0 px-0 pr-8" v-bind:class="[index === directives.length-1 ? 'pb-0' : 'pb-6']">
                                <p class="font-weight-semibold mb-0 h-php-setting-title">{{ item.name }}</p>
                                <span class="text-muted h-php-setting-description" v-html="item.description"></span>
                                <div v-if="item.type === 'number' && php[item.name] && php[item.name] > item.max" class="form-text-sm font-weight-semibold text-danger mt-1 mb-0">
                                    <i class="fas fa-info-circle fa-fw" aria-hidden="true"></i>
                                    O valor configurado é maior do que o máximo permitido.
                                </div>
                            </td>
                            <td v-if="item.type === 'number'" class="col-nowrap col-w-1 pt-0 pb-6 px-0">
                                <span class="w-20 d-inline-block align-middle text-right mr-4">
                                    {{ php[item.name] + item.unit }}
                                </span>
                                <vue-slider
                                        v-if="php[item.name] <= item.max"
                                        class="d-inline-block align-middle pr-0"
                                        v-model="php[item.name]"
                                        tooltip="none"
                                        :min="item.min"
                                        :max="item.max"
                                        :interval="item.interval"
                                        :width="200"
                                        :height="8"
                                        :dotSize="18"
                                        :useKeyboard="true" />
                            </td>
                            <td v-else-if="item.type === 'boolean'" class="col-nowrap col-w-1 pt-0 pb-6 px-0 text-right">
                                <div class="mt-1">
                                    <toggle-switch v-model="php[item.name]" />
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <div v-show="!isLoadingPhp && php" class="form-buttons">
                <div class="row">
                    <div class="col">
                        <button-form ref="btnRestoreSettings" theme="secondary" class="mr-2" @click="$refs.modalRestoreSettings.show()">Restaurar padrões</button-form>
                    </div>
                    <div class="col text-right">
                        <button-form ref="btnUpdateSettings" theme="success" class="btn-action" @click="updateSettings">Salvar</button-form>
                    </div>
                </div>
            </div>
        </card-title>

        <b-modal ref="modalPhpSettings">

        </b-modal>

        <modal-confirm ref="modalRestoreSettings" title="Restaurar configurações padrão" @confirm="restoreSettings">
            Tem certeza que deseja restaurar as configurações do PHP para os valores padrão?
        </modal-confirm>
    </hosting-settings-template>
</template>

<script>
    import { mapActions }          from 'vuex'
    import VueSlider               from 'vue-slider-component'
    import AjaxService             from '@/services/AjaxService';
    import ToggleSwitch            from '@/components/atoms/ToggleSwitch'
    import ButtonForm              from '@/components/atoms/ButtonForm'
    import CardTitle               from '@/components/molecules/CardTitle'
    import HostingSettingsTemplate from '@/components/templates/HostingSettingsTemplate'
    import TextLoading from '@/components/atoms/TextLoading'
    import ModalConfirm from '@/components/atoms/ModalConfirm'

    export default {
        props: ['resource'],
        components: {
            ModalConfirm,
            TextLoading,
            ButtonForm,
            ToggleSwitch,
            CardTitle,
            HostingSettingsTemplate,
            VueSlider
        },
        data() {
            return {
                directives: [
                    {
                        name: 'post_max_size',
                        type: 'number', min: 2, max: 1024, interval: 2, unit: ' MB',
                        description: 'Tamanho máximo de dados enviados via POST (inclusive arquivos).'
                    },
                    {
                        name: 'upload_max_filesize',
                        type: 'number', min: 2, max: 1024, interval: 2, unit: ' MB',
                        description: 'Tamanho máximo de um arquivo enviado por upload.'
                    },
                    {
                        name: 'memory_limit',
                        type: 'number', min: 16, max: 1024, interval: 16, unit: ' MB',
                        description: 'Quantidade máxima de memória que um script pode alocar.'
                    },
                    {
                        name: 'max_execution_time',
                        type: 'number', min: 30, max: 600, interval: 10, unit: ' seg',
                        description: 'Tempo máximo de execução de um script.'
                    },
                    {
                        name: 'max_input_time',
                        type: 'number', min: 30, max: 600, interval: 10, unit: ' seg',
                        description: 'Tempo máximo permitido para interpretar dados de entrada em um script.'
                    },
                    {
                        name: 'max_input_vars',
                        type: 'number', min: 1000, max: 10000, interval: 1000, unit: '',
                        description: 'Quantidade máxima de variáveis de entrada aceitas.'
                    },
                    {
                        name: 'display_errors',
                        type: 'boolean',
                        description: 'Exibir as mensagens de erro do PHP direto na página.'
                    },
                    {
                        name: 'output_compression',
                        type: 'boolean',
                        description: 'Permitir a compressão de páginas do website de modo transparente.'
                    },
                ],
                php: null,
                currentPhpVersion: null,
                isLoadingPhp: false,
                isChangingPhp: false,
                checkPhpJobTimeout: null,
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.checkPhpJobTimeout && clearTimeout(this.checkPhpJobTimeout);
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.fetchPhp();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert: 'alert/error',
            }),

            /**
             * Obter informações do PHP na hospedagem.
             */
            fetchPhp()
            {
                this.php = null;
                this.currentPhpVersion = null;
                this.isLoadingPhp = true;

                this.$api.get(`/hosting/${this.resource}/php?directives=1`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.php = response.data.data;
                        this.currentPhpVersion = this.php.version;
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingPhp = false);
            },

            /**
             * Alterar versão do PHP.
             */
            changePhpVersion(value)
            {
                this.isChangingPhp = true;

                this.$api.post(`/hosting/${this.resource}/php`, { 'version': value })
                    .then (response => {
                        const job = response.data.data.job;

                        const onSuccess = () => {
                            this.successNotification({ message: 'Versão do PHP atualizada!' });
                            this.$eventBus.$emit('php-changed');
                            this.isChangingPhp = false;
                        };

                        const onError = (error) => {
                            this.showErrorAlert(error);
                            this.isChangingPhp = false;
                        };

                        this.checkPhpJobTimeout = setTimeout(() => this.checkPhpJob(job, onSuccess, onError), 3000);
                    })
                    .catch(error => this.showErrorAlert(error));
            },

            /**
             * Atualizar configurações do PHP.
             */
            updateSettings()
            {
                this.$refs.btnUpdateSettings.loadingFocus();
                this.$refs.btnRestoreSettings.setDisabled(true);

                let postData = Object.assign({}, this.php);

                postData.version = null;

                this.$api.post(`/hosting/${this.resource}/php`, postData)
                    .then (response => {
                        const job = response.data.data.job;

                        const onSuccess = () => {
                            this.successNotification({ message: 'Configurações atualizadas!' });
                            this.$refs.btnUpdateSettings.setLoading(false);
                            this.$refs.btnRestoreSettings.setDisabled(false);
                        };

                        const onError = (error) => {
                            this.showErrorAlert(error);
                            this.$refs.btnUpdateSettings.setLoading(false);
                            this.$refs.btnRestoreSettings.setDisabled(false);
                        };

                        this.checkPhpJobTimeout = setTimeout(() => this.checkPhpJob(job, onSuccess, onError), 3000);
                    })
                    .catch(error => this.showErrorAlert(error));
            },

            checkPhpJob(id, successCallback, errorCallback)
            {
                this.$api.get(`/job/${id}`)
                    .then(response => {
                        const status = response.data.data.status;

                        if (status === 'queued' || status === 'executing') {
                            this.checkPhpJobTimeout = setTimeout(() => this.checkPhpJob(id, successCallback, errorCallback), 5000);
                        }
                        else {
                            successCallback();
                        }
                    })
                    .catch(error => errorCallback(error))
            },

            /**
             * Restaurar valores padrão das configurações do PHP.
             */
            restoreSettings()
            {
                this.$refs.btnRestoreSettings.loadingFocus();
                this.$refs.btnUpdateSettings.setDisabled(true);

                this.$api.post(`/hosting/${this.resource}/php/restore`)
                    .then (response => this.fetchPhp())
                    .catch(error => this.showErrorAlert(error))
                    .then (() => {
                        this.$refs.btnRestoreSettings.setLoading(false);
                        this.$refs.btnUpdateSettings.setDisabled(false);
                    });
            }
        }
    }
</script>

<style lang="stylus">
    .h-p-hosting-php
        .h-btn-group-php
            outline 0
            border-radius 5px
            box-shadow inset 0 0 0 1px $primary

            .btn
                border-color transparent !important
                border-radius 5px !important
                padding 0.5rem 1.5rem
                font-size 1.25rem

        .h-table-php-settings:hover
            tr .h-php-setting-title
                color $gray-500

            tr:hover
                .h-php-setting-title
                    color $primary

                .h-php-setting-description
                    color $gray-900 !important
</style>
