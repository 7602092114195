<template>
    <admin-page-template quick-access="none">
        <div class="pt-md-6">
            <h3 class="text-primary font-weight-bold font-family-title">
                <span v-show="user">Olá, {{ user.name }}!</span>
                Bem-vindo à Hostoo.
            </h3>
            <p class="mb-6">
                Para começar, escolha uma das opções abaixo.
            </p>
        </div>

        <div class="pb-md-4"></div>

        <div class="row">
            <div class="col-md-4">
                <card-link
                    text="Solicitar migração"
                    link="/hosting/migration"
                    icon-class="fas fa-exchange-alt"
                />
            </div>
            <div class="col-md-8">
                <card-link
                    text="Instalar WordPress"
                    link="/hosting/add?wordpress=1"
                    icon-class="fab fa-wordpress-simple"
                    :primary="true"
                />
            </div>
            <div class="col-md-4">
                <card-link
                    class="mb-md-0"
                    text="Criar uma hospedagem"
                    link="/hosting/add"
                    icon-class="fas fa-server"
                />
            </div>
            <div class="col-md-4">
                <card-link
                    class="mb-md-0"
                    text="Registrar um domínio"
                    link="/domain/add"
                >
                    <template slot="icon">
                        <svg width="37px" height="32px" viewBox="0 0 37 32" focusable="false">
                            <g transform="translate(1.663 -33.182)">
                                <g transform="translate(-1.663 33.182)">
                                    <path d="M34.122,34.62H2.878A2.878,2.878,0,0,0,0,37.5v3.271a.411.411,0,0,0,.411.411H36.589A.411.411,0,0,0,37,40.769V37.5A2.878,2.878,0,0,0,34.122,34.62ZM5.189,38.828a.928.928,0,1,1,.928-.928A.928.928,0,0,1,5.189,38.828Zm2.974,0a.928.928,0,1,1,.928-.928A.928.928,0,0,1,8.163,38.828Zm2.974,0a.928.928,0,1,1,.928-.928A.928.928,0,0,1,11.137,38.828Z" transform="translate(0 -34.62)" />
                                    <path d="M36.589,148.15H.411a.411.411,0,0,0-.411.411v20.505a2.878,2.878,0,0,0,2.878,2.878H34.122A2.878,2.878,0,0,0,37,169.067V148.561A.411.411,0,0,0,36.589,148.15Zm-23.711,9.936-1.435,4.429a.822.822,0,0,1-1.476.188l-1.1-1.733-1.1,1.733a.823.823,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767L8.171,159a.823.823,0,0,1,1.388,0l.859,1.35.9-2.767A.822.822,0,0,1,12.878,158.086Zm9.635,0-1.435,4.429a.822.822,0,0,1-1.476.188l-1.1-1.733L17.4,162.7a.823.823,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767.859-1.35a.823.823,0,0,1,1.387,0l.859,1.35.9-2.767A.822.822,0,0,1,22.513,158.086Zm9.635,0-1.435,4.429a.823.823,0,0,1-1.476.188l-1.1-1.733-1.1,1.733a.822.822,0,0,1-1.476-.188l-1.435-4.429a.822.822,0,0,1,1.564-.507l.9,2.767.859-1.35a.823.823,0,0,1,1.387,0l.859,1.35.9-2.767A.822.822,0,0,1,32.148,158.086Z" transform="translate(0 -139.945)" />
                                </g>
                            </g>
                        </svg>
                    </template>
                </card-link>
            </div>
            <div class="col-md-4">
                <card-link
                    class="mb-md-0"
                    text="Criar um e-mail"
                    link="/hosting/add?email=1"
                    icon-class="fas fa-envelope-open-text"
                />
            </div>
        </div>
    </admin-page-template>
</template>

<script>
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'
    import CardTitle from '@/components/molecules/CardTitle'
    import CardLink from '@/components/molecules/CardLink'
    import {mapActions, mapState} from 'vuex'

    export default {
        components: {
            CardLink,
            CardTitle,
            AdminPageTemplate
        },
        computed: {
            ...mapState('user', ['user', 'isLoadingUser']),
        },
        created()
        {
            this.fetchUser();
        },
        methods: {
            ...mapActions({
                fetchUser: 'user/fetchUser',
            })
        }
    }
</script>
