<template>
    <div class="htm-progress-resource">
        <h6 v-show="title">{{ title }}</h6>
        <div class="d-flex align-items-center">
            <div class="progress">
                <div
                    class="progress-bar"
                    role="progressbar"
                    v-bind:style="getProgressBarStyle"
                    :aria-valuenow="progress"
                    :aria-valuemin="min"
                    :aria-valuemax="max"></div>
            </div>
            <span class="ht-progress-value" v-show="!loading">{{ progress + "%" }}</span>
            <span class="ht-progress-loading" v-show="loading"><i class="fas fa-circle-notch fa-spin mr-1"/></span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: [Number,String],
                default: "0"
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            },
            title: {
                type: String,
                default: ""
            },
            color: {
                type: String,
                default: ""
            },
            loading: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                progress: 0,
            }
        },
        computed: {
            getProgressBarStyle: function () {
                let styles = {
                    width: Math.round(this.progress / this.max * 100) + "%",
                };

                if (this.color) {
                    styles["backgroundColor"] = this.color;
                }

                return styles;
            }
        },
        mounted() {
            this.progress = Math.floor(parseFloat(this.value));
        },
        watch: {
            value: function (val) {
                this.progress = Math.floor(parseFloat(val));
            }
        }
    }
</script>

<style lang="stylus">
    .htm-progress-resource
        margin-bottom 1rem

        h6
            padding-left .125rem
            margin-bottom .125rem
            font-size .875rem
            font-weight 700

        .progress
            flex-grow 1
            border-radius .5rem

        .ht-progress-value, .ht-progress-loading
            display block
            width 2.5rem
            text-align right
            line-height 1
            color $gray-500

        .ht-progress-value
            font-size 12px
            font-weight 700

        .ht-progress-loading
            line-height 1rem
            height 1rem
</style>