    <template>
    <hosting-files-template :resource="resource" class="h-p-hosting-ftp">
        <card-title class="h-card-ftp-info" title="Acesso FTP" inner-body>
            <p class="mb-6">
                O protocolo FTP permite que você gerencie os arquivos de sua hospedagem através de um software como o
                <a href="https://filezilla-project.org/download.php" target="_blank"><strong>FileZilla</strong></a>
                <a href="https://filezilla-project.org/download.php" target="_blank"><i class="fas fa-external-link-alt ml-1"></i></a>.
            </p>

            <text-loading v-show="isLoadingFtp" />

            <div class="table-wrapper">
                <table v-if="ftpInfo" v-show="!isLoadingFtp" class="table table-list table-sm mb-0">
                    <tbody>
                        <tr>
                            <th class="col-w-px col-nowrap">Host:</th>
                            <td class="ht-text-copy-wrapper">
                                <text-copy :text="ftpInfo.host"/>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Porta:</th>
                            <td class="ht-text-copy-wrapper">
                                <text-copy :text="ftpInfo.port"/>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Usuário:</th>
                            <td class="ht-text-copy-wrapper">
                                <text-copy :text="ftpInfo.username"/>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Senha:</th>
                            <td class="ht-text-copy-wrapper">
                                <text-copy :text="'&bull;'.repeat(8)" :copy="ftpInfo.password" />
                            </td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Host alternativo:</th>
                            <td class="ht-text-copy-wrapper">
                                <text-copy :text="hosting.server"/>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">IP do servidor:</th>
                            <td class="ht-text-copy-wrapper">
                                <text-copy :text="hosting.serverIp"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="btn-toolbar mt-6" role="toolbar">
                <div class="btn-group ml-auto" role="group">
                    <button type="button" class="btn btn-secondary" @click="showChangePasswordModal">Alterar senha</button>
                </div>
            </div>

            <!-- TODO: Exibir informação sobre acesso SSH -->
            <!--
            <div class="alert alert-primary">
                Para configurar o <strong>Acesso SSH</strong> à sua hospedagem,
                <router-link :to="`/hosting/${resource}/ssh`">clique aqui</router-link>.
            </div>
            -->
        </card-title>

        <card-table
            ref="cardFtpUsers"
            inner-title="Usuários FTP Adicionais"
            add-text="Adicionar usuário"
            modal-title="Adicionar usuário"
            edit-text="Alterar senha"
            edit-icon-html="<i class='fas fa-key fa-fw'></i>"
            modal-title-edit="Alterar senha"
            remove-title="Excluir usuário"
            table-empty-text="Nenhum usuário adicional encontrado"
            :table-fields="tableFtpUsersFields"
            :table-data="ftpUsers"
            :table-loading="isLoadingFtpUsers"
            :api-save-url="getApiSaveUrl"
            api-edit-method="POST"
            @created="fetchFtpUsers"
            @remove="removeFtpUser"
        >
            <template slot="intro-text">
                Adicione outros usuários com acesso a apenas um diretório específico em sua hospedagem. Os usuários
                adicionais possuem um nome no formato <strong>usuario@meudominio.com.br</strong>.
            </template>

            <template slot="table-username" slot-scope="{ rowData }">
                <text-copy :text="rowData.username" />
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group v-if="formData.isEditing" label="Usuário" label-for="ftpUser">
                    <input type="text" id="ftpUser" class="form-control" autocomplete="username" readonly :value="formData.username">
                </form-group>

                <form-group v-else label="Usuário *" label-for="ftpUsername" :error="formErrors.username">
                    <div class="input-group">
                        <input
                            type="text"
                            id="ftpUsername"
                            class="form-control"
                            spellcheck="false"
                            maxlength="20"
                            autocomplete="username"
                            v-model="formData.username"
                            v-input-lowercase>
                        <div class="input-group-append">
                            <span class="input-group-text">{{ '@' + resource }}</span>
                        </div>
                    </div>
                </form-group>

                <form-group label="Senha *" label-for="ftpPassword" :error="formErrors.password">
                    <password-generator id="ftpPassword" autocomplete="new-password" v-model="formData.password" />
                </form-group>

                <form-group v-if="!formData.isEditing" label="Diretório" label-for="ftpHomedir" :error="formErrors.homedir">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text px-4">/</span>
                        </div>
                        <input type="text" id="ftpHomedir" class="form-control" spellcheck="false" v-model="formData.homedir">
                    </div>
                </form-group>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir o usuário abaixo?</p>
                <p class="mb-0"><strong>{{ item.username }}</strong></p>
            </template>
        </card-table>

        <b-modal ref="modalChangePassword" title="Alterar senha de FTP" centered hide-footer hide-header-close>
            <form @submit.prevent="changePassword">
                <form-group v-if="ftpInfo" class="d-none">
                    <input type="text" class="form-control" autocomplete="username" :value="ftpInfo.username">
                </form-group>

                <form-group label="Nova senha" label-for="ftpNewPassword" :error="formErrors.password">
                    <password-generator id="ftpNewPassword" autocomplete="new-password" v-model="newPassword.password" @generated="onPasswordGenerate" />
                </form-group>

                <form-group label="Confirme a nova senha" label-for="ftpPasswordConfirm" :error="formErrors.passwordConfirm">
                    <input type="password" id="ftpPasswordConfirm" class="form-control" autocomplete="new-password" v-model="newPassword.passwordConfirm">
                </form-group>

                <div class="form-buttons text-right">
                    <button ref="btnChangePasswordCancel" type="button" class="btn btn-secondary px-6 mr-2" @click="$refs.modalChangePassword.hide()">Cancelar</button>
                    <button-form ref="btnChangePasswordSubmit" type="submit" theme="success" class="px-6">Alterar</button-form>
                </div>
            </form>
        </b-modal>
    </hosting-files-template>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import AjaxService              from '@/services/AjaxService'
    import ErrorService             from '@/services/error.service'
    import ButtonForm               from '@/components/atoms/ButtonForm'
    import TextLoading              from '@/components/atoms/TextLoading'
    import CardTitle                from '@/components/molecules/CardTitle'
    import TextCopy                 from '@/components/molecules/TextCopy'
    import FormGroup                from '@/components/molecules/FormGroup'
    import PasswordGenerator        from '@/components/molecules/PasswordGenerator'
    import CardTable                from '@/components/organisms/CardTable'
    import HostingFilesTemplate     from '@/components/templates/HostingFilesTemplate'

    export default {
        props: ['resource'],
        components: {
            TextLoading,
            ButtonForm,
            PasswordGenerator,
            FormGroup,
            CardTable,
            TextCopy,
            CardTitle,
            HostingFilesTemplate
        },
        data() {
            return {
                tableFtpUsersFields: [
                    {
                        name: '__slot:username',
                        sortField: 'username',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Usuário',
                        titleClass: 'col-w-64',
                        dataClass: 'col-w-64 col-nowrap',
                    },
                    {
                        name: 'homedir',
                        sortField: 'homedir',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Diretório',
                        dataClass: 'text-monospace',
                    }
                ],

                ftpInfo: null,
                ftpUsers: [],

                newPassword: {},
                formErrors: {},

                isLoadingFtp: false,
                isLoadingFtpUsers: false
            }
        },
        computed: {
            ...mapState('hosting', ['hosting'])
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.fetchFtp();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert: 'alert/error'
            }),

            /**
             * Carregar informações e usuários de FTP.
             */
            fetchFtp(onlyUsers)
            {
                onlyUsers = (typeof onlyUsers !== "undefined" && onlyUsers);

                this.isLoadingFtp = !onlyUsers;
                this.isLoadingFtpUsers = true;

                return this.$api.get(`/hosting/${this.resource}/ftp`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        const data = response.data.data;

                        if (!onlyUsers) this.ftpInfo = data.main;
                        this.ftpUsers = data.sub;
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingFtp = this.isLoadingFtpUsers = false);
            },

            /**
             * Carregar usuários FTP adicionais.
             */
            fetchFtpUsers()
            {
                this.fetchFtp(true);
            },

            /**
             * Exibir modal para alteração de senha .
             */
            showChangePasswordModal()
            {
                this.newPassword = { username: "" };
                this.$refs.modalChangePassword.show();
            },

            /**
             * Alterar senha de FTP da conta principal.
             */
            changePassword()
            {
                this.formErrors = {};

                this.$refs.btnChangePasswordSubmit.loadingFocus();
                this.$refs.btnChangePasswordCancel.disabled = true;

                this.$api.post(`/hosting/${this.resource}/ftp/password`, this.newPassword)
                    .then(response => {
                        this.$refs.modalChangePassword.hide();
                        this.successNotification({ message: 'Senha atualizada!' });
                        this.fetchFtp();
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnChangePasswordCancel.disabled = false;
                        this.$refs.btnChangePasswordSubmit.setLoading(false);
                    });
            },

            /**
             * URLs de cadastro e edição de usuário FTP.
             */
            getApiSaveUrl(data, isEditing)
            {
                return !isEditing ?
                    `/hosting/${this.resource}/ftp/add-user` :
                    `/hosting/${this.resource}/ftp/password`;
            },

            /**
             * Remover usuário FTP.
             */
            removeFtpUser(item)
            {
                const data = { username: item.username };

                this.$api.post(`/hosting/${this.resource}/ftp/remove-user`, data)
                    .then (response => {})
                    .catch(error => {
                        error.message = 'Ocorreu um erro ao tentar remover o usuário de FTP. Por favor, atualize sua página e tente novamente.';
                        this.showErrorAlert(error);
                    });
            },

            onPasswordGenerate(value)
            {
                this.newPassword.passwordConfirm = value;
            }
        }
    }
</script>

<style lang="stylus">
    .h-p-hosting-ftp
        .h-card-ftp-info
            .table-wrapper
                margin-left -2.5rem
                margin-right -2.5rem

            .table-list
                border 0

                tbody tr
                    > th:first-child
                        padding-left 2.5rem

                    > td:last-child
                        padding-right 2.5rem
</style>