<template>
    <card-title title="Alterar Plano">
        <p class="mb-6">
            Na Hostoo é possível alterar o plano de sua hospedagem <strong>a qualquer momento</strong> de forma muito
            prática. Ao escolher um novo plano a sua hospedagem passará a ser cobrada automaticamente de hora em hora com
            o novo valor.
        </p>

        <div v-show="isLoading">
            <div class="text-muted">
                <i class="fas fa-circle-notch fa-spin mr-1"/> Carregando&hellip;
            </div>
        </div>

        <div v-show="!isLoading">
            <hosting-plan-list ref="hostingPlanList" hourly v-model="newPackage" :region="hosting.region" :disabled="isLoadingChangePackage" @ready="isLoading = false" />

            <div class="form-buttons">
                <button-form
                    ref="btnChangePackage"
                    theme="success"
                    class="btn-block py-2"
                    :disabled="!newPackage || !newPackage.id || newPackage.id == hosting.plan"
                    @click.native="changePackage"
                >
                    Alterar
                    <span v-if="newPackage && newPackage.id && newPackage.id != hosting.plan">
                        para R$ {{ (newPackage.price[hosting.region] / 720).toFixed(4).replace('.', ',') }}/hora
                    </span>
                </button-form>
            </div>
        </div>
    </card-title>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ButtonForm               from '@/components/atoms/ButtonForm'
    import CardTitle                from '@/components/molecules/CardTitle'
    import HostingPlanList          from '@/components/organisms/HostingPlanList'

    export default {
        props: ['resource'],
        components: {
            ButtonForm,
            CardTitle,
            HostingPlanList
        },
        data() {
            return {
                newPackage: null,
                isLoading: false,
                isLoadingChangePackage: false
            }
        },
        computed: {
            ...mapState('hosting', ['hosting'])
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.hostingPlanList.cancelFetch();
            next();
        },
        mounted()
        {
            this.isLoading  = true;
            this.newPackage = this.hosting.plan;
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert: 'alert/error'
            }),

            /**
             * Alterar o plano da hospedagem.
             */
            changePackage()
            {
                this.isLoadingChangePackage = true;
                this.$refs.btnChangePackage.loadingFocus();

                this.$api.post(`/hosting/${this.resource}/plan`, { 'id': this.newPackage.id })
                    .then (response => {
                        this.successNotification({ message: 'Plano alterado!' });
                        setTimeout(() => window.location.reload(), 3000);
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.btnChangePackage.setLoading(false);
                        this.isLoadingChangePackage = false;
                    });
            }
        }
    }
</script>
