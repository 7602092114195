<template>
    <div class="h-m-logo-upload">
        <span v-show="logo.imagePreview" class="img-thumbnail mb-6">
            <img v-bind:src="logo.imagePreview" class="img-fluid rounded-0" alt="Preview" />
        </span>

        <form-group class="mb-0" :error="formErrors.logo">
            <div class="custom-file w-auto d-inline-block align-top">
                <input type="file" class="custom-file-input" id="logoFile" ref="logoFile" accept="image/png,image/jpeg" hidden @change="onChangeLogo">
                <label class="btn btn-secondary btn-rounded" for="logoFile" v-bind:class="{'disabled': isLoading}">Escolher arquivo</label>
            </div>
            <button v-if="logo.image || logo.hasChanged" type="button" class="btn btn-danger d-inline-block align-top ml-1" v-bind:class="{'btn-loading': isLoading}" :disabled="isLoading" @click="onRemoveLogo">
                Remover
            </button>
            <button v-if="logo.hasChanged" type="button" class="btn btn-success d-inline-block align-top px-6 ml-1" v-bind:class="{'btn-loading': isLoading}" :disabled="isLoading" @click="onUpdateLogo">
                Salvar
            </button>
        </form-group>
    </div>
</template>

<script>
import FormGroup from '@/components/molecules/FormGroup'

export default {
    name: "LogoUpload",
    components: {
        FormGroup
    },
    props: {
        image: {
            type: String,
        },
        height: {
            type: Number,
            default: 80,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            logo: {
                image: null,
                file: null,
                hasChanged: false,
                imagePreview: "",
                isImageLoaded: false,
            },
            formErrors: {},
            $_isLoading: false,
        }
    },
    computed: {
        logoHeight: function () {
            return this.height > 0 ? this.height : 80;
        },
        isLoading: function () {
            return this.loading || this.$_isLoading;
        }
    },
    mounted()
    {
        if (this.image) {
            this.logo.image         = this.image;
            this.logo.imagePreview  = this.logo.image;
            this.logo.isImageLoaded = true;
        }
    },
    methods: {
        /**
         * Evento de alterar imagem do logotipo.
         */
        onChangeLogo(e)
        {
            let vm = this;

            this.formErrors = {};

            const files = e.target.files || e.dataTransfer.files;
            const photo = files.length ? files[0] : null;

            let reader = new FileReader();

            reader.onload = (event) => {
                const image = new Image();

                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width   = image.width,
                        height  = image.height,
                        start_x = 0,
                        start_y = 0,
                        max_height = this.logoHeight;

                    if (height > max_height) {
                        width   = (width/height) * max_height;
                        height  = max_height;
                    }

                    canvas.width  = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, -start_x, -start_y, width, height);

                    vm.logo.imagePreview = canvas.toDataURL('image/png');
                };

                image.src = event.target.result;
            };

            if (photo) {
                if (/\.(jpe?g|png)$/i.test(photo.name))
                {
                    this.logo.file = photo;
                    this.logo.hasChanged = true;

                    reader.readAsDataURL( photo );
                }
                else {
                    this.formErrors.logo = "Escolha uma imagem no formato jpg ou png.";
                }
            }
        },

        /**
         * Remover logotipo atual ou imagem escolhida.
         */
        onRemoveLogo()
        {
            if (this.logo.image) {
                this.$emit('remove');
            }
            else {
                this.reset();
            }
        },

        /**
         * Reset component image.
         */
        reset(newImage)
        {
            this.logo.file            = null;
            this.logo.image           = (typeof newImage !== 'undefined' ? newImage : null);
            this.logo.imagePreview    = this.logo.image || "";
            this.logo.hasChanged      = (this.logo.image !== null);
            this.$refs.logoFile.value = "";
        },

        /**
         * Atualizar logotipo.
         */
        onUpdateLogo()
        {
            this.$emit('change', this.logo.file);
        }
    },
    watch: {
        image: function (val, oldVal) {
            if (val !== oldVal) {
                this.logo.image         = val;
                this.logo.imagePreview  = this.logo.image;
                this.logo.isImageLoaded = true;
            }
        }
    }
}
</script>

<style lang="stylus">
    .h-m-logo-upload {
        .img-thumbnail {
            display inline-block
            padding 0
            border-radius 0
            background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill-opacity='.125'%3E%3Crect x='10' width='10' height='10' /%3E%3Crect y='10' width='10' height='10' /%3E%3C/svg%3E")
            background-repeat repeat
        }
    }
</style>
