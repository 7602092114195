<template>
    <div class="h-m-nav-help">
        <div class="btn-group-vertical" role="group">
            <div class="btn-group" role="group" title="Chat" v-b-tooltip.hover.left>
                <button ref="btnChat" type="button" class="btn btn-white btn-chat" disabled @click="openChat">
                    <i class="fas fa-comments fa-2x fa-fw d-block"></i>
                </button>
            </div>
            <div class="btn-group" role="group" title="Tutorial" v-b-tooltip.hover.left>
                <button ref="btnVideo" type="button" class="btn btn-white btn-chat" @click="showTutorial">
                    <i class="fas fa-question-circle fa-2x fa-fw d-block"></i>
                </button>
            </div>
            <a href="https://help.hostoo.io" target="_blank" class="btn btn-white btn-help" title="Ajuda" v-b-tooltip.hover.left>
                <i class="fas fa-life-ring fa-2x fa-fw d-block"></i>
            </a>
        </div>
    </div>
</template>

<script>
    import tutorialVideo from '@/mixins/tutorialVideo'

    export default {
        mixins: [
            tutorialVideo
        ],
        data() {
            return {
                isChatConfigured: false,
                jivochatTriggerSegmentId: 0
            }
        },
        mounted()
        {
            document.addEventListener('jivoLoaded',           (e) => this.initChat());
            document.addEventListener('jivoClosed',           (e) => this.closeChat());
            document.addEventListener('jivoFirstMessageSent', (e) => this.firstMessageSent())
            document.addEventListener('jivoMessageReceived',  (e) => this.messageReceived())

            setTimeout(() => this.initChat(), 500);

            this.getMessageJivochat();
        },
        methods: {
            /**
             *
             */
            initChat()
            {
                if (this.isChatConfigured || typeof window.jivo_config === 'undefined') return;

                this.isChatConfigured = true;

                if (jivo_config.chat_mode === 'online') {
                    this.$refs.btnChat.classList.add('text-success');
                }

                this.$refs.btnChat.disabled = false;
            },

            /**
             * Ação de abrir a janela de chat.
             */
            openChat()
            {
                if (typeof window.jivo_api !== 'undefined') {
                    jivo_api.open();
                    this.$refs.btnChat.disabled = true;
                }
            },

            /**
             * Ação de fechar a janela de chat.
             */
            closeChat()
            {
                this.$refs.btnChat.disabled = false;
                this.setJivochatAutoMessageInteraction();
            },

            /**
             * Show video tutorials.
             */
            showTutorial()
            {
                this.showTutorialVideo();
            },

            /**
             * Ação quando o cliente envia a primeira mensagem
             */
            firstMessageSent()
            {
                this.setJivochatAutoMessageInteraction();
            },

            /**
             * Ação quando o cliente recebe uma mensagem do operador
             */
            messageReceived()
            {
                this.setJivochatAutoMessageInteraction();
            },

            /**
             * Obter mensagens automáticas especificas do jivochat
             */
            getMessageJivochat()
            {
                if (typeof window.jivo_config === 'undefined') return;

                this.$api.get('/user/jivochat/message')
                    .then (response => {
                        const chatMode = jivo_api.chatMode();

                        if (chatMode === 'online') {
                            if (response.data.data) {
                                if (response.data.data.message) {
                                    jivo_api.showProactiveInvitation(response.data.data.message);
                                    this.jivochatTriggerSegmentId = response.data.data.segment_id;
                                }
                            }
                        }
                    })
                    .catch(error => {});   
            },

            /**
             * Sinaliza para o servidor quando o cliente interagir 
             * com a mensagem automática.
             */
            setJivochatAutoMessageInteraction()
            {
                if (this.jivochatTriggerSegmentId)
                {
                    this.$api.get('/user/jivochat/message/interaction/'+this.jivochatTriggerSegmentId);
                    this.jivochatTriggerSegmentId = 0;
                }
            }
        }
    }
</script>

<style lang="stylus">
    .h-m-nav-help
        position fixed
        right 0
        bottom 1.5rem
        z-index 10100

        .btn-group-vertical
            .btn
                padding .75rem .625rem
                border-top-right-radius 0
                border-bottom-right-radius 0
                box-shadow -3px 3px 6px rgba(black, 0.15)

            > .btn:first-child, .btn-group:first-child > .btn
                border-top-left-radius 12px

            > .btn:last-child, .btn-group:last-child > .btn
                border-bottom-left-radius 12px

            > .btn:nth-child(n+1), .btn-group:nth-child(n+1)
                margin-top 3px

        .btn-help
            color #FA6A6A
</style>