<template>
    <admin-page-template>
        <tabs-pages :tabs="tabs">
            <slot></slot>
        </tabs-pages>
    </admin-page-template>
</template>

<script>
    import TabsPages         from "../organisms/TabsPages"
    import AdminPageTemplate from "./AdminPageTemplate"

    export default {
        components: {
            TabsPages,
            AdminPageTemplate,
        },
        data: () => ({
            tabs: [
                {
                    title: 'Recarga',
                    link: '/payment/recharge'
                },
                {
                    title: 'Histórico',
                    link: '/payment/history'
                },
                {
                    title: 'Consumo',
                    link: '/payment/billing'
                }
            ]
        })
    }
</script>

<style lang="stylus">
    .h-t-admin-page.promotion {
        .h-m-nav-pages {
            .nav-item {
                .nav-link.active {
                    background-color: #0D0D0D;
                }
            }
        }
        .h-m-banner-promotion {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
</style>
