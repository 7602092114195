<template>
    <hosting-domain-template :resource="resource">
        <card-table
            ref="cardSubdomains"
            inner-title="Subdomínios"
            add-text="Adicionar subdomínio"
            modal-title="Adicionar subdomínio"
            modal-title-edit="Alterar diretório do subdomínio"
            edit-text="Alterar diretório"
            edit-icon-html="<i class='far fa-folder-open fa-fw'></i>"
            remove-title="Excluir subdomínio"
            table-empty-text="Nenhum subdomínio encontrado"
            :table-fields="tableFields"
            :api-url="`/domain/${resource}/subdomain`"
            :api-save-url="getApiSaveUrl"
            @remove="removeSubdomain"
        >
            <template slot="intro-text">
                Um subdomínio é uma extensão do seu domínio principal podendo funcionar como uma subseção do seu site.
                Use subdomínios para definir áreas importantes do seu site com URLs próprias sem precisar registrar um novo domínio.
                Por exemplo, você pode criar um subdomínio para seu blog que pode ser acessado por <strong>blog.meudominio.com</strong>.
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item v-if="isValidDomain(rowData.fullDomain)" :href="`http://${rowData.fullDomain}`" target="_blank">
                    <i class="fas fa-external-link-alt fa-fw"></i> Acessar
                </b-dropdown-item>
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group v-if="!formData.isEditing" label="Subdomínio" label-for="subdomainDomain" :error="formErrors.domain">
                    <div class="input-group">
                        <input
                            type="text"
                            id="subdomainDomain"
                            class="form-control"
                            maxlength="100"
                            spellcheck="false"
                            v-model="formData.domain"
                            v-input-lowercase
                            @blur="copySubdomainToDir(formData)">
                        <div class="input-group-append">
                            <span class="input-group-text">{{ '.' + resource }}</span>
                        </div>
                    </div>
                </form-group>

                <form-group v-else label="Subdomínio" label-for="subdomainFullDomain">
                    <input type="text" id="subdomainFullDomain" class="form-control" readonly :value="formData.fullDomain">
                </form-group>

                <form-group label="Diretório" label-for="subdomainDir" :error="formErrors.dir">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">/public_html/</span>
                        </div>
                        <input type="text" id="subdomainDir" class="form-control" spellcheck="false" v-model="formData.dir" v-input-lowercase>
                    </div>
                </form-group>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir o subdomínio abaixo?</p>
                <p class="mb-0"><strong v-html="item.fullDomain"></strong></p>
            </template>
        </card-table>
    </hosting-domain-template>
</template>

<script>
    import { mapActions }        from 'vuex'
    import FormGroup             from '@/components/molecules/FormGroup'
    import CardTable             from '@/components/organisms/CardTable'
    import HostingDomainTemplate from '@/components/templates/HostingDomainTemplate'

    export default {
        props: ['resource'],
        components: {
            FormGroup,
            CardTable,
            HostingDomainTemplate
        },
        data() {
            return {
                tableFields: [
                    {
                        name: 'fullDomain',
                        sortField: 'fullDomain',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Subdomínio',
                    },
                    {
                        name: 'fullDir',
                        sortField: 'fullDir',
                        sortFunction: 'text',
                        filterable: true,
                        title: 'Diretório',
                        dataClass: 'text-monospace',
                    }
                ]
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardSubdomains.cancelFetch();
            next();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Verifica se é um domínio válido.
             */
            isValidDomain(domain)
            {
                return /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/.test(domain);
            },

            /**
             * Copiar valor do subdomínio informado para o campo de diretório.
             */
            copySubdomainToDir(data)
            {
                if (typeof data.dir === 'undefined' || !data.dir) {
                    this.$set(data, 'dir', data.domain);
                }
            },

            /**
             * URLs de cadastro e edição.
             */
            getApiSaveUrl(data, isEditing)
            {
                return !isEditing ?
                    `/domain/${this.resource}/subdomain` :
                    `/domain/${this.resource}/subdomain/${data.domain}`;
            },

            /**
             * Excluir subdomínio.
             */
            removeSubdomain(item)
            {
                const domain = item.domain;

                this.$api.delete(`/domain/${this.resource}/subdomain/${domain}`)
                    .then (response => {})
                    .catch(error => this.showErrorAlert(`Ocorreu um erro ao tentar excluir o subdomínio. Por favor, atualize sua página e tente novamente.`));
            }
        }
    }
</script>