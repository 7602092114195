export default {
    methods: {
        /**
         * @return {string}
         */
        getOperatingSystem()
        {
            const userAgent = window.navigator.userAgent,
                  platform = window.navigator.platform,
                  macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                  iosPlatforms = ['iPhone', 'iPad', 'iPod'];

            let os = '';

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'MacOS';
            }
            else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            }
            else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            }
            else if (/Android/.test(userAgent)) {
                os = 'Android';
            }
            else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            return os;
        },

        /**
         * @return {boolean}
         */
        isMac()
        {
            return 'MacOS' === this.getOperatingSystem();
        },

        /**
         * @see https://stackoverflow.com/a/54398733
         *
         * @param {string} value
         * @return {string}
         */
        killXSS(value)
        {
            // Avoid null characters
            value = value.replaceAll(/\0/g, "");

            // Avoid anything between script tags
            value = value.replaceAll(/<script>(.*?)<\/script>/ig, "");

            // Avoid anything in a src='...' type of expression
            value = value.replaceAll(/src[\r\n]*=[\r\n]*\\'(.*?)\\'/igm, "");
            value = value.replaceAll(/src[\r\n]*=[\r\n]*\\"(.*?)\\"/igm, "");

            // Remove any lonesome </script> tag
            value = value.replaceAll(/<\/script>/ig, "");

            // Remove any lonesome <script ...> tag
            value = value.replaceAll(/<script(.*?)>/igm, "");

            // Avoid eval(...) expressions
            value = value.replaceAll(/eval\\((.*?)\\)/igm, "");

            // Avoid expression(...) expressions
            value = value.replaceAll(/expression\\((.*?)\\)/igm, "");

            // Avoid javascript:... expressions
            value = value.replaceAll(/javascript:/ig, "");

            // Avoid vbscript:... expressions
            value = value.replaceAll(/vbscript:/ig, "");

            // Avoid onload= expressions
            value = value.replaceAll(/onload(.*?)=/igm, "");

            // Avoid http:... expressions
            value = value.replaceAll(/https?:\/\//ig, "");

            // Sanitize
            value = value.replaceAll(/&/g, "&amp;")
                .replaceAll(/</g, "&lt;")
                .replaceAll(/>/g, "&gt;")
                .replaceAll(/"/g, "&quot;")
                .replaceAll(/'/g, "&#x27;");

            return value;
        }
    }
}