<template>
    <payment-template class="h-p-payment-billing">
        <card-table
            ref="cardBilling"
            inner-title="Consumo de Créditos"
            hide-add
            hide-dropdown
            :table-fields="tableBillingFields"
            :table-data="hostings"
            :table-loading="isLoadingBilling"
            table-empty-text="Nenhuma informação de consumo"
        >
            <template slot="toolbar-before">
                <div class="input-group ml-lg-auto mt-4 mt-sm-0">
                    <select class="custom-select" v-model="yearMonth" :disabled="isLoadingBilling">
                        <option :value="$moment().format('YYYY-MM')">Mês atual</option>
                        <option :value="$moment().subtract(1, 'month').format('YYYY-MM')">Mês anterior</option>
                        <option value="-1">Outro mês</option>
                    </select>
                </div>

                <div v-show="yearMonth === '-1'" class="input-group ml-2 mt-4 mt-sm-0">
                    <b-select v-model="otherMonth" :options="previousMonths" :disabled="isLoadingBilling" />
                </div>

                <div class="input-group ml-2 mt-4 mt-sm-0">
                    <button-form ref="btnFetchBilling" theme="success" @click="fetchBilling">Buscar</button-form>
                </div>
            </template>

            <template slot="table-amount" slot-scope="{ rowData }">
                {{ rowData.amount | formatMoney }}
            </template>

            <template slot="table-after">
                <div v-show="!isLoadingBilling" class="card h-card-billing-total">
                    <div class="card-body">
                        <div class="d-flex flex-column flex-sm-row justify-content-between">
                            <strong>Total consumido no mês</strong>
                            <span class="w-40 pl-sm-6 pr-sm-3">{{ totalBilling | formatMoney }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </card-table>
    </payment-template>
</template>

<script>
    import { mapActions }  from 'vuex'
    import AjaxService     from '@/services/AjaxService'
    import TextLoading     from '@/components/atoms/TextLoading'
    import ButtonForm      from '@/components/atoms/ButtonForm'
    import CardTable       from '@/components/organisms/CardTable'
    import PaymentTemplate from '@/components/templates/PaymentTemplate'

    export default {
        components: {
            ButtonForm,
            CardTable,
            TextLoading,
            PaymentTemplate
        },
        data() {
            return {
                tableBillingFields: [
                    {
                        name: 'domain',
                        sortField: 'domain',
                        filterable: true,
                        title: 'Domínio'
                    },
                    {
                        name: '__slot:amount',
                        sortField: 'amount',
                        sortFunction: 'number',
                        title: 'Consumo',
                        titleClass: 'col-w-40',
                        dataClass: 'col-w-40'
                    }
                ],

                yearMonth: '',
                otherMonth: '',
                previousMonths: [],

                hostings: [],
                totalBilling: 0,
                isLoadingBilling: false
            }
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            let month, i;

            this.$set(this, 'yearMonth', this.$moment().format('YYYY-MM'));
            this.$set(this, 'otherMonth', this.$moment().subtract(2, 'month').format('YYYY-MM'));

            for (i = 2; i <= 23; i++)
            {
                month = this.$moment().subtract(i, 'month');

                this.previousMonths.push({
                    value: month.format('YYYY-MM'),
                    text:  month.format('MMMM/YYYY')
                });
            }

            this.fetchBilling();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error'
            }),

            /**
             * Obter dados de consumo de créditos.
             */
            fetchBilling()
            {
                this.isLoadingBilling = true;
                this.$refs.btnFetchBilling.loadingFocus();

                const yearMonth = this.yearMonth === '-1' ? this.otherMonth : this.yearMonth;
                const dateParts = yearMonth.split('-');

                const params = {
                    year: parseInt(dateParts[0]),
                    month: parseInt(dateParts[1])
                };

                this.$api.get('/billing', { params: params, cancelToken: AjaxService.getCancelToken() })
                    .then(response => {
                        this.hostings = response.data.data.billing;
                        this.totalBilling = response.data.data.total;
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => {
                        this.isLoadingBilling = false;
                        this.$refs.btnFetchBilling && this.$refs.btnFetchBilling.setLoading(false);
                    });
            }
        }
    }
</script>

<style lang="stylus">
    .h-p-payment-billing
        .h-o-card-table table tbody tr:last-child td
            border-bottom 0

        .h-card-billing-total
            border 0
            background-color #f4f4f4

            .card-body
                padding .5rem .75rem


</style>