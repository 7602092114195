import { ApiCancel } from "../plugins/api"

/**
 * @deprecated Utilizar services/error.service.js
 */
export default
{
    handleFormError(errorResponse, vm, errorObjKey, errorMsgKey)
    {
        let isErrorHandled = false;

        if (ApiCancel.isCancel(errorResponse)) return;

        if (errorResponse && errorResponse.response)
        {
            if (errorResponse.response.status === 401 || errorResponse.response.status === 403) {
                isErrorHandled = true;
            }
            else if (vm)
            {
                const _errorObjKey = typeof errorObjKey !== "undefined" && errorObjKey ? errorObjKey : "formErrors";
                const _errorMsgKey = typeof errorMsgKey !== "undefined" && errorMsgKey ? errorMsgKey : "errorMsg";

                if (errorResponse.response.status === 422 || errorResponse.response.data.status === "fail") {
                    if (typeof vm[_errorObjKey] !== "undefined") {
                        vm[_errorObjKey] = errorResponse.response.data.data;
                        isErrorHandled = true;
                    }
                }
                else if (errorResponse.response.data.message) {
                    if (typeof vm[_errorMsgKey] !== "undefined") {
                        vm[_errorMsgKey] = errorResponse.response.data.message;
                    }
                    else {
                        alert(errorResponse.response.data.message);
                    }

                    isErrorHandled = true;
                }
            }
        }

        if (!isErrorHandled) {
            alert("Ocorreu um erro ao tentar processar sua solicitação.");
        }
    },

    showErrorAlert(error, timeout)
    {
        let errorMsg = "Ocorreu um erro ao tentar processar sua solicitação.";
        let _timeout = typeof timeout !== "undefined" ? timeout : 5000;

        if (ApiCancel.isCancel(error)) return;

        if (error && error.response) {
            if (error.response.status >= 400) {
                if (error.response.data.message) {
                    errorMsg = error.response.data.message;
                }

                alert(errorMsg);
                // GlobalAlert.add(errorMsg, "danger", _timeout);
            }
        }
    },
}