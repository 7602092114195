<template>
    <div>
        <card-table
            ref="cardDatabases"
            inner-title="Bancos de Dados"
            add-text="Adicionar banco de dados"
            modal-title="Adicionar banco de dados"
            modal-title-edit="Editar banco de dados"
            edit-text="Alterar senha"
            edit-icon-html="<i class='fas fa-key fa-fw'></i>"
            remove-title="Excluir banco de dados"
            table-empty-text="Nenhum banco de dados encontrado"
            :table-fields="tableDatabaseFields"
            :api-url="databasesApiUrl"
            :api-save-url="apiSaveUrl"
            :form-data-transform="formDataTransform"
            @remove="removeDatabase"
            @loaded="showFetchErrorMessage=false"
            v-on:fetch-error="onFetchError"
        >
            <template slot="toolbar-after">
                <alert-compact
                    v-if="showFetchErrorMessage"
                    variant="warning"
                    icon-class="fas fa-exclamation-triangle"
                    class="mb-0 mt-4"
                >
                    Não foi possível listar alguns dos seus bancos de dados devido a uma instabilidade no servidor.
                    Pedimos que aguarde alguns minutos e atualize sua página para tentar novamente.
                </alert-compact>
            </template>

            <template slot="table-dbms" slot-scope="{ rowData }">
                {{ _getDatabaseName(rowData.dbms) }}
            </template>

            <template slot="table-dbname" slot-scope="{ rowData }">
                <text-copy :text="rowData.dbname" />
            </template>

            <template slot="table-user" slot-scope="{ rowData }">
                <text-copy :text="rowData.user" />
            </template>

            <template slot="table-size" slot-scope="{ rowData }">
                <span v-if="rowData.size">{{ rowData.size | formatBytes(0) }}</span>
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item v-if="rowData.dbms === 'mysql'" v-show="linkPhpmyadmin" :href="linkPhpmyadmin" target="_blank">
                    <i class="fas fa-external-link-alt fa-fw"></i> Acessar
                </b-dropdown-item>
                <b-dropdown-item v-if="rowData.dbms === 'postgres'" v-show="linkPhppgadmin" :href="linkPhppgadmin" target="_blank">
                    <i class="fas fa-external-link-alt fa-fw"></i> Acessar
                </b-dropdown-item>
                <b-dropdown-item-button v-if="rowData.dbms === 'mysql'" @click="showUsageModal(rowData.dbname)">
                    <i class="fas fa-chart-bar fa-fw"></i> Conexões
                </b-dropdown-item-button>
                <!--<b-dropdown-item-button disabled title="Em breve" v-b-tooltip.left.hover>
                    <i class="fas fa-cloud-download-alt fa-fw"></i> Download
                </b-dropdown-item-button>-->
            </template>

            <template slot="form-html" slot-scope="{ formData, formErrors }">
                <form-group v-if="!formData.isEditing" label="Tipo de banco" :error="formErrors.dbms">
                    <div><b-form-radio-group buttons button-variant="outline-primary" v-model="formData.dbms" :options="dbTypes" /></div>
                </form-group>

                <div v-show="formData.isEditing" class="form-group">
                    <label class="form-label ml-2" for="dbType">Tipo de banco</label>
                    <input type="text" id="dbType" class="form-control" :value="_getDatabaseName(formData.dbms)" readonly>
                </div>

                <form-group label="Nome do banco" label-for="dbName" :error="formErrors.dbname">
                    <input ref="dbName" type="text" id="dbName" class="form-control" autocomplete="off" spellcheck="false" v-model="formData.dbname" :readonly="formData.isEditing" v-input-lowercase>
                </form-group>

                <form-group label="Usuário" label-for="dbUser" :error="formErrors.user">
                    <input type="text" id="dbUser" class="form-control" autocomplete="off" spellcheck="false" v-model="formData.user" :readonly="formData.isEditing" v-input-lowercase>
                </form-group>

                <form-group label="Senha" label-for="dbPassword" :error="formErrors.password">
                    <password-generator ref="dbPassword" id="dbPassword" autocomplete="new-password" v-model="formData.password" />
                </form-group>
            </template>

            <template slot="remove-html" slot-scope="{ item }">
                <p class="mb-2">Tem certeza que deseja excluir o banco de dados abaixo?</p>
                <p class="mb-0"><strong v-html="item.dbname"></strong></p>
            </template>
        </card-table>

        <card-title inner-body>
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-grow-1">
                    <h4 class="card-title-inner">Acesso Remoto (MySQL)</h4>
                </div>
                <div class="pl-3" v-show="isLoadingRemoteAccess">
                    <div class="mt-1">
                        <i class="fas fa-circle-notch fa-spin text-muted"></i>
                    </div>
                </div>
                <div class="pl-3">
                    <toggle-switch v-model="remote.active" :callback="updateRemoteAccess" :disabled="isLoadingRemoteAccess" />
                </div>
            </div>

            <p class="mb-0">
                Permite que os bancos de dados MySQL sejam acessados a partir de um host externo.
            </p>

            <b-collapse v-model="remote.active">
                <div class="mt-6">
                    <table class="table table-sm table-borderless mb-4">
                        <tbody>
                            <tr>
                                <th class="col-nowrap col-w-1 pl-0">IP do servidor:</th>
                                <td class="pl-4">
                                    <text-copy v-if="hosting" :text="hosting.serverIp" />
                                </td>
                            </tr>
                            <tr>
                                <th class="col-nowrap col-w-1 pl-0">Porta:</th>
                                <td class="pl-4">
                                    <text-copy text="3306" />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <b-form-radio-group v-model="remote.mode" stacked>
                        <b-form-radio value="ALL" :disabled="isLoadingRemoteAccess" @change="updateRemoteAccess">Permitir acesso a partir de qualquer IP</b-form-radio>
                        <b-form-radio value="IPS" :disabled="isLoadingRemoteAccess">Limitar acesso a apenas alguns IPs</b-form-radio>
                    </b-form-radio-group>

                    <div class="mt-4" v-show="remote.mode === 'IPS'">
                        <form-group label="Host" sr-only>
                            <textarea
                                class="form-control"
                                rows="2"
                                placeholder="Endereço IP ou múltiplos IPs separados por vírgula"
                                spellcheck="false"
                                v-model="remote.hosts"></textarea>
                        </form-group>

                        <div class="form-buttons text-right">
                            <button-form theme="success" class="btn-action" :disabled="isLoadingRemoteAccess" @click="updateRemoteAccess">Salvar</button-form>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </card-title>

        <card-title inner-body>
            <h4 class="card-title-inner">Versão do Banco de Dados</h4>

            <table class="table table-sm table-borderless mb-0">
                <tbody>
                    <tr class="copy-text-wrapper">
                        <th class="col-nowrap col-w-1 pl-0">MySQL:</th>
                        <td class="pl-4">
                            <i v-if="isLoadingInfo" class="fas fa-circle-notch fa-spin text-muted"></i>
                            <text-copy v-if="dbInfo.version" :text="dbInfo.version.mysql" />
                        </td>
                    </tr>
                    <tr class="copy-text-wrapper">
                        <th class="col-nowrap col-w-1 pl-0">PostgreSQL:</th>
                        <td class="pl-4">
                            <i v-if="isLoadingInfo" class="fas fa-circle-notch fa-spin text-muted"></i>
                            <text-copy v-if="dbInfo.version" :text="dbInfo.version.postgres" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </card-title>

        <b-modal ref="modalUsage" title="Histórico de Conexões Simultâneas" size="lg" centered hide-footer @hidden="cancelUpdateUsage">
            <text-loading v-show="isLoadingUsage" />
            <chart-resource v-show="!isLoadingUsage" ref="chartDatabase" theme="blue" :before-render="setChartOptions" :style="{ height:'300px' }" />
            <div class="text-center">
                <b-form-radio-group buttons button-variant="outline-primary" class="mt-6" v-model="chartPeriod" :disabled="isLoadingUsage" @input="changeChartPeriod">
                    <b-form-radio value="1">1 hora</b-form-radio>
                    <b-form-radio value="6">6 horas</b-form-radio>
                    <b-form-radio value="12">12 horas</b-form-radio>
                </b-form-radio-group>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import AjaxService       from '@/services/AjaxService'
    import Vuetable          from '@/components/atoms/Vuetable'
    import InputSearch       from '@/components/atoms/InputSearch'
    import ToggleSwitch      from '@/components/atoms/ToggleSwitch'
    import ButtonForm        from '@/components/atoms/ButtonForm'
    import ModalConfirm      from '@/components/atoms/ModalConfirm'
    import TextLoading       from '@/components/atoms/TextLoading'
    import AlertCompact      from "@/components/atoms/AlertCompact.vue";
    import CardTitle         from '@/components/molecules/CardTitle'
    import FormGroup         from '@/components/molecules/FormGroup'
    import TextCopy          from '@/components/molecules/TextCopy'
    import PasswordGenerator from '@/components/molecules/PasswordGenerator'
    import ChartResource     from '@/components/molecules/ChartResource'
    import CardTable         from '@/components/organisms/CardTable'

    export default {
        props: ['resource'],
        components: {
            AlertCompact,
            TextLoading,
            ChartResource,
            CardTable,
            InputSearch,
            ModalConfirm,
            PasswordGenerator,
            TextCopy,
            ButtonForm,
            FormGroup,
            Vuetable,
            ToggleSwitch,
            CardTitle
        },
        data() {
            return {
                tableDatabaseFields: [
                    {
                        name: '__slot:dbms',
                        sortField: 'dbms',
                        filterable: true,
                        title: 'Tipo',
                        titleClass: 'col-w-32',
                        dataClass: 'col-w-32',
                    },
                    {
                        name: '__slot:dbname',
                        sortField: 'dbname',
                        filterable: true,
                        title: 'Nome',
                    },
                    {
                        name: '__slot:user',
                        sortField: 'user',
                        filterable: true,
                        title: 'Usuário',
                    },
                    {
                        name: '__slot:size',
                        sortField: 'size',
                        title: 'Uso de Disco',
                        titleClass: 'col-w-32',
                        dataClass: 'col-w-32',
                    }
                ],

                dbTypes: [
                    { value: 'mysql', text: 'MySQL' },
                    { value: 'postgres', text: 'PostgreSQL' },
                ],

                chartSteps: {
                    1:  { step: 10, unit: 'minute' },
                    6:  { step: 30, unit: 'minute' },
                    12: { step:  1, unit: 'hour'   },
                },
                chartMaxLevels: [25,50,75,100,150,200,300,500],
                chartMax: 25,
                chartPeriod: "1",
                chartDatabase: "",

                databases: [],
                remote: {},
                dbInfo: {},
                linkPhpmyadmin: "",
                linkPhppgadmin: "",

                database: {},
                formErrors: {},
                itemToRemove: {},
                filterText: "",

                isLoadingDatabases: false,
                isLoadingRemoteAccess: false,
                isLoadingInfo: false,
                isLoadingUsage: false,

                updateChartTimeout: null,

                useApiUrlSimple: false,
                showFetchErrorMessage: false,
            }
        },
        computed: {
            ...mapState({
                hosting: state => state.hosting.hosting,
            }),
            modalDatabaseTitle: function() {
                return this.database.editing ? "Editar banco de dados" : "Novo banco de dados";
            },
            databasesApiUrl: function() {
                return this.useApiUrlSimple ? `/hosting/${this.resource}/database?simple=1` : `/hosting/${this.resource}/database`;
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.updateChartTimeout && clearTimeout(this.updateChartTimeout);
            this.$refs.cardDatabases.cancelFetch();
            AjaxService.cancel();
            next();
        },
        mounted()
        {
            this.getDatabaseInfo();
        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert: 'alert/error'
            }),

            /**
             * Obter nome do tipo de banco de dados.
             */
            _getDatabaseName(type)
            {
                const item = this.dbTypes.find(el => el.value === type);
                return item ? item.text : type;
            },

            onFetchError()
            {
                if (this.useApiUrlSimple) {
                    this.showFetchErrorMessage = true;
                }
                else {
                    this.useApiUrlSimple = true;
                    this.$refs.cardDatabases.fetchData();
                }
            },

            /**
             * Obter informações sobre os bancos de dados.
             */
            getDatabaseInfo()
            {
                this.isLoadingRemoteAccess = true;
                this.isLoadingInfo = true;

                this.$api.get(`/hosting/${this.resource}/database/info`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => {
                        this.dbInfo = response.data.data;
                        this.remote = response.data.data.remote;
                        this.remote.hosts = this.remote.hosts.join(", ");
                        this.linkPhpmyadmin = response.data.data.phpmyadmin;
                        this.linkPhppgadmin = response.data.data.phppgadmin;
                    })
                    .catch(error => {})
                    .then (() => this.isLoadingRemoteAccess = this.isLoadingInfo = false);
            },

            formDataTransform(data)
            {
                if (!data.isEditing) {
                    return { dbms: this.dbTypes[0].value };
                }

                return data;
            },

            apiSaveUrl(data, isEditing)
            {
                return !isEditing ?
                    `/hosting/${this.resource}/database` :
                    `/hosting/${this.resource}/database/${data.dbname}`;
            },

            /**
             * Excluir banco de dados.
             */
            removeDatabase(item)
            {
                const data = {
                    dbms:   item.dbms,
                    dbname: item.dbname
                };

                this.$api.post(`/hosting/${this.resource}/database/remove`, data)
                    .then (response => {})
                    .catch(error => {
                        error.message = 'Ocorreu um erro ao tentar excluir o banco de dados. Por favor, atualize sua página e tente novamente.';
                        this.showErrorAlert(error);
                    });
            },

            /**
             * Atualizar opção de acesso remoto.
             */
            updateRemoteAccess()
            {
                this.isLoadingRemoteAccess = true;

                this.$api.patch(`/hosting/${this.resource}/database/remote`, this.remote)
                    .then (response => {
                        this.remote = response.data.data;
                        this.remote.hosts = this.remote.hosts.join(", ");
                        this.successNotification({ message: 'Configuração atualizada!' });
                    })
                    .catch(error => this.showErrorAlert(error))
                    .then (() => this.isLoadingRemoteAccess = false);
            },

            setChartOptions(options)
            {
                options.scales.xAxes[0].time.stepSize = this.chartSteps[this.chartPeriod].step;
                options.scales.xAxes[0].time.unit     = this.chartSteps[this.chartPeriod].unit;

                options.scales.yAxes[0].ticks.max      = Math.max(Math.floor(this.chartMax * 1.1), this.chartMax + 5);
                options.scales.yAxes[0].ticks.stepSize = this.chartMax > 50 ? this.chartMax / 10 : 5;
                options.scales.yAxes[0].ticks.callback = function (value) { return value };

                options.tooltips.callbacks.label = function (tooltipItem, data) {
                    return 'Conexões: ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y || 0;
                };

                options.tooltips.filter = function (tooltipItem) {
                    return tooltipItem && tooltipItem.datasetIndex === 0 && Math.floor(tooltipItem.value) > 0;
                };

                options.tooltips.mode = "nearest";
                options.tooltips.position = "nearest";

                options.annotation.annotations[0] = {};

                return options;
            },

            showUsageModal(dbname)
            {
                this.isLoadingUsage = true;
                this.chartPeriod = "1";
                this.chartDatabase = dbname;

                this.updateUsage();

                this.$refs.modalUsage.show();
            },

            updateUsage()
            {
                this.cancelUpdateUsage();

                this.$api.get(`/hosting/${this.resource}/database/${this.chartDatabase}/usage`, {
                    params: { period: this.chartPeriod },
                    cancelToken: AjaxService.getCancelToken()
                })
                    .then (response => {
                        let dataConnections = [],
                            dataLimits = [],
                            max = 0,
                            limit = null,
                            i;

                        for (i in response.data.data)
                        {
                            dataConnections.push({ t: i, y: response.data.data[i].connections });
                            dataLimits.push({ t: i, y: limit });

                            if (response.data.data[i].connections > max) {
                                max = response.data.data[i].connections;
                            }

                            if (response.data.data[i].limit) {
                                limit = response.data.data[i].limit;
                                dataLimits = dataLimits.map(item => { return { t: item.t, y: (item.y || limit) }});

                                if (limit > max) {
                                    max = limit;
                                }
                            }
                        }

                        for (i = 0; i < this.chartMaxLevels.length; i++) {
                            if (max > this.chartMaxLevels[i]) {
                                this.chartMax = (i === this.chartMaxLevels.length-1 ? this.chartMaxLevels[i] : this.chartMaxLevels[i+1]);
                            }
                        }

                        this.$refs.chartDatabase.updateDataset(1, "fill", false);
                        this.$refs.chartDatabase.updateDataset(1, "borderColor", "rgb(210,43,43)");
                        this.$refs.chartDatabase.updateDataset(1, "borderWidth", 2);
                        this.$refs.chartDatabase.updateDataset(1, "pointRadius", 0);
                        this.$refs.chartDatabase.updateDataset(1, "lineTension", 0);

                        if (this.chartPeriod === "1") {
                            this.$refs.chartDatabase.updateDataset(0, "borderWidth", 1.5);
                            this.$refs.chartDatabase.updateDataset(0, "pointRadius", 0.5);
                        }
                        else {
                            this.$refs.chartDatabase.updateDataset(0, "borderWidth", 1);
                            this.$refs.chartDatabase.updateDataset(0, "pointRadius", 0);
                        }

                        this.$refs.chartDatabase.updateData([dataConnections, dataLimits]);
                    })
                    .finally(() => {
                        this.isLoadingUsage = false;
                        this.updateChartTimeout = setTimeout(() => this.updateUsage(), 60000);
                    });
            },

            changeChartPeriod()
            {
                this.isLoadingUsage = true;
                this.updateUsage();
            },

            cancelUpdateUsage()
            {
                this.updateChartTimeout && clearTimeout(this.updateChartTimeout);
            },
        }
    }
</script>