<template>
    <domain-template :resource="resource">
        <card-title title="Informações do Domínio" inner-body>
            <!--TODO: Alterar texto informativo-->
            <!--<p class="mb-6">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid doloribus fugiat ipsum maiores ullam
                voluptas, voluptates. Aperiam consectetur corporis debitis molestiae, nobis pariatur placeat, provident
                quidem ratione similique veritatis voluptatibus?
            </p>-->

            <text-loading v-show="isLoading" />

            <div class="table-list-wrapper">
                <table v-if="domain" v-show="!isLoading" class="table table-list table-sm mb-0">
                    <tbody>
                        <tr>
                            <th class="col-w-px col-nowrap">Status:</th>
                            <td v-if="domain.isPendingPayment">Aguardando Pagamento</td>
                            <td v-else>{{ domain.statusText }}</td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Data de registro:</th>
                            <td>{{ domain.registerDate | moment("DD/MM/YYYY") }}</td>
                        </tr>
                        <tr v-if="domain.expirationDate">
                            <th class="col-w-px col-nowrap">Data de expiração:</th>
                            <td>{{ domain.expirationDate | moment("DD/MM/YYYY") }}</td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Preço de renovação:</th>
                            <td>
                                {{ 'R$ ' + domain.renewPrice.toFixed(2).replace('.', ',') }}
                                <span v-if="domain.period > 1">({{ domain.period }} anos)</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-w-px col-nowrap">Bloqueio:</th>
                            <td>Ativado</td>
                        </tr>
                        <!--<tr>
                            <th class="col-w-px col-nowrap">Código EPP:</th>
                            <td>
                                <button type="button" class="btn btn-link p-0">Exibir</button>
                            </td>
                        </tr>-->
                    </tbody>
                </table>
            </div>

            <div v-if="domain" class="btn-toolbar mt-6" role="toolbar">
                <div v-if="domain.isPending" class="btn-group ml-auto" role="group">
                    <button type="button" class="btn btn-primary btn-action" @click="payPendingDomain">Pagar domínio</button>
                </div>
                <div v-if="domain.isRenewable" class="btn-group ml-auto" role="group">
                    <button-form ref="btnRenew" variant="success" class="btn-action" @click="$refs.modalRenew.show()">Renovar domínio</button-form>
                </div>
            </div>
        </card-title>

        <modal-confirm ref="modalRenew" title="Renovar domínio" confirm-text="Renovar" @confirm="renewDomain">
            <p>Deseja prosseguir com a renovação do domínio abaixo?</p>
            <table class="table table-sm table-borderless mb-0">
                <tr>
                    <th class="col-w-1 pl-0 pr-4">Domínio:</th>
                    <td>{{ domain.domain }}</td>
                </tr>
                <tr>
                    <th class="col-w-1 pl-0 pr-4">Período:</th>
                    <td>{{ domain.period }} {{ domain.period > 1 ? 'anos' : 'ano' }}</td>
                </tr>
                <tr>
                    <th class="col-w-1 pl-0 pr-4">Preço:</th>
                    <td>{{ 'R$ ' + domain.renewPrice.toFixed(2).replace('.', ',') }}</td>
                </tr>
            </table>
        </modal-confirm>
    </domain-template>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import AjaxService      from '@/services/AjaxService'
    import ButtonForm       from '@/components/atoms/ButtonForm'
    import TextLoading      from '@/components/atoms/TextLoading'
    import ModalConfirm     from '@/components/atoms/ModalConfirm'
    import CardTitle        from '@/components/molecules/CardTitle'
    import DomainTemplate   from '@/components/templates/DomainTemplate'

    export default {
        name: 'DomainInfo',
        props: ['resource'],
        components: {
            ButtonForm,
            ModalConfirm,
            TextLoading,
            DomainTemplate,
            CardTitle
        },
        data() {
            return {
                isLoading: false,
            }
        },
        computed: {
            ...mapState({
                domain: state => state.domain.data,
            })
        },
        beforeRouteLeave(to, from, next)
        {
            AjaxService.cancel();
            next();
        },
        mounted()
        {

        },
        methods: {
            ...mapActions({
                successNotification: 'notification/success',
                showErrorAlert: 'alert/error',
            }),

            payPendingDomain()
            {
                const queryOptions = {
                    order: this.domain.orderId,
                };

                setTimeout(() => this.$router.push({
                    path: '/payment/manual-recharge',
                    query: queryOptions
                }), 1000);
            },

            /**
             * Renovar domínio.
             */
            renewDomain()
            {
                this.$refs.btnRenew.setLoadingAndFocus();

                this.$api.post(`/domain/${this.resource}/renew`)
                    .then (response => {
                        this.successNotification({ message: 'Seu domínio foi renovado!' });
                        window.location.reload();
                    })
                    .catch(error => {
                        this.showErrorAlert(error);
                        this.$refs.btnRenew.setLoading(false);
                    });
            }
        }
    }
</script>
