import formatBytes from './_format-bytes'
import formatMoney from './_format-money'

export default
{
    install(Vue)
    {
        Vue.filter('formatBytes', formatBytes)
        Vue.filter('formatMoney', formatMoney)
    }
}
