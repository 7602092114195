<template>
    <tabs-pages :tabs="tabs">
        <slot></slot>
    </tabs-pages>
</template>

<script>
    import { mapState } from 'vuex'
    import TabsPages    from '@/components/organisms/TabsPages'

    export default {
        props: ['resource'],
        components: {
            TabsPages
        },
        data() {
            return {
                tabs: [],
            }
        },
        computed: {
            ...mapState('hosting', ['hosting']),

            isNotActive: function () {
                return !this.hosting || this.hosting.status !== 'Active';
            }
        },
        mounted()
        {
            this.$acl.onChange = () => { this.updateTabs(); };
            this.updateTabs();
        },
        methods: {
            /**
             *
             */
            updateTabs()
            {
                this.tabs = [
                    {
                        title: 'Status',
                        link: `/hosting/${this.resource}/domain`,
                        hidden: this.$acl.not.check('hasDomain'),
                    },
                    {
                        title: 'Editor de DNS',
                        link: `/hosting/${this.resource}/dns`,
                        hidden: this.$acl.not.check('hasDomain') || this.isNotActive,
                    },
                    {
                        title: 'Apontamentos',
                        link: `/hosting/${this.resource}/alias`,
                        hidden: this.$acl.not.check('hasDomain') || this.isNotActive,
                    },
                    {
                        title: 'Subdomínios',
                        link: `/hosting/${this.resource}/subdomain`,
                        hidden: this.$acl.not.check('hasDomain') || this.isNotActive,
                    },
                    {
                        title: 'Redirecionamentos',
                        link: `/hosting/${this.resource}/redirect`,
                        hidden: this.$acl.not.check('hasDomain') || this.isNotActive,
                    }
                ];
            }
        }
    }
</script>