const state = {
    items: [],
    currentId: 0,
};

function getTimeout(timeout) {
    return (typeof timeout === 'number') ? timeout : 3000;
}

const actions = {
    add({commit}, item) {
        commit('add', item);
        return item;
    },
    success({ dispatch }, { message, timeout }) {
        let item = { type: 'success', message: message, timeout: getTimeout(timeout) };
        return dispatch('add', item);
    },
    error({ dispatch }, { message, timeout }) {
        let item = { type: 'error', message: message, timeout: getTimeout(timeout) };
        return dispatch('add', item);
    },
    info({ dispatch }, { message, timeout }) {
        let item = { type: 'info', message: message, timeout: getTimeout(timeout) };
        return dispatch('add', item);
    },
    close({ commit }, id) {
        commit('close', id);
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    add(state, item) {
        ++state.currentId;
        item.id = state.currentId;
        state.items.push(item);
    },
    close(state, id) {
        const idx = state.items.findIndex(el => el.id === id);
        state.items.splice(idx, 1);
    },
    clear(state) {
        state.items = [];
    }
}

export const notification = {
    namespaced: true,
    state,
    actions,
    mutations
}
